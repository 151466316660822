import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import custompagesApiController from "../../services/custompages.service";
// import HandleError from "../HandleError";
// import HandleResponse from "../HandleResponse";

// export const CustompagesApi = createAsyncThunk("custompages/view", async (formValues, thunkAPI) => {
//   try {
//     const resposedata = await custompagesApiController
//       .view(formValues, thunkAPI)
//       .then((response) => HandleResponse(thunkAPI, response, "view"))
//       .catch((error) => HandleError(thunkAPI, error, "view"));
//     return resposedata;
//   } catch (error) {
//     const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//     return thunkAPI.rejectWithValue(message);
//   }
// });

const initialState = {
  istabview : "billingdetails",
  isopenCreditCardEditForm: "",
  isopenBillingDetailEditForm: "",
  isopenActivePlanInvoiceHistory: "",
  isInvoiceHistoryDetails:"",
  isopenUpdateCreditCard:"",
  ispaymenTabSelection:"planhistory",
  isApiStatus: {
    CustompagesApi: "",
  },
};

const packageplanSlice = createSlice({
  name: "packageplan",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenCreditCardEditForm: (state, action) => {
      state.isopenCreditCardEditForm = action.payload;
    },
    OpenBillingDetailEditForm: (state, action) => {
      state.isopenBillingDetailEditForm = action.payload;
    },
    OpenActivePlanInvoiceHistory: (state, action) => {
      state.isopenActivePlanInvoiceHistory = action.payload;
    },
    myplantabview: (state, action) => {
      state.istabview = action.payload;
    },
    paymenTabSelection: (state, action) => {
      state.ispaymenTabSelection = action.payload;
    },
    InvoiceHistoryDetails: (state, action) => {
      state.isInvoiceHistoryDetails = action.payload;
    },
    OpenUpdateCreditCard: (state, action) => {
      state.isopenUpdateCreditCard = action.payload;
    },
  },
//   extraReducers: {
//     [CustompagesApi.pending]: () => {},
//     [CustompagesApi.fulfilled]: (state, action) => {
//       state.isPages = action.payload;
//     },
//     [CustompagesApi.rejected]: () => {},
//   },
});
// Action creators are generated for each case reducer function
export const { reset, OpenCreditCardEditForm, OpenBillingDetailEditForm,myplantabview,OpenActivePlanInvoiceHistory,InvoiceHistoryDetails,OpenUpdateCreditCard,paymenTabSelection } = packageplanSlice.actions;
export default packageplanSlice.reducer;
