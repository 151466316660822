import moment from 'moment';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { DefaultCardRemoveApi, FreeTrialUpgradePackageTexts, StaffUpgradePackageTexts } from 'store/slices/packageSlice';
import config from "../../../config"
import { SmsUsesOptionsApi, smsDefaultCardSelectApi } from 'store/slices/smsusesSlice';
import { OpenSmsPurchase } from 'store/slices/settingSlice';

const Footer = () => {
    const { t } = useTranslation();
    let location = useLocation();
    const { pathname } = location;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const isActivePlan = useSelector((state) => state.salon.isActivePlan);
    const isDefaultCardSelect = useSelector((state) => state.smsuses.isDefaultCardSelect);
    const stripe_card_remove_id = isDefaultCardSelect && isDefaultCardSelect.id;
    const currentUser = auth.user;
    const is_trial_plan = currentUser.salon && currentUser.salon.is_trial_plan;
    const trial_plan_start_datetime = currentUser.salon && currentUser.salon.trial_plan_start_datetime && moment().format("YYYY-MM-DD HH:mm:ss");
    const trial_plan_end_datetime = currentUser.salon && currentUser.salon.trial_plan_end_datetime && moment(currentUser.salon.trial_plan_end_datetime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
    const remaining_trial_days = trial_plan_end_datetime && trial_plan_start_datetime && moment(currentUser.salon.trial_plan_end_datetime, "YYYY-MM-DD HH:mm:ss").diff(trial_plan_start_datetime, "days");
    const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
    const invoice_payment_failed_at = isActivePlan && isActivePlan.invoice_payment_failed_at ? moment(isActivePlan.invoice_payment_failed_at, "YYYY-MM-DD HH:mm:ss").diff(currentDate, "days") : "";

    useEffect(() => {
        dispatch(smsDefaultCardSelectApi());
    }, [])


    return (
        <React.Fragment>
            {pathname == "/plans" ? null : (
                <>
                    {is_trial_plan && (
                        <div className="trial-expire-calendar-plan">
                            <p className="me-2">{t("You have {{ remaining_trial_days }} days left in your trial", { remaining_trial_days: remaining_trial_days })}</p>
                            <a className="rounded plan-upgrade-calendar-btn p-2 cursor-pointer" onClick={() => {
                                navigate(config.basePath + "/plans");
                                dispatch(FreeTrialUpgradePackageTexts({ isfreeTrialTitleTexts: "Pick a plan to continue.", isfreeTrialdescriptionTexts: "Boost your business growth or add more staff with a plan upgrade." }));
                                dispatch(StaffUpgradePackageTexts({ isstaffTitleTexts: "", isstaffdescriptionTexts: "" }));
                            }}>
                                {t("Select a Plan")}
                            </a>
                        </div>
                    )}
                    {!is_trial_plan && invoice_payment_failed_at && (
                        <div className="trial-expire-calendar-plan fixed-bottom bg-primary d-flex align-items-center justify-content-center py-3">
                            <div className="container text-white">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-auto">
                                        <p className="me-2">
                                            <i className="fas fa-exclamation-triangle text-white me-2 mt-1"></i>
                                            {t("There was a problem with your Beauti subscription payment. Please update your payment method to keep your account active. Your account will be suspended in {{ expire_days }} days.", { expire_days: invoice_payment_failed_at })}
                                        </p>
                                    </div>
                                    <div className="col-md-auto d-flex justify-content-center align-items-center">
                                        <a className="rounded btn btn-light px-4 py-2 cursor-pointer" onClick={() => {
                                            dispatch(DefaultCardRemoveApi({ id: stripe_card_remove_id })).then(() => {
                                                dispatch(OpenSmsPurchase("open"));
                                                dispatch(SmsUsesOptionsApi());
                                                dispatch(smsDefaultCardSelectApi());
                                                setTimeout(() => {
                                                    navigate(config.basePath + "/settings?tab=SMSUsage");
                                                }, 500)
                                            })
                                        }}>
                                            {t("Update Payment")}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </React.Fragment>
    )
}

export default Footer;