import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import addanotherApiController from "../../services/addanother.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const AddAnotherServicesApi = createAsyncThunk("addanother/addanotherservices", async (formValues, thunkAPI) => {
  try {
    const resposedata = await addanotherApiController
      .services(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addanotherservices"))
      .catch((error) => HandleError(thunkAPI, error, "addanotherservices"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AddAnotherServicePriceApi = createAsyncThunk("addanother/addanotherserviceprice", async (formValues, thunkAPI) => {
  try {
    const resposedata = await addanotherApiController
      .serviceprice(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addanotherserviceprice"))
      .catch((error) => HandleError(thunkAPI, error, "addanotherserviceprice"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AddAnotherStaffOptionsApi = createAsyncThunk("addanother/addanotherstaffOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await addanotherApiController
      .staff(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addanotherstaffOptions"))
      .catch((error) => HandleError(thunkAPI, error, "addanotherstaffOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AddAnotherResourceOptionsApi = createAsyncThunk("addanother/addanotherresourceOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await addanotherApiController
      .addanotherserviceresourceitems(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addanotherresourceOptions"))
      .catch((error) => HandleError(thunkAPI, error, "addanotherresourceOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StaffAvailableTimeApi = createAsyncThunk("addanother/StaffAvailableTime", async (formValues, thunkAPI) => {
  try {
    const resposedata = await addanotherApiController
      .staffavailabletime(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "StaffAvailableTime"))
      .catch((error) => HandleError(thunkAPI, error, "StaffAvailableTime"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isAddAnother: [], //{ serviceprice: "", services: [], staff: [], duration: "", cost: "" }
  isAddAnotherServices: [],
  isAddAnotherServicePrice: [],
  isAddAnotherStaffOptions: [],
  isAddAnotherResourceOptions: [],
  isApiStatus: {
    AddAnotherServicesApi: "",
    AddAnotherServicePriceApi: "",
    AddAnotherResourceOptionsApi:""
  },
};

const addanotherSlice = createSlice({
  name: "addanother",
  initialState,
  reducers: {
    reset: () => initialState,
    AddAnotherData: (state, action) => {
      if (action.payload) {
        const { id, ...changes } = action.payload;
        const existingData = state.isAddAnother.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        } else {
          state.isAddAnother = [...state.isAddAnother, action.payload];
        }
      } else {
        state.isAddAnother = [];
      }
    },
    AddAnotherDataRemove: (state, action) => {
      const { id } = action.payload;
      state.isApiStatus = { ...state.isApiStatus, serviceDeleteApi: "succeeded" };
      const newisAddAnother = state.isAddAnother ? state.isAddAnother.filter((item) => item.id != id) : state.isAddAnother.filter((item) => item.id != id);
      state.isAddAnother = newisAddAnother.map((item, index) => ({ ...item, id: index + 1 }));
    },
  },
  extraReducers:(builder)=> {
    builder
    .addCase(AddAnotherServicesApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddAnotherServicesApi: "loading" };
    })
    .addCase(AddAnotherServicesApi.fulfilled, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddAnotherServicesApi: "succeeded" };
    })
    .addCase(AddAnotherServicesApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddAnotherServicesApi: "failed" };
      state.isAddAnotherServicePrice = [];
    })
    .addCase(AddAnotherServicePriceApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddAnotherServicePriceApi: "loading" };
    })
    .addCase(AddAnotherServicePriceApi.fulfilled, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddAnotherServicePriceApi: "succeeded" };
    })
    .addCase(AddAnotherServicePriceApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddAnotherServicePriceApi: "failed" };
      state.isAddAnotherServicePrice = [];
    })
    .addCase(AddAnotherStaffOptionsApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddAnotherStaffOptionsApi: "loading" };
    })
    .addCase(AddAnotherStaffOptionsApi.fulfilled, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddAnotherStaffOptionsApi: "succeeded" };
    })
    .addCase(AddAnotherStaffOptionsApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddAnotherStaffOptionsApi: "failed" };
    })
    .addCase(AddAnotherResourceOptionsApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddAnotherResourceOptionsApi: "loading" };
    })
    .addCase(AddAnotherResourceOptionsApi.fulfilled, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddAnotherResourceOptionsApi: "succeeded" };
    })
    .addCase(AddAnotherResourceOptionsApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddAnotherResourceOptionsApi: "failed" };
    })
    .addCase(StaffAvailableTimeApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableTimeApi: "loading" };
    })
    .addCase(StaffAvailableTimeApi.fulfilled, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableTimeApi: "succeeded" };
    })
    .addCase(StaffAvailableTimeApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableTimeApi: "failed" };
    });  
  },
});
// Action creators are generated for each case reducer function
export const { reset, AddAnotherData, AddAnotherDataRemove } = addanotherSlice.actions;
export default addanotherSlice.reducer;
