import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";
import moment from "moment";

const API_URL = config.API_URL;

const create = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    const action = "afterlogin/generallogin/store";
    formData.append("auth_key", auth_key);
    formData.append("action", action);
    return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const view = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const result = values && values.result ? values.result : "row_array";
    const action = `afterlogin/generallogin/view`;
    
    const data = {
      auth_key: auth_key,
      action: action,
      pagination: false, //true or false
      id: values && values.id ? values.id : "",
      result:result
    };
    return axios.post(API_URL + action, data, { headers: authHeader() });
  };

const generalLoginApiController = {
    create,
    view,
};

export default generalLoginApiController;