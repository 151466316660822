import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const emailverifys = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }
    const action = "afterlogin/salons/emailverification";
    formData.append("auth_key", auth_key);
    formData.append("action", action);
    formData.append("email", values.email);
    return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
  };

const businessEmailVerifyApiController = {
    emailverifys,
  };

export default businessEmailVerifyApiController;