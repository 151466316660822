import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import config from "../../../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import PaginationLoader from "component/PaginationLoader";
import { ClientMembershipListViewApi } from "store/slices/clientmembershipSlice";
import Moment from "react-moment";
import ApiStatus from "component/ApiStatus";
import moment from "moment";

const Membership = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.salonmodule.isAccess);
  const clientmembershipApiStatus = useSelector((state) => state.clientmembership.isApiStatus);
  const membershipViews = useSelector((state) => state.clientmembership.isListView);
  const detail = useSelector((state) => state.client.isDetailData);

  const fetchDataMembershipList = () => {
    dispatch(ClientMembershipListViewApi({ client_id: detail.id, next_page_url: membershipViews.next_page_url }));
  };
  const [isFetching, setIsFetching] = useState(false);
  const loadMoreItemsList = () => {
    setIsFetching(true);
    dispatch(ClientMembershipListViewApi({ next_page_url: membershipViews && membershipViews.next_page_url, client_id: detail.id }));
    //mocking an API call
    setTimeout(() => {
      setIsFetching(false);
    }, 2000);
  };

  return (
    <>
      <div className="drawer-header">
        <h2 className="mb-4 pe-md-5 mb-lg-5">{t("Package")}</h2>
      </div>
      <div className="content-wrp" id="membershiplist">
        {clientmembershipApiStatus && clientmembershipApiStatus.ClientMembershipListViewApi === "succeeded" && membershipViews && membershipViews.data && membershipViews.data.length > 0 ? (
          <InfiniteScroll className="row gx-0" dataLength={membershipViews.data && membershipViews.data.length ? membershipViews.data.length : "0"} next={fetchDataMembershipList} scrollableTarget="membershiplist" hasMore={membershipViews.next_page_url ? true : false} loader={<PaginationLoader />}>
            {Object.keys(membershipViews.data).map((item, i) => {
              let id = membershipViews.data[item].id;
              let membership = membershipViews.data[item].membership;
              let membership_name = membership && membership.name;
              let membership_credit = membership && membership.credit;
              // let cost = membershipViews.data[item].cost;
              //let client_id = membershipViews.data[item].client_id;
              //let note = membershipViews.data[item].note;
              let original_membership_data = membershipViews.data[item] &&  membershipViews.data[item].membership;
              let original_membership_amount = original_membership_data ? original_membership_data.cost : "$0";
              let original_membership_credit = original_membership_data ? original_membership_data.credit : "$0";
              let created_at = membershipViews.data[item].created_at ? moment(membershipViews.data[item].created_at,"YYYY-MM-DD HH:mm:ss").format("Do MMMM YYYY"):"";
              let membershipData = membershipViews.data[item] && membershipViews.data[item].membershipcreditmanage;
              let Purchase_amount = membershipData ? membershipData.cost : original_membership_data ? original_membership_amount : "$0";
              let credit_amount = membershipData ? membershipData.credit : original_membership_data ? original_membership_credit : "$0";;
              let remaining_amount = membershipData ? membershipData.pending_credit : original_membership_data ? original_membership_credit : "$0";
              return (
                <div className="text-decoration-none event-box-pink mb-3" href="" key={item && item.id ? item.id : i} data-id={id}>
                  <h6 className="mb-1 color-wine fw-semibold d-flex justify-content-between align-items-start">
                    {membership_name} <span className="active">{t("Active")}</span>
                  </h6>
                  <h6 className="mb-1 color-default">
                    {t("Date of purchase")} : {created_at}
                  </h6>
                  <div className="row">
                    <h6 className="col-md-6 mb-1"> {t("Purchase amount")}: </h6>
                    <h6 className="col-md-6 mb-1 text-end">${Purchase_amount} </h6>
                  </div>
                  <div className="row">
                    <h6 className="col-6 mb-1">{t("Credit")}:</h6>
                    <h6 className="col-6 mb-1 text-end">${credit_amount}</h6>
                  </div>
                  <div className="row">
                    <h6 className="col-6 mb-0 fw-semibold">Balance</h6>
                    <h6 className="col-6 mb-0 text-end fw-semibold">${remaining_amount}</h6>
                  </div>
                </div>
              );
            })}
            {!isFetching && membershipViews.next_page_url && (
              <div className="col-2 m-auto p-3 text-center">
                <button onClick={loadMoreItemsList} className="btn btn-primary">
                  {t("More")}
                </button>
              </div>
            )}
          </InfiniteScroll>
        ) : (
          <>
            {clientmembershipApiStatus &&
              clientmembershipApiStatus.ClientMembershipListViewApi === "loading" ? (
              <ApiStatus actionType="clientmembershipview" />
            ) : (
              <div className="complete-box text-center d-flex flex-column justify-content-center mt-xl-4">
                <div className="complete-box-wrp text-center">
                  <img src={config.imagepath + "nots.png"} alt="" className="mb-md-4 mb-3" />
                  <h5 className="mb-2 fw-semibold">{t("There are no packages available.")}</h5>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Membership;
