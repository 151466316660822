import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["formdata"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "afterlogin/form/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const update = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["formdata", "delete_form_element_id"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "afterlogin/form/update/" + values.id;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  let sortstring = "";
  if (sort) {
    let sortArray = [];
    Object.keys(sort).map(function (key, index) {
      return (sortArray[index] = `sort[${key}]=${sort[key]}`);
    });
    if (sortArray.length > 0) {
      let jsort = sortArray.join("&");
      sortstring = jsort;
    }
  }
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/form/view?page=${page}&${sortstring}` : `afterlogin/form/view?${sortstring}`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: pagination, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    option: values && values.option ? values.option : "",
  };
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const deleted = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/form/delete/${values.id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const formelementtype = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/form/formelementtype`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const preview = (values) => {
  const result = values && values.result ? values.result : "";
  const location = values && values.pathname;
  const action = `widget/formpreview`;
  const data = {
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    location: location,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};
const previewcreate = (values) => {
  
  const formData = new FormData();
  // for (let key in values) {
  //   if (values.hasOwnProperty(key)) {
  //     // Check if the value is an object and has a form_type property
  //     if (typeof values[key] === 'object' && values[key].hasOwnProperty('form_type')) {
  //       if (values[key].form_type === 'file') {
  //         // If it's a file type, append it to FormData
  //         formData.append(key, values[key], values[key].name);
  //       } else {
  //         // For other types, append them as strings
  //         formData.append(key, values[key].answer);
  //       }
  //     } else {
  //       // If it's not an object, append it directly
  //       formData.append(key, values[key]);
  //     }
  //   }
  // }
  for (let value in values) {
    if (["formpreview"].includes(value) && values[value] && typeof values[value] === "object") {
      values[value].forEach(item => {
        // Check if the item's form_type is "file"
        if (item.form_type === "file") {
          // Append the file to the FormData
          formData.append(item.name, item.answer);
        } else {
          // Append other form fields as JSON string
          formData.append(item.name, JSON.stringify(item.answer));
        }
      });
      // Append the formpreview array itself as a JSON string
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "widget/formpreview/store";
  // formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

// const previewcreate = (values) => {
//   const formData = new FormData();
//   for (let key in values) {
//     if (values.hasOwnProperty(key)) {
//       // Check if the current value is an array
//       if (Array.isArray(values[key])) {
//         // Handle arrays of objects
//         values[key].forEach(item => {
//           if (item.form_type === "file") {
//             // Append the File object with a custom name
//             formData.append(key, item.answer, item.name);
//           } else {
//             // Append other values directly
//             formData.append(key, item.answer);
//           }
//         });
//       } else {
//         // Append other values directly
//         formData.append(key, values[key]);
//       }
//     }
//   }
//   const action = "widget/formpreview/store";
//   formData.append("action", action);
//   console.log('formData: ', formData);
//   return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
// };


const formApiController = {
  create,
  update,
  view,
  deleted,
  formelementtype,
  preview,
  previewcreate,
};
export default formApiController;
