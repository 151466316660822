import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import appointmentApiController from "../../services/appointment.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const appointmentStoreApi = createAsyncThunk("appointment/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const appointmentPaymentConfirmApi = createAsyncThunk("appointmentpayment/confirm", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .appointmentpayconfirm(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "appointmentpaymentconfirm"))
      .catch((error) => HandleError(thunkAPI, error, "appointmentpaymentconfirm"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});
export const refundPaymentStoreApi = createAsyncThunk("refundpayment/store", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .refundpaymentstore(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "refundpaymentstore"))
      .catch((error) => HandleError(thunkAPI, error, "refundpaymentstore"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const refundPaymentFailedApi = createAsyncThunk("refundpayment/paymentfailed", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .refundpayfail(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "refundpaymentfailed"))
      .catch((error) => HandleError(thunkAPI, error, "refundpaymentfailed"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});
export const refundPaymentConfirmApi = createAsyncThunk("refundpayment/confirm", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .refundpayconfirm(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "refundpaymentconfirm"))
      .catch((error) => HandleError(thunkAPI, error, "refundpaymentconfirm"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const appointmentPaymentFailedApi = createAsyncThunk("appointmentpayment/paymentfailed", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .appointmentpayfail(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "appointmentpaymentfailed"))
      .catch((error) => HandleError(thunkAPI, error, "appointmentpaymentfailed"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const appointmentUpdateApi = createAsyncThunk("appointment/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const appointmentRescheduleApi = createAsyncThunk("appointment/reschedule", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .reschedule(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "reschedule"))
      .catch((error) => HandleError(thunkAPI, error, "reschedule"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const appointmentListViewApi = createAsyncThunk("appointment/listview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "listview"))
      .catch((error) => HandleError(thunkAPI, error, "listview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientAppointmentListViewApi = createAsyncThunk("appointment/clientappointmentlistview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "clientappointmentlistview"))
      .catch((error) => HandleError(thunkAPI, error, "clientappointmentlistview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const appointmentOptions = createAsyncThunk("appointment/appointmentOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "appointmentOptions"))
      .catch((error) => HandleError(thunkAPI, error, "appointmentOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const appointmentDetailApi = createAsyncThunk("appointment/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const appointmentDeleteApi = createAsyncThunk("appointment/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const appointmentSuggetionListApi = createAsyncThunk("appointment/suggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .suggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AppointmentAutofillserviceOptionsApi = createAsyncThunk("service/autoserviceOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .appointmentautofillserviceoptions(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "autoserviceOptions"))
      .catch((error) => HandleError(thunkAPI, error, "autoserviceOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AppointmentResourceItemsApi = createAsyncThunk("appointment/resourceitem", async (formValues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .appointmentresourceitems(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "appointmentresourceitem"))
      .catch((error) => HandleError(thunkAPI, error, "appointmentresourceitem"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientInvoiceSaleCartApi = createAsyncThunk("appointment/salecartsdata", async (formValues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .salecarts(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "salecartsdata"))
      .catch((error) => HandleError(thunkAPI, error, "salecartsdata"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const AppointmentAutoFillResourceItemsApi = createAsyncThunk("appointment/autofillresourceitem", async (formValues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .appointmentresourceitems(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "appointmentautofillresourceitem"))
      .catch((error) => HandleError(thunkAPI, error, "appointmentautofillresourceitem"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AppointmentAutoFillStaffOptionsApi = createAsyncThunk("appointment/autofillstaffoption", async (formValues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .appointmentstaffoptions(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "appointmentautofillstaffoption"))
      .catch((error) => HandleError(thunkAPI, error, "appointmentautofillstaffoption"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookNextAvailableResourceApi = createAsyncThunk("appointment/nextavailableresource", async (formValues, thunkAPI) => {
  try {
    const resposedata = await appointmentApiController
      .resourcenextavailable(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "nextavailableresource"))
      .catch((error) => HandleError(thunkAPI, error, "nextavailableresource"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedRescheduleForm: "",
  isOpenedDetailModal: "",
  isListView: [],
  isClientAppointmentListView: [],
  isAppointmentPaymentFailedApi: [],
  isAppointmentPaymentConfirmApi: [],
  isDetailData: "",
  isFilter: "",
  isAddOnServiceTab: "",
  isDataLoading: false,
  isOpenAppointmentAutoFillModal: "",
  isAppointmentAutofillData: "",
  isAppointmentAutoFillFinalData: "",
  isAppointmentAvailableTimeLoader: false,
  isAppointmentAvailableTime: "",
  isAppointmentAutofillserviceOptions: [],
  isOpenInvoiceStripePayModal: "",
  isOpenDepositPayTab: true,
  iscalendarAddFormSetActivebtnState: "",
  isServiceDepositData: "",
  isStripeTokenAppointment: "",
  isOpenAppointmentRefundModal: "",
  isGetCancelledAppointmentData: "",
  isOpenAppointmentcancelRefundStripe: "",
  isGetAppointmentcancelRefundStripe: "",
  isEditAppointmentFormData: "",
  isOpenEditAppointmentRefunds: "",
  isCancellationReason: "",
  isRangeInfoEdits: "",
  isCalendarEditFormSetActivebtnState: "",
  isdepositBalance: "",
  isCancelDepositBalance: "",
  isAddOnServiceButtonRender: false,
  isBookNextModalOpen: false,
  isOpenInvoiceStripePayModal: "",
  isOpenDepositPayTab: true,
  iscalendarAddFormSetActivebtnState: "",
  isServiceDepositData: "",
  isStaffBusinessHourArrays: [],
  isAppointmentResourceItems: [],
  isClientInvoiceSaleCart: [],
  isBookNextAvailableResource: [],
  isApiStatus: { appointmentStore: "", appointmentUpdate: "", appointmentReschedule: "", appointmentListViewApi: "", ClientAppointmentList: "", appointmentDetail: "", appointmentDelete: "" },
  isApiStatus: {
    AppointmentAutofillserviceOptionsApi: "",
    appointmentStoreApi: "",
    appointmentUpdateApi: "",
    appointmentRescheduleApi: "",
    appointmentListViewApi: "",
    ClientAppointmentListViewApi: "",
    appointmentDetailApi: "",
    appointmentDeleteApi: "",
    appointmentPaymentConfirmApi: "",
    appointmentPaymentFailedApi: "",
    refundPaymentStoreApi: "",
    refundPaymentFailedApi: "",
    refundPaymentConfirmApi: "",
    appointmentPaymentConfirmApi: "",
    appointmentPaymentStoreApi: "",
    appointmentPaymentFailedApi: "",
    AppointmentResourceItemsApi: "",
    ClientInvoiceSaleCartApi: "",
    BookNextAvailableResourceApi: ""
  },
};

const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    reset: () => initialState,
    AddAppointmentForm: (state, action) => {
      state.isOpenedEditForm = "";
      state.isOpenedRescheduleForm = "";
      state.isOpenedAddForm = action.payload;
    },
    EditAppointmentForm: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = action.payload;
      state.isOpenedRescheduleForm = "";
    },
    RescheduleAppointmentForm: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedRescheduleForm = action.payload;
    },
    AppointmentDetailModal: (state, action) => {
      state.isOpenedDetailModal = action.payload;
    },
    openAppointmentFilter: (state, action) => {
      state.isFilter = action.payload;
    },
    ShowLoadingSpinner: (state, action) => {
      state.isDataLoading = action.payload;
    },
    closeAppointmentFilter: (state) => {
      state.isFilter = "";
    },
    AddOnServiceTab: (state, action) => {
      // if (newTodo.trim() === '') return;
      state.isAddOnServiceTab = [...state.isAddOnServiceTab, action.payload];
    },
    OpenAppointmentAutoFillModal: (state, action) => {
      state.isOpenAppointmentAutoFillModal = action.payload;
    },
    AppointmentAutofillData: (state, action) => {
      state.isAppointmentAutofillData = action.payload;
    },
    AppointmentAutoFillFinalData: (state, action) => {
      state.isAppointmentAutoFillFinalData = action.payload;
    },
    AppointmentAvailableTimeLoader: (state, action) => {
      state.isAppointmentAvailableTimeLoader = action.payload;
    },
    AppointmentAvailableTime: (state, action) => {
      state.isAppointmentAvailableTime = action.payload;
    },
    OpenInvoiceStripePayModal: (state, action) => {
      state.isOpenInvoiceStripePayModal = action.payload;
    },
    OpenDepositPayTab: (state, action) => {
      state.isOpenDepositPayTab = action.payload;
    },
    CalendarAddFormSetActivebtnState: (state, action) => {
      state.iscalendarAddFormSetActivebtnState = action.payload;
    },
    serviceDepositData: (state, action) => {
      state.isServiceDepositData = action.payload;
    },
    GetStripeTokenAppointment: (state, action) => {
      state.isStripeTokenAppointment = action.payload;
    },
    OpenAppointmentRefundModal: (state, action) => {
      state.isOpenAppointmentRefundModal = action.payload;
    },
    GetCancelledAppointmentData: (state, action) => {
      state.isGetCancelledAppointmentData = action.payload;
    },
    OpenAppointmentcancelRefundStripe: (state, action) => {
      state.isOpenAppointmentcancelRefundStripe = action.payload;
    },
    GetAppointmentcancelRefundStripe: (state, action) => {
      state.isGetAppointmentcancelRefundStripe = action.payload;
    },
    EditAppointmentFormData: (state, action) => {
      state.isEditAppointmentFormData = action.payload;
    },
    OpenEditAppointmentRefunds: (state, action) => {
      state.isOpenEditAppointmentRefunds = action.payload;
    },
    CalendarEditFormSetActivebtnState: (state, action) => {
      state.isCalendarEditFormSetActivebtnState = action.payload;
    },
    CancellationReason: (state, action) => {
      state.isCancellationReason = action.payload.selectedCancellationReason;
      state.isRangeInfoEdits = action.payload.isRangeInfo;
      state.isdepositBalance = action.payload.depositBalance;
    },
    CancelDepositBalance: (state, action) => {
      state.isCancelDepositBalance = action.payload;
    },
    AddOnServiceButtonRender: (state, action) => {
      state.isAddOnServiceButtonRender = action.payload;
    },
    ClearAppointmentDetailData: (state) => {
      state.isDetailData = [];
    },
    StaffBusinessHourArrays: (state, action) => {
      state.isStaffBusinessHourArrays = action.payload;
    },
    AppointmentResourceItemsClears: (state) => {
      state.isAppointmentResourceItems = [];
    },
    BookNextModalOpen: (state, action) => {
      state.isBookNextModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(appointmentStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentStore: "loading" };
      })
      .addCase(appointmentStoreApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentStore: "succeeded" };
        // if (state.isListView && state.isListView.data) {
        //   state.isListView.data = [action.payload, ...state.isListView.data];
        // } else {
        //   state.isListView = { data: [action.payload] };
        // }
      })
      .addCase(appointmentStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentStore: "failed" };
      })
      .addCase(appointmentUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentUpdate: "loading" };
      })
      .addCase(appointmentUpdateApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentUpdate: "succeeded" };
      })
      .addCase(appointmentUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentUpdate: "failed" };
      })
      .addCase(appointmentRescheduleApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentReschedule: "loading" };
      })
      .addCase(appointmentRescheduleApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentReschedule: "succeeded" };
      })
      .addCase(appointmentRescheduleApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentReschedule: "failed" };
      })
      .addCase(refundPaymentStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, refundPaymentStoreApi: "loading" };
      })
      .addCase(refundPaymentStoreApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, refundPaymentStoreApi: "succeeded" };
      })
      .addCase(refundPaymentStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, refundPaymentStoreApi: "failed" };
      })
      .addCase(refundPaymentFailedApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, refundPaymentFailedApi: "loading" };
      })
      .addCase(refundPaymentFailedApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, refundPaymentFailedApi: "succeeded" };
      })
      .addCase(refundPaymentFailedApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, refundPaymentFailedApi: "failed" };
      })
      .addCase(refundPaymentConfirmApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, refundPaymentConfirmApi: "loading" };
      })
      .addCase(refundPaymentConfirmApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, refundPaymentConfirmApi: "succeeded" };
      })
      .addCase(refundPaymentConfirmApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, refundPaymentConfirmApi: "failed" };
      })
      .addCase(appointmentListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentListViewApi: "loading" };
      })
      .addCase(AppointmentAutofillserviceOptionsApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentAutofillserviceOptionsApi: "loading" };
      })
      .addCase(AppointmentAutofillserviceOptionsApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentAutofillserviceOptionsApi: "succeeded" };
        state.isAppointmentAutofillserviceOptions = action.payload;
      })
      .addCase(AppointmentAutofillserviceOptionsApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentAutofillserviceOptionsApi: "failed" };
        state.isAppointmentAutofillserviceOptions = [];
      })
      .addCase(appointmentListViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isListView.current_page ? state.isListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isListView && state.isListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, appointmentListViewApi: "succeeded" };
        state.isListView = action.payload;
      })
      .addCase(appointmentListViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentListViewApi: "failed" };
        state.isListView = [];
      })
      .addCase(ClientAppointmentListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientAppointmentListViewApi: "loading" };
      })
      .addCase(ClientAppointmentListViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isClientAppointmentListView.current_page ? state.isClientAppointmentListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isClientAppointmentListView && state.isClientAppointmentListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isClientAppointmentListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isClientAppointmentListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, ClientAppointmentListViewApi: "succeeded" };
        state.isClientAppointmentListView = action.payload;
      })
      .addCase(ClientAppointmentListViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientAppointmentListViewApi: "failed" };
        state.isClientAppointmentListView = [];
      })
      .addCase(appointmentDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentDetail: "loading" };
      })
      .addCase(appointmentDetailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentDetail: "succeeded" };
        state.isDetailData = action.payload;
      })
      .addCase(appointmentDetailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentDetail: "failed" };
        state.isDetailData = "";
      })
      .addCase(appointmentDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentDelete: "loading" };
      })
      .addCase(appointmentDeleteApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentDelete: "succeeded" };
      })
      .addCase(appointmentDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentDelete: "failed" };
      })
      .addCase(appointmentPaymentConfirmApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentPaymentConfirmApi: "loading" };
      })
      .addCase(appointmentPaymentConfirmApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentPaymentConfirmApi: "succeeded" };
        state.isAppointmentPaymentConfirmApi = action.payload;
      })
      .addCase(appointmentPaymentConfirmApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentPaymentConfirmApi: "failed" };
        state.isAppointmentPaymentConfirmApi = [];
      })
      .addCase(appointmentPaymentFailedApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentPaymentFailedApi: "loading" };
      })
      .addCase(appointmentPaymentFailedApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentPaymentFailedApi: "succeeded" };
        state.isAppointmentPaymentFailedApi = action.payload;
      })
      .addCase(appointmentPaymentFailedApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, appointmentPaymentFailedApi: "failed" };
        state.isAppointmentPaymentFailedApi = [];
      })
      .addCase(AppointmentResourceItemsApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentResourceItemsApi: "loading" };
      })
      .addCase(AppointmentResourceItemsApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentResourceItemsApi: "succeeded" };
        state.isAppointmentResourceItems = action.payload;
      })
      .addCase(AppointmentResourceItemsApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentResourceItemsApi: "failed" };
        state.isAppointmentResourceItems = [];
      })
      .addCase(ClientInvoiceSaleCartApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientInvoiceSaleCartApi: "loading" };
      })
      .addCase(ClientInvoiceSaleCartApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, ClientInvoiceSaleCartApi: "succeeded" };
        state.isClientInvoiceSaleCart = action.payload;
      })
      .addCase(ClientInvoiceSaleCartApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientInvoiceSaleCartApi: "failed" };
        state.isClientInvoiceSaleCart = [];
      })
      .addCase(BookNextAvailableResourceApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookNextAvailableResourceApi: "loading" };
      })
      .addCase(BookNextAvailableResourceApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookNextAvailableResourceApi: "succeeded" };
        state.isBookNextAvailableResource = action.payload;
      })
      .addCase(BookNextAvailableResourceApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookNextAvailableResourceApi: "failed" };
        state.isBookNextAvailableResource = [];
      });
  },
});
// Action creators are generated for each case reducer function
export const {
  reset,
  AddAppointmentForm,
  EditAppointmentForm,
  AppointmentDetailModal,
  openAppointmentFilter,
  closeAppointmentFilter,
  RescheduleAppointmentForm,
  AddOnServiceTab,
  ShowLoadingSpinner,
  OpenAppointmentAutoFillModal,
  AppointmentAutofillData,
  AppointmentAutoFillFinalData,
  AppointmentAvailableTimeLoader,
  AppointmentAvailableTime,
  OpenInvoiceStripePayModal,
  OpenDepositPayTab,
  CalendarAddFormSetActivebtnState,
  serviceDepositData,
  GetStripeTokenAppointment,
  OpenAppointmentRefundModal,
  GetCancelledAppointmentData,
  OpenAppointmentcancelRefundStripe,
  GetAppointmentcancelRefundStripe,
  EditAppointmentFormData,
  OpenEditAppointmentRefunds,
  CancellationReason,
  CalendarEditFormSetActivebtnState,
  CancelDepositBalance,
  AddOnServiceButtonRender,
  ClearAppointmentDetailData,
  StaffBusinessHourArrays,
  AppointmentResourceItemsClears,
  BookNextModalOpen
} = appointmentSlice.actions;
export default appointmentSlice.reducer;
