import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import InputMask from "react-input-mask";
import config from "../../config";
import { selectImage, removeImage } from "../../store/slices/imageSlice";
import CustomSelect from "../../component/form/CustomSelect";
import { Field } from "formik";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
// import moment from "moment";
import { DatalistInput, includesValueFilter, startsWithValueFilter, useComboboxControls } from "react-datalist-input";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import { CanvaTemplateSelected } from "store/slices/canvaTemplateSlice";
import { InputGroup } from "react-bootstrap";
import { GetAvailableTime, GetTotalDurationWithPadding } from "./GetAvailableTime";
import { AppointmentResourceItemsApi, AppointmentResourceItemsClears } from "store/slices/appointmentSlice";

function LoaderAnimation() {
  return <Spinner animation="grow" />;
}

const FloatLabelInputField = ({ label, controlId, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <FloatingLabel controlId={controlId} label={label} className="">
        <Form.Control {...field} {...props} isInvalid={meta.touched && !!meta.error} />
        {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
      </FloatingLabel>
    </>
  );
};

const InputField = ({ label, controlId, ...props }) => {
  const [field, meta] = useField(props);
  const name = props.name;
  // var arr = name.match(/\b(service_price)\b/g) ? name.match(/(?<=\[)[^\][]*(?=])/g) : "";
  var arr = name.match(/\b(service_price)\b/g) ? name.match(/(?:\[)[^\][]*(?=])/g) : "";
  var arr2 = name.match(/\b(addonservices)\b/g) ? name.split(".") : "";
  const checkname = arr.length == 2 && (arr.includes("price") || arr.includes("add_on_price")) && ["service_price[" + arr[0] + "][price]", "service_price[" + arr[0] + "][add_on_price]"].includes(name);
  const checkname2 = arr2.length == 3 && (arr2.includes("addonservices")) && ["addonservices." + arr2[1] + ".cost"].includes(name);
  if (checkname2 || checkname || ["amount", "price", "deposit_booked_price", "cost", "credit", "cost_price", "retail_price","one_off_amount"].includes(name)) {
    return (
      <>
        <Form.Group className="" controlId={controlId}>
          <Form.Label>{label}</Form.Label>
          <div className="searchbox-square price">
            <i className="fas fa-dollar-sign"></i>
            {field.name === "phone_number" || field.name === "business_phone_number" || field.name === "duration" ? <Form.Control as={InputMask} {...field} {...props} isInvalid={meta.touched && !!meta.error} /> : <Form.Control {...field} {...props} isInvalid={meta.touched && !!meta.error} />}
            {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
          </div>
        </Form.Group>
      </>
    );
  } else {
    return (
      <>
        <Form.Group className="" controlId={controlId}>
          <Form.Label>{label}</Form.Label>
          {field.name === "phone_number" || field.name === "business_phone_number" || field.name === "duration" ? <Form.Control as={InputMask} {...field} {...props} isInvalid={meta.touched && !!meta.error} /> : <Form.Control {...field} {...props} isInvalid={meta.touched && !!meta.error} />}
          {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
        </Form.Group>
      </>
    );
  }
};
const InputGroupField = ({ label, controlId, leftSpan, rightSpan, ...props }) => {
  const [field, meta] = useField(props);
  // const name = props.name;
  // var arr = name.match(/\b(service_price)\b/g) ? name.match(/(?<=\[)[^\][]*(?=])/g) : "";
  // const checkname = arr.length == 2 && (arr.includes("price") || arr.includes("add_on_price")) && ["service_price[" + arr[0] + "][price]", "service_price[" + arr[0] + "][add_on_price]"].includes(name);
  return (
    <>
      <Form.Group className="" controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <InputGroup className="">
          {leftSpan && (
            <>
              {leftSpan.onClick && (
                <InputGroup.Text id={leftSpan.id ? leftSpan.id : ""} className={leftSpan.class ? leftSpan.class : ""} onClick={rightSpan.onClick}>
                  {leftSpan.text ? leftSpan.text : ""}
                </InputGroup.Text>
              )}
              {leftSpan.onChange && (
                <InputGroup.Text id={leftSpan.id ? leftSpan.id : ""} className={leftSpan.class ? leftSpan.class : ""} onChange={rightSpan.onChange}>
                  {leftSpan.text ? leftSpan.text : ""}
                </InputGroup.Text>
              )}
            </>
          )}
          <Form.Control {...field} {...props} isInvalid={meta.touched && !!meta.error} />
          {rightSpan && (
            <>
              {rightSpan.onClick && (
                <InputGroup.Text id={rightSpan.id ? rightSpan.id : ""} className={rightSpan.class ? rightSpan.class : ""} onClick={rightSpan.onClick}>
                  {rightSpan.text ? rightSpan.text : ""}
                </InputGroup.Text>
              )}
              {rightSpan.onChange && (
                <InputGroup.Text id={rightSpan.id ? rightSpan.id : ""} className={rightSpan.class ? rightSpan.class : ""} onChange={rightSpan.onChange}>
                  {rightSpan.text ? rightSpan.text : ""}
                </InputGroup.Text>
              )}
            </>
          )}
        </InputGroup>
        {meta.touched && (
          <Form.Control.Feedback type="invalid" className="d-block">
            {meta.error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};
const InputPickerField = ({ label, controlId , ...props}) => {
  const [field, meta] = useField(props);
  const dispatch = useDispatch();
  const items = props.options;
  const className = props.className;
  const formik = props.formikData;
  const slotTime = props.slotTime;
  const availableTimeLoading = useSelector((state) => state.staff.isApiStatus);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const { value, setValue } = useComboboxControls({ initialValue: "" }); // Same as: const [value, setValue] = useState("Chocolate");
  const { setFieldValue, setFieldTouched } = useFormikContext();
  let getstyle = props.style !== undefined ? props.style : {};
  const [eventKeyState, setEventKeyState] = useState("");
  // const { isExpanded, setIsExpanded, setValue, value } = useComboboxControls({ initialValue: "", isExpanded: true });
  // const [selectedItems, setSelectedItems] = useState([]);
  const customFilter = useCallback((options, value) => {
    const testElements = document.getElementsByClassName("react-datalist-input__listbox-option");
    var total = 0;
    let matchstr = [];
    Array.prototype.filter.call(testElements, (testElement, i) => {
      const str = testElement.getAttribute("id");
      if (str.search(value) + 1) {
        matchstr.push({ id: i, time: str });
      }
    });
    if (matchstr.length > 0) {
      Array.prototype.filter.call(testElements, (testElement, i) => {
        if (i < matchstr[0].id) {
          total += testElement.offsetHeight;
        }
      });
      var myCustomScrollbart = document.querySelector(".react-datalist-input__listbox");
      if (myCustomScrollbart) {
        myCustomScrollbart.scrollTop = total;
      }
    }
    return options;
  });
  // Custom filter: Filter displayed items based on previous selections
  const filters = [(items) => items, customFilter];
  // const test = (e) => {
  // }
  return (
    <>
      {/* <Form.Group className="" controlId={controlId}> */}
      <DatalistInput
        style={getstyle}
        placeholder={availableTimeLoading && availableTimeLoading.StaffAvailableTimeApi === "loading" ? "Loading..." : ""}
        value={props.value ? moment(props.value, "HH:mm").format("hh:mma") : ""}
        onSelect={(item) => {
          setValue(item.value);
          setFieldValue(field.name, moment(item.value, "h:mma").format("HH:mm"));
          setFieldTouched(field.name, false);
          dispatch(AppointmentResourceItemsClears());
         
          const start_time = moment(item.value, "h:mma").format("HH:mm");
          if (controlId === "busytimeForm-start_time" && formik) {
            formik.setFieldValue('end_time', "", false);
          }
          formik.values.start_time = start_time
          if(formik && formik.values && (formik.values.formtype == "edit_busytime" || formik.values.formtype == "add_busytime")){
            GetAvailableTime({
              ...formik.values,
              start_time:start_time
          },dispatch);
          }
          if(formik && formik.values && (formik.values.form_types == "add_appointment" || formik.values.form_types == "edit_appointment")){
            formik.setFieldValue("resource_item_id", "", true);
            const duration = formik.values.duration ? formik.values.duration : "";
            const durationWithPadding = formik.values.durationWithPadding ? formik.values.durationWithPadding : "";

            const parentAppointmentEndTime = moment(formik.values.dateof, 'dddd, DD MMMM YYYY').format('YYYY-MM-DD ' + start_time + ':00');

            let formName = "";
            if (formik.values && formik.values.form_types) {
              formName = formik.values.form_types === "add_appointment" ? "appointmentForm/add/start_time" : (formik.values.form_types === "edit_appointment" ? "appointmentForm/edit/start_time" : "")
            }
            
            GetAvailableTime({
              ...formik.values,
              dateof: formik.values.dateof,
              duration,
              durationWithPadding,
              parentAppointmentEndTime: parentAppointmentEndTime,
              listview: 'BusyTime',
              availableBusyTime: "end",
              isDurationCheck: true,
              formName,
              listview: "Appointment"
            }, dispatch);
          
          const dateOfFormatted = moment(formik.values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD");
          const dateTimeString = start_time && dateOfFormatted ? `${dateOfFormatted} ${start_time + ":00"}` : "";
          
          if (formik.values && formik.values.form_types) {
            formName = formik.values.form_types === "add_appointment" ? "appointmentForm/add/start_time" : (formik.values.form_types === "edit_appointment" ? "appointmentForm/edit/start_time" : "")
          }
          const totalDurationWithPadding = GetTotalDurationWithPadding(formik.values.durationWithPadding, formik.values.addonservices, formik.values.addanotherservices)
          const obj = {
            appointment_id: formik.values.form_types && formik.values.form_types === "edit_appointment" && formik.values.id ? formik.values.id : "",
            busytime_id: "",
            dateof: formik.values.dateof ? formik.values.dateof : "",
            start_datetime: dateTimeString,
            duration: formik.values.duration ? formik.values.duration : "",
            padding_time: formik.values.padding_time ? formik.values.padding_time : "",
            durationWithPadding: formik.values.durationWithPadding ? formik.values.durationWithPadding : "",
            totalDurationWithPadding: totalDurationWithPadding && totalDurationWithPadding.totalHours,
            listview: "Appointment",
            salon_id: currentUser.salon_id,
            service_id: formik.values.service_id ? formik.values.service_id : "",
            staff_id: formik.values.staff_id ? formik.values.staff_id : "",
            resource_item_id: formik.values.resource_item_id ? formik.values.resource_item_id : "",
            uid: 1,
            formtype: "appointmentForm",
            formName
          }
          dispatch(AppointmentResourceItemsApi({
            option: { valueField: "id", labelField: "resource_item" },
            service_id: formik.values.service_id ? formik.values.service_id : "",
            availebleTimeObj: obj
          }));

          }
            // const durationFormatted = moment.utc(slotTime * 60000).format("HH:mm");
        }}
        label={label}
        items={items}
        // filters={[(items) => items, test]}
        filters={filters}
        labelProps={{ className: "form-label" }}
        inputProps={{ name: props.name, id: controlId, className: (className ? className + " " : "form-control ") + (meta.touched && !!meta.error ? "is-invalid" : ""), onChange: props.onChange }}
      />
      {meta.touched && (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          {meta.error}
        </Form.Control.Feedback>
      )}
      {/* </Form.Group> */}
      {/* <Form.Group className="" controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control {...field} {...props} list={controlId + "_list"} isInvalid={meta.touched && !!meta.error} />
        <datalist id={controlId + "_list"}>
          {options.map((item, index) => {
            return <option key={index} value={item.value} />;
          })}
        </datalist>
        {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
      </Form.Group> */}
    </>
  );
};

const InlineInputField = ({ label, controlId, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="d-flex">
        {label ? <Form.Label className="mb-0 me-3">{label}</Form.Label> : ""}
        {field.name === "phone_number" || field.name === "business_phone_number" || field.name === "duration" ? <Form.Control as={InputMask} {...field} {...props} isInvalid={meta.touched && !!meta.error} id={controlId} /> : <Form.Control {...field} {...props} isInvalid={meta.touched && !!meta.error} id={controlId} />}
      </div>
      {meta.touched && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {meta.error}
        </Form.Control.Feedback>
      )}
    </>
  );
};

const TextareaField = ({ label, controlId, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Form.Group className="" controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        {field.name === "appointment_times_description" || field.name === "cancellation_description" || controlId === "subscriptionForm-terms_and_conditions" || controlId === "settingbooklink-cancellation_policy" ? <Form.Control as="textarea" rows={6} {...field} {...props} isInvalid={meta.touched && !!meta.error} /> : field.name === "clientForm-message" ? <Form.Control as="textarea" className="new-class-name-checks" rows={3} {...field} {...props} isInvalid={meta.touched && !!meta.error} style={{ backgroundColor: "red" }} /> : <Form.Control as="textarea" rows={3} {...field} {...props} isInvalid={meta.touched && !!meta.error} />}
        {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
      </Form.Group>
    </>
  );
};

const SelectField = ({ label, controlId, options, ...props }) => {
  const [field, meta] = useField(props);
  let List =
    options && Array.isArray(options) &&
    options.length > 0 &&
    options.map((item, i) => {
      return (
        <option key={i} value={item.value}>
          {item.label}
        </option>
      );
    });
  return (
    <>
      <Form.Group className="" controlId={controlId}>
        {label === "0" ? "" : <Form.Label>{label}</Form.Label>}
        <Form.Control as="select" {...field} {...props} isInvalid={meta.touched && !!meta.error}>
          {props.placeholder && (
            <option key="0" value="">
              {props.placeholder}
            </option>
          )}
          {List}
        </Form.Control>
        {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
      </Form.Group>
    </>
  );
};
const CheckboxField = ({ label, controlId, ...props }) => {
  const [field] = useField(props);
  const checked = field.value === 1 ? true : "";
  return (
    <>
      <Form.Group className="" controlId={controlId}>
        {props.bsPrefix && props.bsPrefix !== undefined ? (
          <Form.Check type={"checkbox"} id={controlId} bsPrefix={props.bsPrefix}>
            <Form.Check.Input {...field} {...props} label={label} checked={checked} />
            <Form.Check.Label>{label}</Form.Check.Label>
          </Form.Check>
        ) : (
          <Form.Check.Input {...field} {...props} label={label} type="checkbox" id={controlId} checked={checked} />
        )}
      </Form.Group>
    </>
  );
};

const InputCheckbox = ({ label, controlId, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <input {...field} {...props} type="checkbox" id={controlId} />
      <label htmlFor={controlId} className="mb-0 ms-2">
        {label}
      </label>
    </>
  );
};

const SwitchField = ({ label, controlId, ...props }) => {
  const [field] = useField(props);
  const checked = field.value === 1 ? true : "";
  return (
    <>
      <Form.Group className="" controlId={controlId}>
        <Form.Check {...field} {...props} label={label} type="switch" id={controlId} checked={checked} />
      </Form.Group>
    </>
  );
};
const InputFieldImage = ({ label, controlId, page, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const image = useSelector((state) => state.image);
  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
      if (SUPPORTED_FORMATS.includes(e.target.files[0].type)) {
        dispatch(CanvaTemplateSelected(""));
        dispatch(selectImage({ name: e.target.files[0].name, size: e.target.files[0].size, type: e.target.files[0].type, url: URL.createObjectURL(e.target.files[0]) }));
        setFieldValue(props.name, e.target.files[0]);
      }
    }
    e.target.value = null;
  };
  field.onChange = imageChange;
  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setFieldValue(props.name, "");
    dispatch(removeImage());
  };
  return (
    <>
      {page === "client-addform" ? (
        <Form.Group className="" controlId={controlId}>
          <div className={(image && image.selected ? "bg-transparent" : "") + " insert-photo input-file position-relative  d-flex align-content-center flex-wrap justify-content-center m-auto"}>
            <Form.Control type="file" onChange={field.onChange} {...props} isInvalid={meta.touched && !!meta.error} className={image && image.selected ? "input-photo d-none" : "input-photo"} />
            <img
              src={image && image.selected ? image.url : config.imagepath + "addphoto-box.png"}
              alt=""
              className={image && image.selected ? "image-preview" : ""}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = config.imageOnError;
              }}
            />
            <span className={"cursor-pointer remove-img " + (image && image.selected ? "d-block" : "d-none")} onClick={removeSelectedImage}>
              {<i className="fas fa-times"></i>}
            </span>
            <span className={"cursor-pointer " + (image && image.selected ? "d-none" : "d-block")}>{label}</span>
          </div>
          <div className="d-flex align-content-center flex-wrap justify-content-center ms-lg-5">
            {meta.touched && (
              <Form.Control.Feedback type="invalid" className={image && image.selected ? "d-none" : "d-block"}>
                {meta.error}
              </Form.Control.Feedback>
            )}
          </div>
        </Form.Group>
      ) : (
        // <Form.Group className="" controlId={controlId}>
        //   <div className={(image && image.selected ? "bg-transparent" : "") + "insert-photo d-flex flex-column justify-content-center align-items-center ms-md-auto"}>
        //     <img src={image && image.selected ? image.url : config.imagepath + "addphoto-box.png"} alt="" className={image && image.selected ? "image-preview mb-3" : "mb-3"} />
        //     <button type="button" className={image && image.selected ? "d-none" : "btn btn-primary btn-sm position-relative"}>
        //       <Form.Control type="file" onChange={field.onChange} {...props} isInvalid={meta.touched && !!meta.error} />
        //       {label}
        //     </button>
        //     <button type="button" className={image && image.selected ? "btn btn-primary btn-sm position-relative" : "d-none"} onClick={removeSelectedImage}>
        //       {t("Remove")}
        //     </button>
        //     {meta.touched && (
        //       <Form.Control.Feedback type="invalid" className={image && image.selected ? "d-none" : "d-block"}>
        //         {meta.error}
        //       </Form.Control.Feedback>
        //     )}
        //   </div>
        // </Form.Group>
        ""
      )}

      {page === "staff-form" ? (
        <>
          <Form.Group className="" controlId={controlId}>
            <div className={(image && image.selected ? "bg-transparent" : "") + " input-file position-relative ms-md-auto d-flex align-content-center flex-wrap justify-content-center"}>
              <Form.Control type="file" onChange={field.onChange} {...props} isInvalid={meta.touched && !!meta.error} className={image && image.selected ? "input-photo d-none" : "input-photo"} />
              <img
                src={image && image.selected ? image.url : config.imagepath + "addphoto.png"}
                alt=""
                className={image && image.selected ? "image-preview" : "ms-3"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = config.imageOnError;
                }}
              />
              <span className={"cursor-pointer remove-img " + (image && image.selected ? "d-block" : "d-none")} onClick={removeSelectedImage}>
                {<i className="fas fa-times"></i>}
              </span>
              <span className={"cursor-pointer " + (image && image.selected ? "d-none" : "d-block")}>{label}</span>
            </div>
            <div className="d-flex align-content-center flex-wrap justify-content-center ms-lg-5">
              {meta.touched && (
                <Form.Control.Feedback type="invalid" className={image && image.selected ? "d-none" : "d-block"}>
                  {meta.error}
                </Form.Control.Feedback>
              )}
            </div>
          </Form.Group>
        </>
      ) : (
        ""
      )}

      {page === "supplier-form" || page === "product-form" || page === "business-form" ? (
        <>
          <Form.Group className="" controlId={controlId}>
            <div className={(image && image.selected ? "bg-transparent" : "") + " input-file input-file-square position-relative d-flex align-content-center flex-wrap justify-content-center"}>
              <Form.Control type="file" onChange={field.onChange} {...props} isInvalid={meta.touched && !!meta.error} className={image && image.selected ? "input-photo d-none" : "input-photo"} />
              <img
                src={image && image.selected ? image.url : config.imagepath + "addphoto.png"}
                alt=""
                className={image && image.selected ? "image-preview rounded mb-3 custom-image-preview-css" : "mb-3"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = config.imageOnError;
                }}
              />
              <span className={"cursor-pointer remove-img " + (image && image.selected ? "d-block" : "d-none")} onClick={removeSelectedImage}>
                {<i className="fas fa-times"></i>}
              </span>
              <span className={"cursor-pointer " + (image && image.selected ? "d-none" : "d-block")}>{label}</span>
            </div>
            <div className="d-flex align-content-center flex-wrap justify-content-center ms-lg-5">
              {meta.touched && (
                <Form.Control.Feedback type="invalid" className={image && image.selected ? "d-none" : "d-block"}>
                  {meta.error}
                </Form.Control.Feedback>
              )}
            </div>
          </Form.Group>
        </>
      ) : (
        // <div className="insert-photo d-flex flex-column justify-content-center align-items-center ms-md-auto">
        //   <img src={image && image.selected ? image.url : config.imagepath + "addphoto-box.png"} alt="" className="" />
        //   <button type="button" className={image && image.selected ? "d-none" : "btn btn-sm position-relative"}>
        //     <Form.Control type="file" onChange={field.onChange} {...props} isInvalid={meta.touched && !!meta.error} />
        //     {label}
        //   </button>
        //   <button type="button" className={image && image.selected ? "btn btn-sm position-relative" : "d-none"} onClick={removeSelectedImage}>
        //     {t("Remove")}
        //   </button>
        //   <Form.Control.Feedback type="invalid" className={image && image.selected ? "d-none" : "d-block"}>
        //     {meta.error}
        //   </Form.Control.Feedback>
        // </div>
        ""
      )}

      {["appointmentcampaignForm-addform", "appointmentcampaignForm-editform", "birthdaycampaignForm-addform", "birthdaycampaignForm-editform", "reviewcampaignForm-addform", "reviewcampaignForm-editform"].includes(page) ? (
        <>
          <Form.Group className="" controlId={controlId}>
            <div className={(image && image.selected ? "" : "") + " rounded position-relative"}>
              <Form.Control type="file" onChange={field.onChange} {...props} isInvalid={meta.touched && !!meta.error} className={image && image.selected ? "input-photo d-none" : "input-photo"} />
              {/* <img
                src={image && image.selected ? image.url : config.imagepath + "addphoto.png"}
                alt=""
                className={image && image.selected ? "image-preview" : "ms-3"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = config.imageOnError;
                }}
              /> */}
              <span className={"cursor-pointer btn btn-outline-grey remove-img " + (image && image.selected ? "d-block" : "d-none")} onClick={removeSelectedImage}>
                <i className="fas fa-times"></i>
                {t("Remove Image")}
              </span>
              <span className={"cursor-pointer btn btn-outline-grey " + (image && image.selected ? "d-none" : "d-block")}>
                <i className="fas fa-plus"></i> {label}
              </span>
            </div>
            <div className="d-flex align-content-center flex-wrap justify-content-center ms-lg-5">
              {meta.touched && (
                <Form.Control.Feedback type="invalid" className={image && image.selected ? "d-none" : "d-block"}>
                  {meta.error}
                </Form.Control.Feedback>
              )}
            </div>
          </Form.Group>
          {/* <div className="">
            <img
              src={image && image.selected ? image.url : config.imagepath + "addphoto.png"}
              alt=""
              className={image && image.selected ? "image-preview" : "ms-3"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = config.imageOnError;
              }}
            />
          </div> */}
        </>
      ) : (
        ""
      )}

      {["oneoffcampaignForm-addform", "oneoffcampaignForm-editform"].includes(page) ? (
        <>
          <Form.Group className="" controlId={controlId}>
            <div className="col-md-4 mb-4">
              <img
                src={image && image.selected ? image.url : config.imagepath + "addphoto.png"}
                alt=""
                className={image && image.selected ? "img-thumbnail" : "ms-3"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = config.imageOnError;
                }}
              />
            </div>
            <div className={(image && image.selected ? "" : "") + " btn btn-outline-grey rounded position-relative"}>
              <Form.Control type="file" onChange={field.onChange} {...props} isInvalid={meta.touched && !!meta.error} className={image && image.selected ? "input-photo" : "input-photo"} />
              {/* <span className={"cursor-pointer remove-img " + (image && image.selected ? "d-block" : "d-none")} onClick={removeSelectedImage}>
                <i className="fas fa-times"></i>
                {t("Remove Image")}
              </span> */}
              <span className={"cursor-pointer " + (image && image.selected ? "d-block" : "d-block")}>
                <i className="fas fa-camera"></i> {label}
              </span>
            </div>
            <div className="d-flex align-content-center flex-wrap justify-content-center ms-lg-5">
              {meta.touched && (
                <Form.Control.Feedback type="invalid" className={image && image.selected ? "d-none" : "d-block"}>
                  {meta.error}
                </Form.Control.Feedback>
              )}
            </div>
          </Form.Group>
          {/* <div className="">
            <img
              src={image && image.selected ? image.url : config.imagepath + "addphoto.png"}
              alt=""
              className={image && image.selected ? "image-preview" : "ms-3"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = config.imageOnError;
              }}
            />
          </div> */}
        </>
      ) : (
        ""
      )}
    </>
  );
  //Multiple Formik image
  // validationSchema={Yup.object({
  //profile:Yup.array().min(1,"select at least 1 file")
  // })}
  // let data = new FormData();
  //       values.profile.forEach((photo, index) => {
  //         data.append(`photo${index}`, values.profile[index]);+
  //       });
  // <input
  //   id="file"
  //   name="profile"
  //   type="file"
  //   onChange={(event) => {
  //     const files = event.target.files;
  //     let myFiles = Array.from(files);
  //     formik.setFieldValue("profile", myFiles);
  //   }}
  //   multiple
  // />;
};

const ReactSelectField = ({ label, controlId, options, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Form.Group className="" controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Field {...field} {...props} options={options} component={CustomSelect} isInvalid={meta.touched && !!meta.error} className={"custom-select " + (meta.touched && meta.error ? "is-invalid" : "")} controlId={controlId} />
        {meta.touched && (
          <Form.Control.Feedback type="invalid" className="d-block">
            {meta.error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};

const MapAddressField = ({ label, controlId, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <Form.Group className="" controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        {/* <InputGroup hasValidation> */}
        <div className="search-address">
          <i className="far fa-search"></i>
          <Form.Control {...field} {...props} isInvalid={meta.touched && !!meta.error} aria-describedby="inputGroupPrepend" className="search-input" />
          {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
        </div>
        {/* </InputGroup> */}
      </Form.Group>
    </>
  );
};
const MapStreetField = ({ label, controlId, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <Form.Group className="" controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        {/* <InputGroup hasValidation> */}
        <div className="search-address">
          <i className="far fa-search"></i>
          <Form.Control {...field} {...props} isInvalid={meta.touched && !!meta.error} aria-describedby="inputGroupPrepend" className="form-control" />
          {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
        </div>
        {/* </InputGroup> */}
      </Form.Group>
    </>
  );
};

const DatePickerField = ({ label, controlId, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <Form.Group className="" controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control as={DatePicker} {...field} {...props} isInvalid={meta.touched && !!meta.error} inputClass={props.classname} plugins={[<TimePicker hideSeconds style={{ width: "auto" }} />]} disableDayPicker format="hh:mm A" />
        {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
      </Form.Group>
    </>
  );
};

FloatLabelInputField.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
};

InputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  controlId: PropTypes.string,
};
InputGroupField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  controlId: PropTypes.string,
  leftSpan: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  rightSpan: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
InlineInputField.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
};

SelectField.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.oneOfType([null, PropTypes.array, PropTypes.object]),
};

ReactSelectField.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

MapAddressField.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
};
MapStreetField.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
};

TextareaField.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
};

CheckboxField.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
  className: PropTypes.string,
  bsPrefix: PropTypes.string,
};

InputCheckbox.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
  className: PropTypes.string,
  isServiceChecked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SwitchField.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
};

InputFieldImage.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
  name: PropTypes.string,
  page: PropTypes.string,
};

DatePickerField.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
  classname: PropTypes.string,
};

InputPickerField.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  slotTime: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.oneOfType([null, PropTypes.array, PropTypes.object]),
  formikData: PropTypes.oneOfType([null, PropTypes.array, PropTypes.object]),
  formikData: PropTypes.oneOfType([null, PropTypes.array, PropTypes.object]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
};

export { FloatLabelInputField, InputField, InputGroupField, InlineInputField, SelectField, ReactSelectField, MapAddressField, MapStreetField, TextareaField, CheckboxField, InputCheckbox, SwitchField, InputFieldImage, DatePickerField, InputPickerField };
