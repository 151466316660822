import customToast from "component/Toastr";
import moment from "moment";
import React, { useEffect } from "react";
import Moment from "react-moment";
import { StaffAvailableTimeApi } from "store/slices/addonSlice";

const AddonGetAvailableTime = (values, dispatch) => {

  if (values && values.service_id && values.staff_id && values.dateof) {
    dispatch(StaffAvailableTimeApi({ ...values, roster_time: true })).then((action) => {
      if (action.meta.requestStatus === "fulfilled") {
        const service_assign_resource = action && action.payload && action.payload.service_assign_resource ? action.payload.service_assign_resource : "";
        const start_datetime = action && action.payload && action.payload.start_datetime ? action.payload.start_datetime : "";
        if (service_assign_resource) {
          if (values && values.parentAppointmentEndTime) {
            const dateof = values && values.parentAppointmentEndTime ? moment(values.parentAppointmentEndTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") : "";
            if (dateof && dateof !== start_datetime) {
              customToast.error("Add on - Resource is not available this time")
            }
          }
        } else {
          if (!start_datetime) {
            customToast.error("Add on - Staff is not available this time")
          }
        }
        const payloaddata = action.payload;
        if (payloaddata.time && payloaddata.time.length == 0) {
          if (payloaddata.appointment && payloaddata.appointment.length > 0) {
            customToast.success(`Appointment not available at this time`);
          } else if (payloaddata.addonservices && payloaddata.addonservices.length > 0) {
            customToast.success(`Add-on services not available at this time`);
          } else if (payloaddata.breaktime && payloaddata.breaktime.length > 0) {
            customToast.success(`Please choose another time as business is breaktime`);
          } else if (payloaddata.busytime && payloaddata.busytime.length > 0) {
            customToast.success(`This staff member is busy at this time`);
          } else if (payloaddata.booking && payloaddata.booking.length > 0) {
            customToast.success(`This staff member is on break at this time`);
          } else {
            customToast.success(`There is not enough time available for this service.`);
          }
        }
      } else if (action.meta.requestStatus === "rejected") {
        const status = action.payload && action.payload.status;
        const errors = action.payload && action.payload.message && action.payload.message.errors;
        const response = action.payload && action.payload.message && action.payload.message;
        if (status === 422) {
          const NotifyContent = () => {
            return (
              <>
                <p className="mb-2 text-light text-justify">{response && response.message}</p>
                <ul className="list-unstyled">
                  {errors &&
                    Object.keys(errors).map((a, n) => {
                      if (errors[a].length > 0) {
                        return (
                          <>
                            {errors[a].map((as, ns) => {
                              return (
                                <li key={n + "-" + ns} className="text-light form-text text-start">
                                  {as}
                                </li>
                              );
                            })}
                          </>
                        );
                      }
                    })}
                </ul>
              </>
            );
          };
          customToast.error(<NotifyContent />);
        } else if (status === 410) {
          const NotifyContent = () => {
            return (
              <>
                <p className="mb-2 text-white text-justify">{response && response.message}</p>
                {response && response.booked && (
                  <>
                    <p className="mb-2 text-white text-justify">{t("Staff are available at this time.")}</p>
                    {response && response.dateof && <Moment format="MMMM DD YYYY">{response.dateof}</Moment>}
                    <ul className="list-unstyled">
                      {response.booked.map((a, n) => (
                        <li key={n} className="text-light form-text">
                          <Moment format="hh:mm A">{response.dateof + "T" + a}</Moment>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            );
          };
          customToast.error(<NotifyContent />);
        }
      }
    });
  } else {
    dispatch({ type: "addon/StaffAvailableTime/rejected" });
  }
};

export { AddonGetAvailableTime };
