import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import bookinglinkApiController from "services/bookinglink.service";
import categoryApiController from "../../services/category.service";
import serviceApiController from "../../services/service.service";
import staffApiController from "../../services/staff.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const BookingLinkCategoryOptions = createAsyncThunk("bookinglink/categoryOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await categoryApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "categoryOptions"))
      .catch((error) => HandleError(thunkAPI, error, "categoryOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingLinkServiceOptions = createAsyncThunk("bookinglink/serviceOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "serviceOptions"))
      .catch((error) => HandleError(thunkAPI, error, "serviceOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingLinkStaffOptions = createAsyncThunk("bookinglink/staffOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "staffOptions"))
      .catch((error) => HandleError(thunkAPI, error, "staffOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const bookinglinkStoreApi = createAsyncThunk("bookinglink/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await bookinglinkApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const bookinglinkUpdateApi = createAsyncThunk("bookinglink/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await bookinglinkApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const bookinglinkListViewApi = createAsyncThunk("bookinglink/listview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookinglinkApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "listview"))
      .catch((error) => HandleError(thunkAPI, error, "listview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const bookinglinkCancellationPolicyApi = createAsyncThunk("bookinglink/cancellationpolicy", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookinglinkApiController
      .cancellationpolicies(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "cancellationpolicy"))
      .catch((error) => HandleError(thunkAPI, error, "cancellationpolicy"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isCategoryOption: [],
  isServiceOption: [],
  isStaffOption: [],
  isListView: [],
  isCopyLink: true,
  isCopyLinkFromBooking: false,
  isApiStatus: {
    BookingLinkCategoryOptions: "",
    BookingLinkServiceOptions: "",
    BookingLinkStaffOptions: "",
    bookinglinkStoreApi: "",
    bookinglinkUpdateApi: "",
    bookinglinkCancellationPolicyApi: ""
  },
};

const bookinglinkSlice = createSlice({
  name: "bookinglink",
  initialState,
  reducers: {
    reset: () => initialState,
    BookingLinkServiceOptionReset: (state) => {
      state.isServiceOption = [];
    },
    BookingLinkStaffOptionReset: (state) => {
      state.isStaffOption = [];
    },
    CopyLinkResetFunction: (state, action) => {
      state.isCopyLink = action.payload;
    },
    RemoveCopyLinkFunction: (state, action) => {
      state.isCopyLinkFromBooking = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(BookingLinkCategoryOptions.pending, (state) => { })
      .addCase(BookingLinkCategoryOptions.fulfilled, (state, action) => {
        state.isCategoryOption = action.payload;
      })
      .addCase(BookingLinkCategoryOptions.rejected, (state) => {
        state.isCategoryOption = [];
      })
      .addCase(BookingLinkServiceOptions.pending, (state) => { })
      .addCase(BookingLinkServiceOptions.fulfilled, (state, action) => {
        state.isServiceOption = action.payload;
      })
      .addCase(BookingLinkServiceOptions.rejected, (state) => {
        state.isServiceOption = [];
      })
      .addCase(BookingLinkStaffOptions.pending, (state) => { })
      .addCase(BookingLinkStaffOptions.fulfilled, (state, action) => {
        state.isStaffOption = action.payload;
      })
      .addCase(BookingLinkStaffOptions.rejected, (state) => {
        state.isStaffOption = [];
      })
      .addCase(bookinglinkStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, bookinglinkStoreApi: "loading" };
      })
      .addCase(bookinglinkStoreApi.fulfilled, (state, action) => {
        state.isListView = action.payload;
        state.isApiStatus = { ...state.isApiStatus, bookinglinkStoreApi: "succeeded" };
      })
      .addCase(bookinglinkStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, bookinglinkStoreApi: "failed" };
      })
      .addCase(bookinglinkUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, bookinglinkUpdateApi: "loading" };
      })
      .addCase(bookinglinkUpdateApi.fulfilled, (state, action) => {
        state.isListView = action.payload;
        state.isApiStatus = { ...state.isApiStatus, bookinglinkUpdateApi: "succeeded" };
      })
      .addCase(bookinglinkUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, bookinglinkUpdateApi: "failed" };
      })
      .addCase(bookinglinkListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, bookinglinkListViewApi: "loading" };
      })
      .addCase(bookinglinkListViewApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, bookinglinkListViewApi: "succeeded" };
        state.isListView = action.payload;
      })
      .addCase(bookinglinkListViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, bookinglinkListViewApi: "failed" };
        state.isListView = [];
      })
      .addCase(bookinglinkCancellationPolicyApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, bookinglinkCancellationPolicyApi: "loading" };
      })
      .addCase(bookinglinkCancellationPolicyApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, bookinglinkCancellationPolicyApi: "succeeded" };
      })
      .addCase(bookinglinkCancellationPolicyApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, bookinglinkCancellationPolicyApi: "failed" };
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, BookingLinkServiceOptionReset, BookingLinkStaffOptionReset, CopyLinkResetFunction, RemoveCopyLinkFunction } = bookinglinkSlice.actions;
export default bookinglinkSlice.reducer;
