import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/stripe/stripeStore";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const updatekeys = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/stripe/stripeupdatekeys";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const setup = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/stripe/setup";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const oauth = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/stripe/oauth";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const cardpayment = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  // const stripe = require('stripe')('sk_test_51Ko2rOSFsrov7HTSJAkhuTXyQiUGw5kfiU67lVR7riELEoXvcoUI6duFWM6djjYVNwmvGMec5OhyVeZyy5X3eRcj00r1l2zaoX');
  // const token = await stripe.tokens.create({
  //   card: {
  //     number: '4242424242424242',
  //     exp_month: 4,
  //     exp_year: 2023,
  //     cvc: '314',
  //   },
  // });
  const action = "afterlogin/stripe/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const stripepayment = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["paymentIntent", "setupIntent"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/stripe/stripepayment`;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const stripepaymentfailed = (values) => {
  const formData = new FormData();
  for (let value in values) {
    if (["error"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/stripe/stripepaymentfailed`;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const stripeApiController = {
  create,
  setup,
  cardpayment,
  oauth,
  updatekeys,
  stripepayment,
  stripepaymentfailed,
};
export default stripeApiController;
