import { createSlice } from '@reduxjs/toolkit';

const zoomcontrolSlice = createSlice({
  name: 'zoom',
  initialState: {
    percentage: 100,
  },
  reducers: {
    increaseZoom: (state) => {
      if (state.percentage < 175) {
        state.percentage += 5;
      }
    },
    decreaseZoom: (state) => {
      if (state.percentage > 25) {
        state.percentage -= 5;
      }
    },
    resetZoom: (state) => {
      state.percentage = 100;
    },
  },
});

export const { increaseZoom, decreaseZoom, resetZoom } = zoomcontrolSlice.actions;
export default zoomcontrolSlice.reducer;


// // zoomcontrolSlice.js
// import { createSlice } from '@reduxjs/toolkit';

// const zoomcontrolSlice = createSlice({
//   name: 'zoom',
//   initialState: {
//     height: 20,
//   },
//   reducers: {
//     increaseZoom: (state) => {
//       if (state.height < 36) {
//         state.height += 1;
//       }
//     },
//     decreaseZoom: (state) => {
//       if (state.height > 20) {
//         state.height -= 1;
//       }
//     },
//     resetZoom: (state) => {
//       state.height = 20;
//     },
//   },
// });

// export const { increaseZoom, decreaseZoom, resetZoom } = zoomcontrolSlice.actions;
// export default zoomcontrolSlice.reducer;

