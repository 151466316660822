import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import settingApiController from "../../services/setting.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const SettingsApi = createAsyncThunk("setting/view", async (formValues, thunkAPI) => {
  try {
    const resposedata = await settingApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "view"))
      .catch((error) => HandleError(thunkAPI, error, "view"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SmsPlaceholderApi = createAsyncThunk("setting/smsplaceholder", async (formValues, thunkAPI) => {
  try {
    const resposedata = await settingApiController
      .smsplaceholder(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "smsplaceholder"))
      .catch((error) => HandleError(thunkAPI, error, "smsplaceholder"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SmsCharCountApi = createAsyncThunk("setting/smscharactercount", async (formValues, thunkAPI) => {
  try {
    const resposedata = await settingApiController
      .smscharactercounts(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "smscharactercount"))
      .catch((error) => HandleError(thunkAPI, error, "smscharactercount"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const IntegrationDisConnect = createAsyncThunk("integration/disconnect", async (formValues, thunkAPI) => {
  try {
    const resposedata = await settingApiController
      .integrationdisconnect(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "integrationdisconnect"))
      .catch((error) => HandleError(thunkAPI, error, "integrationdisconnect"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


const initialState = {
  isTabView: "",
  isOpenedMailchimpForm: "",
  isSetting: "",
  isSmsPlaceholder: "",
  isOpenSmsPurchaseDrawer:"",
  isIntegrationDisconnect:"",
  isSmsCharCountCredits:"",
  isApiStatus: {
    SettingsApi: "",
    SmsPlaceholderApi: "",
    IntegrationDisConnect:"",
    SmsCharCountApi:"",
  },
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    reset: () => initialState,
    SettingTabGridView: (state, action) => {
      state.isTabView = action.payload;
    },
    OpenSmsPurchase: (state, action) => {
      state.isOpenSmsPurchaseDrawer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(SettingsApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SettingsApi: "loading" };
    })
    .addCase(SettingsApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, SettingsApi: "succeeded" };
      state.isSetting = action.payload;
    })
    .addCase(SettingsApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SettingsApi: "failed" };
    })
    .addCase(SmsPlaceholderApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SmsPlaceholderApi: "loading" };
    })
    .addCase(SmsPlaceholderApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, SmsPlaceholderApi: "succeeded" };
      state.isSmsPlaceholder = action.payload;
    })
    .addCase(SmsPlaceholderApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SmsPlaceholderApi: "failed" };
    })
    .addCase(IntegrationDisConnect.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, IntegrationDisConnect: "loading" };
    })
    .addCase(IntegrationDisConnect.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, IntegrationDisConnect: "succeeded" };
      state.isIntegrationDisconnect = action.payload;
    })
    .addCase(IntegrationDisConnect.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, IntegrationDisConnect: "failed" };
    })
    .addCase(SmsCharCountApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SmsCharCountApi: "loading" };
    })
    .addCase(SmsCharCountApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, SmsCharCountApi: "succeeded" };
      state.isSmsCharCountCredits = action.payload;
    })
    .addCase(SmsCharCountApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SmsCharCountApi: "failed" };
    });
  },
});
// Action creators are generated for each case reducer function
export const { reset, SettingTabGridView, OpenSmsPurchase } = settingSlice.actions;
export default settingSlice.reducer;
