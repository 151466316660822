import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import PaginationLoader from "component/PaginationLoader";
import { ClientInvoiceListViewApi } from "store/slices/clientinvoiceSlice";
import config from "../../../../config";
import Moment from "react-moment";
import { openAddSaleForm, SaleProductStaffDropdownApi, SaleProductToCartApi, SaleSubscriptionStaffDropdownApi, SaleTabView } from "store/slices/saleSlice";
import { ClientDataToSale, CloseClientDetailModal } from "store/slices/clientSlice";
import { clienttosaleproduct } from "store/slices/clientproductSlice";
import moment from "moment";
import ApiStatus from "component/ApiStatus";

const products = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.salonmodule.isAccess);
  const productApiStatus = useSelector((state) => state.clientproduct.isApiStatus);
  const productViews = useSelector((state) => state.clientproduct.isListView);
  const detail = useSelector((state) => state.client.isDetailData);
  // console.log("productViews",productViews)

  const fetchDataInvoiceList = () => {
    dispatch(ClientproductApi({ client_id: detail.id, next_page_url: productViews.next_page_url }));
  };

  const [isFetching, setIsFetching] = useState(false);
  const loadMoreItemsList = () => {
    setIsFetching(true);
    dispatch(ClientproductApi({ next_page_url: productViews && productViews.next_page_url, client_id: detail.id }));
    //mocking an API call
    setTimeout(() => {
      setIsFetching(false);
    }, 2000);
  };
  return (
    <React.Fragment>
      {openModal && (
        <>
          <div className={openModal ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openModal ? "block" : "none" }}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body pb-0">
                  <div className="p-3">
                    <h5 className="mb-3 fw-semibold text-dark">{t("Professional Product")}</h5>
                    <p className="fw-semibold mb-2">{t("This professional product cannot be purchased again.")}</p>
                  </div>
                  <div className="row mt-3 bg-cream p-4 justify-content-center">
                    <div className="col-6">
                      <button className="btn btn-primary w-100" type="button" onClick={() => {
                        setOpenModal(false);
                      }}>
                        {t("OK")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openModal && <div className="modal-backdrop show"></div>}
        </>
      )}
      <div className="drawer-header">
        <h2 className="mb-4 pe-md-5 mb-lg-5">
          {t("Products")}
        </h2>
      </div>
      <div className="content-wrp" id="invoicelist">
        {productApiStatus && productApiStatus.ClientproductApi === "succeeded" && productViews && productViews.data && productViews.data.length > 0 ? (
          <InfiniteScroll className="content-wrp row gx-0" dataLength={productViews.data.length} next={fetchDataInvoiceList} scrollableTarget="invoicelist" hasMore={productViews.next_page_url ? true : false} loader={<PaginationLoader />}>
            {Object.keys(productViews.data).map((item, i) => {
              let products = productViews.data[item].products;
              let sale = productViews.data[item].sale;
              let id = products.id;
              let productName = products.name;
              let invoicedate = sale.invoicedate;
              let purchasedate = moment(invoicedate).format("dddd DD MMMM YYYY");
              //   let image_urls = productViews.data[item].storage_url+'/image/'+productViews.data[item].image;
              let images_url = products.image_url;
              const ProfessionalProduct = productViews.data[item] && productViews.data[item].products && (productViews.data[item].products.is_professional == 1 || productViews.data[item].products.is_professional == '1') ? true : false;
              return (
                <div className="event-box" key={item && item.id ? item.id : i} data-id={id}>
                  <div className="row align-items-center justify-contents-start">
                    <div className="col-2">
                      {images_url ? (
                        <a className="ms-auto invoice-link">
                          <img src={images_url ? images_url : ""} alt="" className="width-height-products" />
                        </a>
                      ) : (
                        <div className="user-initial me-md-3 me-2 p-md-3 client-user-circles rounded wh-70">{productName ? productName.charAt(0) : "P"}</div>
                      )}

                    </div>
                    <div className="col-10">
                      <h6 className="mb-1 fw-semibold">{productName}</h6>
                      <div className="d-flex justify-content-between align-items-start">
                        <h6 className="mb-0">{`Purchased: ${purchasedate ? purchasedate : ""}`}</h6>
                        <a className="btn btn-outline btn-sm" onClick={() => {
                          if (ProfessionalProduct) {
                            setOpenModal(true);
                          } else {
                            dispatch(SaleProductStaffDropdownApi({ option: { valueField: "id", labelField: "CONCAT(first_name,' ',last_name)" } }));
                            dispatch(CloseClientDetailModal(""));
                            dispatch({ type: "sale/reset" });
                            dispatch(SaleTabView("products"))
                            dispatch(openAddSaleForm());
                            dispatch(ClientDataToSale(detail));
                            dispatch(SaleProductToCartApi({ product_id: id }));
                          }
                        }}>
                          {t("Buy Again")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {!isFetching && productViews.next_page_url && (
              <div className="col-2 m-auto p-3 text-center">
                <button onClick={loadMoreItemsList} className="btn btn-primary">
                  {t("More")}
                </button>
              </div>
            )}
          </InfiniteScroll>
        ) : (
          <>
            {productApiStatus &&
              productApiStatus.ClientproductApi === "loading" ? (
              <ApiStatus actionType="clientproductview" />
            ) : (
              <div className="complete-box text-center d-flex flex-column justify-content-center mt-xl-4">
                <div className="complete-box-wrp text-center">
                  <img src={config.imagepath + "products.png"} alt="" className="mb-md-4 mb-3" />
                  <h5 className="mb-2 fw-semibold">{t("There are no products purchased.")}</h5>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default products;