import React, { useState } from 'react';
import moment from 'moment';
import config from "../../../../../config";
import { useLocation, useNavigate } from 'react-router';
import { NotificationToCalendar, NotificationUpdateApi, UpcomingNotifyDetailDrawer } from 'store/slices/notificationSlice';
import { useSelector, useDispatch } from 'react-redux';
import MoonLoader from "react-spinners/MoonLoader";
import { AddAppointmentForm, AppointmentDetailModal, RescheduleAppointmentForm, ShowLoadingSpinner, appointmentDetailApi, appointmentListViewApi } from 'store/slices/appointmentSlice';
import { calendarRangeInfo, calendarStaffList } from 'store/slices/calendarSlice';
import { AddBusytimeForm, EditBusytimeForm, busytimeListViewApi } from 'store/slices/busytimeSlice';
import { RosterStaffApi, StaffAwayApi, StaffworkinghoursApi } from 'store/slices/staffSlice';
import { ClosedDateApi } from 'store/slices/salonSlice';
import { sweatalert } from 'component/Sweatalert2';
import customToast from 'component/Toastr';
import { usercheckAccess } from 'helpers/Functions';
import { useTranslation } from 'react-i18next';

const BookingNotificationList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [loader, setLoader] = useState({});
  const views = props && props.view;
  const viewData = views && views.data ? views.data : views;
  const objectData = viewData && viewData.length > 0 ? viewData.filter((item) => item.type === "online_booking") : [];
  const notificationTabs = useSelector((state) => state.notification.isUpcomingNotifyDetailTab);
  const isClientDetailTab = useSelector((state) => state.client.isClientDetailTab);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const single_staff_access = usercheckAccess("calendar_view", currentUser);
  const singleStaffAccess = role_id === 8 ? '' : (single_staff_access && currentUser && currentUser.is_owner === 0) ? 1 : '';

  return (
    <div>
      {objectData && objectData.length > 0 ? (
        <>
          {Object.keys(objectData).map((item, index) => {
            const id = objectData[item] && objectData[item].id ? objectData[item].id : "";
            const profilePhotoUrl = objectData[item] && objectData[item].appointment && objectData[item].appointment.client && objectData[item].appointment.client.profile_photo_url ? objectData[item].appointment.client.profile_photo_url : "";
            const first_name = objectData[item] && objectData[item].appointment && objectData[item].appointment.client && objectData[item].appointment.client.first_name ? objectData[item].appointment.client.first_name : "";
            const last_name = objectData[item] && objectData[item].appointment && objectData[item].appointment.client && objectData[item].appointment.client.last_name ? objectData[item].appointment.client.last_name : "";
            const iconUrl = objectData[item] && objectData[item].type && objectData[item].type === "online_booking" ? "eye-round.png" : objectData[item] && objectData[item].type && objectData[item].type === "message" ? "reply-round.png" : "";
            const datedifference = objectData[item] && objectData[item].created_at ? moment(objectData[item].created_at, "YYYY-MM-DD hh:mm:ss").fromNow() : "";
            const dateFormat = objectData[item] && objectData[item].created_at ? moment(objectData[item].created_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY") : "";
            const service_name = objectData[item] && objectData[item].appointment && objectData[item].appointment.service && objectData[item].appointment.service.name ? objectData[item].appointment.service.name : "";
            const appointment_time = objectData[item] && objectData[item].appointment && (objectData[item].appointment.start_time && objectData[item].appointment.end_time) ? (`${moment(objectData[item].appointment.start_time, "HH:mm:ss").format("HH:mm A")} - ${moment(objectData[item].appointment.end_time, "HH:mm:ss").format("HH:mm A")}`) : "";
            const is_seen = objectData[item] && objectData[item].is_seen == 0 ? 0 : 1;
            const staff_first_name = objectData[item] && objectData[item].appointment && objectData[item].appointment.staff && objectData[item].appointment.staff.first_name ? objectData[item].appointment.staff.first_name : "";
            const staff_last_name = objectData[item] && objectData[item].appointment && objectData[item].appointment.staff && objectData[item].appointment.staff.last_name ? objectData[item].appointment.staff.last_name : "";
            const staffId = objectData[item] && objectData[item].appointment && objectData[item].appointment.staff_id ? objectData[item].appointment.staff_id : "";
            let notification_appointment_id = objectData[item] && objectData[item].appointment && objectData[item].appointment.id ? objectData[item].appointment.id : "";
            let notification_client_id = objectData[item] && objectData[item].appointment && objectData[item].appointment.client && objectData[item].appointment.client.id ? objectData[item].appointment.client.id : "";
            let notification_showdate = objectData[item] && objectData[item].appointment && objectData[item].appointment.dateof ? objectData[item].appointment.dateof : "";
            let notification_start_date = objectData[item] && objectData[item].appointment && objectData[item].appointment.start_datetime ? objectData[item].appointment.start_datetime : "";
            let notification_end_date = objectData[item] && objectData[item].appointment && objectData[item].appointment.end_datetime ? objectData[item].appointment.end_datetime : "";

            return (
              <div className="box-image-cover notification-boxs w-100 mx-0 py-md-4 py-3 text-start" style={{ background: `${is_seen == 1 ? "#fff" : "#f9f6f4"}`,marginBottom:`${index === objectData.length - 1 ? "43px" : "0px"}`  }} key={index}>
                <div className="row align-items-center">
                  <div className="col-xxl-9 col-md-8 mb-md-0 mb-2">
                    <div className="d-flex align-items-center">
                      <div className="text-center">
                        {profilePhotoUrl ? (
                          <img src={profilePhotoUrl} alt="" className="rounded-circle wh-40 profile-image-obj mwh-40" />
                        ) : (
                          <div className="user-initial text-uppercase profile-image-height wh-40 mwh-40">{first_name && last_name && first_name.charAt(0) + "" + last_name.charAt(0)}</div>
                        )}
                      </div>
                      <div className="ps-3">
                        <div>
                          <h6 className='fw-semibold mb-0'>{`${first_name} ${last_name}`}</h6>
                        </div>
                        <div>
                          <p className='fw-semibold mb-0'>{`${service_name} with ${staff_first_name + " " + staff_last_name}`}</p>
                          <p className='fw-semibold mb-0'>{dateFormat}</p>
                          <div>
                            <span className='text-dark'>{appointment_time}</span>
                          </div>
                          <div>
                            <span className='color-wine'>{datedifference}</span>
                          </div>
                          {objectData[item] && objectData[item].appointment && objectData[item].appointment.status === "Cancelled" ? (
                            <div>
                              <p className='mb-0 text-danger'>{t("The appointment has been canceled.")}</p>
                            </div>
                            ) : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-md-4 ps-md-0 d-flex justify-content-end align-items-center">
                    {objectData[item] && objectData[item].appointment && objectData[item].appointment.status === "Cancelled" ? null : (
                      <>
                        {iconUrl ? (
                          <span
                            onClick={async () => {
                              setLoader((prevLoader) => ({ ...prevLoader, [index]: true }));
                              dispatch(NotificationUpdateApi({ id, is_seen: 1 })).then((action) => {
                                if (action.meta.requestStatus === "fulfilled") {
                                  dispatch(NotificationListApi());
                                }
                              });
                              dispatch(NotificationToCalendar(objectData[item]));
                              if (location && location.pathname === "/calendar") {
                                try {
                                  dispatch(ShowLoadingSpinner(true));
                                  dispatch(AddAppointmentForm(""));
                                  dispatch(AddBusytimeForm(""));
                                  dispatch(EditBusytimeForm(""));
                                  dispatch(RescheduleAppointmentForm(""));
                                  dispatch(
                                    appointmentDetailApi({
                                      id: notification_appointment_id,
                                      client_id: notification_client_id,
                                      showdate: notification_showdate,
                                      showstartdate: notification_start_date,
                                      showenddate: notification_end_date,
                                      page_name: "calendar",
                                      singlestaffaccess: singleStaffAccess
                                    }),
                                  ).then((action) => {
                                    setLoader((prevLoader) => ({ ...prevLoader, [index]: false }));
                                    if (action.meta.requestStatus === "fulfilled") {
                                      dispatch(AppointmentDetailModal(true));
                                      setTimeout(() => {
                                        dispatch(AppointmentDetailModal("open"));
                                      }, config.modalOpenTimeOut);
                                    } else if (action.meta.requestStatus === "rejected") {
                                      if (action.payload.status === 422) {
                                        let error = action.payload;
                                        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                                        sweatalert({
                                          title: message.errors.document[0],
                                          text: message.errors.document,
                                          icon: "error",
                                        });
                                      }
                                    }
                                  })
                                } catch (error) {
                                  console.log(error);
                                  customToast.error('Sorry there was a problem, please refresh your browser');
                                  setLoader((prevLoader) => ({ ...prevLoader, [index]: false })); // Set loader back to false after the action is completed
                                  dispatch(ShowLoadingSpinner(false));
                                  dispatch(UpcomingNotifyDetailDrawer(""));
                                }
                              } else {
                                setTimeout(() => {
                                  setLoader((prevLoader) => ({ ...prevLoader, [index]: false }));
                                  navigate(config.basePath + "/calendar");
                                  dispatch(UpcomingNotifyDetailDrawer(""));
                                }, 1000);
                              }
                            }}>
                            {loader[index] ? (
                              <MoonLoader color="#8c1c4d" size="22px" />
                            ) : (
                              <img src={config.imagepath + iconUrl} alt="" className="rounded-circle wh-25 profile-image-obj" />
                            )}
                          </span>
                        ) : ""}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </>
      ) : (
        <div className="complete-box text-center d-flex flex-column justify-content-center mt-5">
          <div className="complete-box-wrp text-center">
            {/* <img src={config.imagepath + "bellnotifications.png"} alt="" className="mb-md-4 mb-3" /> */}
            <img src={config.imagepath + "notification-bell.png"} alt="" className="mb-md-4 mb-3" />
            <h5 className="mb-2 fw-semibold" style={{ color: 'rgb(151,136,136)' }}>{t("No notification found")}</h5>
          </div>
        </div>
      )}
    </div>
  )
}

export default BookingNotificationList;