import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
// validation Formik
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import yupconfig from "../../../yupconfig";
import { SaleEmailInvoiceApi } from "store/slices/saleSlice";
import customToast from "component/Toastr";
import { swalErrorHtml } from "component/Sweatalert2";
import { OpenCustomAlerts } from "store/slices/commonSlice";

const SaleEmailInvoiceForm = (props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const SaleCompletedData = props.isSaleCompletedData;
  const initialValues = {
    email: "",
    SaleCompletedData: SaleCompletedData && JSON.stringify(SaleCompletedData),
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().max(100).email().label(t("Email Address")).required(),
  });
  yupconfig();

  const handlesaleSubmit = (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
    setLoading(true);
    try {
      dispatch(SaleEmailInvoiceApi(values)).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          setStatus({ success: true });
          resetForm();
          customToast.success(t("Invoice Sent Successfully"));
          setLoading(false);
        } else if (action.meta.requestStatus === "rejected") {
          const status = action.payload && action.payload.status;
          const response = action.payload && action.payload.message && action.payload.message;
          const setup = action.payload && action.payload.message && action.payload.setup;
          const errors = action.payload && action.payload.message && action.payload.message.errors;
          if (status === 422) {
            dispatch(
              OpenCustomAlerts({
                  isOpen: "open",
                  errors: (
                    <>
                      <p className="mb-2 text-danger salert text-justify">{response && response.message}</p>
                      <ul className="list-unstyled">
                        {errors &&
                          Object.keys(errors).map((a, n) => {
                            if (errors[a].length > 0) {
                              return (
                                <>
                                  {errors[a].map((as, ns) => {
                                    return (
                                      <li key={n + "-" + ns} className="text-danger salert form-text text-start">
                                        {as}
                                      </li>
                                    );
                                  })}
                                </>
                              );
                            }
                          })}
                      </ul>
                    </>
                  )
              })
          );
            setErrors(errors);
          } else if (status === 410) {
            const NotifyContent = () => {
              return (
                <>
                  <p className="mb-2 text-white text-justify">{response && response.message}</p>
                </>
              );
            };
            customToast.error(<NotifyContent />);
            setStatus({ warning: response && response.message, booked: response && response.booked });
          }
          setStatus({ success: false });
          setSubmitting(false);
          setLoading(false);
        }
      });
    } catch (err) {
      setErrors(err.message);
      setStatus({ success: false });
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <Formik enableReinitialize={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handlesaleSubmit}>
        {(formik) => {
          // useEffect(() => {
          //   console.log(SaleCompletedData);
          //   formik.setFieldValue("SaleCompletedData", SaleCompletedData && JSON.stringify(SaleCompletedData));
          // }, [SaleCompletedData]);
          return (
            <form noValidate onSubmit={formik.handleSubmit} className="w-100 mt-lg-0 mt-2">
              <div className="d-flex align-items-end">
                <div className="w-100">
                  <label htmlFor="">{t("Email Invoice")}</label>
                  <Field type="text" name="email" value={formik.values.email} placeholder="josmith@gmail.com" label={t("Email Invoice")} className={formik.touched.email && formik.errors.email ? "form-control is-invalid p-3" : "form-control p-3"} />
                </div>
                <button type="submit" className="btn btn-dark ms-3 p-3 fo" disabled={loading}>
                  {loading && <span className="spinner-border spinner-border-sm"></span>}
                  {t("Send")}
                </button>
              </div>
              <div className="invalid-feedback d-block">
                <ErrorMessage name="email" />
              </div>
            </form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
SaleEmailInvoiceForm.propTypes = {
  isSaleCompletedData: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
};
export default SaleEmailInvoiceForm;
