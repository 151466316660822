import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// import PropTypes from "prop-types";
// import config from "../../../../config";
import { swalConfirm } from "../../../../component/Sweatalert2";
import { ClientphotoGridViewApi, clientphotoUpdateApi, clientphotoDeleteApi, closePhotoDrawer, CommentData, OpenCommentModal, OpenClientProfileAddModal } from "store/slices/clientphotoSlice";
import config from "../../../../config";
import ImageUpload from "component/form/ImageUpload";
import InfiniteScroll from "react-infinite-scroll-component";
import PaginationLoader from "component/PaginationLoader";
import { ClientGridViewApi, ClientListViewApi } from "store/slices/clientSlice";
import customToast from "component/Toastr";
import ClientProfilePhotoAddForm from "./ClientProfilePhotoAddForm";
import moment from "moment";

const Photos = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openModalTwo, setOpenModalTwo] = useState(false);
  const [photoToDelete, setPhotoToDelete] = useState(null);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.salonmodule.isAccess);
  const isOpenClientProfileAddModal = useSelector((state) => state.clientphoto.isOpenClientProfileAddModal);

  const photoViews = useSelector((state) => state.clientphoto.isGridView);
  const detail = useSelector((state) => state.client.isDetailData);

  const fetchDataPhotoList = () => {
    dispatch(ClientphotoGridViewApi({ client_id: detail.id, next_page_url: photoViews.next_page_url }));
  };

  const [isFetching, setIsFetching] = useState(false);
  const loadMoreItemsList = () => {
    setIsFetching(true);
    dispatch(ClientphotoGridViewApi({ next_page_url: photoViews && photoViews.next_page_url, client_id: detail.id }));
    //mocking an API call
    setTimeout(() => {
      setIsFetching(false);
    }, 2000);
  };

  const handlePhotoDelete = (e) => {
    const props = JSON.parse(e.currentTarget.dataset.obj);
    setPhotoToDelete(props);
    setOpenModal(true);
  };

  const handleDeleteConfirmation = () => {
    if (photoToDelete) {
      dispatch(clientphotoDeleteApi({ id: photoToDelete.id })).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          dispatch(ClientGridViewApi());
          dispatch(ClientListViewApi());
          customToast.success("Image Removed Successfully")
        } else if (action.meta.requestStatus === "rejected") {
          customToast.error("Failed to delete")
        }
      });
    }
    setOpenModal(false);
  };

  return (
    <>
     {openModal && (
        <>
          <div className={openModal ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openModal ? "block" : "none" }}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body pb-0">
                  <div className="p-3">
                    <h5 className="mb-3 fw-semibold text-dark">{t("Confirm photo deletion")}</h5>
                    <p className="fw-semibold mb-2">{t("Are you sure you want to delete this photo?")}</p>
                  </div>
                  <div className="row mt-3 bg-cream p-4 justify-content-center">
                    <div className="col-6">
                      <button className="preview btn me-1 cursor-pointer btn-preview w-100" type="button" onClick={() => {
                        setOpenModal(false);
                      }}>
                        {t("Cancel")}
                      </button>
                    </div>
                    <div className="col-6">
                      <button className="btn btn-primary w-100" onClick={handleDeleteConfirmation} type="button">
                        {t("Confirm")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openModal && <div className="modal-backdrop show"></div>}
        </>
      )}
     {openModalTwo && (
        <>
          <div className={openModalTwo ? "modal fade show" : "modal fade"} id="add-emailverification1" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openModalTwo ? "block" : "none" }}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body pb-0">
                  <div className="p-3">
                    <h5 className="mb-3 fw-semibold text-dark">{t("Confirm all photo deletion")}</h5>
                    <p className="fw-semibold mb-2">{t("Are you sure you want to delete all photos?")}</p>
                  </div>
                  <div className="row mt-3 bg-cream p-4 justify-content-center">
                    <div className="col-6">
                      <button className="preview btn me-1 cursor-pointer btn-preview w-100" type="button" onClick={() => {
                        setOpenModalTwo(false);
                      }}>
                        {t("Cancel")}
                      </button>
                    </div>
                    <div className="col-6">
                      <button className="btn btn-primary w-100" onClick={()=>{
                        if(photoViews && photoViews.data && photoViews.data.length > 0){
                          photoViews.data.forEach((photo) => {
                            dispatch(clientphotoDeleteApi({ id: photo.id }));
                          });
                          setOpenModalTwo(false);
                        }
                      }} type="button">
                        {t("Confirm")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openModalTwo && <div className="modal-backdrop show"></div>}
        </>
      )}
      <div className="drawer-header">
        <h2 className="mb-4 pe-md-5 mb-lg-5">
          Photos
          <button className="btn btn-primary btn-sm ms-2"
          onClick={()=>{
            dispatch(OpenClientProfileAddModal("open"));
          }}
          >Add Photo</button>
           {/* {t("Photos")} <ImageUpload name="photo" className="btn btn-outline btn-sm ms-" accept="image/*" label={t("Add Photo")} page="client-addphotoform" controlId="clientForm-photo" client_id={detail.id} /> */}
        {photoViews && photoViews.data && photoViews.data.length > 0 ? <button className="btn btn-apricots btn-sm ms-3" onClick={()=>setOpenModalTwo(true)}>Delete All</button> : ""}
        </h2>
      </div>
      <div className="content-wrp" id="photolist">
        {photoViews && photoViews.data && photoViews.data.length > 0 ? (
          <InfiniteScroll className="row" dataLength={photoViews.data.length} next={fetchDataPhotoList} scrollableTarget="photolist" hasMore={photoViews.next_page_url ? true : false} loader={<PaginationLoader />}>
            {Object.keys(photoViews.data).map((item, i) => {
              let id = photoViews.data[item].id;
              let photo_url = photoViews.data[item].photo_url;
              let client_id = photoViews.data[item].client_id;
              let is_profile_photo = photoViews.data[item].is_profile_photo;
              const comment = photoViews.data[item].comment ? photoViews.data[item].comment : "";
              // created_at: "2024-05-20 17:29:22"
              const created_at = photoViews.data[item].created_at ? moment(photoViews.data[item].created_at, "YYYY-MM-DD HH:mm:ss").format("Do MMMM YYYY") : "";
              return (
                <div className="col-6 mb-md-4 mb-3" key={item && item.id ? item.id : i}>
                  <div className="img-wrap mb-3 text-center border rounded">
                    <a data-fancybox="" data-src={photo_url}>
                      <img src={photo_url} alt="" />
                    </a>
                  </div>
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
                    <a
                      className={(is_profile_photo === 1 ? "disabled btn btn-secondary " : " btn btn-outline-secondary text-dark") + " btn-sm cursor-pointer"}
                      onClick={() => {
                        dispatch(clientphotoUpdateApi({ id: id, client_id: client_id }));
                        dispatch(ClientphotoGridViewApi({ client_id: client_id }));
                        dispatch(ClientGridViewApi());
                        dispatch(ClientListViewApi());
                      }}
                    >
                       {is_profile_photo === 1 ? t("Profile Image") : t("Use as Profile Image")}
                    </a>
                    <a className="remove mt-md-0 mt-2 cursor-pointer" data-obj={JSON.stringify(photoViews.data[item])} onClick={handlePhotoDelete}>
                      {t("Remove")}
                    </a>
                  </div>
                  <h6 className="mb-0 fw-bold">{created_at}</h6>
                  {comment ? (
                    <p className="mb-0 fw-semibold">{comment}</p>
                  ) : ""}
                </div>
              );
            })}
            {!isFetching && photoViews.next_page_url && (
              <div className="col-2 m-auto p-3 text-center">
                <button onClick={loadMoreItemsList} className="btn btn-primary">
                  {t("More")}
                </button>
              </div>
            )}
          </InfiniteScroll>
        ) : (
          <div className="complete-box text-center d-flex flex-column justify-content-center mt-xl-4">
            <div className="complete-box-wrp text-center">
              <img src={config.imagepath + "addphoto-box.png"} alt="" className="mb-md-4 mb-3" />
              <h5 className="mb-2 fw-semibold">
                {t("Add a client profile photo and keep a record of their treatments.")}
                <br />
                <ImageUpload name="photo" className="" accept="image/*" label={t("Add your first photo.")} page="client-addphotoform-circle" controlId="clientForm-photo" client_id={detail.id} />
              </h5>
            </div>
          </div>
        )}
      </div>
      {isOpenClientProfileAddModal && <ClientProfilePhotoAddForm client={detail}/>}
    </>
  );
};

// Photos.propTypes = {
//   // props: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
// };

export default Photos;
