import React, { useState } from 'react';
import moment from 'moment';
import config from "../../../../../config";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { ClientDetailApi, ClientDetailTab, OpenClientDetailModal } from 'store/slices/clientSlice';
import { ClientchatGridViewApi } from 'store/slices/clientChatSlice';
import { ClientAppointmentListViewApi } from 'store/slices/appointmentSlice';
import { ClientformApi } from 'store/slices/clientformSlice';
import { ClientphotoGridViewApi } from 'store/slices/clientphotoSlice';
import { ClientInvoiceListViewApi } from 'store/slices/clientinvoiceSlice';
import { ClientdocumentGridViewApi } from 'store/slices/clientdocumentSlice';
import { ClientnoteGridViewApi } from 'store/slices/clientnoteSlice';
import { ClientSubscriptionListViewApi } from 'store/slices/clientsubscriptionSlice';
import { ClientproductApi } from 'store/slices/clientproductSlice';
import { ClientMembershipListViewApi } from 'store/slices/clientmembershipSlice';
import { ClientVoucherListViewApi } from 'store/slices/clientvoucherSlice';
import MoonLoader from "react-spinners/MoonLoader";
import { NotificationListApi, NotificationUpdateApi } from 'store/slices/notificationSlice';
import { useTranslation } from 'react-i18next';

const MessageNotificationList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const views = props && props.view;
  const [loader, setLoader] = useState({});
  const viewData = views && views.data ? views.data : views;
  const objectData = viewData && viewData.length > 0 ? viewData.filter((item) => item.type === "message") : [];
  const notificationTabs = useSelector((state) => state.notification.isUpcomingNotifyDetailTab);
  const isClientDetailTab = useSelector((state) => state.client.isClientDetailTab);
  const ClientTabApiStatus = useSelector((state) => state.client.isApiStatus);

  return (
    <div>
      {objectData && objectData.length > 0 ? (
        <>
          {Object.keys(objectData).map((item, index) => {
            const id = objectData[item] && objectData[item].id ? objectData[item].id : "";
            const profilePhotoUrlMessage = objectData[item] && objectData[item].clientchat && objectData[item].clientchat.client && objectData[item].clientchat.client.profile_photo_url ? objectData[item].clientchat.client.profile_photo_url : "";
            const message_client_first_name = objectData[item] && objectData[item].clientchat && objectData[item].clientchat.client && objectData[item].clientchat.client.first_name ? objectData[item].clientchat.client.first_name : "";
            const message_client_last_name = objectData[item] && objectData[item].clientchat && objectData[item].clientchat.client && objectData[item].clientchat.client.last_name ? objectData[item].clientchat.client.last_name : "";
            const iconUrl = objectData[item] && objectData[item].type && objectData[item].type === "online_booking" ? "eye-round.png" : objectData[item] && objectData[item].type && objectData[item].type === "message" ? "reply-round.png" : "";
            const datedifference = objectData[item] && objectData[item].created_at ? moment(objectData[item].created_at, "YYYY-MM-DD hh:mm:ss").fromNow() : "";
            const messages = objectData[item] && objectData[item].clientchat && objectData[item].clientchat.message ? objectData[item].clientchat.message.replace(/\r?\n|\r/g, ' ') : "";
            const message_client_id = objectData[item] && objectData[item].clientchat && objectData[item].clientchat.client && objectData[item].clientchat.client.id ? objectData[item].clientchat.client.id : "";
            const is_seen = objectData[item] && objectData[item].is_seen == 0 ? 0 : 1;
            return (
              <div className="box-image-cover notification-boxs w-100 mx-0 py-md-4 py-3 text-start" style={{ background: `${is_seen == 1 ? "#fff" : "#f9f6f4"}`,marginBottom:`${index === objectData.length - 1 ? "43px" : "0px"}` }} key={index}>
                <div className="row align-items-center">
                  <div className="col-xxl-9 col-md-8 mb-md-0 mb-2">
                    <div className="d-flex align-items-center">
                      <div className="text-center">
                        {profilePhotoUrlMessage ? (
                          <img src={profilePhotoUrlMessage} alt="" className="rounded-circle wh-40 profile-image-obj mwh-40" />
                        ) : (
                          <div className="user-initial text-uppercase profile-image-height wh-40 mwh-40">{message_client_first_name && message_client_last_name ? message_client_first_name.charAt(0) + "" + message_client_last_name.charAt(0) : ""}</div>
                        )}
                      </div>
                      <div className="ps-3">
                        <div>
                          <h6 className='fw-semibold mb-0'>{`${message_client_first_name} ${message_client_last_name}`}</h6>
                        </div>
                        <div>
                          <p className='fw-semibold mb-0'>{messages}</p>
                          <div>
                            <span className='color-wine'>{datedifference}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-md-4 ps-md-0 d-flex justify-content-end align-items-center">
                    {iconUrl ? (
                      <span
                        onClick={() => {
                          setLoader((prevLoader) => ({ ...prevLoader, [index]: true })); // Set loader to true for the clicked index
                          dispatch(NotificationUpdateApi({id,is_seen:1})).then((action)=>{
                            if(action.meta.requestStatus === "fulfilled"){
                              dispatch(NotificationListApi());
                            }
                          });
                          dispatch(ClientDetailApi({ id: message_client_id })).then((action) => {
                            setLoader((prevLoader) => ({ ...prevLoader, [index]: false }));
                            dispatch(ClientDetailTab("chatmessages"));
                            if (action.meta.requestStatus === "fulfilled") {
                              dispatch(ClientchatGridViewApi({ client_id: message_client_id }));
                              dispatch(OpenClientDetailModal(true));
                              setTimeout(() => {
                                dispatch(OpenClientDetailModal("open"));
                              }, config.modalOpenTimeOut);
                            }
                          });
                        }}>
                        {loader[index] ? (
                          <MoonLoader color="#8c1c4d" size="22px" />
                        ) : (
                          <img src={config.imagepath + iconUrl} alt="" className="rounded-circle wh-25 profile-image-obj" />
                        )}
                      </span>
                    ) : ""}
                  </div>
                </div>
              </div>
            )
          })}
        </>
      ) : (
        <div className="complete-box text-center d-flex flex-column justify-content-center mt-5">
          <div className="complete-box-wrp text-center">
            {/* <img src={config.imagepath + "bellnotifications.png"} alt="" className="mb-md-4 mb-3" /> */}
            <img src={config.imagepath + "notification-bell.png"} alt="" className="mb-md-4 mb-3" />
            <h5 className="mb-2 fw-semibold" style={{ color: 'rgb(151,136,136)' }}>{t("No notification found")}</h5>
          </div>
        </div>
      )}
    </div>
  )
}

export default MessageNotificationList;