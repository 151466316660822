import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import saleApiController from "../../services/sale.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const saleStoreApi = createAsyncThunk("sale/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const saleUpdateApi = createAsyncThunk("sale/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const InvoiceListViewApi = createAsyncThunk("sale/invoicelistview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .invoiceview(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "invoicelistview"))
      .catch((error) => HandleError(thunkAPI, error, "invoicelistview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreateInvoiceListViewApi = createAsyncThunk("sale/createinvoicelistview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .createinvoiceview(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "createinvoicelistview"))
      .catch((error) => HandleError(thunkAPI, error, "createinvoicelistview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const saleDetailApi = createAsyncThunk("sale/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const saleDeleteApi = createAsyncThunk("sale/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleServiceApi = createAsyncThunk("sale/services", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .services(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "services"))
      .catch((error) => HandleError(thunkAPI, error, "services"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleProductApi = createAsyncThunk("sale/products", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .products(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "products"))
      .catch((error) => HandleError(thunkAPI, error, "products"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleProductStaffDropdownApi = createAsyncThunk("sale/productsstafflists", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .productstafflist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "saleproductsstafflists"))
      .catch((error) => HandleError(thunkAPI, error, "saleproductsstafflists"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleSubscriptionStaffDropdownApi = createAsyncThunk("sale/subscriptionsstafflists", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .subscriptionstafflist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "salesubscriptionsstafflists"))
      .catch((error) => HandleError(thunkAPI, error, "salesubscriptionsstafflists"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleVoucherApi = createAsyncThunk("sale/vouchers", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .vouchers(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "vouchers"))
      .catch((error) => HandleError(thunkAPI, error, "vouchers"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleMembershipApi = createAsyncThunk("sale/membership", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .membership(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "membership"))
      .catch((error) => HandleError(thunkAPI, error, "membership"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleSubscriptionApi = createAsyncThunk("sale/subscription", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .subscription(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "subscription"))
      .catch((error) => HandleError(thunkAPI, error, "subscription"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleServiceToCartApi = createAsyncThunk("sale/servicetocart", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .services(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "servicetocart"))
      .catch((error) => HandleError(thunkAPI, error, "servicetocart"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleProductToCartApi = createAsyncThunk("sale/producttocart", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .products(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "producttocart"))
      .catch((error) => HandleError(thunkAPI, error, "producttocart"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleVoucherToCartApi = createAsyncThunk("sale/vouchertocart", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .vouchers(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "vouchertocart"))
      .catch((error) => HandleError(thunkAPI, error, "vouchertocart"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleMembershipToCartApi = createAsyncThunk("sale/membershiptocart", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .membership(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "membershiptocart"))
      .catch((error) => HandleError(thunkAPI, error, "membershiptocart"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleSubscriptionToCartApi = createAsyncThunk("sale/subscriptiontocart", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .subscription(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "subscriptiontocart"))
      .catch((error) => HandleError(thunkAPI, error, "subscriptiontocart"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientSuggetionListApi = createAsyncThunk("sale/clientsuggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .clientsuggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleEmailInvoiceApi = createAsyncThunk("sale/saleemailinvoice", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .sendEmailInvoice(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "saleemailinvoice"))
      .catch((error) => HandleError(thunkAPI, error, "saleemailinvoice"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const VoucherApplyApi = createAsyncThunk("sale/voucherapply", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .voucherapply(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "voucherapply"))
      .catch((error) => HandleError(thunkAPI, error, "voucherapply"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ReturnPaymentApi = createAsyncThunk("sale/returnpayment", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .returnpayment(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "returnpayment"))
      .catch((error) => HandleError(thunkAPI, error, "returnpayment"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SendEmailVoucherApi = createAsyncThunk("sale/sendemailvoucher", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .sendemailvoucher(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "sendemailvoucher"))
      .catch((error) => HandleError(thunkAPI, error, "sendemailvoucher"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SaleServicesManageApi = createAsyncThunk("sale/saleservicesmanage", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .saleservicesmanage(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "saleservicesmanage"))
      .catch((error) => HandleError(thunkAPI, error, "saleservicesmanage"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const saleInvoiceToCartApi = createAsyncThunk("sale/invoicetocart", async (formValues, thunkAPI) => {
  try {
    const resposedata = await saleApiController
      .createinvoiceview(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "invoicetocart"))
      .catch((error) => HandleError(thunkAPI, error, "invoicetocart"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isOpenedAddForm: "",
  isOpenedVoucherToForm: "",
  isOpenedDetailModal: "",
  isTabView: "viewinvoice",
  isSaleTabView: "services",
  isInvoiceListView: [],
  isCreateInvoiceListView: [],
  isDetailData: "",
  isServices: [],
  isServiceSearch: "",
  isServiceSearchName: "",
  isProducts: [],
  isProductSearch: "",
  isProductSearchName: "",
  isCart: { appointments: [], services: [], products: [], vouchers: [], membership: [], oneoffvoucher: [], subscription: [] },
  isCartTotalPrice: [],
  isAppointmentDetail: "",
  isSuggetionListView: [],
  isSearchList: "",
  isSearchName: "",
  isSearchObj: "",
  isVouchers: [],
  isMembership: [],
  isSubscription: [],
  isVoucherToFormData: [],
  isCartVoucherCount: [],
  isOpenedCheckoutForm: "",
  isOpenSubscriptionCheckoutForm: "",
  isCheckoutData: [],
  isOpenedSaleCompleted: "",
  isSaleCompletedData: "",
  isOpenCardPaymentForm: "",
  isCardPaymentData: "",
  isOpenedVoucherApplyForm: "",
  isApplyVoucherCodeData: "",
  isSaleServicesManage: "",
  isSendEmailVoucher: "",
  isSaleProductStaffDropdownList: [],
  isSaleSubscriptionStaffDropdownList: [],
  isApiStatus: {
    saleStoreApi: "",
    saleUpdateApi: "",
    InvoiceListViewApi: "",
    CreateInvoiceListViewApi: "",
    saleDetailApi: "",
    saleDeleteApi: "",
    SaleServiceApi: "",
    SaleProductApi: "",
    SaleVoucherApi: "",
    SaleMembershipApi: "",
    SaleSubscriptionApi: "",
    SaleServiceToCartApi: "",
    SaleProductToCartApi: "",
    SaleVoucherToCartApi: "",
    SaleMembershipToCartApi: "",
    SaleSubscriptionToCartApi: "",
    ClientSuggetionListApi: "",
    SaleEmailInvoiceApi: "",
    VoucherApplyApi: "",
    ReturnPaymentApi: "",
    SendEmailVoucherApi: "",
    SaleServicesManageApi: "",
    SaleProductStaffDropdownApi: "",
    saleInvoiceToCartApi: "",
  },
};

const saleSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {
    reset: () => initialState,
    InvoiceTabView: (state, action) => {
      state.isTabView = action.payload;
    },
    SaleTabView: (state, action) => {
      state.isSaleTabView = action.payload;
    },
    openAddSaleForm: (state = initialState) => {
      // state.isOpenedEditForm = "";
      state.isOpenedAddForm = "open";
    },
    closeAddSaleForm: (state = initialState) => {
      // state.isOpenedEditForm = "";
      state.isOpenedAddForm = "";
    },
    OpenVoucherToForm: (state = initialState) => {
      // state.isOpenedEditForm = "";
      state.isOpenedVoucherToForm = "open";
    },
    CloseVoucherToForm: (state = initialState) => {
      // state.isOpenedEditForm = "";
      state.isOpenedVoucherToForm = "";
    },
    OpenVoucherApplyForm: (state = initialState) => {
      // state.isOpenedEditForm = "";
      state.isOpenedVoucherApplyForm = "open";
    },
    CloseVoucherApplyForm: (state = initialState) => {
      // state.isOpenedEditForm = "";
      state.isOpenedVoucherApplyForm = "";
    },
    VoucherToFormData: (state, action) => {
      state.isVoucherToFormData = action.payload;
    },
    openSaleDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "open";
    },
    closeSaleDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "";
    },
    SaleProductSearchName: (state, action) => {
      state.isProductSearchName = action.payload;
    },
    SaleServiceSearchName: (state, action) => {
      state.isServiceSearchName = action.payload;
    },
    SaleServiceRemoveToCart: (state, action) => {
      const { id } = action.payload;
      state.isCart.services = state.isCart.services ? state.isCart.services.filter((item) => item.id != id) : [];
    },
    SaleProductRemoveToCart: (state, action) => {
      const { id } = action.payload;
      state.isCart.products = state.isCart.products ? state.isCart.products.filter((item) => item.id != id) : [];
    },
    SaleVoucherRemoveToCart: (state, action) => {
      const { id } = action.payload;
      state.isCart.vouchers = state.isCart.vouchers ? state.isCart.vouchers.filter((item) => item.id != id) : [];
    },
    SaleOneOffVoucherRemoveToCart: (state, action) => {
      const { id } = action.payload;
      state.isCart.oneoffvoucher = state.isCart.oneoffvoucher ? state.isCart.oneoffvoucher.filter((item) => item.id != id) : [];
      // state.isCart.oneoffvoucher = state.isCart.oneoffvoucher ? state.isCart.oneoffvoucher.slice(0, i).concat(state.isCart.oneoffvoucher.slice(i + 1, state.isCart.oneoffvoucher.length)) : [];
    },
    SaleMembershipRemoveToCart: (state, action) => {
      const { id } = action.payload;
      state.isCart.membership = state.isCart.membership ? state.isCart.membership.filter((item) => item.id != id) : [];
    },
    SaleSubscriptionRemoveToCart: (state, action) => {
      const { id } = action.payload;
      state.isCart.subscription = state.isCart.subscription ? state.isCart.subscription.filter((item) => item.id != id) : [];
    },
    AppointmentDetail: (state, action) => {
      state.isAppointmentDetail = action.payload;
    },
    OpenClientSearchList: (state) => {
      state.isSearchList = "open";
    },
    CloseClientSearchList: (state) => {
      state.isSearchList = "";
    },
    ClientSearchName: (state, action) => {
      state.isSearchName = action.payload;
    },
    ClientSearchObj: (state, action) => {
      state.isSearchObj = action.payload;
    },
    SaleOneOffVoucherToCartApi: (state, action) => {
      const { id, ...changes } = action.payload;
      const existingData = state.isCart.oneoffvoucher.find((event) => event.id === id);
      if (existingData) {
        Object.keys(changes).map((keyName) => {
          existingData[keyName] = changes[keyName];
        });
      } else {
        action.payload = { ...action.payload, id: state.isCart.oneoffvoucher.length + 1 };
        const oneoffvoucher = [...state.isCart.oneoffvoucher, action.payload];
        state.isCart.oneoffvoucher = oneoffvoucher;
      }
    },
    SaleAppointmentToCartApi: (state, action) => {
      const { id, ...changes } = action.payload;
      const existingData = state.isCart.appointments.find((event) => event.id === id);
      if (existingData) {
        Object.keys(changes).map((keyName) => {
          existingData[keyName] = changes[keyName];
        });
      } else {
        action.payload = { ...action.payload, id: state.isCart.appointments.length + 1 };
        const appointments = [...state.isCart.appointments, action.payload];
        state.isCart.appointments = appointments;
      }
    },
    SaleCheckoutData: (state, action) => {
      state.isCheckoutData = action.payload;
      //console.log('action.payload: ', action.payload);
      // state.isCart = action.payload;
    },
    OpenCheckoutForm: (state = initialState) => {
      // state.isOpenedEditForm = "";
      state.isOpenedCheckoutForm = "open";
    },
    CloseCheckoutForm: (state = initialState) => {
      // state.isOpenedEditForm = "";
      state.isOpenedCheckoutForm = "";
    },
    OpenSubscriptionCheckoutForm:(state,action)=>{
      state.isOpenSubscriptionCheckoutForm = action.payload;
    },
    SaleCartUpdate: (state, action) => {
      let cartupdate = action.payload;
      if (cartupdate) {
        const { id, ...changes } = action.payload;
        let existingData = "";
        if (cartupdate.type === "products") {
          existingData = state.isCart.products.find((event) => event.id === id);
        }
        if (cartupdate.type === "services") {
          existingData = state.isCart.services.find((event) => event.id === id);
        }
        if (cartupdate.type === "subscription") {
          existingData = state.isCart.subscription.find((event) => event.id === id);
        }
        if (cartupdate.type === "appointments") {
          existingData = state.isCart.appointments.find((event) => event.id === id);
        }
        if (cartupdate.type === "appointments_addon") {
          existingData = state.isCart.appointments[0].addonservices.find((event) => event.id === id);
        }
        if (cartupdate.type === "appointments_addanother") {
          existingData = state.isCart.appointments[0].addanotherservices.find((event) => event.id === id);
        }
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
      }
    },
    OpenSaleCompleted: (state = initialState) => {
      state.isOpenedSaleCompleted = "open";
    },
    CloseSaleCompleted: (state = initialState) => {
      state.isOpenedSaleCompleted = "";
    },
    SaleCompletedData: (state, action) => {
      state.isSaleCompletedData = action.payload;
    },
    OpenCardPaymentForm: (state = initialState) => {
      // state.isOpenedEditForm = "";
      state.isOpenCardPaymentForm = "open";
    },
    CloseCardPaymentForm: (state = initialState) => {
      // state.isOpenedEditForm = "";
      state.isOpenCardPaymentForm = "";
    },
    CardPaymentData: (state, action) => {
      state.isCardPaymentData = action.payload;
    },
    RemoveApplyVoucherCode: (state) => {
      state.isApplyVoucherCodeData = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saleInvoiceToCartApi.pending, (state) => {
        state.isApiStatus.saleInvoiceToCartApi = 'loading';
      })
      .addCase(saleInvoiceToCartApi.fulfilled, (state, action) => {
        state.isApiStatus.saleInvoiceToCartApi = 'succeeded';
      })
      .addCase(saleInvoiceToCartApi.rejected, (state) => {
        state.isApiStatus.saleInvoiceToCartApi = 'failed';
      })
      .addCase(saleStoreApi.pending, (state) => {
        state.isApiStatus.saleStoreApi = 'loading';
      })
      .addCase(saleStoreApi.fulfilled, (state, action) => {
        if (state.isInvoiceListView && state.isInvoiceListView.data) {
          state.isInvoiceListView.data = [action.payload, ...state.isInvoiceListView.data];
        } else {
          state.isInvoiceListView = { data: [action.payload] };
        }
        state.isApiStatus.saleStoreApi = 'succeeded';
      })
      .addCase(saleStoreApi.rejected, (state) => {
        state.isApiStatus.saleStoreApi = 'failed';
      })
      .addCase(saleUpdateApi.pending, (state) => {
        state.isApiStatus.saleUpdateApi = 'loading';
      })
      .addCase(saleUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isInvoiceListView.data.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus.saleUpdateApi = 'succeeded';
      })
      .addCase(saleUpdateApi.rejected, (state) => {
        state.isApiStatus.saleUpdateApi = 'failed';
      })
      .addCase(InvoiceListViewApi.pending, (state) => {
        state.isApiStatus.InvoiceListViewApi = 'loading';
      })
      .addCase(InvoiceListViewApi.fulfilled, (state, action) => {
        const oldCurrentPage = state.isInvoiceListView.current_page || "";
        const newCurrentPage = action.payload.current_page || "";
        const viewData = state.isInvoiceListView && state.isInvoiceListView.data;
        const newViewData = action.payload && action.payload.data;
        state.isInvoiceListView = action.payload;
        if (oldCurrentPage && newCurrentPage && oldCurrentPage < newCurrentPage && oldCurrentPage !== newCurrentPage) {
          state.isInvoiceListView.data = viewData && newViewData ? [...viewData, ...newViewData] : action.payload;
        }
        state.isApiStatus.InvoiceListViewApi = 'succeeded';
      })
      .addCase(InvoiceListViewApi.rejected, (state) => {
        state.isApiStatus.InvoiceListViewApi = 'failed';
        state.isInvoiceListView = [];
      })
      .addCase(CreateInvoiceListViewApi.pending, (state) => {
        state.isApiStatus.CreateInvoiceListViewApi = 'loading';
      })
      .addCase(CreateInvoiceListViewApi.fulfilled, (state, action) => {
        const oldCurrentPage = state.isCreateInvoiceListView.current_page || "";
        const newCurrentPage = action.payload.current_page || "";
        const viewData = state.isCreateInvoiceListView && state.isCreateInvoiceListView.data;
        const newViewData = action.payload && action.payload.data;
        state.isCreateInvoiceListView = action.payload;
        if (oldCurrentPage && newCurrentPage && oldCurrentPage < newCurrentPage && oldCurrentPage !== newCurrentPage) {
          state.isCreateInvoiceListView.data = viewData && newViewData ? [...viewData, ...newViewData] : action.payload;
        }
        state.isApiStatus.CreateInvoiceListViewApi = 'succeeded';
      })
      .addCase(CreateInvoiceListViewApi.rejected, (state) => {
        state.isApiStatus.CreateInvoiceListViewApi = 'failed';
        state.isCreateInvoiceListView = [];
      })
      .addCase(saleDetailApi.pending, (state) => {
        state.isApiStatus.saleDetailApi = 'loading';
      })
      .addCase(saleDetailApi.fulfilled, (state, action) => {
        state.isApiStatus.saleDetailApi = 'succeeded';
        state.isDetailData = action.payload;
      })
      .addCase(saleDetailApi.rejected, (state) => {
        state.isApiStatus.saleDeleteApi = 'failed';
        state.isDetailData = "";
      })
      .addCase(saleDeleteApi.pending, (state) => {
        state.isApiStatus.saleDeleteApi = 'loading';
      })
      .addCase(saleDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isApiStatus.saleDeleteApi = 'succeeded';
        state.isInvoiceListView.data = state.isInvoiceListView.data ? state.isInvoiceListView.data.filter((item) => item.id !== id) : state.isInvoiceListView.filter((item) => item.id !== id);
      })
      .addCase(saleDeleteApi.rejected, (state) => {
        state.isApiStatus.saleDeleteApi = 'failed';
      })
      .addCase(SaleServiceApi.pending, (state) => {
        state.isApiStatus.SaleServiceApi = 'loading';
      })
      .addCase(SaleServiceApi.fulfilled, (state, action) => {
        const oldCurrentPage = state.isServices.current_page || "";
        const newCurrentPage = action.payload.current_page || "";
        const viewData = state.isServices && state.isServices.data;
        const newViewData = action.payload && action.payload.data;
        state.isServices = action.payload;
        if (oldCurrentPage && newCurrentPage && oldCurrentPage < newCurrentPage && oldCurrentPage !== newCurrentPage) {
          state.isServices.data = viewData && newViewData ? [...viewData, ...newViewData] : action.payload;
        }
        state.isApiStatus.SaleServiceApi = 'succeeded';
      })
      .addCase(SaleServiceApi.rejected, (state) => {
        state.isServices = [];
        state.isApiStatus.SaleServiceApi = 'failed';
      })
      .addCase(SaleProductApi.pending, (state) => {
        state.isApiStatus.SaleProductApi = 'loading';
      })
      .addCase(SaleProductApi.fulfilled, (state, action) => {
        const oldCurrentPage = state.isProducts.current_page || "";
        const newCurrentPage = action.payload.current_page || "";
        const viewData = state.isProducts && state.isProducts.data;
        const newViewData = action.payload && action.payload.data;
        state.isProducts = action.payload;
        if (oldCurrentPage && newCurrentPage && oldCurrentPage < newCurrentPage && oldCurrentPage !== newCurrentPage) {
          state.isProducts.data = viewData && newViewData ? [...viewData, ...newViewData] : action.payload;
        }
        state.isApiStatus.SaleProductApi = 'succeeded';
      })
      .addCase(SaleProductApi.rejected, (state) => {
        state.isProducts = [];
        state.isApiStatus.SaleProductApi = 'failed';
      })
      .addCase(SaleVoucherApi.pending, (state) => {
        state.isApiStatus.SaleVoucherApi = 'loading';
      })
      .addCase(SaleVoucherApi.fulfilled, (state, action) => {
        const oldCurrentPage = state.isVouchers.current_page || "";
        const newCurrentPage = action.payload.current_page || "";
        const viewData = state.isVouchers && state.isVouchers.data;
        const newViewData = action.payload && action.payload.data;
        state.isVouchers = action.payload;
        if (oldCurrentPage && newCurrentPage && oldCurrentPage < newCurrentPage && oldCurrentPage !== newCurrentPage) {
          state.isVouchers.data = viewData && newViewData ? [...viewData, ...newViewData] : action.payload;
        }
        state.isApiStatus.SaleVoucherApi = 'succeeded';
      })
      .addCase(SaleVoucherApi.rejected, (state) => {
        state.isVouchers = [];
        state.isApiStatus.SaleVoucherApi = 'failed';
      })
      .addCase(SaleMembershipApi.pending, (state) => {
        state.isApiStatus.SaleMembershipApi = 'loading';
      })
      .addCase(SaleMembershipApi.fulfilled, (state, action) => {
        const oldCurrentPage = state.isMembership.current_page || "";
        const newCurrentPage = action.payload.current_page || "";
        const viewData = state.isMembership && state.isMembership.data;
        const newViewData = action.payload && action.payload.data;
        state.isMembership = action.payload;
        if (oldCurrentPage && newCurrentPage && oldCurrentPage < newCurrentPage && oldCurrentPage !== newCurrentPage) {
          state.isMembership.data = viewData && newViewData ? [...viewData, ...newViewData] : action.payload;
        }
        state.isApiStatus.SaleMembershipApi = 'succeeded';
      })
      .addCase(SaleMembershipApi.rejected, (state) => {
        state.isMembership = [];
        state.isApiStatus.SaleMembershipApi = 'failed';
      })
      .addCase(SaleSubscriptionApi.pending, (state) => {
        state.isApiStatus.SaleSubscriptionApi = 'loading';
      })
      .addCase(SaleSubscriptionApi.fulfilled, (state, action) => {
        const oldCurrentPage = state.isSubscription.current_page || "";
        const newCurrentPage = action.payload.current_page || "";
        const viewData = state.isSubscription && state.isSubscription.data;
        const newViewData = action.payload && action.payload.data;
        state.isSubscription = action.payload;
        if (oldCurrentPage && newCurrentPage && oldCurrentPage < newCurrentPage && oldCurrentPage !== newCurrentPage) {
          state.isSubscription.data = viewData && newViewData ? [...viewData, ...newViewData] : action.payload;
        }
        state.isApiStatus.SaleSubscriptionApi = 'succeeded';
      })
      .addCase(SaleSubscriptionApi.rejected, (state) => {
        state.isSubscription = [];
        state.isApiStatus.SaleSubscriptionApi = 'failed';
      })
      .addCase(SaleServiceToCartApi.pending, (state) => {
        state.isApiStatus.SaleServiceToCartApi = 'loading';
      })
      .addCase(SaleServiceToCartApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isCart.services.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        } else {
          const services = [...state.isCart.services, action.payload];
          state.isCart.services = services;
        }
        state.isApiStatus.SaleServiceToCartApi = 'succeeded';
      })
      .addCase(SaleServiceToCartApi.rejected, (state) => {
        state.isCart.services = [];
        state.isApiStatus.SaleServiceToCartApi = 'failed';
      })
      .addCase(SaleProductToCartApi.pending, (state) => {
        state.isApiStatus.SaleProductToCartApi = 'loading';
      })
      .addCase(SaleProductToCartApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isCart.products.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        } else {
          const products = [...state.isCart.products, action.payload];
          state.isCart.products = products;
        }
        state.isApiStatus.SaleProductToCartApi = 'succeeded';
      })
      .addCase(SaleProductToCartApi.rejected, (state) => {
        state.isCart.products = [];
        state.isApiStatus.SaleProductToCartApi = 'failed';
      })
      .addCase(SaleVoucherToCartApi.pending, (state) => {
        state.isApiStatus.SaleVoucherToCartApi = 'loading';
      })
      .addCase(SaleVoucherToCartApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isCart.vouchers.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        } else {
          const vouchers = [...state.isCart.vouchers, action.payload];
          state.isCart.vouchers = vouchers;
        }
        state.isApiStatus.SaleVoucherToCartApi = 'succeeded';
      })
      .addCase(SaleVoucherToCartApi.rejected, (state) => {
        state.isCart.vouchers = [];
        state.isApiStatus.SaleVoucherToCartApi = 'failed';
      })
      .addCase(SaleMembershipToCartApi.pending, (state) => {
        state.isApiStatus.SaleMembershipToCartApi = 'loading';
      })
      .addCase(SaleMembershipToCartApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isCart.membership.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        } else {
          const membership = [...state.isCart.membership, action.payload];
          state.isCart.membership = membership;
        }
        state.isApiStatus.SaleMembershipToCartApi = 'succeeded';
      })
      .addCase(SaleMembershipToCartApi.rejected, (state) => {
        state.isCart.membership = [];
        state.isApiStatus.SaleMembershipToCartApi = 'failed';
      })
      .addCase(SaleSubscriptionToCartApi.pending, (state) => {
        state.isApiStatus.SaleSubscriptionToCartApi = 'loading';
      })
      .addCase(SaleSubscriptionToCartApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isCart.subscription.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        } else {
          const subscription = [...state.isCart.subscription, action.payload];
          state.isCart.subscription = subscription;
        }
        state.isApiStatus.SaleSubscriptionToCartApi = 'succeeded';
      })
      .addCase(SaleSubscriptionToCartApi.rejected, (state) => {
        state.isCart.subscription = [];
        state.isApiStatus.SaleSubscriptionToCartApi = 'failed';
      })
      .addCase(ClientSuggetionListApi.pending, (state) => {
        state.isApiStatus.ClientSuggetionListApi = 'loading';
      })
      .addCase(ClientSuggetionListApi.fulfilled, (state, action) => {
        let old_current_page = state.isSuggetionListView.current_page ? state.isSuggetionListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isSuggetionListView && state.isSuggetionListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isSuggetionListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isSuggetionListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus.ClientSuggetionListApi = 'succeeded';
      })
      .addCase(ClientSuggetionListApi.rejected, (state) => {
        state.isSuggetionListView = [];
        state.isApiStatus.ClientSuggetionListApi = 'failed';
      })
      .addCase(SaleEmailInvoiceApi.pending, (state) => {
        state.isApiStatus.SaleEmailInvoiceApi = 'loading';
      })
      .addCase(SaleEmailInvoiceApi.fulfilled, (state) => {
        state.isApiStatus.SaleEmailInvoiceApi = 'succeeded';
      })
      .addCase(SaleEmailInvoiceApi.rejected, (state) => {
        state.isApiStatus.SaleEmailInvoiceApi = 'failed';
      })
      .addCase(VoucherApplyApi.pending, (state) => {
        state.isApiStatus.VoucherApplyApi = 'loading';
      })
      .addCase(VoucherApplyApi.fulfilled, (state, action) => {
        state.isApiStatus.VoucherApplyApi = 'succeeded';
        state.isApplyVoucherCodeData = action.payload;
      })
      .addCase(VoucherApplyApi.rejected, (state) => {
        state.isApiStatus.VoucherApplyApi = 'failed';
      })
      .addCase(ReturnPaymentApi.pending, (state) => {
        state.isApiStatus.ReturnPaymentApi = 'loading';
      })
      .addCase(ReturnPaymentApi.fulfilled, (state) => {
        state.isApiStatus.ReturnPaymentApi = 'succeeded';
      })
      .addCase(ReturnPaymentApi.rejected, (state) => {
        state.isApiStatus.ReturnPaymentApi = 'failed';
      })
      .addCase(SendEmailVoucherApi.pending, (state) => {
        state.isApiStatus.SendEmailVoucherApi = 'loading';
      })
      .addCase(SendEmailVoucherApi.fulfilled, (state, action) => {
        state.isApiStatus.SendEmailVoucherApi = 'succeeded';
        state.isSendEmailVoucher = action.payload;
      })
      .addCase(SendEmailVoucherApi.rejected, (state) => {
        state.isApiStatus.SendEmailVoucherApi = 'failed';
        state.isSendEmailVoucher = "";
      })
      .addCase(SaleServicesManageApi.pending, (state) => {
        state.isApiStatus.SaleServicesManageApi = 'loading';
      })
      .addCase(SaleServicesManageApi.fulfilled, (state, action) => {
        state.isApiStatus.SaleServicesManageApi = 'succeeded';
        state.isSaleServicesManage = action.payload;
      })
      .addCase(SaleServicesManageApi.rejected, (state) => {
        state.isSaleServicesManage = "";
        state.isApiStatus.SaleServicesManageApi = 'failed';
      })
      .addCase(SaleProductStaffDropdownApi.pending, (state) => {
        state.isApiStatus.SaleProductStaffDropdownApi = 'loading';
      })
      .addCase(SaleProductStaffDropdownApi.fulfilled, (state, action) => {
        state.isApiStatus.SaleProductStaffDropdownApi = 'succeeded';
        state.isSaleProductStaffDropdownList = action.payload;
      })
      .addCase(SaleProductStaffDropdownApi.rejected, (state) => {
        state.isSaleProductStaffDropdownList = [];
        state.isApiStatus.SaleProductStaffDropdownApi = 'failed';
      })
      .addCase(SaleSubscriptionStaffDropdownApi.pending, (state) => {
        state.isApiStatus.SaleSubscriptionStaffDropdownApi = 'loading';
      })
      .addCase(SaleSubscriptionStaffDropdownApi.fulfilled, (state, action) => {
        state.isApiStatus.SaleSubscriptionStaffDropdownApi = 'succeeded';
        state.isSaleSubscriptionStaffDropdownList = action.payload;
      })
      .addCase(SaleSubscriptionStaffDropdownApi.rejected, (state) => {
        state.isSaleSubscriptionStaffDropdownList = [];
        state.isApiStatus.SaleSubscriptionStaffDropdownApi = 'failed';
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, InvoiceTabView, openAddSaleForm, closeAddSaleForm, openSaleDetailModal, closeSaleDetailModal, SaleTabView, SaleProductSearchName, SaleServiceSearchName, SaleServiceRemoveToCart, SaleProductRemoveToCart, AppointmentDetail, OpenClientSearchList, CloseClientSearchList, ClientSearchName, ClientSearchObj, SaleVoucherRemoveToCart, SaleMembershipRemoveToCart, OpenVoucherToForm, CloseVoucherToForm, VoucherToFormData, SaleOneOffVoucherToCartApi, SaleAppointmentToCartApi, SaleOneOffVoucherRemoveToCart, SaleCheckoutData, OpenCheckoutForm, CloseCheckoutForm, SaleCartUpdate, OpenSaleCompleted, CloseSaleCompleted, SaleCompletedData, OpenCardPaymentForm, CloseCardPaymentForm, CardPaymentData, OpenVoucherApplyForm, CloseVoucherApplyForm, SaleSubscriptionRemoveToCart, RemoveApplyVoucherCode,OpenSubscriptionCheckoutForm } = saleSlice.actions;
export default saleSlice.reducer;
