import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import config from "../../../../config";
import AllNotificationList from "./List/AllNotificationList";
import { NotificationListApi, UpcomingNotifyDetailDrawer, UpcomingNotifyDetailTab, notificationClearAllApi, notificationCountApi, notificationMarkAllAsReadApi } from "store/slices/notificationSlice";
import MessageNotificationList from "./List/MessageNotificationList";
import BookingNotificationList from "./List/BookingNotificationList";
import InfiniteScroll from "react-infinite-scroll-component";
import PaginationLoader from "component/PaginationLoader";
import ApiStatus from "component/ApiStatus";
import ClientDetailModal from "pages/clients/Detail";
import ClockLoader from "react-spinners/ClockLoader";

const NotificationDrawer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [activeState, setActiveState] = useState("");
    const rightDrawerOpened = useSelector((state) => state.notification.isUpcomingNotifyDetailDrawer);
    const notificationTabs = useSelector((state) => state.notification.isUpcomingNotifyDetailTab);
    const isNotificationListView = useSelector((state) => state.notification.isNotificationListView);
    const isOpenedDetailModal = useSelector((state) => state.client.isOpenedDetailModal);
    const isApiStatus = useSelector((state) => state.notification.isApiStatus);

    const fetchDataList = () => {
        dispatch(NotificationListApi({ next_page_url: isNotificationListView && isNotificationListView.next_page_url, }));
    };

    const [isFetching, setIsFetching] = useState(false);

    const loadMoreItemsList = () => {
        setIsFetching(true);
        dispatch(NotificationListApi({ next_page_url: isNotificationListView && isNotificationListView.next_page_url, }));
        //mocking an API call
        setTimeout(() => {
            setIsFetching(false);
        }, 2000);
    };

    return (
        <React.Fragment>
            <div className={"drawer p-0 overflow-y-client-detail-auto custom-trantion-css " + rightDrawerOpened}>
                <div className="drawer-header px-3 pt-4 pb-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="fw-semibold mb-0">Notifications</h5>
                        <a className="cursor-pointer" onClick={() => {
                            dispatch(UpcomingNotifyDetailDrawer(""));
                        }}>
                            <img src={config.imagepath + "close-icon.svg"} alt="" />
                        </a>
                    </div>
                    <div className="row bg-white align-items-center sticky-top common-tab">
                        <div className="col-12">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a href="#0" className={"nav-link" + (notificationTabs && notificationTabs == "all_notification" ? " active" : "")} id="all_notification-tab" data-bs-toggle="tab" data-bs-target="#all_notification" type="button" role="tab" aria-controls="all_notification" aria-selected="true" onClick={() => dispatch(UpcomingNotifyDetailTab("all_notification"))}>
                                        {t("All")}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#0" className={"nav-link" + (notificationTabs && notificationTabs == "messages" ? " active" : "")} id="messages-tab" data-bs-toggle="tab" data-bs-target="#messages" type="button" role="tab" aria-controls="messages" aria-selected="true" onClick={() => dispatch(UpcomingNotifyDetailTab("messages"))}>
                                        {t("Messages")}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#0" className={"nav-link" + (notificationTabs && notificationTabs == "online_booking" ? " active" : "")} id="online_booking-tab" data-bs-toggle="tab" data-bs-target="#online_booking" type="button" role="tab" aria-controls="online_booking" aria-selected="true" onClick={() => dispatch(UpcomingNotifyDetailTab("online_booking"))}>
                                        {t("Online Booking")}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="drawer-wrp">
                    <div className="drawer-body custom-drawer-height-notification row">
                        {openModal && (
                            <>
                                <div className={openModal ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openModal ? "block" : "none" }}>
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-body pb-0">
                                                <div className="p-3">
                                                    {activeState && activeState == "mark_as_read" ? (
                                                        <h5 className="mb-3 fw-semibold text-dark">{t("Are you sure you want to mark all as read?")}</h5>
                                                    ) : (
                                                        <h5 className="mb-3 fw-semibold text-dark">{t("Are you sure you want to clear all notification?")}</h5>
                                                    )}
                                                </div>
                                                <div className="row mt-3 bg-cream p-4 justify-content-center">
                                                    <div className="col-6">
                                                        <button className="preview btn me-1 cursor-pointer btn-preview w-100" type="button" onClick={() => {
                                                            setOpenModal(false);
                                                        }}>
                                                            {t("Cancel")}
                                                        </button>
                                                    </div>
                                                    <div className="col-6">
                                                        <button className="btn btn-primary w-100" type="button"
                                                            disabled={isApiStatus && (isApiStatus.NotificationListApi || isApiStatus.notificationClearAllApi || isApiStatus.notificationMarkAllAsReadApi) === "loading" ? true : false}
                                                            onClick={() => {
                                                                if (activeState && activeState == "mark_as_read") {
                                                                    dispatch(notificationMarkAllAsReadApi()).then((action) => {
                                                                        if (action.meta.requestStatus === "fulfilled") {
                                                                            dispatch(NotificationListApi()).then((actions1) => {
                                                                                dispatch(notificationCountApi());
                                                                                setOpenModal(false);
                                                                            });
                                                                        }
                                                                    })
                                                                } else if (activeState && activeState === "delete_all") {
                                                                    dispatch(notificationClearAllApi()).then((action) => {
                                                                        if (action.meta.requestStatus === "fulfilled") {
                                                                            dispatch(NotificationListApi()).then((actions1) => {
                                                                                dispatch(notificationCountApi());
                                                                                setOpenModal(false);
                                                                            });
                                                                        }
                                                                    })
                                                                }
                                                            }}>
                                                            {isApiStatus && (isApiStatus.NotificationListApi || isApiStatus.notificationClearAllApi || isApiStatus.notificationMarkAllAsReadApi) === "loading" ? <span className="spinner-border spinner-border-sm"></span> : ""}
                                                            {t("Confirm")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {openModal && <div className="modal-backdrop show"></div>}
                            </>
                        )}
                        <div className={`content col-md-2 position-relative w-100 p-0`}>
                            <div className="tab-content" id="myTabContent">
                                {isNotificationListView && isNotificationListView.data && isNotificationListView.data.length > 0 && isApiStatus && isApiStatus.NotificationListApi !== "loading" ? (
                                    <>
                                        <div className={"tab-pane fade" + (notificationTabs && notificationTabs == "all_notification" ? " show active" : "")} id="all_notification-tab" role="tabpanel" aria-labelledby="all_notification-tab">
                                            {notificationTabs && notificationTabs == "all_notification" ? (
                                                <>
                                                    <InfiniteScroll dataLength={isNotificationListView.data.length} next={fetchDataList} scrollableTarget="clientdetail_appointment" hasMore={isNotificationListView.next_page_url ? true : false} loader={<PaginationLoader />} style={{ overflow: isNotificationListView.next_page_url ? "auto" : "inherit" }}>
                                                        <AllNotificationList view={isNotificationListView} />
                                                        {!isFetching && isNotificationListView.next_page_url && (
                                                            <div className="col-2 m-auto p-3 text-center">
                                                                <button onClick={loadMoreItemsList} className="btn btn-primary">
                                                                    {t("More")}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </InfiniteScroll>
                                                </>
                                            ) : ""}
                                        </div>
                                        <div className={"tab-pane fade" + (notificationTabs && notificationTabs == "messages" ? " show active" : "")} id="messages-tab" role="tabpanel" aria-labelledby="messages-tab">
                                            {notificationTabs && notificationTabs == "messages" ? (
                                                <>
                                                    <InfiniteScroll dataLength={isNotificationListView.data.length} next={fetchDataList} scrollableTarget="clientdetail_appointment" hasMore={isNotificationListView.next_page_url ? true : false} loader={<PaginationLoader />} style={{ overflow: isNotificationListView.next_page_url ? "auto" : "inherit" }}>
                                                        <MessageNotificationList view={isNotificationListView} />
                                                        {!isFetching && isNotificationListView.next_page_url && (
                                                            <div className="col-2 m-auto p-3 text-center">
                                                                <button onClick={loadMoreItemsList} className="btn btn-primary">
                                                                    {t("More")}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </InfiniteScroll>
                                                </>
                                            ) : ""}
                                        </div>
                                        <div className={"tab-pane fade" + (notificationTabs && notificationTabs == "online_booking" ? " show active" : "")} id="online_booking-tab" role="tabpanel" aria-labelledby="online_booking-tab">
                                            {notificationTabs && notificationTabs == "online_booking" ? (
                                                <>
                                                    <InfiniteScroll dataLength={isNotificationListView.data.length} next={fetchDataList} scrollableTarget="clientdetail_appointment" hasMore={isNotificationListView.next_page_url ? true : false} loader={<PaginationLoader />} style={{ overflow: isNotificationListView.next_page_url ? "auto" : "inherit" }}>
                                                        <BookingNotificationList view={isNotificationListView} />
                                                        {!isFetching && isNotificationListView.next_page_url && (
                                                            <div className="col-2 m-auto p-3 text-center">
                                                                <button onClick={loadMoreItemsList} className="btn btn-primary">
                                                                    {t("More")}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </InfiniteScroll>
                                                </>
                                            ) : ""}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {isApiStatus && isApiStatus.NotificationListApi === "loading" ? (
                                            <ApiStatus actionType="notificationslistview" />
                                        ) : (
                                            <div className="complete-box text-center d-flex flex-column justify-content-center mt-5">
                                                <div className="complete-box-wrp text-center">
                                                    {/* <img src={config.imagepath + "bellnotifications.png"} alt="" className="mb-md-4 mb-3" /> */}
                                                    <img src={config.imagepath + "notification-bell.png"} alt="" className="mb-md-4 mb-3" />
                                                    <h5 className="mb-2 fw-semibold" style={{ color: 'rgb(151,136,136)' }}>{t("No notification found")}</h5>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {isNotificationListView && isNotificationListView.data && isNotificationListView.data.length > 0 ? (
                        <div className="custom-drawer-buttons-notification-bar">
                            <div className="d-flex justify-content-end py-2">
                                <button className="btn btn-primary btn-sm btn-pills me-3" type="button" onClick={() => {
                                    setOpenModal(true);
                                    setActiveState("mark_as_read");
                                }}>Mark all as read</button>
                                <button className="btn btn-apricots btn-sm btn-pills padding-x-2" type="button" onClick={() => {
                                    setOpenModal(true);
                                    setActiveState("delete_all");
                                }}>Clear All</button>
                            </div>
                        </div>
                        ) : ""}
                    </div>
                </div>
            </div>
            {isOpenedDetailModal && <ClientDetailModal />}
        </React.Fragment >
    );
};

export default NotificationDrawer;
