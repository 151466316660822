import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import dashboardApiController from "../../services/dashboard.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const UpcomingAppointmentListViewApi = createAsyncThunk("dashboard/UpcomingAppointmentListView", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await dashboardApiController
      .upcomingappointment(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "UpcomingAppointmentListView"))
      .catch((error) => HandleError(thunkAPI, error, "UpcomingAppointmentListView"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const DashboardViewApi = createAsyncThunk("dashboard/DashboardView", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await dashboardApiController
      .dashboardview(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "DashboardView"))
      .catch((error) => HandleError(thunkAPI, error, "DashboardView"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

const initialState = {
  isUpComingAppointment: "",
  isDashboard: { appointments: 0, averageBookingValue: 0, numberofClients: 0, numberofNewClients: 0 },
  issendDataToCalendars:"",
  isApiStatus: {
    UpcomingAppointmentListViewApi: "",
    DashboardViewApi: "",
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    reset: () => initialState,
    SendDataToCalendars:(state,action)=>{
      state.issendDataToCalendars = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(UpcomingAppointmentListViewApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, UpcomingAppointmentListViewApi: "loading" };
    })
    .addCase(UpcomingAppointmentListViewApi.fulfilled, (state, action) => {
      let old_current_page = state.isUpComingAppointment.current_page ? state.isUpComingAppointment.current_page : "";
      let new_current_page = action.payload.current_page ? action.payload.current_page : "";
      let viewdata = state.isUpComingAppointment && state.isUpComingAppointment.data;
      let newviewdata = action.payload && action.payload.data;
      state.isUpComingAppointment = action.payload;
      if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
        viewdata && newviewdata ? (state.isUpComingAppointment.data = [...viewdata, ...newviewdata]) : action.payload;
      }
      state.isApiStatus = { ...state.isApiStatus, UpcomingAppointmentListViewApi: "succeeded" };
    })
    .addCase(UpcomingAppointmentListViewApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, UpcomingAppointmentListViewApi: "failed" };
    })
    .addCase(DashboardViewApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, DashboardViewApi: "loading" };
    })
    .addCase(DashboardViewApi.fulfilled, (state, action) => {
      state.isDashboard = action.payload;
      state.isApiStatus = { ...state.isApiStatus, DashboardViewApi: "succeeded" };
    })
    .addCase(DashboardViewApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, DashboardViewApi: "failed" };
    });    
  },
});
// Action creators are generated for each case reducer function
export const { reset,SendDataToCalendars } = dashboardSlice.actions;
export default dashboardSlice.reducer;
