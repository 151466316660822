import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";

  // let sortstring = "";
  // if (sort) {
  //   let sortArray = [];
  //   let sortSubArray = [];
  //   Object.keys(sort).map(function (key, index) {
  //     if (Object.keys(sort[key]).length > 0) {
  //       Object.keys(sort[key]).map(function (subkey, subindex) {
  //         sortSubArray[subindex] = `sort[${key}.${subkey}]=${sort[key][subkey]}`;
  //       });
  //     }
  //     if (key != "supplier") {
  //       sortArray[index] = `sort[${key}]=${sort[key]}`;
  //     }
  //   });
  //   if (sortSubArray.length > 0) {
  //     let jsubsort = sortSubArray.join("&");
  //     sortstring = jsubsort;
  //   }
  //   if (sortArray.length > 0) {
  //     let jsort = sortArray.join("&");
  //     sortstring = jsort;
  //   }
  // }
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/everydaymarketingcategory/view?page=${page}` : `afterlogin/everydaymarketingcategory/view?`;
  const data = {
      auth_key: auth_key,
      action: action,
      pagination: false, //true or false
      id: values && values.id ? values.id : "",
      result: result, //business_name,owner_name
      option: values && values.option ? values.option : "",
    };
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const everydaymarketingcategoryApiController = {
  view,
};
export default everydaymarketingcategoryApiController;
