import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import smtpApiController from "../../services/smtp.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const SmtpUpdateApi = createAsyncThunk("smtp/update", async (formValues, thunkAPI) => {
  try {
    const resposedata = await smtpApiController
      .update(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SmtpDetailApi = createAsyncThunk("smtp/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await smtpApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SmtpTestMailApi = createAsyncThunk("smtp/testmail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await smtpApiController.testmail(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "testmail"))
      .catch((error) => HandleError(thunkAPI, error, "testmail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isDetailData: "",
  isSmtp: "",
  isOpenedSmtpForm: "",
  isOpenedSmtpTestMailModal: "",
  isTestMail: "",
  isApiStatus: {
    SmtpUpdateApi: "",
    SmtpDetailApi: "",
    SmtpTestMailApi: "",
  },
};


const smtpSlice = createSlice({
  name: "smtp",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenSmtpForm: (state, action) => {
      state.isOpenedSmtpForm = action.payload;
    },
    SmtpTestMailModal: (state, action) => {
      state.isOpenedSmtpTestMailModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SmtpUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SmtpUpdateApi: "loading" };
      })
      .addCase(SmtpUpdateApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, SmtpUpdateApi: "succeeded" };
        state.isSmtp = action.payload;
      })
      .addCase(SmtpUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SmtpUpdateApi: "failed" };
        state.isSmtp = "";
      })
      .addCase(SmtpDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SmtpDetailApi: "loading" };
      })
      .addCase(SmtpDetailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, SmtpDetailApi: "succeeded" };
        state.isDetailData = action.payload;
      })
      .addCase(SmtpDetailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SmtpDetailApi: "failed" };
        state.isDetailData = "";
      })
      .addCase(SmtpTestMailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SmtpTestMailApi: "loading" };
      })
      .addCase(SmtpTestMailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, SmtpTestMailApi: "succeeded" };
        state.isTestMail = action.payload;
      })
      .addCase(SmtpTestMailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SmtpTestMailApi: "failed" };
        state.isTestMail = "";
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, OpenSmtpForm, SmtpTestMailModal } = smtpSlice.actions;
export default smtpSlice.reducer;
