import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import salonsubscriptionplanApiController from "services/salonsubscriptionplan.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const salonfeatureActivePlanViewApi = createAsyncThunk("salonfeatureactiveplan/listview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await salonsubscriptionplanApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "salonfeatureactiveplanview"))
      .catch((error) => HandleError(thunkAPI, error, "salonfeatureactiveplan/view"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const salonfeaturePaymentHistoryApi = createAsyncThunk("salonfeaturepayment/listview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await salonsubscriptionplanApiController
      .paymentshistory(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "salonfeaturepaymentlistview"))
      .catch((error) => HandleError(thunkAPI, error, "salonfeaturepayment/listview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isTableView: [],
  ispaymentHistoryTableView:[],
  isOpenSmsPurchaseHistory:"",
  isSmsPurchaseHistoryData:"",
  isOneOffPurchaseHistoryData:"",
  isOpenOneOffPurchaseHistory:"",
  isApiStatus: {
    salonfeatureActivePlanViewApi: "",
    salonfeaturePaymentHistoryApi:""
  },
};

const salonsubscriptionSlice = createSlice({
  name: "salonsubscription",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenSmsPurchaseHistory: (state, action) => {
      state.isOpenSmsPurchaseHistory = action.payload;
    },
    SmsPurchaseHistoryData: (state, action) => {
      state.isSmsPurchaseHistoryData = action.payload;
    },
    OpenOneOffPurchaseHistory: (state, action) => {
      state.isOpenOneOffPurchaseHistory = action.payload;
    },
    OneOffPurchaseHistoryData: (state, action) => {
      state.isOneOffPurchaseHistoryData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(salonfeatureActivePlanViewApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, salonfeatureActivePlanViewApi: "loading" };
    })
    .addCase(salonfeatureActivePlanViewApi.fulfilled, (state, action) => {
      let old_current_page = state.isTableView.current_page ? state.isTableView.current_page : "";
      let new_current_page = action.payload.current_page ? action.payload.current_page : "";
      let viewdata = state.isTableView && state.isTableView.data;
      let newviewdata = action.payload && action.payload.data;
      state.isTableView = action.payload;
      if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
        viewdata && newviewdata ? (state.isTableView.data = [...viewdata, ...newviewdata]) : action.payload;
      }
      state.isApiStatus = { ...state.isApiStatus, salonfeatureActivePlanViewApi: "succeeded" };
      state.isTableView = action.payload;
    })
    .addCase(salonfeatureActivePlanViewApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, salonfeatureActivePlanViewApi: "failed" };
      state.isTableView = [];
    })
    .addCase(salonfeaturePaymentHistoryApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, salonfeaturePaymentHistoryApi: "loading" };
    })
    .addCase(salonfeaturePaymentHistoryApi.fulfilled, (state, action) => {
      let old_current_page = state.ispaymentHistoryTableView.current_page ? state.ispaymentHistoryTableView.current_page : "";
      let new_current_page = action.payload.current_page ? action.payload.current_page : "";
      let viewdata = state.ispaymentHistoryTableView && state.ispaymentHistoryTableView.data;
      let newviewdata = action.payload && action.payload.data;
      state.ispaymentHistoryTableView = action.payload;
      if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
        viewdata && newviewdata ? (state.ispaymentHistoryTableView.data = [...viewdata, ...newviewdata]) : action.payload;
      }
      state.isApiStatus = { ...state.isApiStatus, salonfeaturePaymentHistoryApi: "succeeded" };
      state.ispaymentHistoryTableView = action.payload;
    })
    .addCase(salonfeaturePaymentHistoryApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, salonfeaturePaymentHistoryApi: "failed" };
      state.ispaymentHistoryTableView = [];
    });
  },
});
// Action creators are generated for each case reducer function
export const { reset,OpenSmsPurchaseHistory,SmsPurchaseHistoryData,OneOffPurchaseHistoryData,OpenOneOffPurchaseHistory } = salonsubscriptionSlice.actions;
export default salonsubscriptionSlice.reducer;