import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/smsuses/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const options = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/smsuses/options";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};
const smspayment = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["paymentIntent", "PaymentMethodData"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/smsuses/smspayment`;
  formData.append("action", action);
  formData.append("auth_key", auth_key);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const smspaymentfailed = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["error"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/smsuses/smspaymentfailed`;
  formData.append("action", action);
  formData.append("auth_key", auth_key);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const smsDefaultList = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const result = values && values.result ? values.result : "row_array";
  const action = `afterlogin/creditcard/listPaymentMethod`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const smsusesApiController = {
  create,
  options,
  smspayment,
  smspaymentfailed,
  smsDefaultList,
};
export default smsusesApiController;
