import { loadStripe } from "@stripe/stripe-js";
import config from "./../config";
import { useSelector } from "react-redux";

let stripePromise;
const getStripe = () => {
  const auth = useSelector((state) => state.auth);
  let email = "";
  if (auth && auth.user) {
    const currentUser = auth.user;
    email = currentUser.email;
  }
  let stripe_publishable_key = '';
  if (email && config.stripe_test_email.includes(email)) {
    stripe_publishable_key = "pk_test_51MgGqQL7LE9nuyaBoZDE8OdCzjwAXLZJr9xsOKYQf9VboonDq2NYLCeezQLXgLe08VPVDAA1PJGa34Tcas1gIpcP0068GCZGQD";//client_owner_test_key
  } else {
    stripe_publishable_key = "pk_live_51MgGqQL7LE9nuyaBW9AGM42gZbIYivFHjlhK520Sbta5cjoKs9Zbz5legwPx3gpl1Zk9YfdrNicyLNQfIYuO3w4m00lcBF0rAJ";//client_owner_key
  }
  // 
  // 
  // const stripe_publishable_key = "pk_test_51Ko2rOSFsrov7HTSarhjgTN7nrsYLEVvOKqhMM3lq8b4ZOdIbP5Pj7TgUbZxr9C3apilJAmeSvEH9HcwLWbj6Nw400febhX2Ig";
  //   const stripe_secrete_key = "sk_test_51Ko2rOSFsrov7HTSJAkhuTXyQiUGw5kfiU67lVR7riELEoXvcoUI6duFWM6djjYVNwmvGMec5OhyVeZyy5X3eRcj00r1l2zaoX";
  if (!stripePromise) {
    //     stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
    stripePromise = loadStripe(stripe_publishable_key);
  }
  return stripePromise;
};

export default getStripe;
