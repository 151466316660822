import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import serviceApiController from "../../services/service.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const serviceStoreApi = createAsyncThunk("service/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const serviceUpdateApi = createAsyncThunk("service/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const serviceListViewApi = createAsyncThunk("service/listview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "listview"))
      .catch((error) => HandleError(thunkAPI, error, "listview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const serviceDetailApi = createAsyncThunk("service/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const serviceDeleteApi = createAsyncThunk("service/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const serviceSuggetionListApi = createAsyncThunk("service/suggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .suggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addonservices = createAsyncThunk("service/addonservices", async (formValues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .addonservices(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addonservices"))
      .catch((error) => HandleError(thunkAPI, error, "addonservices"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AddonServicesFieldApi = createAsyncThunk("service/addonservicesfield", async (formValues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .addonservicesfield(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addonservicesfield"))
      .catch((error) => HandleError(thunkAPI, error, "addonservicesfield"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addonstaff = createAsyncThunk("service/addonstaff", async (formValues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .addonstaff(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addonstaff"))
      .catch((error) => HandleError(thunkAPI, error, "addonstaff"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const serviceOptions = createAsyncThunk("service/serviceOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "serviceOptions"))
      .catch((error) => HandleError(thunkAPI, error, "serviceOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const servicePriceApi = createAsyncThunk("service/serviceprice", async (formValues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .serviceprice(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "serviceprice"))
      .catch((error) => HandleError(thunkAPI, error, "serviceprice"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ServiceOptionsDropdown = createAsyncThunk("service/ServiceOptionsDropdown", async (formValues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "ServiceOptionsDropdown"))
      .catch((error) => HandleError(thunkAPI, error, "ServiceOptionsDropdown"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ServiceResourceDropdownApi = createAsyncThunk("service/resourceDropdown", async (formValues, thunkAPI) => {
  try {
    const resposedata = await serviceApiController
      .resourceviews(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "serviceresourceDropdown"))
      .catch((error) => HandleError(thunkAPI, error, "serviceresourceDropdown"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedDetailModal: "",
  isListView: [],
  isSuggetionListView: [],
  isDetailData: "",
  isTabView: "service",
  isSort: "",
  isSearchList: "",
  isSearchName: "",
  isAddonServices: [],
  isAddonServicesField: [],
  isAddonStaff: [],
  isServiceOption: [],
  isServicePrice: [],
  isServiceOptionDropdown: [],
  isServiceResourceDropdown:[],
  isApiStatus: {
    serviceStoreApi: "",
    serviceUpdateApi: "",
    serviceListViewApi: "",
    serviceSuggetionListApi: "",
    serviceDetailApi: "",
    serviceDeleteApi: "",
    serviceOptions: "",
    servicePriceApi: "",
    ServiceOptionsDropdown: "",
    addonstaff: "",
    addonservices: "",
    AddonServicesFieldApi: "",
  },
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    reset: () => initialState,
    serviceTabView: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
      state.isTabView = action.payload;
    },
    openAddServiceForm: (state = initialState) => {
      state.isOpenedAddForm = "open";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
    },
    closeAddServiceForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
    },
    openEditServiceForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "open";
      state.isOpenedDetailModal = "";
    },
    closeEditServiceForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
    },
    openServiceDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "open";
    },
    closeServiceDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
    },
    serviceSort: (state, action) => {
      // let sort = state.isSort ? state.isSort : {};
      // state.isSort = Object.assign(sort, action.payload);
      state.isSort = action.payload;
    },
    serviceSortRemove: (state) => {
      state.isSort = "";
    },
    openServiceSearchList: (state) => {
      state.isSearchList = "open";
    },
    closeServiceSearchList: (state) => {
      state.isSearchList = "";
    },
    serviceSearchName: (state, action) => {
      state.isSearchName = action.payload;
    },
    addonservicesAction: (state, action) => {
      const { id, ...changes } = action.payload;
      const existingData = state.isAddonServices.find((event) => event.id === id);
      if (existingData) {
        Object.keys(changes).map((keyName) => {
          existingData[keyName] = changes[keyName];
        });
      }
    },
    addonstaffAction: (state, action) => {
      const { id, ...changes } = action.payload;
      const existingData = state.isAddonStaff.find((event) => event.id === id);
      if (existingData) {
        Object.keys(changes).map((keyName) => {
          existingData[keyName] = changes[keyName];
        });
      }
    },
  },
  extraReducers: (builder) => {
   builder
    .addCase(serviceStoreApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceStoreApi: "loading" };
    })
    .addCase(serviceStoreApi.fulfilled, (state, action) => {
      if (state.isListView && state.isListView.data) {
        state.isListView.data = [action.payload, ...state.isListView.data];
      } else {
        state.isListView = { data: [action.payload] };
      }
      state.isApiStatus = { ...state.isApiStatus, serviceStoreApi: "succeeded" };
    })
    .addCase(serviceStoreApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceStoreApi: "failed" };
    })
    .addCase(serviceUpdateApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceUpdateApi: "loading" };
    })
    .addCase(serviceUpdateApi.fulfilled, (state, action) => {
      const { id, ...changes } = action.payload;
      const existingData = state.isListView && state.isListView.data && state.isListView.data.find((event) => event.id === id);
      if (existingData) {
        Object.keys(changes).map((keyName) => {
          existingData[keyName] = changes[keyName];
        });
      }
      state.isApiStatus = { ...state.isApiStatus, serviceUpdateApi: "succeeded" };
    })
    .addCase(serviceUpdateApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceUpdateApi: "failed" };
    })
    .addCase(serviceListViewApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceListViewApi: "loading" };
    })
    .addCase(serviceListViewApi.fulfilled, (state, action) => {
      let old_current_page = state.isListView.current_page ? state.isListView.current_page : "";
      let new_current_page = action.payload.current_page ? action.payload.current_page : "";
      let viewdata = state.isListView && state.isListView.data;
      let newviewdata = action.payload && action.payload.data;
      state.isListView = action.payload;
      if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
        viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
      }
      state.isApiStatus = { ...state.isApiStatus, serviceListViewApi: "succeeded" };
      state.isListView = action.payload;
    })
    .addCase(serviceListViewApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceListViewApi: "failed" };
      state.isListView = [];
    })
    .addCase(serviceSuggetionListApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceSuggetionListApi: "loading" };
    })
    .addCase(serviceSuggetionListApi.fulfilled, (state, action) => {
      let old_current_page = state.isSuggetionListView.current_page ? state.isSuggetionListView.current_page : "";
      let new_current_page = action.payload.current_page ? action.payload.current_page : "";
      let viewdata = state.isSuggetionListView && state.isSuggetionListView.data;
      let newviewdata = action.payload && action.payload.data;
      state.isSuggetionListView = action.payload;
      if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
        viewdata && newviewdata ? (state.isSuggetionListView.data = [...viewdata, ...newviewdata]) : action.payload;
      }
      state.isApiStatus = { ...state.isApiStatus, serviceSuggetionListApi: "succeeded" };
      state.isSuggetionListView = action.payload;
    })
    .addCase(serviceSuggetionListApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceSuggetionListApi: "failed" };
      state.isSuggetionListView = [];
    })
    .addCase(serviceDetailApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceDetailApi: "loading" };
    })
    .addCase(serviceDetailApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, serviceDetailApi: "succeeded" };
      state.isDetailData = action.payload;
    })
    .addCase(serviceDetailApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceDetailApi: "failed" };
      state.isDetailData = "";
    })
    .addCase(serviceDeleteApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceDeleteApi: "loading" };
    })
    .addCase(serviceDeleteApi.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.isApiStatus = { ...state.isApiStatus, serviceDeleteApi: "succeeded" };
      state.isListView.data = state.isListView.data ? state.isListView.data.filter((item) => item.id != id) : state.isListView.filter((item) => item.id != id);
    })
    .addCase(serviceDeleteApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceDeleteApi: "failed" };
    })
    .addCase(addonservices.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, addonservices: "loading" };
    })
    .addCase(addonservices.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, addonservices: "succeeded" };
      state.isAddonServices = action.payload;
    })
    .addCase(addonservices.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, addonservices: "failed" };
      state.isAddonServices = [];
    })
    .addCase(addonstaff.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, addonstaff: "loading" };
    })
    .addCase(addonstaff.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, addonstaff: "succeeded" };
      state.isAddonStaff = action.payload;
    })
    .addCase(addonstaff.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, addonstaff: "failed" };
      state.isAddonStaff = [];
    })
    .addCase(serviceOptions.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceOptions: "loading" };
    })
    .addCase(serviceOptions.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, serviceOptions: "succeeded" };
      state.isServiceOption = action.payload;
    })
    .addCase(serviceOptions.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, serviceOptions: "failed" };
      state.isTaxOption = [];
    })
    .addCase(servicePriceApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, servicePriceApi: "loading" };
    })
    .addCase(servicePriceApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, servicePriceApi: "succeeded" };
      state.isServicePrice = action.payload;
    })
    .addCase(servicePriceApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, servicePriceApi: "failed" };
      state.isServicePrice = [];
    })
    .addCase(ServiceOptionsDropdown.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, ServiceOptionsDropdown: "loading" };
    })
    .addCase(ServiceOptionsDropdown.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, ServiceOptionsDropdown: "succeeded" };
      state.isServiceOptionDropdown = action.payload;
    })
    .addCase(ServiceOptionsDropdown.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, ServiceOptionsDropdown: "failed" };
      state.isServiceOptionDropdown = [];
    })
    .addCase(AddonServicesFieldApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddonServicesFieldApi: "loading" };
    })
    .addCase(AddonServicesFieldApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, AddonServicesFieldApi: "succeeded" };
      state.isAddonServicesField = action.payload;
    })
    .addCase(AddonServicesFieldApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddonServicesFieldApi: "failed" };
      state.isAddonServicesField = [];
    })
    .addCase(ServiceResourceDropdownApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, ServiceResourceDropdownApi: "loading" };
    })
    .addCase(ServiceResourceDropdownApi.fulfilled, (state, action) => {
      state.isServiceResourceDropdown = action.payload;
      state.isApiStatus = { ...state.isApiStatus, ServiceResourceDropdownApi: "succeeded" };
    })
    .addCase(ServiceResourceDropdownApi.rejected, (state) => {
      state.isServiceResourceDropdown = [];
      state.isApiStatus = { ...state.isApiStatus, ServiceResourceDropdownApi: "failed" };
    });
  },
});
// Action creators are generated for each case reducer function
export const { reset, serviceTabView, openAddServiceForm, closeAddServiceForm, openEditServiceForm, closeEditServiceForm, serviceTabGridView, openServiceDetailModal, closeServiceDetailModal, serviceDetailTab, serviceSort, serviceSortRemove, openServiceSearchList, closeServiceSearchList, serviceSearchName, addonservicesAction, addonstaffAction } = serviceSlice.actions;
export default serviceSlice.reducer;
