import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import PaginationLoader from "component/PaginationLoader";
import config from "../../../../config";
import AppointmentListview from "./AppointmentListview";
import { ClientAppointmentListViewApi } from "store/slices/appointmentSlice";
import AppointmentEditForm from "pages/calendar/Form/AppointmentEditForm";
import Appointmentinvoice from "./Appointmentinvoice";
import ApiStatus from "component/ApiStatus";
import PartialPaidInvoiceAppointments from "./PartialPaidInvoiceAppointments";
import RefundFromInvoiceModalAppointments from "./RefundFromInvoiceModalAppointments";
import AppointmentCancelRefundStripeAppointment from "./AppointmentCancelRefundStripeAppointment";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import getStripeClient from "pages/getStripeClient";
import ClientPaidInvoice from "../Invoices/ClientPaidInvoice";
import CompletedSaleRefundModal from "../Invoices/CompletedSaleRefundModal";
import RefundFromInvoiceModal from "../Invoices/RefundFromInvoiceModal";
import PartialPaidInvoice from "../Invoices/PartialPaidInvoice";

const Appointment = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const stripePromise = getStripeClient();
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.salonmodule.isAccess);
  const appointmentApiStatus = useSelector((state) => state.appointment.isApiStatus);
  const ListView = useSelector((state) => state.appointment.isClientAppointmentListView);
  const isFilter = useSelector((state) => state.appointment.isFilter);
  const editForm = useSelector((state) => state.appointment.isOpenedEditForm);
  const detail = useSelector((state) => state.client.isDetailData);
  const isOpenInvoiceDrawer = useSelector((state) => state.clientpastinvoice.isPastInvoiceDrawer);
  const isOpenRefundInvoice = useSelector((state) => state.clientpastinvoice.isOpenRefundInvoice);
  const isOpenClientRefundSelection = useSelector((state) => state.clientpastinvoice.isOpenClientRefundSelection);
  const isOpenClientRefundInvoiceStripe = useSelector((state) => state.clientpastinvoice.isOpenClientRefundInvoiceStripe);
  const isOpenClientInvoiceDetailsTab = useSelector((state) => state.clientinvoice.isOpenClientInvoiceDetailsTab);
  const isOpenPartialPaidInvoice = useSelector((state) => state.clientinvoice.isOpenPartialPaidInvoice);
  const isOpenClientInvoiceRefundModal = useSelector((state) => state.clientinvoice.isOpenClientInvoiceRefundModal);
  const isOpenStripeRefundCompletedSaleModal = useSelector((state) => state.clientinvoice.isOpenStripeRefundCompletedSaleModal);
  const isOpenClientInvoiceStripePayModalClient = useSelector((state) => state.clientinvoice.isOpenClientInvoiceStripePayModalClient);
  const fetchDataList = () => {
    dispatch(ClientAppointmentListViewApi({ next_page_url: ListView && ListView.next_page_url, filter: isFilter, client_id: detail.id,page_name:"client_appointment" }));
  };

  const [isFetching, setIsFetching] = useState(false);
  const loadMoreItemsList = () => {
    setIsFetching(true);
    dispatch(ClientAppointmentListViewApi({ next_page_url: ListView && ListView.next_page_url, filter: isFilter, client_id: detail.id,page_name:"client_appointment" }));
    //mocking an API call
    setTimeout(() => {
      setIsFetching(false);
    }, 2000);
  };
  return (
    <>
      <div className="content-wrp" id="clientdetail_appointment">
        {appointmentApiStatus && appointmentApiStatus.ClientAppointmentListViewApi === "succeeded" && ListView && ListView.data && ListView.data.length > 0 ? (
          <InfiniteScroll dataLength={ListView.data.length} next={fetchDataList} scrollableTarget="clientdetail_appointment" hasMore={ListView.next_page_url ? true : false} loader={<PaginationLoader />} style={{ overflow: ListView.next_page_url ? "auto" : "inherit" }}>
            <AppointmentListview currentUser={currentUser} view={ListView} role_id={currentUser} access={access} />
            {!isFetching && ListView.next_page_url && (
              <div className="col-2 m-auto p-3 text-center">
                <button onClick={loadMoreItemsList} className="btn btn-primary">
                  {t("More")}
                </button>
              </div>
            )}
          </InfiniteScroll>
        ) : (
          <>
            {appointmentApiStatus &&
              appointmentApiStatus.ClientAppointmentListViewApi === "loading" ? (
              <ApiStatus actionType="clientappointmentlistview" />
            ) : (
              <div className="complete-box text-center d-flex flex-column justify-content-center mt-xl-4">
                <div className="complete-box-wrp text-center">
                  <img src={config.imagepath + "addphoto-box.png"} alt="" className="mb-md-4 mb-3" />
                  <h5 className="mb-2 fw-semibold">{t("No data found")}</h5>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {isOpenClientInvoiceDetailsTab && <ClientPaidInvoice />}
      {isOpenPartialPaidInvoice && <PartialPaidInvoice />}
      {isOpenClientInvoiceRefundModal && (<Elements stripe={stripePromise}><RefundFromInvoiceModal /></Elements>)}
      {isOpenStripeRefundCompletedSaleModal && (<Elements stripe={stripePromise}><CompletedSaleRefundModal /></Elements>)}
      {isOpenClientInvoiceStripePayModalClient && (<Elements stripe={stripePromise}><PayByStripeModalClient /></Elements>)}
      {editForm && <AppointmentEditForm page={"client-detail"} />}
      {isOpenInvoiceDrawer && <Appointmentinvoice />}
      {isOpenRefundInvoice && <PartialPaidInvoiceAppointments />}
      {isOpenClientRefundSelection && (<Elements stripe={stripePromise}><RefundFromInvoiceModalAppointments /></Elements>)}
      {isOpenClientRefundInvoiceStripe && (<Elements stripe={stripePromise}><AppointmentCancelRefundStripeAppointment /></Elements>)}
    </>
  );
};

export default Appointment;
