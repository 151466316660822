import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
import stafftargetApiController from "services/stafftarget.service";

export const staffTargetCreateApi = createAsyncThunk(
  "stafftarget/store",
  async (formvalues, thunkAPI) => {
    try {
      const resposedata = await stafftargetApiController
        .create(formvalues, thunkAPI)
        .then((response) =>
          HandleResponse(thunkAPI, response, "stafftargetstore")
        )
        .catch((error) => HandleError(thunkAPI, error, "stafftargetstore"));
      return resposedata;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        message: message,
      });
    }
  }
);

export const staffTargetOptionsDropdownApi = createAsyncThunk(
  "stafftarget/staffTargetOptionsDropdown",
  async (formValues, thunkAPI) => {
    try {
      const resposedata = await stafftargetApiController
        .stafflist(formValues, thunkAPI)
        .then((response) =>
          HandleResponse(thunkAPI, response, "staffTargetOptionsDropdown")
        )
        .catch((error) =>
          HandleError(thunkAPI, error, "staffTargetOptionsDropdown")
        );
      return resposedata;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const stafftargetUpdateApi = createAsyncThunk(
  "stafftarget/update",
  async (formvalues, thunkAPI) => {
    try {
      const resposedata = await stafftargetApiController
        .update(formvalues, thunkAPI)
        .then((response) => HandleResponse(thunkAPI, response, "update"))
        .catch((error) => HandleError(thunkAPI, error, "update"));
      return resposedata;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        message: message,
      });
    }
  }
);

export const stafftargetGridViewApi = createAsyncThunk(
  "stafftarget/gridview",
  async (formValues, thunkAPI) => {
    try {
      const resposedata = await stafftargetApiController
        .view(formValues, thunkAPI)
        .then((response) => HandleResponse(thunkAPI, response, "gridview"))
        .catch((error) => HandleError(thunkAPI, error, "gridview"));
      return resposedata;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const stafftargetDetailApi = createAsyncThunk(
  "stafftarget/detail",
  async (formValues, thunkAPI) => {
    try {
      const resposedata = await stafftargetApiController
        .view(formValues, thunkAPI)
        .then((response) => HandleResponse(thunkAPI, response, "detail"))
        .catch((error) => HandleError(thunkAPI, error, "detail"));
      return resposedata;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const stafftargetDeleteApi = createAsyncThunk(
  "stafftarget/delete",
  async (formValues, thunkAPI) => {
    try {
      const resposedata = await stafftargetApiController
        .deleted(formValues, thunkAPI)
        .then((response) => HandleResponse(thunkAPI, response, "delete"))
        .catch((error) => HandleError(thunkAPI, error, "delete"));
      return resposedata;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const stafftargetSuggetionListApi = createAsyncThunk(
  "stafftarget/suggetionlist",
  async (formValues, thunkAPI) => {
    try {
      const resposedata = await stafftargetApiController
        .suggetionlist(formValues, thunkAPI)
        .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
        .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
      return resposedata;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  isGridView: [],
  isStaffTargetCreateFormsOpen: "",
  isStaffTargetEditFormsOpen: "",
  isStaffOptionDropdown: [],
  isStaffTargetFilter: "",
  isChatDrawer: "",
  isDetailData: "",
  isApiStatus: {
    staffTargetCreateApi: "",
    staffTargetOptionsDropdownApi: "",
    stafftargetUpdateApi: "",
    stafftargetGridViewApi: "",
    stafftargetDeleteApi: "",
    stafftargetDetailApi: "",
  },
};

const StaffTargetSlice = createSlice({
  name: "stafftarget",
  initialState,
  reducers: {
    reset: () => initialState,
    StaffTargetCreateFormsOpen: (state, action) => {
      state.isStaffTargetCreateFormsOpen = action.payload;
    },
    StaffTargetEditFormsOpen: (state, action) => {
      state.isStaffTargetEditFormsOpen = action.payload;
    },
    setStaffTargetFilter: (state, action) => {
      state.isStaffTargetFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(staffTargetCreateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, staffTargetCreateApi: "loading" };
      })
      .addCase(staffTargetCreateApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, staffTargetCreateApi: "succeeded" };
      })
      .addCase(staffTargetCreateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, staffTargetCreateApi: "failed" };
      })
      .addCase(staffTargetOptionsDropdownApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, staffTargetOptionsDropdownApi: "loading" };
      })
      .addCase(staffTargetOptionsDropdownApi.fulfilled, (state, action) => {
        state.isStaffOptionDropdown = action.payload;
        state.isApiStatus = { ...state.isApiStatus, staffTargetOptionsDropdownApi: "succeeded" };
      })
      .addCase(staffTargetOptionsDropdownApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, staffTargetOptionsDropdownApi: "failed" };
        state.isStaffOptionDropdown = [];
      })
      .addCase(stafftargetUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, stafftargetUpdateApi: "loading" };
      })
      .addCase(stafftargetUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        let isGridView = state.isGridView && state.isGridView.data ? state.isGridView.data : state.isGridView;
        const existingGridData = isGridView ? isGridView.find((event) => event.id === id) : "";
        if (existingGridData) {
          Object.keys(changes).map((keyName) => {
            existingGridData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, stafftargetUpdateApi: "succeeded" };
      })
      .addCase(stafftargetUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, stafftargetUpdateApi: "failed" };
      })
      .addCase(stafftargetGridViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, stafftargetGridViewApi: "loading" };
      })
      .addCase(stafftargetGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isGridView.current_page ? state.isGridView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isGridView && state.isGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isGridView = action.payload;
        state.isApiStatus = { ...state.isApiStatus, stafftargetGridViewApi: "succeeded" };
      })
      .addCase(stafftargetGridViewApi.rejected, (state) => {
        state.isGridView = [];
        state.isApiStatus = { ...state.isApiStatus, stafftargetGridViewApi: "failed" };
      })
      .addCase(stafftargetDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, stafftargetDeleteApi: "loading" };
      })
      .addCase(stafftargetDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isGridView.data = state.isGridView.data ? state.isGridView.data.filter((item) => item.id != id) : state.isGridView.filter((item) => item.id != id);
        state.isApiStatus = { ...state.isApiStatus, stafftargetDeleteApi: "succeeded" };
      })
      .addCase(stafftargetDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, stafftargetDeleteApi: "failed" };
      })
      .addCase(stafftargetDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, stafftargetDetailApi: "loading" };
      })
      .addCase(stafftargetDetailApi.fulfilled, (state, action) => {
        state.isDetailData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, stafftargetDetailApi: "succeeded" };
      })
      .addCase(stafftargetDetailApi.rejected, (state) => {
        state.isDetailData = "";
        state.isApiStatus = { ...state.isApiStatus, stafftargetDetailApi: "failed" };
      });
  }
});

// Action creators are generated for each case reducer function
export const { reset, StaffTargetCreateFormsOpen, StaffTargetEditFormsOpen, setStaffTargetFilter } =
  StaffTargetSlice.actions;
export default StaffTargetSlice.reducer;
