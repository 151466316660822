import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// validation Formik
import * as Yup from "yup";
import { Formik } from "formik";
import config from "../../../config";
import yupconfig from "../../../yupconfig";
import { decimalOnly } from "component/form/Validation";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import customToast from "component/Toastr";
import { DefaultCardRemoveApi, PackagePaymentApi, PackagePaymentFailedApi, PackageStoreApi } from "store/slices/packageSlice";
import { useNavigate } from "react-router";
import { ActivePlanApi, FeatureAccessApi } from "store/slices/salonSlice";
import { swalErrorHtml } from "component/Sweatalert2";
import { smsDefaultCardSelectApi } from "store/slices/smsusesSlice";
// import { InputField } from "component/form/Field";
import moment from "moment";
import { billingDetailApi } from "store/slices/billingAddressSlice";
import { OpenBillingDetailEditForm } from "store/slices/packageplandetailsSlice";

const PackagePaymentForm = () => {
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const PackageManageData = useSelector((state) => state.package.isPackageManageData);
  const PackageName = useSelector((state) => state.package.isPackageName);

  const id = PackageManageData.id;
  const package_type_id = PackageManageData.package_type_id;
  const title = PackageName.name;
  const price = PackageManageData.price;
  const currency = PackageManageData.currency;
  const type = PackageManageData.type;
  const max_staff_member = PackageManageData.max_staff_member;
  const is_unlimited = PackageManageData.is_unlimited;
  const total_sms_per_salon = PackageManageData.total_sms_per_salon;
  const default_cards = useSelector((state) => state.smsuses.isDefaultCardSelect);
  
  let last4digits = default_cards && default_cards.last4 ? default_cards.last4 : "";
  let exp_months_day = default_cards && default_cards.exp_month ? default_cards.exp_month : "";
  let exp_months = exp_months_day ? moment(exp_months_day, 'M').format("MMMM") : 0;
  let exp_years = default_cards && default_cards.exp_year ? default_cards.exp_year : "";
  let brand = default_cards && default_cards.brand ? default_cards.brand : "";
  const BillingDetail = useSelector((state) => state.billing.isDetailData);
  const stripe_card_remove_id = default_cards && default_cards.id;
  const stripePackageInvoice = useSelector((state) => state.package.isPackageInvoice);
  const upcomingprice = stripePackageInvoice && Math.abs(parseFloat(stripePackageInvoice.amount_due)) > 0 ? stripePackageInvoice.amount_due : price;

  useEffect(() => {
    dispatch(smsDefaultCardSelectApi());
    dispatch(billingDetailApi());
  }, []);

  const PackageTextChanges = () => {
    if (stripePackageInvoice && stripePackageInvoice.newPlanCharge && parseFloat(stripePackageInvoice.newPlanCharge) < 0) {
      const amountNew = Math.abs(stripePackageInvoice.newPlanCharge);
      return (
        <div className="voucher-box mb-4">
          <h5 className="fw-semibold mb-0">{`As you have changed your plan, you have ${currency} $${amountNew} (inc GST) in credit.`}</h5>
          <h5 className="">{`This credit will be applied to your future monthly payments.`}</h5>
        </div>
      )
    }
    else if (stripePackageInvoice && stripePackageInvoice.newPlanCharge && parseFloat(stripePackageInvoice.newPlanCharge) > 0) {
      return (
        <div className="voucher-box mb-4">
          <h5 className="fw-semibold mb-0">{`You will now be charged ${currency} $${stripePackageInvoice.newPlanCharge} (inc GST).`}</h5>
          <h5 className="">{`You will be charged ${currency} $${price} (inc GST) ${type}ly when your monthly plan renews on ${stripePackageInvoice.new_plan_end_period}`}</h5>
        </div>
      )
    }
    else {
      return (
        <div className="voucher-box mb-4">
          <h5 className="fw-semibold mb-0">{`As you have changed your plan, you have ${currency} $0 (inc GST) in credit.`}</h5>
          <h5 className="">{`You will be charged ${currency} $${price} (inc GST) ${type}ly when your monthly plan renews on ${stripePackageInvoice.new_plan_end_period}`}</h5>
        </div>
      )
    }
    // {
    // 	As you have changed your plan, you have $(newplan chagrges) (inc GST) in credit. 
    // 	This credit will be applied to your future monthly payments.
    // }
    // else (new plan charges > 0)
    // {
    // 	you will now be charged AUD $(newplan charges) (inc GST).
    // 	You will be charged AUD (package price)$ (inc GST) Monthly when your monthly plan renews on October 11th, 2023
    // }
    // else 
    // {
    // 	As you have changed your plan, you have $0 (inc GST) in credit. 
    // 	You will be charged AUD (package price)$ (inc GST) Monthly when your monthly plan renews on October 11th, 2023
    // }
  }

  const initialValues = {
    package_type_id: package_type_id,
    package_manage_id: id,
    amount: price,
    currency: currency,
    type: type,
    max_staff_member: max_staff_member,
    total_sms_per_salon: total_sms_per_salon,
    is_unlimited: is_unlimited,
    stripe_payment_method_id: "",
  };
  const validationSchema = Yup.object().shape({
    package_type_id: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().of(Yup.string()).nullable().min(1).required() : Yup.string().nullable().label(t("Package Type")).required())),
    package_manage_id: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().of(Yup.string()).nullable().min(1).required() : Yup.string().nullable().label(t("Package")).required())),
    amount: Yup.string().trim().label(t("Cost Price")).required().test("Decimal only", t("The field should have decimal only"), decimalOnly),
  });
  yupconfig();

  const handlepackageSubmit = async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
    setLoading(true);
    try {
      let stripe_token_id = "";
      let stripe_payment_method_id = "";
      if (values && values.stripe_payment_method_id) {
        stripe_payment_method_id = values.stripe_payment_method_id;
      } else {
        if (!stripe || !elements) {
          return;
        }
        const { token, error } = await stripe.createToken(elements.getElement(CardNumberElement));
        if (error) {
          setError(error.message);
          setLoading(false);
        } else {
          stripe_token_id = token.id;
        }
      }
      if (stripe_token_id || stripe_payment_method_id) {
        const formvalues = stripe_token_id ? { ...values, stripe_token_id } : { ...values, stripe_payment_method_id }
        dispatch(PackageStoreApi(formvalues)).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            const bookingstoredata = action.payload;
            const clientSecret = bookingstoredata.clientSecret;
            const subscriptionApiCall = bookingstoredata.subscriptionApiCall;
            const clientname = bookingstoredata.name;
            const clientemail = bookingstoredata.email;
            const clientphone = bookingstoredata.phone;
            const paymentIntent = bookingstoredata.paymentIntent;
            const stripe_payment_method_id = bookingstoredata.stripe_payment_method_id;
            if (subscriptionApiCall === "updated") {
              const valuesp = { ...bookingstoredata, paymentIntent: { id: paymentIntent.id, status: paymentIntent.status } };
              dispatch(PackagePaymentApi(valuesp)).then((actionpayment) => {
                if (actionpayment.meta.requestStatus === "fulfilled") {
                  setStatus({ success: true });
                  // resetForm();
                  setLoading(false);
                  setSucceeded(true);
                  customToast.success(t("Subscription plan activated."));
                  navigate(config.basePath + "/calendar");
                  dispatch(FeatureAccessApi());
                  dispatch(ActivePlanApi());
                } else if (actionpayment.meta.requestStatus === "rejected") {
                  const status = actionpayment.payload && actionpayment.payload.status;
                  const response = action.payload && action.payload.message && action.payload.message;
                  const errors = actionpayment.payload && actionpayment.payload.message && actionpayment.payload.message.errors;
                  if (status === 422) {
                    setErrors(errors);
                  } else if (status === 410) {
                    const NotifyContent = () => {
                      return (
                        <>
                          <p className="mb-2 text-white text-justify">{response && response.message}</p>
                        </>
                      );
                    };
                    customToast.error(<NotifyContent />);
                    setStatus({ warning: response && response.message, booked: response && response.booked });
                  }
                  setLoading(false);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              });
            } else {
              stripe
                .confirmCardPayment(
                  clientSecret,
                  {
                    payment_method: stripe_payment_method_id,
                  },
                  // { handleActions: false },
                )
                .then(function (result) {
                  // Handle result.error or result.paymentIntent
                  if (result.error) {
                    // console.log("resulterr", result);
                    setError(`Payment failed ${result.error.message}`);
                    setLoading(false);
                    setStatus({ error: true, message: `Payment failed ${result.error.message}` });
                    const valuespf = { ...bookingstoredata, error: result.error };
                    // console.log(valuespf);
                    dispatch(PackagePaymentFailedApi(valuespf));
                  } else {
                    const paymentIntent = result.paymentIntent;
                    // console.log("PaymentIntent ID: " + result.paymentIntent.id);
                    // console.log("PaymentIntent status: " + result.paymentIntent.status);
                    if (paymentIntent && paymentIntent.id && paymentIntent.status === "succeeded") {
                      const valuesp = { ...bookingstoredata, paymentIntent: { id: paymentIntent.id, status: paymentIntent.status } };
                      setError(null);
                      dispatch(PackagePaymentApi(valuesp)).then((actionpayment) => {
                        if (actionpayment.meta.requestStatus === "fulfilled") {
                          setStatus({ success: true });
                          // resetForm();
                          setLoading(false);
                          setSucceeded(true);
                          customToast.success(t("Subscription plan activated."));
                          navigate(config.basePath + "/calendar");
                          dispatch(FeatureAccessApi());
                          dispatch(ActivePlanApi());
                        } else if (actionpayment.meta.requestStatus === "rejected") {
                          const status = actionpayment.payload && actionpayment.payload.status;
                          const response = action.payload && action.payload.message && action.payload.message;
                          const errors = actionpayment.payload && actionpayment.payload.message && actionpayment.payload.message.errors;
                          if (status === 422) {
                            setErrors(errors);
                          } else if (status === 410) {
                            const NotifyContent = () => {
                              return (
                                <>
                                  <p className="mb-2 text-white text-justify">{response && response.message}</p>
                                </>
                              );
                            };
                            customToast.error(<NotifyContent />);
                            setStatus({ warning: response && response.message, booked: response && response.booked });
                          }
                          setLoading(false);
                          setStatus({ success: false });
                          setSubmitting(false);
                        }
                      });
                    }
                  }
                });
            }

            //customToast.success(t("Created Successfully"));
          } else if (action.meta.requestStatus === "rejected") {
            const status = action.payload && action.payload.status;
            const response = action.payload && action.payload.message && action.payload.message;
            const errors = action.payload && action.payload.message && action.payload.message.errors;
            if (status === 422) {
              const NotifyContent = () => {
                return (
                  <>
                    <p className="mb-2 text-danger salert text-justify">{response && response.message}</p>
                    <ul className="list-unstyled">
                      {errors &&
                        Object.keys(errors).map((a, n) => {
                          if (errors[a].length > 0) {
                            return (
                              <>
                                {errors[a].map((as, ns) => {
                                  return (
                                    <li key={n + "-" + ns} className="text-danger salert form-text text-start">
                                      {as}
                                    </li>
                                  );
                                })}
                              </>
                            );
                          }
                        })}
                    </ul>
                  </>
                );
              };
              swalErrorHtml({ title: "Warning", html: <NotifyContent />, icon: "error" });
              setErrors(errors);
            } else if (status === 410) {
              if (response.plan && response.plan === 'downgrade') {
                customToast.success(t("Subscription plan activated."));
                navigate(config.basePath + "/calendar");
              } else {
                const NotifyContent = () => {
                  return (
                    <>
                      <p className="mb-2 text-white text-justify">{response && response.message}</p>
                    </>
                  );
                };
                customToast.error(<NotifyContent />);
              }
              setStatus({ warning: response && response.message, booked: response && response.booked });
            }
            setSubmitting(false);
            setLoading(false);
          }
        });
      }
    } catch (err) {
      setErrors(err.message);
      setStatus({ success: false });
      setLoading(false);
    }
  };

  function formatCardNumber(value) {
    const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "").substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join(" ") : value;
  }

  const handleCardNumberChange = (e, setFieldValue) => {
    const { value } = e.target;
    const formattedValue = formatCardNumber(value);
    setFieldValue('number', formattedValue);
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    // console.log(event);
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const detail = {
    package_type_id: package_type_id,
    package_manage_id: id,
    amount: price,
    currency: currency,
    type: type,
    max_staff_member: max_staff_member,
    total_sms_per_salon: total_sms_per_salon,
    is_unlimited: is_unlimited,
  };
  return (
    <React.Fragment>
      <Formik enableReinitialize={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handlepackageSubmit}>
        {(formik) => {
          useEffect(() => {
            const fields = ["package_type_id", "package_manage_id", "price", "currency", "type", "max_staff_member", "total_sms_per_salon", "is_unlimited"];
            fields.forEach((field) => {
              formik.setFieldValue(field, detail[field] ? detail[field] : "", false);
            });
          }, [detail]);
          useEffect(() => {
            if (default_cards) {
              const fields = ["stripe_payment_method_id"];
              fields.forEach((field) => {
                let default_cards_field = default_cards[field] ? default_cards[field] : "";
                formik.setFieldValue(field, formik.values[field] ? formik.values[field] : default_cards_field, false);
              });
              // formik.setFieldValue('stripe_payment_method_id','', false);
            }
          }, [default_cards]);
          // console.log("formik.values", formik.values);
          // console.log("formik.errors", formik.errors);
          return (
            <form noValidate onSubmit={formik.handleSubmit} id="payment-form">
              <div className="package-box-content">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <p className="plane-selects-descs">Your plan</p>
                    <h3 className="package-box-titles">{title}</h3>
                  </div>
                  <div className="col-6">
                    <h3 className="package-box-desc">
                      {`$${price}`}
                      <sub className="package-amount-pay">{`${currency}/${type}`}</sub>
                    </h3>
                  </div>
                </div>
                <div className="pay-desc-package col-12">
                  {stripePackageInvoice && stripePackageInvoice.newPlanCharge ?
                    PackageTextChanges() :
                    <h5 className="fw-semibold">{`You will be charged ${currency} $${price} (inc GST) ${type}ly.`}</h5>
                  }
                </div>
                {default_cards && default_cards.last4 && formik.values.stripe_payment_method_id ? (
                  <div className="voucher-box p-4 bg-pink mb-3 plan-wr">
                    <div className="row default-pay-card-section align-items-center justify-content-between">
                      {/* <div className="col-lg-2 col-3 card-logo-icon">
                        <img src={config.imagepath + "cclg.svg"} alt="" />
                      </div> */}
                      <div className="default-card-number-show col-12 col-md-8 text-center text-md-start">
                        <h5 className="mb-1">
                          {`${brand} **** ${last4digits}`}
                          <span className="badge paid ms-2">Default</span>
                        </h5>
                        <h5 className="mb-0">{`Expires ${exp_months}/${exp_years}`}</h5>
                      </div>
                      <div className="col-12 col-md-3 mt-3 mt-sm-0 text-center text-md-end">
                        <a className="preview btn me-1 cursor-pointer btn-preview" type="button" onClick={() => {
                          dispatch(DefaultCardRemoveApi({ id: stripe_card_remove_id })).then(action => {
                            if (action.meta.requestStatus == 'fulfilled') {
                              formik.setFieldValue("stripe_payment_method_id", "", false);
                            }
                          })
                        }}>
                          {t("Remove")}
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-12 mb-3">
                    <div className="pay-card-details-wr">
                      <h4 htmlFor="" className="fw-600">Card Details</h4>
                      {/* <div className="pay-card-no">
                        <InputField name="number" value={formik.values.number} controlId="number" placeholder="1234 1234 1234 1234" onChange={(e) => handleCardNumberChange(e, formik.setFieldValue)} />
                      </div>
                      <div className="month-cvc-wr">
                        <div className="row gx-2">
                          <div className="col-sm-4 mb-3">
                            <InputField name="exp_month" value={formik.values.exp_month} controlId="exp_month" placeholder="MM" />
                          </div>
                          <div className="col-sm-4 col-6 mb-3">
                            <InputField name="exp_year" value={formik.values.exp_year} controlId="exp_year" placeholder="YYYY" />
                          </div>
                          <div className="col-sm-4 col-6 mb-3">
                            <InputField name="cvc" value={formik.values.cvc} controlId="cvc" placeholder="cvv" />
                          </div>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-12 mb-3">
                          <label htmlFor="card-number">Card Number</label>
                          <CardNumberElement
                            id="card-number"
                            options={{
                              style: {
                                base: {
                                  fontSize: '16px',
                                  color: '#32325d',
                                  fontFamily: 'Arial, sans-serif',
                                  '::placeholder': {
                                    color: '#aab7c4',
                                  },
                                },
                                invalid: {
                                  color: '#fa755a',
                                },
                              },
                            }}
                          />
                        </div>
                        <div className="col-6 mb-3">
                          <label htmlFor="card-expiry">Expiration Date</label>
                          <CardExpiryElement
                            id="card-expiry"
                            options={{
                              style: {
                                base: {
                                  fontSize: '16px',
                                  color: '#32325d',
                                  fontFamily: 'Arial, sans-serif',
                                  '::placeholder': {
                                    color: '#aab7c4',
                                  },
                                },
                                invalid: {
                                  color: '#fa755a',
                                },
                              },
                            }}
                          />
                        </div>
                        <div className="col-6 mb-3">
                          <label htmlFor="card-cvc">CVC</label>
                          <CardCvcElement
                            id="card-cvc"
                            options={{
                              style: {
                                base: {
                                  fontSize: '16px',
                                  color: '#32325d',
                                  fontFamily: 'Arial, sans-serif',
                                  '::placeholder': {
                                    color: '#aab7c4',
                                  },
                                },
                                invalid: {
                                  color: '#fa755a',
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {BillingDetail && BillingDetail.id ?
                  (
                    <>
                      {error && (
                        <div className="card-error invalid-feedback d-block mb-4 " role="alert">
                          {error}
                        </div>
                      )}
                      <button type="submit" className="btn btn-primary w-100 btn-lg" disabled={loading}>
                        {loading && <span className="spinner-border spinner-border-sm"></span>}
                        {t("Pay now")}
                      </button>

                    </>
                  ) : (
                    <button type="button" className="btn btn-primary w-100 btn-lg" onClick={() => dispatch(OpenBillingDetailEditForm("open"))}>
                      {t("Add Billing Details")}
                    </button>
                  )}
              </div>
            </form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

PackagePaymentForm.propTypes = {
  // isPackageStep: PropTypes.number,
};
export default PackagePaymentForm;
