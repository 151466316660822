import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
import resourceApiController from "services/resource.service";

export const resourceCreateApi = createAsyncThunk(
  "resource/store",
  async (formvalues, thunkAPI) => {
    try {
      const resposedata = await resourceApiController
        .create(formvalues, thunkAPI)
        .then((response) =>
          HandleResponse(thunkAPI, response, "resourcestore")
        )
        .catch((error) => HandleError(thunkAPI, error, "resourcestore"));
      return resposedata;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        message: message,
      });
    }
  }
);


export const resourceUpdateApi = createAsyncThunk(
  "resource/update",
  async (formvalues, thunkAPI) => {
    try {
      const resposedata = await resourceApiController
        .update(formvalues, thunkAPI)
        .then((response) => HandleResponse(thunkAPI, response, "update"))
        .catch((error) => HandleError(thunkAPI, error, "update"));
      return resposedata;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        message: message,
      });
    }
  }
);

export const resourceGridViewApi = createAsyncThunk(
  "resource/gridview",
  async (formValues, thunkAPI) => {
    try {
      const resposedata = await resourceApiController
        .view(formValues, thunkAPI)
        .then((response) => HandleResponse(thunkAPI, response, "gridview"))
        .catch((error) => HandleError(thunkAPI, error, "gridview"));
      return resposedata;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resourceDetailApi = createAsyncThunk("resource/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await resourceApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Printout"))
      .catch((error) => HandleError(thunkAPI, error, "Printout"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const resourceDeleteApi = createAsyncThunk(
  "resource/delete",
  async (formValues, thunkAPI) => {
    try {
      const resposedata = await resourceApiController
        .deleted(formValues, thunkAPI)
        .then((response) => HandleResponse(thunkAPI, response, "delete"))
        .catch((error) => HandleError(thunkAPI, error, "delete"));
      return resposedata;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resourceSuggetionListApi = createAsyncThunk(
  "resource/suggetionlist",
  async (formValues, thunkAPI) => {
    try {
      const resposedata = await resourceApiController
        .suggetionlist(formValues, thunkAPI)
        .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
        .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
      return resposedata;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addonservices = createAsyncThunk("resource/addonservices", async (formValues, thunkAPI) => {
  try {
    const resposedata = await resourceApiController
      .addonservices(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addonservices"))
      .catch((error) => HandleError(thunkAPI, error, "addonservices"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isGridView: [],
  isAddonServices: [],
  isResourceEditFormsOpen: "",
  isOpenResourceAddForm: "",
  isChatDrawer: "",
  isDetailData: "",
  isResourceSingleDataGet: "",
  isResourceItemIdService: "",
  isApiStatus: {
    resourceCreateApi: "",
    resourceUpdateApi: "",
    resourceGridViewApi: "",
    resourceDeleteApi: "",
    resourceDetailApi: "",
    addonservices: "",
  },
};

const resourceSlice = createSlice({
  name: "resource",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenResourceAddForm: (state, action) => {
      state.isOpenResourceAddForm = action.payload;
    },
    ResourceEditFormsOpen: (state, action) => {
      state.isResourceEditFormsOpen = action.payload;
    },
    ResourceSingleDataGet: (state, action) => {
      state.isResourceSingleDataGet = action.payload;
    },
    addonserviceAction: (state, action) => {
      const { id, ...changes } = action.payload;
      const existingData = state.isAddonServices.find((event) => event.id === id);
      if (existingData) {
        Object.keys(changes).map((keyName) => {
          existingData[keyName] = changes[keyName];
        });
      }
      // state.isAddonServices = action.payload;
    },
    ResourceSearchName: (state, action) => {
      state.isSearchName = action.payload;
    },
    ResourceSearchObj: (state, action) => {
      state.isSearchObj = action.payload;
    },
    ResourceItemIdService: (state, action) => {
      state.isResourceItemIdService = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resourceCreateApi.pending, (state) => {
        state.isApiStatus.resourceCreateApi = 'loading';
      })
      .addCase(resourceCreateApi.fulfilled, (state) => {
        state.isApiStatus.resourceCreateApi = 'succeeded';
      })
      .addCase(resourceCreateApi.rejected, (state) => {
        state.isApiStatus.resourceCreateApi = 'failed';
      })
      .addCase(resourceUpdateApi.pending, (state) => {
        state.isApiStatus.resourceUpdateApi = 'loading';
      })
      .addCase(resourceUpdateApi.fulfilled, (state) => {
        state.isApiStatus.resourceUpdateApi = 'succeeded';
      })
      .addCase(resourceUpdateApi.rejected, (state) => {
        state.isApiStatus.resourceUpdateApi = 'failed';
      })
      .addCase(resourceGridViewApi.pending, (state) => {
        state.isApiStatus.resourceGridViewApi = 'loading';
      })
      .addCase(resourceGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isGridView.current_page ? state.isGridView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isGridView && state.isGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus.resourceGridViewApi = 'succeeded';
      })
      .addCase(resourceGridViewApi.rejected, (state) => {
        state.isGridView = [];
        state.isApiStatus.resourceGridViewApi = 'failed';
      })
      .addCase(resourceDeleteApi.pending, (state) => {
        state.isApiStatus.resourceDeleteApi = 'loading';
      })
      .addCase(resourceDeleteApi.fulfilled, (state) => {
        state.isApiStatus.resourceDeleteApi = 'succeeded';
      })
      .addCase(resourceDeleteApi.rejected, (state) => {
        state.isApiStatus.resourceDeleteApi = 'failed';
      })
      .addCase(resourceDetailApi.pending, (state) => {
        state.isApiStatus.resourceDetailApi = 'loading';
      })
      .addCase(resourceDetailApi.fulfilled, (state,action) => {
        state.isDetailData = action.payload;
        state.isApiStatus.resourceDetailApi = 'succeeded';
      })
      .addCase(resourceDetailApi.rejected, (state) => {
        state.isDetailData = "";
        state.isApiStatus.resourceDetailApi = 'failed';
      })
      .addCase(addonservices.pending, (state) => {
        state.isApiStatus.addonservices = 'loading';
      })
      .addCase(addonservices.fulfilled, (state) => {
        state.isApiStatus.addonservices = 'succeeded';
      })
      .addCase(addonservices.rejected, (state) => {
        state.isApiStatus.addonservices = 'failed';
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, OpenResourceAddForm, ResourceEditFormsOpen, ResourceSingleDataGet, addonserviceAction, ResourceSearchName, ResourceSearchObj, ResourceItemIdService } = resourceSlice.actions;
export default resourceSlice.reducer;
