import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import clientApiController from "services/client.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const ClientSuggetionListApi = createAsyncThunk("client/suggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .suggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isSearchBox: "",
  isSuggetionListView: [],
  isSearchList: "",
  isSearchName: "",
  isSearchObj: "",
  isBetaAdBanner:true,
  isApiStatus: {
    ClientSuggetionListApi: "",
  },
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    reset: () => initialState,
    SearchBox: (state, action) => {
      state.isSearchBox = action.payload;
    },
    OpenClientSearchList: (state) => {
      state.isSearchList = "open";
    },
    CloseClientSearchList: (state) => {
      state.isSearchList = "";
    },
    ClientSearchName: (state, action) => {
      state.isSearchName = action.payload;
    },
    ClientSearchObj: (state, action) => {
      state.isSearchObj = action.payload;
    },
    BetaAdBanner: (state, action) => {
      state.isBetaAdBanner = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(ClientSuggetionListApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, ClientSuggetionListApi: "loading" };
    })
    .addCase(ClientSuggetionListApi.fulfilled, (state, action) => {
      let old_current_page = state.isSuggetionListView.current_page ? state.isSuggetionListView.current_page : "";
      let new_current_page = action.payload.current_page ? action.payload.current_page : "";
      let viewdata = state.isSuggetionListView && state.isSuggetionListView.data;
      let newviewdata = action.payload && action.payload.data;
      if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
        if (viewdata.length > 0 && newviewdata.length > 0) {
          // Create a new state object with merged data
          state.isSuggetionListView = {
            ...state.isSuggetionListView,
            data: [...viewdata, ...newviewdata],
          };
        }
      } else {
        state.isSuggetionListView = action.payload;
      }
      state.isApiStatus = { ...state.isApiStatus, ClientSuggetionListApi: "succeeded" };
    })
    .addCase(ClientSuggetionListApi.rejected, (state) => {
      state.isSuggetionListView = [];
      state.isApiStatus = { ...state.isApiStatus, ClientSuggetionListApi: "failed" };
    });    
  },
});
// Action creators are generated for each case reducer function
export const { reset, SearchBox, OpenClientSearchList, CloseClientSearchList, ClientSearchName, ClientSearchObj,BetaAdBanner } = headerSlice.actions;
export default headerSlice.reducer;
