import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;


const stripoemailget = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const page = values && values.page;
    const next_page_url = values && values.next_page_url;
    let q = values && values.q ? values.q : "";
    const action = "stripo/getTemplate";
    const data = {
      auth_key: auth_key,
      action: action,
      temp_name: values && values.temp_name ? values.temp_name : "",
    };
    return axios.post(next_page_url ? `${next_page_url}&q=${q}` : API_URL + action, data, { headers: authHeader() });
  };

const stripoemailsave = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const page = values && values.page;
    const next_page_url = values && values.next_page_url;
    let q = values && values.q ? values.q : "";
    const action = "stripo/tempSave";
    const data = {
      auth_key: auth_key,
      action: action,
      html: values && values.html ? values.html : "",
      temp_name: values && values.temp_name ? values.temp_name : "",
    };
    return axios.post(next_page_url ? `${next_page_url}&q=${q}` : API_URL + action, data, { headers: authHeader() });
  };

  const StripoEmailApiController = {
    stripoemailget,
    stripoemailsave,
  };
  export default StripoEmailApiController;