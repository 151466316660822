import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDropdownToggle: false,
  isStaffDropDownDashboardToggle: false,
  isStaffDropDownReportModuleToggle: false,
  isDropdownToggleCalendar: false,
  isDropDownClientMoreButton:false,
  isDropDownVoucherMoreButton:false,
  isStaffDropDownStaffModuleToggle:false,
  isDropDownProductsMoreButton:false,
  isStaffDropDownCalendarModuleToggle:false,
  isProfileDropdownMenu:false,
  isStaffDropDownStaffTargetToggle:false,
  isDropDownClientImportNoteMoreButton:false,
  isDropDownClientVoucherModalMoreButton:false,
  isDropDownClientGridMoreButton:{},
  isDropDownClientListMoreButton:{},
  isDropDownStaffGridMoreButton:{},
  isDropDownServiceListMoreButton:{},
  isDropdownReportMoreProduct:{},
  isDropDownProductsListMoreButton:{},
  isDropDownCategoryListMoreButton:{},
  isDropDownSupplierGridMoreButton:{},
  isDropDownStaffTargetListMoreButton:{},
  isDropDownResourceListMoreButton:{},
};

const dropdownSlice = createSlice({
  name: 'dropdown',
  initialState,
  reducers: {
    resetDropdownToggle: () => initialState,
    DropDownToggle:(state)=>{
      state.isDropdownToggle = !state.isDropdownToggle;
    },
    DropDownCalendarToggle:(state)=>{
      state.isDropdownToggleCalendar = !state.isDropdownToggleCalendar;
    },
    DropDownClientMoreButton:(state)=>{
      state.isDropDownClientMoreButton = !state.isDropDownClientMoreButton;
    },
    DropDownVoucherMoreButton:(state)=>{
      state.isDropDownVoucherMoreButton = !state.isDropDownVoucherMoreButton;
    },
    DropDownVoucherClientModalMoreButton:(state)=>{
      state.isDropDownClientVoucherModalMoreButton = !state.isDropDownClientVoucherModalMoreButton;
    },
    DropDownClientImportNoteMoreButton:(state)=>{
      state.isDropDownClientImportNoteMoreButton = !state.isDropDownClientImportNoteMoreButton;
    },
    DropDownClientGridMoreButton: (state, action) => {
      const { id } = action.payload;
      const currentStatus = state.isDropDownClientGridMoreButton[id];
      state.isDropDownClientGridMoreButton = { ...state.isDropDownClientGridMoreButton, [id]: !currentStatus };
    },
    DropDownClientListMoreButton: (state, action) => {
      const { id } = action.payload;
      const newState = { ...state.isDropDownClientListMoreButton };

      // Close the clicked dropdown if it's already open
      if (newState[id]) {
        newState[id] = false;
      } else {
        // Close all other open dropdowns
        Object.keys(newState).forEach((key) => {
          newState[key] = false;
        });

        // Open the clicked dropdown
        newState[id] = true;
      }

      state.isDropDownClientListMoreButton = newState;
    },
    DropDownStaffGridMoreButton: (state, action) => {
      const { id } = action.payload;
      const currentStatus = state.isDropDownStaffGridMoreButton[id];
      state.isDropDownStaffGridMoreButton = { ...state.isDropDownStaffGridMoreButton, [id]: !currentStatus };
    },
    DropDownStaffTargetListMoreButton: (state, action) => {
      const { id } = action.payload;
      const currentStatus = state.isDropDownStaffTargetListMoreButton[id];
      state.isDropDownStaffTargetListMoreButton = { ...state.isDropDownStaffTargetListMoreButton, [id]: !currentStatus };
    },
    DropDownServiceListMoreButton: (state, action) => {
      const { id } = action.payload;
      const newState = { ...state.isDropDownServiceListMoreButton };

      // Close the clicked dropdown if it's already open
      if (newState[id]) {
        newState[id] = false;
      } else {
        // Close all other open dropdowns
        Object.keys(newState).forEach((key) => {
          newState[key] = false;
        });

        // Open the clicked dropdown
        newState[id] = true;
      }

      state.isDropDownServiceListMoreButton = newState;
    },
    DropDownResourceListMoreButton: (state, action) => {
      const { id } = action.payload;
      const newState = { ...state.isDropDownResourceListMoreButton };
      if (newState[id]) {
        newState[id] = false;
      } else {
        Object.keys(newState).forEach((key) => {
          newState[key] = false;
        });
        newState[id] = true;
      }

      state.isDropDownResourceListMoreButton = newState;
    },
    DropDownProductsMoreButton: (state, action) => {
      state.isDropDownProductsMoreButton = !state.isDropDownProductsMoreButton;
    },
    DropDownProductsListMoreButton: (state, action) => {
      const { id } = action.payload;
      const newState = { ...state.isDropDownProductsListMoreButton };

      // Close the clicked dropdown if it's already open
      if (newState[id]) {
        newState[id] = false;
      } else {
        // Close all other open dropdowns
        Object.keys(newState).forEach((key) => {
          newState[key] = false;
        });

        // Open the clicked dropdown
        newState[id] = true;
      }

      state.isDropDownProductsListMoreButton = newState;
    },
    DropDownCategoryListMoreButton: (state, action) => {
      const { id } = action.payload;
      const newState = { ...state.isDropDownCategoryListMoreButton };

      // Close the clicked dropdown if it's already open
      if (newState[id]) {
        newState[id] = false;
      } else {
        // Close all other open dropdowns
        Object.keys(newState).forEach((key) => {
          newState[key] = false;
        });

        // Open the clicked dropdown
        newState[id] = true;
      }

      state.isDropDownCategoryListMoreButton = newState;
    },
    DropdownReportMoreProduct: (state, action) => {
      const { id } = action.payload;
      const newState = { ...state.isDropdownReportMoreProduct };

      // Close the clicked dropdown if it's already open
      if (newState[id]) {
        newState[id] = false;
      } else {
        // Close all other open dropdowns
        Object.keys(newState).forEach((key) => {
          newState[key] = false;
        });

        // Open the clicked dropdown
        newState[id] = true;
      }

      state.isDropdownReportMoreProduct = newState;
    },
    DropDownSupplierGridMoreButton: (state, action) => {
      const { id } = action.payload;
      const currentStatus = state.isDropDownSupplierGridMoreButton[id];
      state.isDropDownSupplierGridMoreButton = { ...state.isDropDownSupplierGridMoreButton, [id]: !currentStatus };
    },
    StaffDropDownDashboardToggle:(state)=>{
      state.isStaffDropDownDashboardToggle = !state.isStaffDropDownDashboardToggle;
    },
    StaffDropDownStaffTargetToggle:(state)=>{
      state.isStaffDropDownStaffTargetToggle = !state.isStaffDropDownStaffTargetToggle;
    },
    StaffDropDownDashboardToggle:(state)=>{
      state.isStaffDropDownDashboardToggle = !state.isStaffDropDownDashboardToggle;
    },
    StaffDropDownStaffModuleToggle:(state)=>{
      state.isStaffDropDownStaffModuleToggle = !state.isStaffDropDownStaffModuleToggle;
    },
    StaffDropDownReportModuleToggle:(state)=>{
      state.isStaffDropDownReportModuleToggle = !state.isStaffDropDownReportModuleToggle;
    },
    StaffDropDownCalendarModuleToggle:(state)=>{
      state.isStaffDropDownCalendarModuleToggle = !state.isStaffDropDownCalendarModuleToggle;
    },
    ProfileDropdownMenu:(state)=>{
      state.isProfileDropdownMenu = !state.isProfileDropdownMenu;
    },
  },
});

export const { resetDropdownToggle,DropDownToggle,StaffDropDownDashboardToggle,DropDownCalendarToggle,DropDownClientMoreButton,DropDownClientGridMoreButton,DropDownClientListMoreButton,DropDownStaffGridMoreButton,DropDownServiceListMoreButton,DropDownProductsMoreButton,DropDownProductsListMoreButton,DropDownCategoryListMoreButton,DropDownSupplierGridMoreButton,StaffDropDownStaffModuleToggle,StaffDropDownReportModuleToggle,StaffDropDownCalendarModuleToggle,ProfileDropdownMenu,DropDownStaffTargetListMoreButton,StaffDropDownStaffTargetToggle,DropDownClientImportNoteMoreButton,DropDownResourceListMoreButton,DropDownVoucherMoreButton,DropDownVoucherClientModalMoreButton,DropdownReportMoreProduct } = dropdownSlice.actions;
export default dropdownSlice.reducer;