import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// import PropTypes from "prop-types";
// import config from "../../../../config";
import { swalConfirm } from "../../../../component/Sweatalert2";
import { ClientdocumentGridViewApi, clientdocumentDeleteApi } from "store/slices/clientdocumentSlice";
import config from "../../../../config";
import { ellipseText } from "helpers/Functions";
import DocumentUpload from "component/form/DocumentUpload";
import InfiniteScroll from "react-infinite-scroll-component";
import PaginationLoader from "component/PaginationLoader";

const Documents = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const [openModalTwo, setOpenModalTwo] = useState(false);
  const access = useSelector((state) => state.salonmodule.isAccess);
  const detail = useSelector((state) => state.client.isDetailData);
  const documentViews = useSelector((state) => state.clientdocument.isGridView);

  const fetchDataPhotoList = () => {
    dispatch(ClientdocumentGridViewApi({ client_id: detail.id, next_page_url: documentViews.next_page_url }));
  };

  const [isFetching, setIsFetching] = useState(false);
  const loadMoreItemsList = () => {
    setIsFetching(true);
    dispatch(ClientdocumentGridViewApi({ next_page_url: documentViews && documentViews.next_page_url, client_id: detail.id }));
    //mocking an API call
    setTimeout(() => {
      setIsFetching(false);
    }, 2000);
  };

  const handleClientDelete = (e) => {
    const event = JSON.parse(e.currentTarget.dataset.obj);
    let confirmbtn = swalConfirm(e.currentTarget, { title: t("Are you sure want to delete?"), message: t("Success"), confirmButtonText: t("Yes, delete it!") });
    if (confirmbtn == true) {
      dispatch(clientdocumentDeleteApi({ id: event.id }));
    }
  };
  return (
    <>
      <div className="drawer-header">
        <h2 className="mb-4 pe-md-5 mb-lg-5">
          {t("Documents")} <DocumentUpload name="document" className="btn btn-outline btn-sm ms-2" accept="image/*" label={t("Add Document")} page="client-adddocumentform" controlId="clientForm-document" client_id={detail.id} />
          {documentViews && documentViews.data && documentViews.data.length > 0 ? (
            <button className="btn btn-apricots btn-sm ms-2" onClick={() => setOpenModalTwo(true)}>Delete All</button>
          ) : ""}
        </h2>
      </div>
      <div className="content-wrp" id="documentlist">
        {openModalTwo && (
          <>
            <div className={openModalTwo ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openModalTwo ? "block" : "none" }}>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body pb-0">
                    <div className="p-3">
                      <h5 className="mb-3 fw-semibold text-dark">{t("Confirm all document deletion")}</h5>
                      <p className="fw-semibold mb-2">{t("Are you sure you want to delete all documents?")}</p>
                    </div>
                    <div className="row mt-3 bg-cream p-4 justify-content-center">
                      <div className="col-6">
                        <button className="preview btn me-1 cursor-pointer btn-preview w-100" type="button" onClick={() => {
                          setOpenModalTwo(false);
                        }}>
                          {t("Cancel")}
                        </button>
                      </div>
                      <div className="col-6">
                        <button className="btn btn-primary w-100" onClick={() => {
                          if (documentViews && documentViews.data && documentViews.data.length > 0) {
                            documentViews.data.forEach((doc) => {
                              dispatch(clientdocumentDeleteApi({ id: doc.id }));
                            });
                            setOpenModalTwo(false);
                          }
                        }} type="button">
                          {t("Confirm")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {openModalTwo && <div className="modal-backdrop show"></div>}
          </>
        )}
        {documentViews && documentViews.data && documentViews.data.length ? (
          <InfiniteScroll className="row gx-0" dataLength={documentViews.data.length} next={fetchDataPhotoList} scrollableTarget="documentlist" hasMore={documentViews.next_page_url ? true : false} loader={<PaginationLoader />}>
            {Object.keys(documentViews.data).map((item, i) => {
              //   let id = documentViews.data[item].id;
              //   let client_id = documentViews.data[item].client_id;
              let document = documentViews.data[item].document;
              let document_url = documentViews.data[item].document_url;
              let updated_at = documentViews.data[item].updated_at;
              let ext = document.split(".").pop();
              return (
                <div className="event-box" key={item && item.id ? item.id : i}>
                  <div className="d-flex align-items-start">
                    <a>
                      <img src={config.imagepath + (ext === "pdf" ? "pdf.png" : "doc.png")} alt="" />
                    </a>
                    <div className="w-100 px-md-3 px-2">
                      <h6 className="mb-1">
                        <b>{ellipseText(document, "10")}</b>
                      </h6>
                      <p className="mb-2">
                        {t("uploaded")}: {updated_at}
                      </p>

                      <a href={document_url} target="_blank" download={document} className="btn btn-outline btn-sm">
                        {t("Download")}
                      </a>

                    </div>

                    <a className="remove cursor-pointer" data-obj={JSON.stringify(documentViews.data[item])} onClick={handleClientDelete}>
                      {t("Remove")}
                    </a>

                  </div>
                </div>
              );
            })}
            {!isFetching && documentViews.next_page_url && (
              <div className="col-2 m-auto p-3 text-center">
                <button onClick={loadMoreItemsList} className="btn btn-primary">
                  {t("More")}
                </button>
              </div>
            )}
          </InfiniteScroll>
        ) : (
          <div className="complete-box text-center d-flex flex-column justify-content-center mt-xl-4">
            <div className="complete-box-wrp text-center">
              <img src={config.imagepath + "docs.png"} alt="" className="mb-md-4 mb-3" />
              <h5 className="mb-2 fw-semibold">
                {t("Add documents and keep a record of your client’s treatments.")}
                <br />
                <DocumentUpload name="document" className="" accept="image/*" label={t("Add your first document.")} page="client-adddocumentform-circle" controlId="clientForm-document" client_id={detail.id} />
                {/* <a
            className="add-document cursor-pointer"
            onClick={() => }
          >
            {t("Add your first document.")}
          </a> */}
              </h5>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

// Documents.propTypes = {
//   // props: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
// };

export default Documents;
