import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import stripeApiController from "services/stripe.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const StripeUpdatekeysApi = createAsyncThunk("stripe/updatekeys", async (formValues, thunkAPI) => {
  try {
    const resposedata = await stripeApiController
      .updatekeys(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "updatekeys"))
      .catch((error) => HandleError(thunkAPI, error, "updatekeys"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StripeSetupApi = createAsyncThunk("stripe/setup", async (formValues, thunkAPI) => {
  try {
    const resposedata = await stripeApiController
      .setup(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "setup"))
      .catch((error) => HandleError(thunkAPI, error, "setup"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StripeCardPaymentApi = createAsyncThunk("stripe/cardpayment", async (formValues, thunkAPI) => {
  try {
    const resposedata = await stripeApiController
      .cardpayment(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "cardpayment"))
      .catch((error) => HandleError(thunkAPI, error, "cardpayment"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StripeOauthApi = createAsyncThunk("stripe/Oauth", async (formValues, thunkAPI) => {
  try {
    const resposedata = await stripeApiController
      .oauth(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Oauth"))
      .catch((error) => HandleError(thunkAPI, error, "Oauth"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StripePaymentStore = createAsyncThunk("stripepayment/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await stripeApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const StripePaymentApi = createAsyncThunk("stripe/payment", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await stripeApiController
      .stripepayment(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "payment"))
      .catch((error) => HandleError(thunkAPI, error, "payment"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const StripePaymentFailedApi = createAsyncThunk("stripe/paymentdfailed", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await stripeApiController
      .stripepaymentfailed(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "paymentdfailed"))
      .catch((error) => HandleError(thunkAPI, error, "paymentdfailed"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

const initialState = {
  isOpenedAddForm: "",
  isStripePaymentStatus: "",
  isOnlineStripePayment: "",
  isStripeOauth: "",
  isStripeUpdatekeysApi: "",
  isStripePaymentApi: "",
  isStripePaymentFailedApi: "",
  isListView:"",
  isStripeFormErrorSwal: false,
  isApiStatus: {
    StripeSetupApi: "",
    StripeOauthApi: "",
    StripePaymentApi: "",
    StripePaymentFailedApi: "",
    StripePaymentStore : "",
  },
};

const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenAddStripeForm: (state = initialState) => {
      state.isOpenedAddForm = "open";
    },
    CloseAddStripeForm: (state = initialState) => {
      state.isOpenedAddForm = "";
    },
    StripePaymentStatus: (state, action) => {
      state.isStripePaymentStatus = action.payload;
    },
    OnlineStripePayment: (state, action) => {
      state.isOnlineStripePayment = action.payload;
    },
    StripeFormErrorSwal: (state, action) => {
      state.isStripeFormErrorSwal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(StripePaymentStore.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripePaymentStore: "loading" };
      })
      .addCase(StripePaymentStore.fulfilled, (state, action) => {
        if (state.isListView && state.isListView.data) {
          state.isListView.data = [action.payload, ...state.isListView.data];
        } else {
          state.isListView = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, StripePaymentStore: "succeeded" };
      })
      .addCase(StripePaymentStore.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripePaymentStore: "failed" };
      })
      .addCase(StripeSetupApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripeSetupApi: "loading" };
      })
      .addCase(StripeSetupApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripeSetupApi: "succeeded" };
      })
      .addCase(StripeSetupApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripeSetupApi: "failed" };
      })
      .addCase(StripeOauthApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripeOauthApi: "loading" };
      })
      .addCase(StripeOauthApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, StripeOauthApi: "succeeded" };
        state.isStripeOauth = action.payload;
      })
      .addCase(StripeOauthApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripeOauthApi: "failed" };
      })
      .addCase(StripeUpdatekeysApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripeUpdatekeysApi: "loading" };
      })
      .addCase(StripeUpdatekeysApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, StripeUpdatekeysApi: "succeeded" };
        state.isStripeUpdatekeysApi = action.payload;
      })
      .addCase(StripeUpdatekeysApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripeUpdatekeysApi: "failed" };
      })
      .addCase(StripePaymentApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripePaymentApi: "loading" };
      })
      .addCase(StripePaymentApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, StripePaymentApi: "succeeded" };
        state.isStripePaymentApi = action.payload;
      })
      .addCase(StripePaymentApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripePaymentApi: "failed" };
        state.isStripePaymentApi = [];
      })
      .addCase(StripePaymentFailedApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripePaymentFailedApi: "loading" };
      })
      .addCase(StripePaymentFailedApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, StripePaymentFailedApi: "succeeded" };
        state.isStripePaymentFailedApi = action.payload;
      })
      .addCase(StripePaymentFailedApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripePaymentFailedApi: "failed" };
        state.isStripePaymentFailedApi = [];
      });
  }
});

// Action creators are generated for each case reducer function
export const { reset, OpenAddStripeForm, CloseAddStripeForm, StripePaymentStatus, OnlineStripePayment,StripeFormErrorSwal } = stripeSlice.actions;
export default stripeSlice.reducer;
