import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const create = (values) => {
  const formData = new FormData();
  for (let value in values) {
    if (["BookingSelectedServicesData", "BookingSelectedMembershipData", "paymentIntent"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "widget/booking/store";
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const view = (values) => {
  const result = values && values.result ? values.result : "";
  const location = values && values.pathname;
  const action = "widget/booking/view";
  const data = {
    action: action,
    pagination: false, //true or false
    result: result, //business_name,owner_name
    location: location, //business_name,owner_name
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};


const bookingCategoryView = (values) => {
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const fromPosition = values && values.fromPosition ? values.fromPosition : "";
  const toPosition = values && values.toPosition ? values.toPosition : "";
  const uid = values && values.uid ? values.uid : "";
  const salon_id = values && values.salon_id ? values.salon_id : "";
  let sortstring = "";
  if (sort) {
    let sortArray = [];
    Object.keys(sort).map(function (key, index) {
      return (sortArray[index] = `sort[${key}]=${sort[key]}`);
    });
    if (sortArray.length > 0) {
      let jsort = sortArray.join("&");
      sortstring = jsort;
    }
  }
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `widget/categories/view?page=${page}&${sortstring}` : `widget/categories/view?${sortstring}`;
  const data = {
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    option: values && values.option ? values.option : "",
    fromPosition: fromPosition,
    toPosition: toPosition,
    uid: uid,
    salon_id: salon_id,
  };
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const bookingServiceView = (values) => {
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const dropdown = values && values.dropdown ? values.dropdown : "";
  const result = values && values.result ? values.result : "";
  const fromPosition = values && values.fromPosition ? values.fromPosition : "";
  const toPosition = values && values.toPosition ? values.toPosition : "";
  const uid = values && values.uid ? values.uid : "";
  const salon_id = values && values.salon_id ? values.salon_id : "";
  const isNotServiceIds = values && values.isNotServiceIds ? values.isNotServiceIds : "";
  const primary_service_id = values && values.primary_service_id ? values.primary_service_id : "";
  const availebleTimeObjAnother = values && values.availebleTimeObjAnother ? values.availebleTimeObjAnother : "";
  const isBookingStep = values && values.isBookingStep ? values.isBookingStep : "";

  let sortstring = "";
  if (sort) {
    let sortArray = [];
    let sortSubArray = [];
    Object.keys(sort).map(function (key, index) {
      if (Object.keys(sort[key]).length > 0) {
        Object.keys(sort[key]).map(function (subkey, subindex) {
          sortSubArray[subindex] = `sort[${key}.${subkey}]=${sort[key][subkey]}`;
        });
      }
      if (key != "category") {
        sortArray[index] = `sort[${key}]=${sort[key]}`;
      }
    });
    if (sortSubArray.length > 0) {
      let jsubsort = sortSubArray.join("&");
      sortstring = jsubsort;
    }
    if (sortArray.length > 0) {
      let jsort = sortArray.join("&");
      sortstring = jsort;
    }
  }
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `widget/services/view?page=${page}&${sortstring}` : `widget/services/view?${sortstring}`;
  let servicedata;
  if (dropdown) {
    servicedata = {
      action: action,
      pagination: false, //true or false
      id: "",
      // field: "name,description,duration,padding_time,color,service_booked_online,deposit_booked_online,deposit_booked_price", // first_name,last_name,email
      result: result, //business_name,owner_name
      uid: uid,
      salon_id: salon_id,
      isNotServiceIds: isNotServiceIds,
      primary_service_id: primary_service_id
    };
  } else {
    servicedata = {
      action: action,
      pagination: false, //true or false
      id: values && values.id ? values.id : "",
      // field: values && values.id ? "" : "name,description,duration,padding_time,color,service_booked_online,deposit_booked_online,deposit_booked_price", // first_name,last_name,email
      result: result, //business_name,owner_name
      option: values && values.option ? values.option : "",
      category_id: values && values.category_id ? values.category_id : "",
      fromPosition: fromPosition,
      toPosition: toPosition,
      uid: uid,
      salon_id: salon_id,
      isNotServiceIds: isNotServiceIds,
      primary_service_id: primary_service_id,
      availebleTimeObjAnother:availebleTimeObjAnother,
      isBookingStep:isBookingStep
    };
  }

  const data = servicedata;
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const bookingStaffView = (values) => {
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const service_id = values && values.service_id ? values.service_id : "";
  const uid = values && values.uid ? values.uid : "";
  const salon_id = values && values.salon_id ? values.salon_id : "";
  const availebleTimeObjAnother = values && values.availebleTimeObjAnother ? values.availebleTimeObjAnother : "";
  const isBookingStep = values && values.isBookingStep ? values.isBookingStep : "";
  const formType = "bookingwidget/staff/view";

  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `widget/staff/view?page=${page}` : `widget/staff/view`;

  const data = {
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    option: values && values.option ? values.option : "",
    service_id: service_id,
    uid: uid,
    salon_id: salon_id,
    availebleTimeObjAnother:availebleTimeObjAnother,
    isBookingStep:isBookingStep,
    formType:formType
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const bookingStaffAvailableTime = (values) => {
  const service_id = values && values.service_id ? values.service_id : "";
  const staff_id = values && values.staff_id ? values.staff_id : "";
  const dateof = values && values.dateof ? values.dateof : "";
  const page_type = values && values.page_type ? values.page_type : "";
  const uid = values && values.uid ? values.uid : "";
  const salon_id = values && values.salon_id ? values.salon_id : "";
  const listview = values && values.listview ? values.listview : "";
  // const serviceObjData = values && values.serviceObjData ? values.serviceObjData : "";
  const first_match = values && values.first_match ? values.first_match : "";
  // const staffObjData = values && values.staffObjData ? values.staffObjData : "";
  // const duration = serviceObjData && serviceObjData.duration ? serviceObjData.duration : "";
  // const padding_time = serviceObjData && serviceObjData.padding_time ? serviceObjData.padding_time : "";

  const duration = values && values.duration ? values.duration : "";
  const padding_time = values && values.padding_time ? values.padding_time : "";
  const durationWithPadding = values && values.durationWithPadding ? values.durationWithPadding : "";
  const parentAppointmentEndTime = values && values.parentAppointmentEndTime ? values.parentAppointmentEndTime : "";

  const isBookingSelectedServicesData = values.isBookingSelectedServicesData ? values.isBookingSelectedServicesData : "";
  const BookingSelectedServicesData =
    isBookingSelectedServicesData &&
    isBookingSelectedServicesData.filter((item) => {
      if (item.category_id != "" && item.service_id != "" && item.staff_id != "" && item.datetime != "") {
        return item;
      }
    });
  const action = `widget/staff/availabletime`;

  const data = {
    action: action,
    service_id: service_id,
    staff_id: staff_id,
    dateof: dateof,
    uid: uid,
    salon_id: salon_id,
    duration: duration,
    padding_time: padding_time,
    durationWithPadding: durationWithPadding,
    parentAppointmentEndTime: parentAppointmentEndTime,
    BookingSelectedServicesData: BookingSelectedServicesData,
    // first_match:first_match,
    listview: listview,
    page_type: page_type
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};
const checkStaffAvailableTime = (values) => {
  const service_id = values && values.service_id ? values.service_id : "";
  const staff_id = values && values.staff_id ? values.staff_id : "";
  const dateof = values && values.dateof ? values.dateof : "";
  const page_type = values && values.page_type ? values.page_type : "";
  const uid = values && values.uid ? values.uid : "";
  const salon_id = values && values.salon_id ? values.salon_id : "";
  const serviceObjData = values && values.serviceObjData ? values.serviceObjData : "";
  const first_match = values && values.first_match ? values.first_match : "";
  // const staffObjData = values && values.staffObjData ? values.staffObjData : "";
  const duration = serviceObjData && serviceObjData.duration ? serviceObjData.duration : "";
  const padding_time = serviceObjData && serviceObjData.padding_time ? serviceObjData.padding_time : "";
  const BookingSelectedServicesData = values.BookingSelectedServicesData ? values.BookingSelectedServicesData : "";
  // const BookingSelectedServicesData =
  //   isBookingSelectedServicesData &&
  //   isBookingSelectedServicesData.filter((item) => {
  //     if (item.category_id != "" && item.service_id != "" && item.staff_id != "" && item.datetime != "") {
  //       return item;
  //     }
  //   });
  const action = `widget/booking/availableappointment`;

  const data = {
    action: action,
    service_id: service_id,
    staff_id: staff_id,
    dateof: dateof,
    uid: uid,
    salon_id: salon_id,
    duration: duration,
    padding_time: padding_time,
    BookingSelectedServicesData: BookingSelectedServicesData,
    // first_match:first_match,
    page_type: page_type
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const createPaymentIntent = (values) => {
  // console.log("apivalue", values);
  const action = `widget/booking/createpaymentintent`;

  const data = {
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const bookingpayment = (values) => {
  const formData = new FormData();
  for (let value in values) {
    if (["paymentIntent"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `widget/booking/bookingpayment`;
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const bookingpaymentfailed = (values) => {
  const formData = new FormData();
  for (let value in values) {
    if (["error", 'paymentIntent'].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `widget/booking/bookingpaymentfailed`;
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const bookingMembershipView = (values) => {
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const membership_id = values && values.membership_id ? values.membership_id : "";
  const uid = values && values.uid ? values.uid : "";
  const salon_id = values && values.salon_id ? values.salon_id : "";

  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `widget/membership/view?page=${page}` : `widget/membership/view`;
  const data = {
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    option: values && values.option ? values.option : "",
    membership_id: membership_id,
    uid: uid,
    salon_id: salon_id,
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const bookingVocuherView = (values) => {
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const membership_id = values && values.membership_id ? values.membership_id : "";
  const uid = values && values.uid ? values.uid : "";
  const salon_id = values && values.salon_id ? values.salon_id : "";

  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `widget/voucher/view?page=${page}` : `widget/voucher/view`;
  const data = {
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    option: values && values.option ? values.option : "",
    voucher_type: values && values.voucher_type ? values.voucher_type : "",
    membership_id: membership_id,
    uid: uid,
    salon_id: salon_id,
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const bookingVoucherWithServiceView = (values) => {
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const membership_id = values && values.membership_id ? values.membership_id : "";
  const uid = values && values.uid ? values.uid : "";
  const salon_id = values && values.salon_id ? values.salon_id : "";

  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `widget/voucher/view?page=${page}` : `widget/voucher/view`;
  const data = {
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    option: values && values.option ? values.option : "",
    membership_id: membership_id,
    voucher_online : values && values.voucher_online ? values.voucher_online : "",
    uid: uid,
    salon_id: salon_id,
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const firstavailabletimebookinglink = (values) => {
  const formType = "bookingwidget/staff/view";
  const action = `widget/booking/nextresourceitemtime`;

  const data = {
    ...values,
    formType,formType
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const nextresourceitemtimeget = (values) => {
  const formType = "bookingwidget/staff/view"
  const action = "widget/booking/nextresourceitemtime";

  const data = {
    ...values,
    formType:formType
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const recourceitems = (values) => {

  const action = "widget/booking/bookingresourceitem";

  const data = {
    ...values,
    action
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const disabledates = (values) => {
  const action = "widget/booking/disabledates";
  const data = {
    ...values,
    action
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
}

const anyavailablestaff = (values) => {
  const formType = "bookingwidget/staff/view"
  const action = "widget/booking/anyavailablestaff";

  const data = {
    ...values,
    formType:formType
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const bookingApiController = {
  create,
  view,
  bookingCategoryView,
  bookingServiceView,
  bookingStaffView,
  bookingStaffAvailableTime,
  createPaymentIntent,
  bookingpayment,
  bookingpaymentfailed,
  bookingMembershipView,
  bookingVocuherView,
  bookingVoucherWithServiceView,
  firstavailabletimebookinglink,
  checkStaffAvailableTime,
  nextresourceitemtimeget,
  recourceitems,
  disabledates,
  anyavailablestaff
};
export default bookingApiController;
