import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import config from "../../../config";
import Appointment from "./Appointment";
import ClientEditForm from "../Form/ClientEditForm";
import Voucher from "./Voucher";
import Subscription from "./Subscription";
import Membership from "./Membership";
import Products from "./Products"
import Photos from "./Photos";
import Invoices from "./Invoices";
import Documents from "./Documents";
import Notes from "./Notes";
import Forms from "./Forms"
import { CloseClientDetailModal, ClientDetailTab, ClientDataToSale, SetClientAppointmentCalendar } from "../../../store/slices/clientSlice";
import { ucfirst, usercheckAccess } from "../../../helpers/Functions";
import { ClientphotoGridViewApi } from "store/slices/clientphotoSlice";
import { ClientdocumentGridViewApi } from "store/slices/clientdocumentSlice";
import { ClientnoteGridViewApi } from "store/slices/clientnoteSlice";
import { ClientAppointmentListViewApi, openAppointmentFilter } from "store/slices/appointmentSlice";
import { ClientMembershipListViewApi } from "store/slices/clientmembershipSlice";
import { ClientInvoiceListViewApi, OpenClientInvoiceDetails, OpenClientInvoiceRefundModal, OpenPartialPaidInvoice, OpenStripeRefundCompletedSaleModal } from "store/slices/clientinvoiceSlice";
import { ClientVoucherListViewApi } from "store/slices/clientvoucherSlice";
import { ClientchatGridViewApi } from "store/slices/clientChatSlice";
import { openAddSaleForm, SaleTabView } from "store/slices/saleSlice";
import { useNavigate } from "react-router";
import { ClientproductApi } from "store/slices/clientproductSlice";
import { clientDetailAppointmentPrintApi, clientDetailPrinterApi } from "store/slices/printerSlice";
import ChatMessages from "./ChatMessages";
import { OpenClientRefundSelection, OpenRefundInvoice, openPastInvoiceDrawer } from "store/slices/clientappointmentinvoiceSlice";
import BarLoader from "react-spinners/BarLoader";
import { ClientSubscriptionListViewApi } from "store/slices/clientsubscriptionSlice";
import { ClientformApi } from "store/slices/clientformSlice";

const ClientDetailModal = () => {
  const [loading, setLoading] = useState(false);
  const [clientSmsTabloading, setClientSmsTabloading] = useState(false);
  const rightDrawerOpened = useSelector((state) => state.client.isOpenedDetailModal);
  const detailTab = useSelector((state) => state.client.isClientDetailTab);
  const detail = useSelector((state) => state.client.isDetailData);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const navigate = useNavigate();
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.salonmodule.isAccess);
  const isFilter = useSelector((state) => state.appointment.isFilter);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let emailid = detail.email;
  let mobilenumbers = detail.phone_number;
  const userPermissions = currentUser && currentUser.useraccess;
  const hasAccess = usercheckAccess("client_detail_view", currentUser);
  const client_id = detail && detail.id ? detail.id : "";

  const handleCloseClientDetailModal = () => {
    //dispatch({ type: "client/detail/rejected" });
    dispatch(CloseClientDetailModal(true));
    setTimeout(() => {
      dispatch(CloseClientDetailModal(""));
    }, config.modalCloseTimeOut);
  };
  const first_name = detail.first_name;
  const last_name = detail.last_name;
  const email = detail.email;
  // let phone_number = detail.phone_number;
  const profile_photo_url = detail.profile_photo_url;
  const filterOption = [
    { value: "", label: t("All") },
    { value: "Scheduled", label: t("Scheduled") },
    { value: "Confirmed", label: t("Confirmed") },
    // { value: "Completed", label: t("Completed") },
    { value: "NoShow", label: t("NoShow") },
    { value: "Cancelled", label: t("Cancelled") },
  ];
  const handleCheckout = () => {
    dispatch({ type: "sale/reset" });
    // dispatch(ClientSearchName(""));
    // dispatch(ClientSearchObj(""));
    dispatch(AppointmentDetail(appointmentDetail));
    dispatch(openAddSaleForm());
  };

  useLayoutEffect(() => {
    if(detailTab == ""){
      dispatch(ClientDetailTab("clientdetail"))
    }
    if (detailTab == "clientdetail") {
      dispatch(ClientDetailTab("clientdetail"));
    }
    if (detailTab == "appointment") {
      dispatch(ClientAppointmentListViewApi({ client_id: client_id, page_name: "client_appointment" }));
    }
    if (detailTab == "photos") {
      dispatch(ClientphotoGridViewApi({ client_id: client_id }));
    }
    if (detailTab == "invoices") {
      dispatch(ClientInvoiceListViewApi({ client_id: client_id }));
    }
    if (detailTab == "documents") {
      dispatch(ClientdocumentGridViewApi({ client_id: client_id }));
    }
    if (detailTab == "notes") {
      dispatch(ClientnoteGridViewApi({ client_id: client_id }));
    }
    if (detailTab == "memberships") {
      dispatch(ClientMembershipListViewApi({ client_id: client_id }));
    }
    if (detailTab == "vouchers") {
      dispatch(ClientVoucherListViewApi({ client_id: client_id }));
    }
    if (detailTab == "forms") {
      dispatch(ClientformApi({ client_id: client_id }));
    }
    if (detailTab == "chatmessages") {
      dispatch(ClientchatGridViewApi({ client_id: client_id }));
    }
    if (detailTab == "subscriptions") {
      dispatch(ClientSubscriptionListViewApi({ client_id: client_id }));
    }
    if (detailTab == "products") {
      dispatch(ClientproductApi({ client_id: client_id }));
    }
  }, [detailTab])
  

  return (
    <React.Fragment>
      <div className={"drawer client-detaildrawer p-0 overflow-y-client-detail-auto " + rightDrawerOpened}>
        <div className="drawer-wrp">
          <a className="close-drawer cursor-pointer" onClick={handleCloseClientDetailModal}>
            <img src={config.imagepath + "close-icon.svg"} alt="" />
          </a>
          <div className="drawer-body row">
            <div className="left-menu col-md-5">
              <div className="d-flex mb-3 align-items-baseline">
                {profile_photo_url ? (
                  <div className="user col-2 me-2">
                    <a data-fancybox="" data-src={profile_photo_url}>
                      <img src={profile_photo_url} alt="" className="profile-image-obj rounded-circle wh-40" />
                    </a>
                  </div>
                ) : (
                  <div className="user-initial me-md-3 me-2 p-md-3 client-user-circles custom-client-user-circles-lh">{first_name.charAt(0) + "" + last_name.charAt(0)}</div>
                )}
                <div className="user-id">
                  <h3 className="user-name mb-0">{ucfirst(first_name + " " + last_name)}</h3>
                  <span className="user-id overflow-wrap-anywhere">{email}</span>
                </div>
              </div>
              <div className="row gx-2 action-box mb-3 align-items-end">
                <a className="col text-center text-decoration-none" onClick={() => {
                  dispatch(CloseClientDetailModal(""));
                  dispatch(SetClientAppointmentCalendar(detail));
                  navigate(config.basePath + "/calendar");
                }}>
                  <img src={config.imagepath + "appoinment.png"} alt="" />
                  <span className="d-block">{t("Appointment")}</span>
                </a>
                <a href="#" className="col text-center text-decoration-none" onClick={() => {
                  dispatch(CloseClientDetailModal(""));
                  dispatch({ type: "sale/reset" });
                  dispatch(openAddSaleForm());
                  dispatch(ClientDataToSale(detail));
                }}>
                  <img src={config.imagepath + "sale-light.png"} alt="" />
                  <span className="d-block">{t("Sale")}</span>
                </a>
                <a href={`mailto:${emailid}`} target="_blank" className="col text-center text-decoration-none">
                  <img src={config.imagepath + "email.png"} alt="" />
                  <span className="d-block">{t("Email")}</span>
                </a>
                <a className="col text-center text-decoration-none" onClick={()=>{
                  setClientSmsTabloading(true);
                  dispatch(ClientchatGridViewApi({ client_id: detail.id })).then(()=>{
                    setClientSmsTabloading(false);
                    dispatch(ClientDetailTab("chatmessages"));
                  });
                }}>
                  <img src={config.imagepath + "sms.png"} alt="" />
                  {clientSmsTabloading ? (<BarLoader color="#8c1c4d" className="d-flex align-items-end mt-3" />) : <span className="d-block">{t("SMS")}</span>}
                </a>
              </div>
              <ul className="nav flex-md-column nav-pills mb-0 list-unstyled" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={"nav-link" + (detailTab && detailTab == "appointment" ? " active" : "")}
                    id="appoinment"
                    data-bs-toggle="tab"
                    data-bs-target="#appoinment-tab"
                    type="button"
                    role="tab"
                    onClick={() => {
                      //Invoicerefunds Close
                      dispatch(OpenClientInvoiceDetails(""));
                      dispatch(openPastInvoiceDrawer(""));
                      dispatch(OpenRefundInvoice(""));
                      dispatch(OpenPartialPaidInvoice(""));
                      dispatch(OpenStripeRefundCompletedSaleModal(""));
                      dispatch(OpenClientInvoiceRefundModal(""));
                      dispatch(OpenClientRefundSelection(""));
                      //Invoicerefunds Close
                      dispatch(ClientDetailTab("appointment"));
                      dispatch(ClientAppointmentListViewApi({ client_id: detail.id, isFilter: isFilter, page_name: "client_appointment" }));
                    }}
                  >
                    {t("Appointments")}
                  </button>
                </li>
                {hasAccess && (
                  <li className="nav-item" role="presentation">
                    <button className={"nav-link" + (detailTab && detailTab == "clientdetail" ? " active" : "")} id="client-detail" data-bs-toggle="tab" data-bs-target="#client-detail-tab" type="button" role="tab" onClick={() => dispatch(ClientDetailTab("clientdetail"))}>
                      {t("Client Details")}
                    </button>
                  </li>
                )}
                <li className="nav-item" role="presentation">
                  <button
                    className={"nav-link" + (detailTab && detailTab == "vouchers" ? " active" : "")}
                    id="vouchers"
                    data-bs-toggle="tab"
                    data-bs-target="#vouchers-tab"
                    type="button"
                    role="tab"
                    onClick={() => {
                      dispatch(ClientDetailTab("vouchers"));
                      dispatch(ClientVoucherListViewApi({ client_id: detail.id }));
                    }}
                  >
                    {t("Vouchers")}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={"nav-link" + (detailTab && detailTab == "subscriptions" ? " active" : "")} id="subscriptions" data-bs-toggle="tab" data-bs-target="#subscriptions-tab" type="button" role="tab" onClick={() => dispatch(ClientDetailTab("subscriptions"))}>
                    {t("Subscriptions")}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={"nav-link" + (detailTab && detailTab == "memberships" ? " active" : "")}
                    id="memberships"
                    data-bs-toggle="tab"
                    data-bs-target="#memberships-tab"
                    type="button"
                    role="tab"
                    onClick={() => {
                      dispatch(ClientDetailTab("memberships"));
                      dispatch(ClientMembershipListViewApi({ client_id: detail.id }));
                    }}
                  >
                    {t("Packages")}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={"nav-link" + (detailTab && detailTab == "products" ? " active" : "")}
                    id="products"
                    data-bs-toggle="tab"
                    data-bs-target="#products-tab"
                    type="button"
                    role="tab"
                    onClick={() => {
                      dispatch(ClientDetailTab("products"));
                      dispatch(ClientproductApi({ client_id: detail.id }));
                    }}
                  >
                    {t("Products")}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={"nav-link" + (detailTab && detailTab == "photos" ? " active" : "")}
                    id="photos"
                    data-bs-toggle="tab"
                    data-bs-target="#photos-tab"
                    type="button"
                    role="tab"
                    onClick={() => {
                      dispatch(ClientDetailTab("photos"));
                      dispatch(ClientphotoGridViewApi({ client_id: detail.id }));
                    }}
                  >
                    {t("Photos")}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={"nav-link" + (detailTab && detailTab == "invoices" ? " active" : "")}
                    id="invoices"
                    data-bs-toggle="tab"
                    data-bs-target="#invoices-tab"
                    type="button"
                    role="tab"
                    onClick={() => {
                      //Invoicerefunds Close
                      dispatch(OpenClientInvoiceDetails(""));
                      dispatch(openPastInvoiceDrawer(""));
                      dispatch(OpenRefundInvoice(""));
                      dispatch(OpenPartialPaidInvoice(""));
                      dispatch(OpenStripeRefundCompletedSaleModal(""));
                      dispatch(OpenClientInvoiceRefundModal(""));
                      dispatch(OpenClientRefundSelection(""));
                      //Invoicerefunds Close
                      dispatch(ClientDetailTab("invoices"));
                      dispatch(ClientInvoiceListViewApi({ client_id: detail.id }));
                    }}
                  >
                    {t("Invoices")}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={"nav-link" + (detailTab && detailTab == "documents" ? " active" : "")}
                    id="documents"
                    data-bs-toggle="tab"
                    data-bs-target="#documents-tab"
                    type="button"
                    role="tab"
                    onClick={() => {
                      dispatch(ClientDetailTab("documents"));
                      dispatch(ClientdocumentGridViewApi({ client_id: detail.id }));
                    }}
                  >
                    {t("Documents")}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={"nav-link" + (detailTab && detailTab == "notes" ? " active" : "")}
                    id="notes"
                    data-bs-toggle="tab"
                    data-bs-target="#notes-tab"
                    type="button"
                    role="tab"
                    onClick={() => {
                      dispatch(ClientDetailTab("notes"));
                      dispatch(ClientnoteGridViewApi({ client_id: detail.id }));
                    }}
                  >
                    {t("Notes")}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={"nav-link" + (detailTab && detailTab == "forms" ? " active" : "")}
                    id="forms"
                    data-bs-toggle="tab"
                    data-bs-target="#forms-tab"
                    type="button"
                    role="tab"
                    onClick={() => {
                      dispatch(ClientDetailTab("forms"));
                    }}
                  >
                    {t("Forms")}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={"nav-link" + (detailTab && detailTab == "chatmessages" ? " active" : "")}
                    id="chat-msg"
                    data-bs-toggle="tab"
                    data-bs-target="#chat-msg-tab"
                    type="button"
                    role="tab"
                    onClick={() => {
                      dispatch(ClientDetailTab("chatmessages"));
                      dispatch(ClientchatGridViewApi({ client_id: detail.id }));
                    }}
                  >
                    {t("Messages")}
                  </button>
                </li>
              </ul>
            </div>
            <div className={`content col-md-7 position-relative ${detailTab && detailTab == "chatmessages" ? "p-0" : ""}`}>
              <div className="tab-content" id="myTabContent">
                <div className={"tab-pane fade" + (detailTab && detailTab == "appointment" ? " show active" : "")} id="appoinment-tab" role="tabpanel" aria-labelledby="appoinment-tab">
                  <div className="drawer-header">
                    <h2 className="mb-4 pe-md-5">
                      {t("Appointments")} <span className="cursor-pointer" onClick={() => {
                        dispatch(clientDetailAppointmentPrintApi({ invoice_for: "client_appointment", client_id: detail.id })).then((action) => {
                          setLoading(true)
                          if (action.meta.requestStatus === "fulfilled") {
                            setLoading(false)
                            let fileURL = 'data:application/pdf;base64,' + action.payload.html;
                            let fileName = 'details.pdf';
                            let anchorlink = document.createElement('a');
                            anchorlink.href = fileURL;
                            anchorlink.download = fileName;
                            anchorlink.setAttribute('target', '_blank');
                            anchorlink.click();
                            var string = doc.output('datauristring');
                            var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>";
                            var newWindow = window.open();
                            newWindow.document.open();
                            newWindow.document.write(iframe);
                            newWindow.document.close();
                            var pdf_newTab = window.open("");
                            pdf_newTab.document.write(
                              "<html><head><title>" + encodeURIComponent(fileName) + "</title></head><body><iframe title='MY title' width='100%' height='100%' src='" + fileURL + "'></iframe></body></html>"
                            );
                          }
                        })
                      }}><img src={config.imagepath + "print.png"} alt="" className="ms-md-2 ms-1" /></span>
                    </h2>
                    <div className="row justify-content-end">
                      <div className="col-12 mb-4">
                        <div className="w-50">
                        <select
                          className="form-control"
                          onChange={(e) => {
                            const filter = { status: e.currentTarget.value };
                            dispatch(openAppointmentFilter(filter));
                            dispatch(ClientAppointmentListViewApi({ client_id: detail.id, filter: filter, page_name: "client_appointment" }));
                          }}
                          value={isFilter && isFilter.status}
                        >
                          {filterOption &&
                            Object.keys(filterOption).map((item, i) => {
                              let value = filterOption[item].value;
                              let label = filterOption[item].label;
                              return (
                                <option value={value} key={item && item.id ? item.id : i}>
                                  {label}
                                </option>
                              );
                            })}
                        </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {detailTab && detailTab == "appointment" && <Appointment role_id={currentUser} access={access} />}
                </div>
                {hasAccess && (
                  <div className={"tab-pane fade" + (detailTab && detailTab == "clientdetail" ? " show active" : "")} id="client-detail-tab" role="tabpanel" aria-labelledby="client-detail-tab">
                    <div className="drawer-header">
                      <h2 className="mb-4 pe-md-5">
                        {t("Edit Client")} <img src={config.imagepath + "print.png"} alt="" className="ms-md-2 ms-1" 
                        onClick={() => {
                          dispatch(clientDetailPrinterApi({ invoice_for: "client_details", client_id: detail.id })).then((action) => {
                            setLoading(true)
                            if (action.meta.requestStatus === "fulfilled") {
                              setLoading(false)
                              let fileURL = 'data:application/pdf;base64,' + action.payload.html;
                              let fileName = 'detail.pdf';
                              let anchorlink = document.createElement('a');
                              anchorlink.href = fileURL;
                              anchorlink.download = fileName;
                              anchorlink.setAttribute('target', '_blank');
                              anchorlink.click();
                              var string = doc.output('datauristring');
                              var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>";
                              var newWindow = window.open();
                              newWindow.document.open();
                              newWindow.document.write(iframe);
                              newWindow.document.close();
                              var pdf_newTab = window.open("");
                              pdf_newTab.document.write(
                                "<html><head><title>" + encodeURIComponent(fileName) + "</title></head><body><iframe title='MY title' width='100%' height='100%' src='" + fileURL + "'></iframe></body></html>"
                              );
                            }
                          })
                        }}
                        />
                      </h2>
                    </div>
                    {detailTab && detailTab == "clientdetail" ? (
                      <div className="content-wrp">
                        <ClientEditForm />
                      </div>
                    ) : ""}
                  </div>
                )}
                <div className={"tab-pane fade" + (detailTab && detailTab == "vouchers" ? " show active" : "")} id="vouchers-tab" role="tabpanel" aria-labelledby="vouchers-tab">
                  <div className="drawer-header">
                    <h2 className="mb-4 pe-md-5 mb-lg-5">
                      {t("Vouchers")}
                      <a href="#" className="btn btn-outline-primary btn-sm ms-2" onClick={() => {
                        dispatch(CloseClientDetailModal(""));
                        dispatch({ type: "sale/reset" });
                        dispatch(openAddSaleForm());
                        dispatch(SaleTabView("vouchers"));
                        dispatch(ClientDataToSale(detail));
                      }}>
                        {t("Sell Gift Voucher")}
                      </a>
                    </h2>
                  </div>
                  {detailTab && detailTab == "vouchers" && <Voucher role_id={currentUser} access={access} />}
                </div>
                <div className={"tab-pane fade" + (detailTab && detailTab == "subscriptions" ? " show active" : "")} id="subscriptions-tab" role="tabpanel" aria-labelledby="subscriptions-tab">
                  <div className="drawer-header">
                    <h2 className="mb-4 pe-md-5 mb-lg-5">{t("Subscriptions")}</h2>
                  </div>
                  {detailTab && detailTab == "subscriptions" && <Subscription role_id={currentUser} access={access} />}
                </div>
                <div className={"tab-pane fade" + (detailTab && detailTab == "memberships" ? " show active" : "")} id="memberships-tab" role="tabpanel" aria-labelledby="memberships-tab">
                  {detailTab && detailTab == "memberships" && <Membership role_id={currentUser} access={access} />}
                </div>
                <div className={"tab-pane fade" + (detailTab && detailTab == "products" ? " show active" : "")} id="products-tab" role="tabpanel" aria-labelledby="products-tab">
                  {detailTab && detailTab == "products" && <Products role_id={currentUser} access={access} />}
                </div>
                <div className={"tab-pane fade" + (detailTab && detailTab == "photos" ? " show active" : "")} id="photos-tab" role="tabpanel" aria-labelledby="photos-tab">
                  {detailTab && detailTab == "photos" && <Photos role_id={currentUser} access={access} />}
                </div>
                <div className={"tab-pane fade" + (detailTab && detailTab == "invoices" ? " show active" : "")} id="invoices-tab" role="tabpanel" aria-labelledby="invoices-tab">
                  {detailTab && detailTab == "invoices" && <Invoices role_id={currentUser} access={access} />}
                </div>
                <div className={"tab-pane fade" + (detailTab && detailTab == "documents" ? " show active" : "")} id="documents-tab" role="tabpanel" aria-labelledby="documents-tab">
                  {detailTab && detailTab == "documents" && <Documents role_id={currentUser} access={access} />}
                </div>
                <div className={"tab-pane fade" + (detailTab && detailTab == "notes" ? " show active" : "")} id="notes-tab" role="tabpanel" aria-labelledby="notes-tab">
                  {detailTab && detailTab == "notes" && <Notes role_id={currentUser} access={access} />}
                </div>
                <div className={"tab-pane fade" + (detailTab && detailTab == "forms" ? " show active" : "")} id="forms-tab" role="tabpanel" aria-labelledby="forms-tab">
                  {detailTab && detailTab == "forms" && <Forms role_id={currentUser} access={access} />}
                </div>
                <div className={"tab-pane fade p-0 m-0 " + (detailTab && detailTab == "chatmessages" ? " show active" : "")} id="chat-msg-tab" role="tabpanel" aria-labelledby="chat-msg-tab">
                  {detailTab && detailTab == "chatmessages" && <ChatMessages role_id={currentUser} access={access} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClientDetailModal;
