import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productApiController from "../../services/product.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const productStoreApi = createAsyncThunk("product/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await productApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const productUpdateApi = createAsyncThunk("product/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await productApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const productListViewApi = createAsyncThunk("product/listview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await productApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "listview"))
      .catch((error) => HandleError(thunkAPI, error, "listview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const productDetailApi = createAsyncThunk("product/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await productApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const productDeleteApi = createAsyncThunk("product/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await productApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const productSuggetionListApi = createAsyncThunk("product/suggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await productApiController
      .suggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ProductOptionsDropdown = createAsyncThunk("product/ProductOptionsDropdown", async (formValues, thunkAPI) => {
  try {
    const resposedata = await productApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "ProductOptionsDropdown"))
      .catch((error) => HandleError(thunkAPI, error, "ProductOptionsDropdown"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ProductImportApi = createAsyncThunk("product/Productimport", async (formValues, thunkAPI) => {
  try {
    const resposedata = await productApiController
      .productimport(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Productimport"))
      .catch((error) => HandleError(thunkAPI, error, "Productimport"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ProductExportApi = createAsyncThunk("product/Productexport", async (formValues, thunkAPI) => {
  try {
    const resposedata = await productApiController
      .productexport(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Productexport"))
      .catch((error) => HandleError(thunkAPI, error, "Productexport"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedDetailModal: "",
  isListView: [],
  isSuggetionListView: [],
  isDetailData: "",
  isTabView: "product",
  isSort: "",
  isSearchList: "",
  isSearchName: "",
  isProductManageStock: 0,
  isProductOptionDropdown: [],
  isApiStatus: {
    productStoreApi: "",
    productUpdateApi: "",
    productListViewApi: "",
    productSuggetionListApi: "",
    productDetailApi: "",
    productDeleteApi: "",
    ProductOptionsDropdown: "",
    ProductImportApi: "",
    ProductExportApi: "",
  },
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    reset: () => initialState,
    productTabView: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
      state.isTabView = action.payload;
    },
    openAddProductForm: (state = initialState) => {
      state.isOpenedAddForm = "open";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
    },
    closeAddProductForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
    },
    openEditProductForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "open";
      state.isOpenedDetailModal = "";
    },
    closeEditProductForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
    },
    openProductDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "open";
    },
    closeProductDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
    },
    productSort: (state, action) => {
      // let sort = state.isSort ? state.isSort : {};
      // state.isSort = Object.assign(sort, action.payload);
      state.isSort = action.payload;
    },
    productSortRemove: (state) => {
      state.isSort = "";
    },
    openProductSearchList: (state) => {
      state.isSearchList = "open";
    },
    closeProductSearchList: (state) => {
      state.isSearchList = "";
    },
    productSearchName: (state, action) => {
      state.isSearchName = action.payload;
    },
    productManageStock: (state, action) => {
      state.isProductManageStock = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(productStoreApi.pending, (state) => {
        state.isApiStatus.productStoreApi = 'loading';
      })
      .addCase(productStoreApi.fulfilled, (state, action) => {
        if (state.isListView && state.isListView.data) {
          state.isListView.data = [action.payload, ...state.isListView.data];
        } else {
          state.isListView = { data: [action.payload] };
        }
        state.isApiStatus.productStoreApi = 'succeeded';
      })
      .addCase(productStoreApi.rejected, (state) => {
        state.isApiStatus.productStoreApi = 'failed';
      })
      .addCase(productUpdateApi.pending, (state) => {
        state.isApiStatus.productUpdateApi = 'loading';
      })
      .addCase(productUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isListView.data.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus.productUpdateApi = 'succeeded';
      })
      .addCase(productUpdateApi.rejected, (state) => {
        state.isApiStatus.productUpdateApi = 'failed';
      })
      .addCase(productListViewApi.pending, (state) => {
        state.isApiStatus.productListViewApi = 'loading';
      })
      .addCase(productListViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isListView.current_page ? state.isListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isListView && state.isListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus.productListViewApi = 'succeeded';
      })
      .addCase(productListViewApi.rejected, (state) => {
        state.isListView = [];
        state.isApiStatus.productListViewApi = 'failed';
      })
      .addCase(productSuggetionListApi.pending, (state) => {
        state.isApiStatus.productSuggetionListApi = 'loading';
      })
      .addCase(productSuggetionListApi.fulfilled, (state, action) => {
        let old_current_page = state.isSuggetionListView.current_page ? state.isSuggetionListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isSuggetionListView && state.isSuggetionListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isSuggetionListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isSuggetionListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus.productSuggetionListApi = 'succeeded';
      })
      .addCase(productSuggetionListApi.rejected, (state) => {
        state.isSuggetionListView = [];
        state.isApiStatus.productSuggetionListApi = 'failed';
      })
      .addCase(productDetailApi.pending, (state) => {
        state.isApiStatus.productDetailApi = 'loading';
      })
      .addCase(productDetailApi.fulfilled, (state, action) => {
        state.isDetailData = action.payload;
        state.isApiStatus.productDetailApi = 'succeeded';
      })
      .addCase(productDetailApi.rejected, (state) => {
        state.isDetailData = "";
        state.isApiStatus.productDetailApi = 'failed';
      })
      .addCase(productDeleteApi.pending, (state) => {
        state.isApiStatus.productDeleteApi = 'loading';
      })
      .addCase(productDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isListView.data = state.isListView.data ? state.isListView.data.filter((item) => item.id != id) : state.isListView.filter((item) => item.id != id);
        state.isApiStatus.productDeleteApi = 'succeeded';
      })
      .addCase(productDeleteApi.rejected, (state) => {
        state.isApiStatus.productDeleteApi = 'failed';
      })
      .addCase(ProductOptionsDropdown.pending, (state) => {
        state.isApiStatus.ProductOptionsDropdown = 'loading';
      })
      .addCase(ProductOptionsDropdown.fulfilled, (state, action) => {
        state.isProductOptionDropdown = action.payload;
        state.isApiStatus.ProductOptionsDropdown = 'succeeded';
      })
      .addCase(ProductOptionsDropdown.rejected, (state) => {
        state.isProductOptionDropdown = [];
        state.isApiStatus.ProductOptionsDropdown = 'failed';
      })
      .addCase(ProductImportApi.pending, (state) => {
        state.isApiStatus.ProductImportApi = 'loading';
      })
      .addCase(ProductImportApi.fulfilled, (state) => {
        state.isApiStatus.ProductImportApi = 'succeeded';
      })
      .addCase(ProductImportApi.rejected, (state) => {
        state.isApiStatus.ProductImportApi = 'failed';
      })
      .addCase(ProductExportApi.pending, (state) => {
        state.isApiStatus.ProductExportApi = 'loading';
      })
      .addCase(ProductExportApi.fulfilled, (state) => {
        state.isApiStatus.ProductExportApi = 'succeeded';
      })
      .addCase(ProductExportApi.rejected, (state) => {
        state.isApiStatus.ProductExportApi = 'failed';
      })
  },
});
// Action creators are generated for each case reducer function
export const { reset, productTabView, openAddProductForm, closeAddProductForm, openEditProductForm, closeEditProductForm, productTabGridView, openProductDetailModal, closeProductDetailModal, productDetailTab, productSort, productSortRemove, openProductSearchList, closeProductSearchList, productSearchName, productManageStock } = productSlice.actions;
export default productSlice.reducer;
