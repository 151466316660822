import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// import PropTypes from "prop-types";
import { swalConfirm } from "../../../../component/Sweatalert2";
import { ClientnoteGridViewApi, clientnoteDeleteApi, closeNoteDrawer, openAddNoteForm, openEditNoteForm, clientnoteDetailApi } from "store/slices/clientnoteSlice";
import config from "../../../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import PaginationLoader from "component/PaginationLoader";
import NoteFormDrawer from "./NoteFormDrawer";
import BarLoader from "react-spinners/BarLoader";
import moment from "moment";

const Notes = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [OpenConfirm, SetOpenConfirm] = useState(false);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.salonmodule.isAccess);

  const noteViews = useSelector((state) => state.clientnote.isGridView);
  const isApiStatus = useSelector((state) => state.clientnote.isApiStatus);
  const detail = useSelector((state) => state.client.isDetailData);

  const fetchDataPhotoList = () => {
    dispatch(ClientnoteGridViewApi({ client_id: detail.id, next_page_url: noteViews.next_page_url }));
  };

  const [isFetching, setIsFetching] = useState(false);
  const loadMoreItemsList = () => {
    setIsFetching(true);
    dispatch(ClientnoteGridViewApi({ next_page_url: noteViews && noteViews.next_page_url, client_id: detail.id }));
    //mocking an API call
    setTimeout(() => {
      setIsFetching(false);
    }, 2000);
  };

  const handleClientDelete = (e) => {
    const event = JSON.parse(e.currentTarget.dataset.obj);
    let confirmbtn = swalConfirm(e.currentTarget, { title: t("Are you sure want to delete?"), message: t("Success"), confirmButtonText: t("Yes, delete it!") });
    if (confirmbtn == true) {
      dispatch(clientnoteDeleteApi({ id: event.id }));
    }
  };

  const handleNoteEditForm = (e) => {
    const id = e.currentTarget.closest(".event-box").dataset.id;
    dispatch(openEditNoteForm());
    dispatch(clientnoteDetailApi({ id, client_id: detail.id }));
  };
  return (
    <>
      <div className="drawer-header">
        <h2 className="mb-4 pe-md-5 mb-lg-5">
          {t("Notes")}{" "}
          <button type="button" className="btn btn-outline-primary btn-sm ms-2" onClick={() => dispatch(openAddNoteForm())}>
            {t("Add Note")}
          </button>
          {noteViews && noteViews.data && noteViews.data.length > 0 && (
            <button type="button" className="btn btn-apricots  btn-sm ms-2" onClick={() => SetOpenConfirm(true)}>
              {t("Delete All Note")}
              {isApiStatus && isApiStatus.clientnoteDeleteApi === "loading" && <BarLoader color="#8c1c4d" className="d-flex align-items-end" />}
            </button>
          )}
        </h2>
      </div>
      <div className="content-wrp" id="notelist">
        {OpenConfirm && (
          <>
            <div className={OpenConfirm ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: OpenConfirm ? "block" : "none" }}>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body pb-0">
                    <div className="p-3">
                      <h5 className="mb-3 fw-semibold text-dark">{t("Confirm All Note deletion")}</h5>
                      <p className="fw-semibold mb-2">{t("Are you sure you want to delete all Notes?")}</p>
                    </div>
                    <div className="row mt-3 bg-cream p-4 justify-content-center">
                      <div className="col-6">
                        <button className="preview btn me-1 cursor-pointer btn-preview w-100" type="button" onClick={() => {
                          SetOpenConfirm(false);
                        }}>
                          {t("Cancel")}
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          className="btn btn-primary w-100"
                          onClick={() => {
                            noteViews.data.forEach((note) => {
                              dispatch(clientnoteDeleteApi({ id: note.id }));
                            });
                            SetOpenConfirm(false); // Close the confirmation modal
                          }}
                          type="button"
                        >
                          {isApiStatus && isApiStatus.clientnoteDetailApi === "loading" && (<span className="spinner-border spinner-border-sm"></span>)}
                          {t("Confirm")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {OpenConfirm && <div className="modal-backdrop show"></div>}
          </>
        )}
        {noteViews && noteViews.data && noteViews.data.length > 0 ? (
          <InfiniteScroll className="row gx-0" dataLength={noteViews.data.length} next={fetchDataPhotoList} scrollableTarget="notelist" hasMore={noteViews.next_page_url ? true : false} loader={<PaginationLoader />}>
            {Object.keys(noteViews.data).map((item, i) => {
              let id = noteViews.data[item].id;
              //   let client_id = noteViews.data[item].client_id;
              let note = noteViews.data[item].note;
              // let updated_at = noteViews.data[item].updated_at;
              // let updated_at = noteViews.data[item] && noteViews.data[item].updated_at ? moment(noteViews.data[item].updated_at,"YYYY-MM-DDTHH:mm:ss.SSSSSSZ").format("DD-MM-YYYY") : "";
              let noteDates = noteViews.data[item] && noteViews.data[item].datetime ? moment(noteViews.data[item].datetime,"YYYY-MM-DD hh:mm:ss").format("DD-MM-YYYY") : noteViews.data[item].created_at ? moment(noteViews.data[item].created_at,"YYYY-MM-DD hh:mm:ss").format("DD-MM-YYYY") : "";
              // console.log("noteViews.data[item]",noteViews.data[item])
              return (
                <div className="event-box" key={item && item.id ? item.id : i} data-id={id}>
                  <div className="row gx-1 justify-content-between">
                    <div className="col-md-7 mb-2">{noteDates}</div>
                    <div className="col-md-5 text-end mb-2">
                      <a className="remove me-2 cursor-pointer" data-obj={JSON.stringify(noteViews.data[item])} onClick={handleClientDelete}>
                        {t("Remove")}
                      </a>
                      <a className="btn btn-outline-primary btn-sm cursor-pointer" data-obj={JSON.stringify(noteViews.data[item])} onClick={(e) => handleNoteEditForm(e)}>
                        {t("Edit")}
                      </a>
                    </div>
                  </div>
                  <p>
                  <b dangerouslySetInnerHTML={{ __html: note }} style={{ whiteSpace:"pre-wrap" }}/>
                  {/* <b style={{ whiteSpace:"pre-wrap" }}>{note}</b> */}
                  </p>
                </div>
              );
            })}
            {!isFetching && noteViews.next_page_url && (
              <div className="col-2 m-auto p-3 text-center">
                <button onClick={loadMoreItemsList} className="btn btn-primary">
                  {t("More")}
                </button>
              </div>
            )}
          </InfiniteScroll>
        ) : (
          <div className="complete-box text-center d-flex flex-column justify-content-center mt-xl-4">
            <div className="complete-box-wrp text-center">
              <img src={config.imagepath + "nots.png"} alt="" className="mb-md-4 mb-3" />
              <h5 className="mb-2 fw-semibold">
                {t("Keep a note of anything you need to remember about your client.")}
                <br />
                <a className="add-note cursor-pointer" onClick={() => dispatch(openAddNoteForm())}>
                  {t("Add your first note.")}
                </a>
              </h5>
            </div>
          </div>
        )}
      </div>
      <NoteFormDrawer />
      {/* <NoteAddFormDrawer /> */}
    </>
  );
};

export default Notes;
