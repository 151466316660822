import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from "yup";
import { Formik, FieldArray, Field } from "formik";
import config from "../../../../config";
import yupconfig from "../../../../yupconfig";
import customToast from '../../../../component/Toastr';
import { swalErrorHtml } from '../../../../component/Sweatalert2';
import moment from 'moment';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CalendarAddFormSetActivebtnState, GetAppointmentcancelRefundStripe, GetStripeTokenAppointment, OpenAppointmentcancelRefundStripe, OpenInvoiceStripePayModal } from 'store/slices/appointmentSlice';
import { DefaultCardRemoveApi } from 'store/slices/packageSlice';
import { ActiveBtnState, OpenClientInvoiceStripePayModalClient, RefundStripeToken } from 'store/slices/clientinvoiceSlice';

const AppointmentCancelRefundStripe = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const auth = useSelector((state) => state.auth);
    const currentUser = auth.user;
    const isOpenClientInvoiceStripePayModalClient = useSelector((state) => state.clientinvoice.isOpenClientInvoiceStripePayModalClient);
    const CreditCardDetail = useSelector((state) => state.smsuses.isDefaultCardSelect);
    let last4digits = CreditCardDetail && CreditCardDetail.last4 ? CreditCardDetail.last4 : "";
    let exp_months_day = CreditCardDetail && CreditCardDetail.exp_month ? CreditCardDetail.exp_month : "";
    let exp_months = exp_months_day ? moment(exp_months_day, "M").format("MMMM") : "";
    let exp_years = CreditCardDetail && CreditCardDetail.exp_year ? CreditCardDetail.exp_year : "";
    let brand = CreditCardDetail && CreditCardDetail.brand ? CreditCardDetail.brand : "";
    const stripe_card_remove_id = CreditCardDetail && CreditCardDetail.id;

    const initialValues = {
        stripe_payment_method_id:"",
    };

    const validationSchema = Yup.object().shape({});

    yupconfig();

    const handleBookingSubmit = async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        setLoading(true);
        try {
            if(values && values.stripe_payment_method_id){
                dispatch(RefundStripeToken({stripe_payment_method_id:values.stripe_payment_method_id}));
                dispatch(CalendarAddFormSetActivebtnState("StripeCreditCard"));
                setLoading(false);
                dispatch(OpenClientInvoiceStripePayModalClient(""))
            }else{
                if (!stripe || !elements) {
                    setLoading(false);
                    return;
                }
                const { token, error } = await stripe.createToken(elements.getElement(CardNumberElement));
                if (error) {
                    setErrors(error.message);
                    setError(error.message);
                    setLoading(false);
                } else {
                    const stripe_token_id = token.id;
                    dispatch(RefundStripeToken({stripe_token_id:stripe_token_id}));
                    dispatch(ActiveBtnState("StripeCreditCard"));
                    dispatch(OpenClientInvoiceStripePayModalClient(""))
                    setLoading(false);
                }
            }
        } catch (err) {
            setErrors(err.message);
            setStatus({ success: false });
            setLoading(false);
        }
    };

    return (
        <div>
            <div className={isOpenClientInvoiceStripePayModalClient === "open" ? "modal fade show" : "modal fade"} id="pay-by-stripe-modal" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: isOpenClientInvoiceStripePayModalClient === "open" ? "block" : "none" }}>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleBookingSubmit}>
                    {(formik) => {
                        useEffect(() => {
                            if (CreditCardDetail) {
                                const fields = ["stripe_payment_method_id"];
                                fields.forEach((field) => {
                                    let detail_field = CreditCardDetail[field] ? CreditCardDetail[field] : "";
                                    formik.setFieldValue(field, formik.values[field] ? formik.values[field] : detail_field, false);
                                });
                            }
                        }, [CreditCardDetail]);
                        return (
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body pb-0">
                                        <form noValidate onSubmit={formik.handleSubmit}>
                                            <div className="p-3">
                                                <h5 className="mb-3 fw-semibold text-dark">{t("Pay by Stripe")}</h5>
                                                {CreditCardDetail && formik.values.stripe_payment_method_id ? (
                                                    <div className="voucher-box p-4 bg-pink mb-3 plan-wr">
                                                        <div className="row default-pay-card-section align-items-center justify-content-between">
                                                            <div className="default-card-number-show col-12 col-md-8 text-center text-md-start">
                                                                <h5 className="mb-1">
                                                                    {`${brand} **** ${last4digits}`}
                                                                    <span className="badge paid ms-2">Default</span>
                                                                </h5>
                                                                <h5 className="mb-0">{`Expires ${exp_months}/${exp_years}`}</h5>
                                                            </div>
                                                            <div className="col-12 col-md-3 mt-3 mt-sm-0 text-center text-md-end">
                                                                <a className="preview btn me-1 cursor-pointer btn-preview" type="button" onClick={() => {
                                                                    dispatch(DefaultCardRemoveApi({ id: stripe_card_remove_id })).then(action => {
                                                                        if (action.meta.requestStatus == 'fulfilled') {
                                                                            formik.setFieldValue("stripe_payment_method_id", "", false);
                                                                        }
                                                                    })
                                                                }}>
                                                                    {t("Remove")}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="col-md-12 mb-3">
                                                        <div className="pay-card-details-wr">
                                                            <h4 htmlFor="" className="fw-600">Card Details</h4>
                                                            <div className="row">
                                                                <div className="col-12 mb-3">
                                                                    <label htmlFor="card-number">Card Number</label>
                                                                    <CardNumberElement
                                                                        id="card-number"
                                                                        options={{
                                                                            style: {
                                                                                base: {
                                                                                    fontSize: '16px',
                                                                                    color: '#32325d',
                                                                                    fontFamily: 'Arial, sans-serif',
                                                                                    '::placeholder': {
                                                                                        color: '#aab7c4',
                                                                                    },
                                                                                },
                                                                                invalid: {
                                                                                    color: '#fa755a',
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-6 mb-3">
                                                                    <label htmlFor="card-expiry">Expiration Date</label>
                                                                    <CardExpiryElement
                                                                        id="card-expiry"
                                                                        options={{
                                                                            style: {
                                                                                base: {
                                                                                    fontSize: '16px',
                                                                                    color: '#32325d',
                                                                                    fontFamily: 'Arial, sans-serif',
                                                                                    '::placeholder': {
                                                                                        color: '#aab7c4',
                                                                                    },
                                                                                },
                                                                                invalid: {
                                                                                    color: '#fa755a',
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-6 mb-3">
                                                                    <label htmlFor="card-cvc">CVC</label>
                                                                    <CardCvcElement
                                                                        id="card-cvc"
                                                                        options={{
                                                                            style: {
                                                                                base: {
                                                                                    fontSize: '16px',
                                                                                    color: '#32325d',
                                                                                    fontFamily: 'Arial, sans-serif',
                                                                                    '::placeholder': {
                                                                                        color: '#aab7c4',
                                                                                    },
                                                                                },
                                                                                invalid: {
                                                                                    color: '#fa755a',
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {error && (
                                                    <div id="card-errors" className="card-error invalid-feedback d-block mb-4" role="alert">
                                                        {error}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="row mt-3 bg-cream p-4 justify-content-center">
                                                <div className="col-6">
                                                    <button className="preview btn me-1 cursor-pointer btn-preview w-100" type='button' onClick={() => {
                                                        dispatch(OpenClientInvoiceStripePayModalClient(""))
                                                    }}>
                                                        {t("Cancel")}
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                                                        {loading && <span className="spinner-border spinner-border-sm"></span>}
                                                        {t("Confirm")}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            </div>
            {isOpenClientInvoiceStripePayModalClient === "open" && <div className="modal-backdrop show custom-pay-by-stripe"></div>}
        </div>
    )
}

export default AppointmentCancelRefundStripe;