import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import billingaddressApiController from "services/billingaddress.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const BillingStoreApi = createAsyncThunk("billing/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await billingaddressApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const billingUpdateApi = createAsyncThunk("billing/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await billingaddressApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const billingDetailApi = createAsyncThunk("billing/view", async (formValues, thunkAPI) => {
  try {
    const resposedata = await billingaddressApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "billingdetail"))
      .catch((error) => HandleError(thunkAPI, error, "billingdetail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isBillingAddressUpdates: [],
  isDetailData: "",
  isApiStatus: {
    BillingStoreApi: "",
    billingUpdateApi: "",
    billingDetailApi: "",
  }
}

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    reset: () => initialState,

  },
  extraReducers: (builder) => {
    builder
      .addCase(BillingStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BillingStoreApi: "loading" };
      })
      .addCase(BillingStoreApi.fulfilled, (state, action) => {
        if (state.isBillingAddressUpdates && state.isBillingAddressUpdates.data) {
          state.isBillingAddressUpdates.data = [action.payload, ...state.isBillingAddressUpdates.data];
        } else {
          state.isBillingAddressUpdates = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, BillingStoreApi: "succeeded" };
      })
      .addCase(BillingStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BillingStoreApi: "failed" };
      })
      .addCase(billingUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, billingUpdateApi: "loading" };
      })
      .addCase(billingUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isBillingAddressUpdates && state.isBillingAddressUpdates.data && state.isBillingAddressUpdates.data.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, billingUpdateApi: "succeeded" };
      })
      .addCase(billingUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, billingUpdateApi: "failed" };
      })
      .addCase(billingDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, billingDetailApi: "loading" };
      })
      .addCase(billingDetailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, billingDetailApi: "succeeded" };
        state.isDetailData = action.payload;
      })
      .addCase(billingDetailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, billingDetailApi: "failed" };
        state.isDetailData = "";
      });
  },
});

export const { reset } = billingSlice.actions;
export default billingSlice.reducer;