import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import OneoffCampaignApiController from "../../services/oneoffcampaign.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const OneoffCampaignStoreApi = createAsyncThunk("oneoffcampaign/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await OneoffCampaignApiController.create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const OneoffCampaignUpdateApi = createAsyncThunk("oneoffcampaign/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await OneoffCampaignApiController.update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const OneoffCampaignListViewApi = createAsyncThunk("oneoffcampaign/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await OneoffCampaignApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const OneoffCampaignOptions = createAsyncThunk("oneoffcampaign/OneoffCampaignOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await OneoffCampaignApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "OneoffCampaignOptions"))
      .catch((error) => HandleError(thunkAPI, error, "OneoffCampaignOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const OneoffCampaignDetailApi = createAsyncThunk("oneoffcampaign/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await OneoffCampaignApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const OneoffCampaignDeleteApi = createAsyncThunk("oneoffcampaign/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await OneoffCampaignApiController.deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const OneoffServicesApi = createAsyncThunk("oneoffcampaign/services", async (formValues, thunkAPI) => {
  try {
    const resposedata = await OneoffCampaignApiController.services(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "services"))
      .catch((error) => HandleError(thunkAPI, error, "services"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const OneoffCampaignSendEmailApi = createAsyncThunk("oneoffcampaign/sendemail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await OneoffCampaignApiController.sendemail(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "sendemail"))
      .catch((error) => HandleError(thunkAPI, error, "sendemail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// for payment of one off email and sms

export const oneoffStripePaymentApi = createAsyncThunk("oneoff/payment", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await OneoffCampaignApiController.oneoffpayment(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "payment"))
      .catch((error) => HandleError(thunkAPI, error, "payment"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const oneoffPaymentFailedApi = createAsyncThunk("oneoff/paymentdfailed", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await OneoffCampaignApiController.oneoffpaymentfailed(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "paymentdfailed"))
      .catch((error) => HandleError(thunkAPI, error, "paymentdfailed"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const oneoffDefaultCardSelectApi = createAsyncThunk("oneoffdefaultcard/list", async (formValues, thunkAPI) => {
  try {
    const resposedata = await OneoffCampaignApiController.smsDefaultList(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "oneoffdefaultcardlist"))
      .catch((error) => HandleError(thunkAPI, error, "oneoffdefaultcardlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isOneoffType: "",
  isOpenedTypeForm: "",
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedSendEmailForm: "",
  isOpenedDetailModal: "",
  isOpenedPaymentsModalEmail: "",
  isOneOffStore: "",
  isListView: [],
  isSuggetionListView: [],
  isDetailData: "",
  isSearchList: "",
  isSearchName: "",
  isEmailPreview: "",
  isEmailPreviewForPaymentModal: "",
  isSmsPreview: "",
  isOneoffCampaignSelectedType: "",
  isOneoffServices: [],
  isOneoffSendEmail: "",
  isOpenedSendEmailObj: "",
  isDefaultCardSelect: "",
  isoneoffPaymentFailed: "",
  isoneoffPayments: "",
  isOneoffCampaignStep: 1,
  isOneoffCampaignStepName: "",
  isOneOffPaymentApi: "",
  isOneOffPaymentFailedApi: "",
  isApiStatus: {
    OneoffCampaignStoreApi: "",
    OneoffCampaignUpdateApi: "",
    OneoffCampaignListViewApi: "",
    OneoffCampaignSuggetionListApi: "",
    OneoffCampaignDetailApi: "",
    OneoffCampaignDeleteApi: "",
    OneoffCampaignOptions: "",
    OneoffServicesApi: "",
    OneoffCampaignSendEmailApi: "",
    oneoffStripePaymentApi: "",
    oneoffPaymentFailedApi: "",
    OneOffPaymentApi: "",
    OneOffPaymentFailedApi: "",
  },
};

const OneoffCampaignSlice = createSlice({
  name: "oneoffcampaign",
  initialState,
  reducers: {
    reset: () => initialState,
    OneoffCampaignType: (state, action) => {
      state.isOneoffType = action.payload;
    },
    OneoffCampaignSelectedType: (state, action) => {
      state.isOneoffCampaignSelectedType = action.payload;
    },
    OneoffCampaignFormModal: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedTypeForm = action.payload;
    },
    AddOneoffCampaignFormModal: (state, action) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = action.payload;
    },
    EditOneoffCampaignFormModal: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = action.payload;
    },
    SendEmailOneoffCampaignFormModal: (state, action) => {
      state.isOpenedSendEmailForm = action.payload;
    },
    SendEmailOneoffCampaignPaymentModal: (state, action) => {
      state.isOpenedPaymentsModalEmail = action.payload;
    },
    SendEmailOneoffCampaignObjModal: (state, action) => {
      state.isOpenedSendEmailObj = action.payload;
    },
    SetEmailPreview: (state, action) => {
      state.isEmailPreview = action.payload;
    },
    EmailPreviewForPaymentModal: (state, action) => {
      state.isEmailPreviewForPaymentModal = action.payload;
    },
    SetSmsPreview: (state, action) => {
      state.isSmsPreview = action.payload;
    },
    PreviewStep: (state, action) => {
      state.isOneoffCampaignStep = action.payload - 1;
    },
    NextStep: (state, action) => {
      state.isOneoffCampaignStep = action.payload + 1;
    },
    ConsultationStepName: (state, action) => {
      state.isOneoffCampaignStepName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(OneoffCampaignStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignStoreApi: "loading" };
      })
      .addCase(OneoffCampaignStoreApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignStoreApi: "succeeded" };
      })
      .addCase(OneoffCampaignStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignStoreApi: "failed" };
      })
      .addCase(OneoffCampaignUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignUpdateApi: "loading" };
      })
      .addCase(OneoffCampaignUpdateApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignUpdateApi: "succeeded" };
      })
      .addCase(OneoffCampaignUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignUpdateApi: "failed" };
      })
      .addCase(OneoffCampaignListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignListViewApi: "loading" };
      })
      .addCase(OneoffCampaignListViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isListView.current_page ? state.isListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isListView && state.isListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignListViewApi: "succeeded" };
        state.isListView = action.payload;
      })
      .addCase(OneoffCampaignListViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignListViewApi: "failed" };
        state.isListView = [];
      })
      .addCase(OneoffCampaignDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignDetailApi: "loading" };
      })
      .addCase(OneoffCampaignDetailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignDetailApi: "succeeded" };
        state.isDetailData = action.payload;
      })
      .addCase(OneoffCampaignDetailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignDetailApi: "failed" };
        state.isDetailData = "";
      })
      .addCase(OneoffCampaignDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignDeleteApi: "loading" };
      })
      .addCase(OneoffCampaignDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignDeleteApi: "succeeded" };
        state.isListView.data = state.isListView.data ? state.isListView.data.filter((item) => item.id != id) : [];
      })
      .addCase(OneoffCampaignDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignDeleteApi: "failed" };
      })
      .addCase(OneoffServicesApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffServicesApi: "loading" };
      })
      .addCase(OneoffServicesApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffServicesApi: "succeeded" };
        state.isOneoffServices = action.payload;
      })
      .addCase(OneoffServicesApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffServicesApi: "failed" };
        state.isOneoffServices = [];
      })
      .addCase(OneoffCampaignSendEmailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignSendEmailApi: "loading" };
      })
      .addCase(OneoffCampaignSendEmailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignSendEmailApi: "succeeded" };
        state.isOneoffSendEmail = action.payload;
      })
      .addCase(OneoffCampaignSendEmailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, OneoffCampaignSendEmailApi: "failed" };
        state.isOneoffSendEmail = "";
      })
      .addCase(oneoffStripePaymentApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, oneoffStripePaymentApi: "loading" };
      })
      .addCase(oneoffStripePaymentApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, oneoffStripePaymentApi: "succeeded" };
        state.isoneoffPayments = action.payload;
      })
      .addCase(oneoffStripePaymentApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, oneoffStripePaymentApi: "failed" };
        state.isoneoffPayments = [];
      })
      .addCase(oneoffPaymentFailedApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, oneoffPaymentFailedApi: "loading" };
      })
      .addCase(oneoffPaymentFailedApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, oneoffPaymentFailedApi: "succeeded" };
        state.isoneoffPaymentFailed = action.payload;
      })
      .addCase(oneoffDefaultCardSelectApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, oneoffDefaultCardSelectApi: "loading" };
      })
      .addCase(oneoffDefaultCardSelectApi.fulfilled, (state, action) => {
        state.isDefaultCardSelect = action.payload;
        state.isApiStatus = { ...state.isApiStatus, oneoffDefaultCardSelectApi: "succeeded" };
      })
      .addCase(oneoffDefaultCardSelectApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, oneoffDefaultCardSelectApi: "failed" };
        state.isDefaultCardSelect = "";
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, OneoffCampaignType, OneoffCampaignFormModal, AddOneoffCampaignFormModal, EditOneoffCampaignFormModal, SetEmailPreview, SetSmsPreview, OneoffCampaignSelectedType, SendEmailOneoffCampaignFormModal, SendEmailOneoffCampaignObjModal, SendEmailOneoffCampaignPaymentModal, EmailPreviewForPaymentModal, ConsultationStepName, NextStep, PreviewStep } = OneoffCampaignSlice.actions;
export default OneoffCampaignSlice.reducer;
