// import config from "../config";
import PropTypes from "prop-types";
import ApiStatus from "./ApiStatus";
import PropagateLoader from "react-spinners/PropagateLoader";
// ==============================|| LOADER ||============================== //
const PaginationLoader = (props) => {
  const serviceApiStatus = props.serviceApiStatus;
  const categoryApiStatus = props.categoryApiStatus;
  if (serviceApiStatus && serviceApiStatus.serviceListViewApi == "loading") {
    return <ApiStatus actionType="services" />;
  }else if (categoryApiStatus && categoryApiStatus.serviceListViewApi == "loading") {
    return <ApiStatus actionType="categories" />;
  } else {
    return (
      <div className="d-flex justify-content-center">
        <PropagateLoader color="#8c1c4d" className="d-flex align-items-end mt-3" />
      </div>
    );
  }
};
PaginationLoader.propTypes = {
  serviceApiStatus: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
  categoryApiStatus: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
};
export default PaginationLoader;
