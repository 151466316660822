import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import subscriptionApiController from "../../services/subscription.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const SubscriptionStoreApi = createAsyncThunk("subscription/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await subscriptionApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const SubscriptionUpdateApi = createAsyncThunk("subscription/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await subscriptionApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const SubscriptionGridViewApi = createAsyncThunk("subscription/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await subscriptionApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SubscriptionOptions = createAsyncThunk("subscription/SubscriptionOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await subscriptionApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "SubscriptionOptions"))
      .catch((error) => HandleError(thunkAPI, error, "SubscriptionOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SubscriptionDetailApi = createAsyncThunk("subscription/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await subscriptionApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SubscriptionDeleteApi = createAsyncThunk("subscription/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await subscriptionApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SubscriptionServiceApi = createAsyncThunk("subscription/services", async (formValues, thunkAPI) => {
  try {
    const resposedata = await subscriptionApiController
      .services(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "services"))
      .catch((error) => HandleError(thunkAPI, error, "services"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SubscriptionServiceCartApi = createAsyncThunk("subscription/subscriptionservicecart", async (formValues, thunkAPI) => {
  try {
    const resposedata = await subscriptionApiController
      .services(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "subscriptionservicecart"))
      .catch((error) => HandleError(thunkAPI, error, "subscriptionservicecart"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SubscriptionSuggetionListApi = createAsyncThunk("subscription/suggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await subscriptionApiController
      .suggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
const initialState = {
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedDetailModal: "",
  isGridView: [],
  isSuggetionListView: [],
  isDetailData: "",
  isSearchList: "",
  isSearchName: "",
  isSearchObj: "",
  isSubscriptionOption: [],
  isServices: [],
  isSubscriptionServices: [],
  isApiStatus: {
    SubscriptionStoreApi: "",
    SubscriptionUpdateApi: "",
    SubscriptionGridViewApi: "",
    SubscriptionDetailApi: "",
    SubscriptionDeleteApi: "",
    SubscriptionOptions: "",
    SubscriptionServiceApi: "",
    SubscriptionServiceCartApi: "",
    SubscriptionSuggetionListApi: "",
  },
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenAddSubscriptionForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = "open";
    },
    CloseAddSubscriptionForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = "";
    },
    OpenEditSubscriptionForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "open";
    },
    CloseEditSubscriptionForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
    },
    OpenSubscriptionDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "open";
    },
    CloseSubscriptionDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "";
    },
    SubscriptionServiceRemoveToCart: (state, action) => {
      const { id } = action.payload;
      state.isSubscriptionServices = state.isSubscriptionServices ? state.isSubscriptionServices.filter((item) => item.id != id) : [];
    },
    EditSubscriptionServiceCartApi: (state, action) => {
      state.isSubscriptionServices = action.payload;
    },
    OpenSubscriptionSearchList: (state) => {
      state.isSearchList = "open";
    },
    CloseSubscriptionSearchList: (state) => {
      state.isSearchList = "";
    },
    SubscriptionSearchName: (state, action) => {
      state.isSearchName = action.payload;
    },
    SubscriptionSearchObj: (state, action) => {
      state.isSearchObj = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SubscriptionStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionStoreApi: "loading" };
      })
      .addCase(SubscriptionStoreApi.fulfilled, (state, action) => {
        if (state.isGridView && state.isGridView.data) {
          state.isGridView.data = [action.payload, ...state.isGridView.data];
        } else {
          state.isGridView = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, SubscriptionStoreApi: "succeeded" };
      })
      .addCase(SubscriptionStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionStoreApi: "failed" };
      })
      .addCase(SubscriptionUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionUpdateApi: "loading" };
      })
      .addCase(SubscriptionUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isGridView.data.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, SubscriptionUpdateApi: "succeeded" };
      })
      .addCase(SubscriptionUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionUpdateApi: "failed" };
      })
      .addCase(SubscriptionGridViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionGridViewApi: "loading" };
      })
      .addCase(SubscriptionGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isGridView.current_page ? state.isGridView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isGridView && state.isGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isGridView = action.payload;
        state.isApiStatus = { ...state.isApiStatus, SubscriptionGridViewApi: "succeeded" };
      })
      .addCase(SubscriptionGridViewApi.rejected, (state) => {
        state.isGridView = [];
        state.isApiStatus = { ...state.isApiStatus, SubscriptionGridViewApi: "failed" };
      })
      .addCase(SubscriptionDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionDetailApi: "loading" };
      })
      .addCase(SubscriptionDetailApi.fulfilled, (state, action) => {
        state.isDetailData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, SubscriptionDetailApi: "succeeded" };
      })
      .addCase(SubscriptionDetailApi.rejected, (state) => {
        state.isDetailData = "";
        state.isApiStatus = { ...state.isApiStatus, SubscriptionDetailApi: "failed" };
      })
      .addCase(SubscriptionDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionDeleteApi: "loading" };
      })
      .addCase(SubscriptionDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isApiStatus = { ...state.isApiStatus, SubscriptionDeleteApi: "succeeded" };
        state.isGridView.data = state.isGridView.data ? state.isGridView.data.filter((item) => item.id != id) : state.isGridView.filter((item) => item.id != id);
      })
      .addCase(SubscriptionDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionDeleteApi: "failed" };
      })
      .addCase(SubscriptionOptions.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionOptions: "loading" };
      })
      .addCase(SubscriptionOptions.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionOptions: "succeeded" };
        state.isSubscriptionOption = action.payload;
      })
      .addCase(SubscriptionOptions.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionOptions: "failed" };
        state.isSubscriptionOption = [];
      })
      .addCase(SubscriptionServiceApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionServiceApi: "loading" };
      })
      .addCase(SubscriptionServiceApi.fulfilled, (state, action) => {
        let old_current_page = state.isServices.current_page ? state.isServices.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isServices && state.isServices.data;
        let newviewdata = action.payload && action.payload.data;
        state.isServices = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isServices.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, SubscriptionServiceApi: "succeeded" };
        state.isServices = action.payload;
      })
      .addCase(SubscriptionServiceApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionServiceApi: "failed" };
        state.isServices = [];
      })
      .addCase(SubscriptionServiceCartApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionServiceCartApi: "loading" };
      })
      .addCase(SubscriptionServiceCartApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isSubscriptionServices && state.isSubscriptionServices.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        } else {
          const subscription = [...state.isSubscriptionServices, action.payload];
          state.isSubscriptionServices = subscription;
        }
        state.isApiStatus = { ...state.isApiStatus, SubscriptionServiceCartApi: "succeeded" };
      })
      .addCase(SubscriptionServiceCartApi.rejected, (state) => {
        state.isSubscriptionServices = "";
        state.isApiStatus = { ...state.isApiStatus, SubscriptionServiceCartApi: "failed" };
      })
      .addCase(SubscriptionSuggetionListApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionSuggetionListApi: "loading" };
      })
      .addCase(SubscriptionSuggetionListApi.fulfilled, (state, action) => {
        let old_current_page = state.isSuggetionListView.current_page ? state.isSuggetionListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isSuggetionListView && state.isSuggetionListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isSuggetionListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isSuggetionListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, SubscriptionSuggetionListApi: "succeeded" };
        state.isSuggetionListView = action.payload;
      })
      .addCase(SubscriptionSuggetionListApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubscriptionSuggetionListApi: "failed" };
        state.isSuggetionListView = [];
      });
  }
});
// Action creators are generated for each case reducer function
export const { reset, OpenAddSubscriptionForm, CloseAddSubscriptionForm, OpenEditSubscriptionForm, CloseEditSubscriptionForm, OpenSubscriptionDetailModal, CloseSubscriptionDetailModal, openSubscriptionSearchList, closesubscriptionsearchList, subscriptionSearchName, SubscriptionServiceRemoveToCart, EditSubscriptionServiceCartApi, OpenSubscriptionSearchList, CloseSubscriptionSearchList, SubscriptionSearchName, SubscriptionSearchObj } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
