import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import academyCategoryApiController from "services/academycategory.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";


export const AcademyCategoryGridViewApi = createAsyncThunk("academy/academyGridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await academyCategoryApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "academyview"))
      .catch((error) => HandleError(thunkAPI, error, "academy/view"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


const initialState = {
  isGridView: [],
  isApiStatus: {
    AcademyCategoryGridViewApi: "",
  },
};

const academycategorySlice = createSlice({
  name: "academycategory",
  initialState,
  reducers: {
    reset: () => initialState,

  },
  extraReducers: (builder) => {
    builder
      .addCase(AcademyCategoryGridViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AcademyCategoryGridViewApi: "loading" };
      })
      .addCase(AcademyCategoryGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isGridView.current_page || "";
        let new_current_page = action.payload.current_page || "";
        let viewdata = state.isGridView && state.isGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page !== new_current_page) {
          viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, AcademyCategoryGridViewApi: "succeeded" };
      })
      .addCase(AcademyCategoryGridViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AcademyCategoryGridViewApi: "failed" };
        state.isGridView = [];
      });
  },
})



// Action creators are generated for each case reducer function
export const { reset } = academycategorySlice.actions;
export default academycategorySlice.reducer;
