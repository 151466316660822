import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showSidebar: true,
};

const sidebartoggleSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    reset: () => initialState,
    toggleSidebar: (state) => {
        state.showSidebar = !state.showSidebar;
    },
  },
});
// Action creators are generated for each case reducer function
export const { reset,toggleSidebar } = sidebartoggleSlice.actions;
export default sidebartoggleSlice.reducer;
