import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import bookingApiController from "services/booking.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
import moment from "moment";
export const usersAdapter = createEntityAdapter();

export const BookingStoreApi = createAsyncThunk("Boooking/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const BookingPaymentApi = createAsyncThunk("Boooking/payment", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .bookingpayment(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "payment"))
      .catch((error) => HandleError(thunkAPI, error, "payment"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const BookingPaymentFailedApi = createAsyncThunk("Boooking/paymentdfailed", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .bookingpaymentfailed(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "paymentdfailed"))
      .catch((error) => HandleError(thunkAPI, error, "paymentdfailed"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const BookingApi = createAsyncThunk("booking/view", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "view"))
      .catch((error) => HandleError(thunkAPI, error, "view"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingLinkFirstAvailableTimeApi = createAsyncThunk("bookingavailable/time", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .firstavailabletimebookinglink(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "bookingavailabletime"))
      .catch((error) => HandleError(thunkAPI, error, "bookingavailabletime"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// ------------

export const BookingCategoryOptions = createAsyncThunk("booking/categoryOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .bookingCategoryView(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "categoryOptions"))
      .catch((error) => HandleError(thunkAPI, error, "categoryOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingServiceOptions = createAsyncThunk("booking/serviceOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .bookingServiceView(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "serviceOptions"))
      .catch((error) => HandleError(thunkAPI, error, "serviceOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingStaffOptions = createAsyncThunk("booking/staffOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .bookingStaffView(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "staffOptions"))
      .catch((error) => HandleError(thunkAPI, error, "staffOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingStaffAvailableTimeApi = createAsyncThunk("booking/staffavailabletime", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .bookingStaffAvailableTime(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "staffavailabletime"))
      .catch((error) => HandleError(thunkAPI, error, "staffavailabletime"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CheckAvailableTimeApi = createAsyncThunk("booking/checkavailabletime", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .checkStaffAvailableTime(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "bookingcheckavailabletime"))
      .catch((error) => HandleError(thunkAPI, error, "bookingcheckavailabletime"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingCreatePaymentIntentApi = createAsyncThunk("booking/createPaymentIntent", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .createPaymentIntent(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "createPaymentIntent"))
      .catch((error) => HandleError(thunkAPI, error, "createPaymentIntent"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingMembershipOptions = createAsyncThunk("booking/membershipOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .bookingMembershipView(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "membershipOptions"))
      .catch((error) => HandleError(thunkAPI, error, "membershipOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingVoucherOptions = createAsyncThunk("booking/voucherOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .bookingVocuherView(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "voucherOptions"))
      .catch((error) => HandleError(thunkAPI, error, "voucherOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingVoucherOptionsService = createAsyncThunk("booking/voucherOptionsService", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .bookingVoucherWithServiceView(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "voucherOptionsService"))
      .catch((error) => HandleError(thunkAPI, error, "voucherOptionsService"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const BookingNextResourceItemTimeApi = createAsyncThunk("booking/nextresourceitemtimes", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .nextresourceitemtimeget(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "nextresourceitemtimes"))
      .catch((error) => HandleError(thunkAPI, error, "nextresourceitemtimes"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingResourceItemApi = createAsyncThunk("booking/resourceitems", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .recourceitems(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "bookingresourceitems"))
      .catch((error) => HandleError(thunkAPI, error, "bookingresourceitems"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingDisableDatesApi = createAsyncThunk("booking/disabledates", async (formValues, thunkAPI) => {
  try {

    const resposedata = await bookingApiController
      .disabledates(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "disabledates"))
      .catch((error) => HandleError(thunkAPI, error, "disabledates"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BookingResourceAnyStaffApi = createAsyncThunk("booking/anystaffavailable", async (formValues, thunkAPI) => {
  try {
    const resposedata = await bookingApiController
      .anyavailablestaff(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "anystaffavailable"))
      .catch((error) => HandleError(thunkAPI, error, "anystaffavailable"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isBookingStep: 1,
  isLastBookingStep: 0,
  isBookingStepName: "",
  isBookingApi: "",
  isBookingSelectedServicesData: [],
  isBookingSelectedMembershipData: "",
  isCategoryOption: [],
  isServiceOption: [],
  isMembershipOption: [],
  isVoucherOption: [],
  isStaffOption: [],
  isBookingStaffAvailableTimeApi: [],
  isCheckAvailableTime: [],
  isBookingCreatePaymentIntentApi: "",
  isBookingPaymentApi: "",
  isBookingPaymentFailedApi: "",
  isBookingLinkFirstAvailableTime: [],
  isBookingVoucherOptionsService: [],
  isSingleServiceSelectedDataCopyForStepFour: "",
  isBookingNextResourceItemTime: [],
  isBookingResourceItem: [],
  isBookingResourceModal: "",
  isBookingStepFourData: "",
  isBookingNextAvailableModal: "",
  isBookingDisableDates: [],
  isBookingResourceAnyStaffApi: [],
  isBookingAccordionInfoCategoryOpen: "",
  isBookingAccordionInfoCategory: [],
  isApiStatus: {
    BookingApi: "",
    BookingCategoryOptions: "",
    BookingServiceOptions: "",
    BookingStaffOptions: "",
    BookingStaffAvailableTime: "",
    BookingCreatePaymentIntentApi: "",
    BookingPaymentApi: "",
    BookingPaymentFailedApi: "",
    BookingMembershipOptions: "",
    BookingVoucherOptions: "",
    BookingLinkFirstAvailableTimeApi: "",
    CheckAvailableTimeApi: "",
    BookingVoucherOptionsService: "",
    BookingNextResourceItemTimeApi: "",
    BookingResourceItemApi: "",
    BookingDisableDatesApi: "",
    BookingResourceAnyStaffApi: ""
  },
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    reset: () => initialState,
    PreviewStep: (state, action) => {
      state.isLastBookingStep = action.payload;
      state.isBookingStep = action.payload - 1;
    },
    NextStep: (state, action) => {
      state.isLastBookingStep = action.payload;
      state.isBookingStep = action.payload + 1;
    },
    BookingStepName: (state, action) => {
      state.isBookingStepName = action.payload;
    },
    BookingCalendarTimeSlotReset: (state) => {
      state.isBookingStaffAvailableTimeApi = [];
    },
    SingleServiceSelectedDataCopyForStepFour: (state, action) => {
      state.isSingleServiceSelectedDataCopyForStepFour = action.payload;
    },
    BookingSelectedServicesData: (state, action) => {
      if (action.payload) {
        // const { category_id, service_id, staff_id, ...changes } = action.payload;
        // const existingData = state.isBookingSelectedServicesData.find((event) => event.category_id === category_id && event.service_id === service_id && event.staff_id === staff_id);
        const { id, ...changes } = action.payload;
        const existingData = state.isBookingSelectedServicesData.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        } else {
          state.isBookingSelectedServicesData = [...state.isBookingSelectedServicesData, action.payload];
        }
      } else {
        state.isBookingSelectedServicesData = [];
      }
    },
    BookingRemoveServicesData: (state, action) => {
      const { id } = action.payload;
      state.isApiStatus = { ...state.isApiStatus, categoryDeleteApi: "succeeded" };
      const isBookingSelectedServicesData = state.isBookingSelectedServicesData && state.isBookingSelectedServicesData.length > 0 ? state.isBookingSelectedServicesData.filter((item) => item.id != id).map((item, index) => ({ ...item, id: index + 1 })) : [];
      // state.isBookingSelectedServicesData = isBookingSelectedServicesData;

      // console.log('initialState', isBookingSelectedServicesData, data)
      const updatedState = [];
      for (let i = 0; i < isBookingSelectedServicesData.length; i++) {
        const currentObject = { ...isBookingSelectedServicesData[i], id: i + 1 };
        // const previousObject = i > 0 ? updatedState[i - 1] : null;
        // if (previousObject) {
        //   const prevDatetime = previousObject.datetime;
        //   const prevDurationWithPadding = previousObject.durationWithPadding;
        //   const duration1 = moment.duration(prevDurationWithPadding);

        //   // Check if each duration is valid before using it
        //   const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;

        //   const totalMinutes = minutes1;
        //   const hours = Math.floor(totalMinutes / 60);
        //   const minutes = totalMinutes % 60;

        //   const sumDuration = moment({ hours, minutes });
        //   const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";
        //   // console.log(SumOfDuration);
        //   const durationMoment = moment.duration(SumOfDuration);
        //   const prevTotalDuration = durationMoment;
        //   // const prevDeposit = previousObject.deposit_booked_price;
        //   const formattedDatetime = moment(prevDatetime, 'YYYY-MM-DD HH:mm:ss');
        //   // console.log('prevTotalDuration', previousObject, prevDuration, prevPaddingTime, prevTotalDuration)
        //   // Add the previous total duration in minutes
        //   currentObject.datetime = formattedDatetime.add(prevTotalDuration, 'minutes').format('YYYY-MM-DD HH:mm:ss');
        //   currentObject.parentAppointmentEndTime = currentObject.datetime;
        // }
        // You can now access the previousObject and the currentObject here
        updatedState.push(currentObject);
      }
      const objRemoved = updatedState.filter(itemm => itemm.id < id);
      // // state.isBookingSelectedServicesData = initialState.map((obj, i) => ({ ...obj, id: i + 1 }));
      state.isBookingSelectedServicesData = objRemoved;

    },
    BookingSelectedMembershipData: (state, action) => {
      state.isBookingSelectedMembershipData = action.payload;
    },
    BookingNextResourceTimeSet: (state, action) => {
      state.isBookingStaffAvailableTimeApi = action.payload;
    },
    BookingResourceModal: (state, action) => {
      state.isBookingResourceModal = action.payload;
    },
    BookingNextAvailableModal: (state, action) => {
      state.isBookingNextAvailableModal = action.payload;
    },
    BookingNextAvailableStepFourData: (state, action) => {
      state.isBookingStepFourData = action.payload;
    },
    setBookingNextResourceItemTime: (state, action) => {
      state.isBookingNextResourceItemTime = [];
    },
    BookingAccordionInfoCategory: (state, action) => {
      state.isBookingAccordionInfoCategoryOpen = action.payload;
      state.isBookingAccordionInfoCategory.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(BookingStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingStoreApi: "loading" };
      })
      .addCase(BookingStoreApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingStoreApi: "succeeded" };
      })
      .addCase(BookingStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingStoreApi: "failed" };
      })
      .addCase(BookingApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingApi: "loading" };
      })
      .addCase(BookingApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingApi: "succeeded" };
        state.isBookingApi = action.payload;
      })
      .addCase(BookingApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingApi: "failed" };
        state.isBookingApi = [];
      })
      .addCase(BookingCategoryOptions.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingCategoryOptions: "loading" };
      })
      .addCase(BookingCategoryOptions.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingCategoryOptions: "succeeded" };
        state.isCategoryOption = action.payload;
      })
      .addCase(BookingCategoryOptions.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingCategoryOptions: "failed" };
        state.isCategoryOption = [];
      })
      .addCase(BookingServiceOptions.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingServiceOptions: "loading" };
      })
      .addCase(BookingServiceOptions.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingServiceOptions: "succeeded" };
        state.isServiceOption = action.payload;
      })
      .addCase(BookingServiceOptions.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingServiceOptions: "failed" };
        state.isServiceOption = [];
      })
      .addCase(BookingStaffOptions.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingStaffOptions: "loading" };
      })
      .addCase(BookingStaffOptions.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingStaffOptions: "succeeded" };
        state.isStaffOption = action.payload;
      })
      .addCase(BookingStaffOptions.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingStaffOptions: "failed" };
        state.isStaffOption = [];
      })
      .addCase(BookingStaffAvailableTimeApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingStaffAvailableTimeApi: "loading" };
      })
      .addCase(BookingStaffAvailableTimeApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingStaffAvailableTimeApi: "succeeded" };
        state.isBookingStaffAvailableTimeApi = action.payload;
      })
      .addCase(BookingStaffAvailableTimeApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingStaffAvailableTimeApi: "failed" };
        state.isBookingStaffAvailableTimeApi = [];
      })
      .addCase(CheckAvailableTimeApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CheckAvailableTimeApi: "loading" };
      })
      .addCase(CheckAvailableTimeApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, CheckAvailableTimeApi: "succeeded" };
        state.isCheckAvailableTime = action.payload;
      })
      .addCase(CheckAvailableTimeApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CheckAvailableTimeApi: "failed" };
        state.isCheckAvailableTime = [];
      })
      .addCase(BookingCreatePaymentIntentApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingCreatePaymentIntentApi: "loading" };
      })
      .addCase(BookingCreatePaymentIntentApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingCreatePaymentIntentApi: "succeeded" };
        state.isBookingCreatePaymentIntentApi = action.payload;
      })
      .addCase(BookingCreatePaymentIntentApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingCreatePaymentIntentApi: "failed" };
        state.isBookingCreatePaymentIntentApi = [];
      })
      .addCase(BookingPaymentApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingPaymentApi: "loading" };
      })
      .addCase(BookingPaymentApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingPaymentApi: "succeeded" };
        state.isBookingPaymentApi = action.payload;
      })
      .addCase(BookingPaymentApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingPaymentApi: "failed" };
        state.isBookingPaymentApi = [];
      })
      .addCase(BookingPaymentFailedApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingPaymentFailedApi: "loading" };
      })
      .addCase(BookingPaymentFailedApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingPaymentFailedApi: "succeeded" };
        state.isBookingPaymentFailedApi = action.payload;
      })
      .addCase(BookingPaymentFailedApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingPaymentFailedApi: "failed" };
        state.isBookingPaymentFailedApi = [];
      })
      .addCase(BookingMembershipOptions.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingMembershipOptions: "loading" };
      })
      .addCase(BookingMembershipOptions.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingMembershipOptions: "succeeded" };
        state.isMembershipOption = action.payload;
      })
      .addCase(BookingMembershipOptions.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingMembershipOptions: "failed" };
        state.isMembershipOption = [];
      })
      .addCase(BookingVoucherOptions.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingVoucherOptions: "loading" };
      })
      .addCase(BookingVoucherOptions.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingVoucherOptions: "succeeded" };
        state.isVoucherOption = action.payload;
      })
      .addCase(BookingVoucherOptions.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingVoucherOptions: "failed" };
        state.isVoucherOption = [];
      })
      .addCase(BookingLinkFirstAvailableTimeApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingLinkFirstAvailableTimeApi: "loading" };
      })
      .addCase(BookingLinkFirstAvailableTimeApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingLinkFirstAvailableTimeApi: "succeeded" };
        state.isBookingLinkFirstAvailableTime = action.payload;
      })
      .addCase(BookingLinkFirstAvailableTimeApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingLinkFirstAvailableTimeApi: "failed" };
        state.isBookingLinkFirstAvailableTime = [];
      })
      .addCase(BookingVoucherOptionsService.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingVoucherOptionsService: "loading" };
      })
      .addCase(BookingVoucherOptionsService.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingVoucherOptionsService: "succeeded" };
        state.isBookingVoucherOptionsService = action.payload;
      })
      .addCase(BookingVoucherOptionsService.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingVoucherOptionsService: "failed" };
        state.isBookingVoucherOptionsService = [];
      })
      .addCase(BookingNextResourceItemTimeApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingNextResourceItemTimeApi: "loading" };
      })
      .addCase(BookingNextResourceItemTimeApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingNextResourceItemTimeApi: "succeeded" };
        state.isBookingNextResourceItemTime = action.payload;
      })
      .addCase(BookingNextResourceItemTimeApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingNextResourceItemTimeApi: "failed" };
        state.isBookingNextResourceItemTime = [];
      })
      .addCase(BookingResourceItemApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingResourceItemApi: "loading" };
      })
      .addCase(BookingResourceItemApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingResourceItemApi: "succeeded" };
        state.isBookingResourceItem = action.payload;
      })
      .addCase(BookingResourceItemApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingResourceItemApi: "failed" };
        state.isBookingResourceItem = [];
      })
      .addCase(BookingDisableDatesApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingDisableDatesApi: "loading" };
      })
      .addCase(BookingDisableDatesApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingDisableDatesApi: "succeeded" };
        state.isBookingDisableDates = action.payload;
      })
      .addCase(BookingDisableDatesApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingDisableDatesApi: "failed" };
        state.isBookingDisableDates = [];
      })
      .addCase(BookingResourceAnyStaffApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingResourceAnyStaffApi: "loading" };
      })
      .addCase(BookingResourceAnyStaffApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BookingResourceAnyStaffApi: "succeeded" };
        state.isBookingResourceAnyStaffApi = action.payload;
      })
      .addCase(BookingResourceAnyStaffApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BookingResourceAnyStaffApi: "failed" };
        state.isBookingResourceAnyStaffApi = [];
      });
  },
});

export const { BookingStep, BookingStepName, PreviewStep, NextStep, BookingSelectedServicesData, BookingRemoveServicesData, BookingSelectedMembershipData, SingleServiceSelectedDataCopyForStepFour, BookingNextResourceTimeSet, BookingResourceModal, BookingNextAvailableStepFourData, BookingNextAvailableModal, setBookingNextResourceItemTime, BookingAccordionInfoCategory, BookingCalendarTimeSlotReset } = bookingSlice.actions;
export default bookingSlice.reducer;
