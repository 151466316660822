import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["service_price"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "afterlogin/services/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const update = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["service_price"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "afterlogin/services/update/" + values.id;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const dropdown = values && values.dropdown ? values.dropdown : "";
  const result = values && values.result ? values.result : "";
  const fromPosition = values && values.fromPosition ? values.fromPosition : "";
  const toPosition = values && values.toPosition ? values.toPosition : "";
  const pagetype = values && values.pagetype ? values.pagetype : "";
  const formName = values && values.formName ? values.formName : "";
  let sortstring = "";
  if (sort) {
    let sortArray = [];
    let sortSubArray = [];
    Object.keys(sort).map(function (key, index) {
      if (Object.keys(sort[key]).length > 0) {
        Object.keys(sort[key]).map(function (subkey, subindex) {
          sortSubArray[subindex] = `sort[${key}.${subkey}]=${sort[key][subkey]}`;
        });
      }
      if (key != "category") {
        sortArray[index] = `sort[${key}]=${sort[key]}`;
      }
    });
    if (sortSubArray.length > 0) {
      let jsubsort = sortSubArray.join("&");
      sortstring = jsubsort;
    }
    if (sortArray.length > 0) {
      let jsort = sortArray.join("&");
      sortstring = jsort;
    }
  }
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/services/view?page=${page}&${sortstring}` : `afterlogin/services/view?${sortstring}`;
  let servicedata;
  if (dropdown) {
    servicedata = {
      auth_key: auth_key,
      action: action,
      pagination: false, //true or false
      id: "",
      // field: "name,description,duration,padding_time,color,service_booked_online,deposit_booked_online,deposit_booked_price", // first_name,last_name,email
      result: result, //business_name,owner_name
      pagetype: pagetype,
      formName
    };
  } else {
    servicedata = {
      auth_key: auth_key,
      action: action,
      pagination: pagination, //true or false
      id: values && values.id ? values.id : "",
      // field: values && values.id ? "" : "name,description,duration,padding_time,color,service_booked_online,deposit_booked_online,deposit_booked_price", // first_name,last_name,email
      result: result, //business_name,owner_name
      option: values && values.option ? values.option : "",
      category_id: values && values.category_id ? values.category_id : "",
      fromPosition: fromPosition,
      toPosition: toPosition,
      pagetype: pagetype,
      formName
    };
  }

  const data = servicedata;
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const deleted = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/services/delete/${values.id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const suggetionlist = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  let q = values && values.q ? values.q : "";
  const action = page ? `afterlogin/services/view?page=${page}&q=${q}` : `afterlogin/services/view?q=${q}`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: true, //true or false
    id: values && values.id ? values.id : "",
    // field: values && values.id ? "" : "name,description,duration,padding_time,color,service_booked_online,deposit_booked_online,deposit_booked_price", // first_name,last_name,email
    apicall: "suggetion",
  };
  return axios.post(next_page_url ? `${next_page_url}&q=${q}` : API_URL + action, data, { headers: authHeader() });
};

const addonservices = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  let isNotId = values && values.isNotId ? values.isNotId : "";
  const action = page ? `afterlogin/services/addonservices?page=${page}&isNotId=${isNotId}` : `afterlogin/services/addonservices?isNotId=${isNotId}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const addonstaff = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  let service_id = values && values.service_id ? values.service_id : "";
  const action = page ? `afterlogin/services/addonstaff?page=${page}&service_id=${service_id}` : `afterlogin/services/addonstaff?service_id=${service_id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const serviceprice = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  let service_id = values && values.service_id ? values.service_id : "";
  let staff_id = values && values.service_id ? values.staff_id : "";
  const action = `afterlogin/services/serviceprice`;
  const data = {
    auth_key: auth_key,
    action: action,
    service_id: service_id,
    staff_id: staff_id,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const addonservicesfield = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  let service_id = values && values.service_id ? values.service_id : "";
  let add_on_service_id = values && values.add_on_service_id ? values.add_on_service_id : "";
  let isNotId = values && values.isNotId ? values.isNotId : "";
  const action = page ? `afterlogin/services/addonservicesfield?page=${page}&isNotId=${isNotId}` : `afterlogin/services/addonservicesfield?isNotId=${isNotId}`;
  const data = {
    auth_key: auth_key,
    action: action,
    service_id: service_id,
    add_on_service_id: add_on_service_id,
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const addonserviceprice = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  let service_id = values && values.service_id ? values.service_id : "";
  let staff_id = values && values.service_id ? values.staff_id : "";
  let add_on_service_id = values && values.add_on_service_id ? values.add_on_service_id : "";
  let index_id = values && values.index_id ? values.index_id : "";
  const action = `afterlogin/services/addonserviceprice`;
  const data = {
    auth_key: auth_key,
    action: action,
    service_id: service_id,
    staff_id: staff_id,
    add_on_service_id: add_on_service_id,
    index_id: index_id,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const resourceviews = (values) => {
  // Get the authentication token from the Redux store
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;

  // Extract parameters from the 'values' object
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const user_id = values && values.user_id ? values.user_id  :"";
  const label = values && values.label ? values.label  :"";
  let q = values && values.q ? values.q : "";
  const action = "afterlogin/resource/view";

  // Create the 'data' object to be sent in the request
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false,
    id: values && values.id ? values.id : "",
    service_id: values && values.service_id ? values.service_id : "",
    option: values && values.option ? values.option : "",
    user_id: user_id,
    label: label,
    result: result,
  };
  
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const serviceApiController = {
  create,
  update,
  view,
  deleted,
  suggetionlist,
  addonservices,
  addonstaff,
  serviceprice,
  addonservicesfield,
  addonserviceprice,
  resourceviews
};
export default serviceApiController;
