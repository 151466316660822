import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import clientchatApiController from "../../services/clientchat.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const clientchatStoreApi = createAsyncThunk("clientchat/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await clientchatApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const clientchatUpdateApi = createAsyncThunk("clientchat/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await clientchatApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const ClientchatGridViewApi = createAsyncThunk("clientchat/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientchatApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const clientchatDetailApi = createAsyncThunk("clientchat/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientchatApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const clientchatDeleteApi = createAsyncThunk("clientchat/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientchatApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const clientchatSuggetionListApi = createAsyncThunk("clientchat/suggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientchatApiController
      .suggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isGridView: [],
  isOpenedAddForm: "",
  // isOpenedEditForm: "",
  isChatDrawer: "",
  isDetailData: "",
  isApiStatus: {
    clientchatStoreApi: "",
    clientchatUpdateApi: "",
    ClientchatGridViewApi: "",
    clientchatDeleteApi: "",
    clientchatDetailApi: "",
  },
};

const clientchatSlice = createSlice({
  name: "clientchat",
  initialState,
  reducers: {
    reset: () => initialState,
    openChatDrawer: (state) => {
      state.isChatDrawer = "open";
      // state.isOpenedAddForm = "";
      // state.isOpenedEditForm = "";
    },
    closeChatDrawer: (state) => {
      state.isChatDrawer = "";
      // state.isOpenedAddForm = "";
      // state.isOpenedEditForm = "";
    },
    openAddChatForm: (state = initialState) => {
      state.isOpenedAddForm = "open";
      // state.isOpenedEditForm = "";
    },
    closeAddChatForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      // state.isOpenedEditForm = "";
    },
    openEditChatForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      // state.isOpenedEditForm = "open";
    },
    closeEditChatForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      // state.isOpenedEditForm = "";
    },
    AppendListViewData: (state = initialState) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clientchatStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientchatStoreApi: "loading" };
      })
      .addCase(clientchatStoreApi.fulfilled, (state, action) => {
        if (state.isGridView && state.isGridView.data) {
          state.isGridView.data = [action.payload, ...state.isGridView.data];
        } else {
          state.isGridView = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, clientchatStoreApi: "succeeded" };
      })
      .addCase(clientchatStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientchatStoreApi: "failed" };
      })
      .addCase(clientchatUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientchatUpdateApi: "loading" };
      })
      .addCase(clientchatUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        let isGridView = state.isGridView && state.isGridView.data ? state.isGridView.data : state.isGridView;
        const existingGridData = isGridView ? isGridView.find((event) => event.id === id) : "";
        if (existingGridData) {
          Object.keys(changes).map((keyName) => {
            existingGridData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, clientchatUpdateApi: "succeeded" };
      })
      .addCase(clientchatUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientchatUpdateApi: "failed" };
      })
      .addCase(ClientchatGridViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientchatGridViewApi: "loading" };
      })
      .addCase(ClientchatGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isGridView.current_page ? state.isGridView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isGridView && state.isGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isGridView = action.payload;
        state.isApiStatus = { ...state.isApiStatus, ClientchatGridViewApi: "succeeded" };
      })
      .addCase(ClientchatGridViewApi.rejected, (state) => {
        state.isGridView = [];
        state.isApiStatus = { ...state.isApiStatus, ClientchatGridViewApi: "failed" };
      })
      .addCase(clientchatDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientchatDeleteApi: "loading" };
      })
      .addCase(clientchatDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isGridView.data = state.isGridView.data ? state.isGridView.data.filter((item) => item.id != id) : state.isGridView.filter((item) => item.id != id);
        state.isApiStatus = { ...state.isApiStatus, clientchatDeleteApi: "succeeded" };
      })
      .addCase(clientchatDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientchatDeleteApi: "failed" };
      })
      .addCase(clientchatDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientchatDetailApi: "loading" };
      })
      .addCase(clientchatDetailApi.fulfilled, (state, action) => {
        state.isDetailData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, clientchatDetailApi: "succeeded" };
      })
      .addCase(clientchatDetailApi.rejected, (state) => {
        state.isDetailData = "";
        state.isApiStatus = { ...state.isApiStatus, clientchatDetailApi: "failed" };
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, openChatDrawer, closeChatDrawer, openAddChatForm, closeAddChatForm } = clientchatSlice.actions;
export default clientchatSlice.reducer;
