import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ComingSoonPopUpAlerts, ComingSoonPopUpAlertsTextsData, OpenValidationAlerts } from 'store/slices/commonSlice'

const ComingSoonAlertPopUps = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isComingSoonPopUpAlerts = useSelector((state) => state.common.isComingSoonPopUpAlerts);
    const isComingSoonPopUpAlertsTextsData = useSelector((state) => state.common.isComingSoonPopUpAlertsTextsData);

    const PopUpTextChanges = () => {
        if (isComingSoonPopUpAlertsTextsData) {
            return (
                <div className="p-3">
                    <h5 className="mb-3 fw-semibold text-dark">{t(isComingSoonPopUpAlertsTextsData.title)}</h5>
                    <p className="subtitle mb-0" style={{overflowWrap:"break-word",textAlign:"left"}}>{t(isComingSoonPopUpAlertsTextsData.description)}</p>
                </div>
            );
        } else {
            return (
                <div className="p-3">
                    <h5 className="mb-3 fw-semibold text-dark">{t("This plan is not available right now.")}</h5>
                    <p className="fw-semibold mb-0">{t("This plan will soon be available for purchase.")}</p>
                </div>
            );
        }
    };

    return (
        <>
            <div className={isComingSoonPopUpAlerts === "open" ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: isComingSoonPopUpAlerts === "open" ? "block" : "none" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content ">
                        <div className="modal-body pb-0">
                            {PopUpTextChanges()}
                            <div className="row mt-3 bg-cream p-4 justify-content-center">
                                <div className="col-6">
                                    <button className="btn btn-primary w-100" type="submit" onClick={() => {
                                        dispatch(ComingSoonPopUpAlertsTextsData({ title: "", description: "" }));
                                        dispatch(ComingSoonPopUpAlerts(""))
                                    }}>
                                        {t("OK")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isComingSoonPopUpAlerts === "open" && <div className="modal-backdrop show"></div>}
        </>
    )
}

export default ComingSoonAlertPopUps;