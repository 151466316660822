import ApiStatus from 'component/ApiStatus';
import PaginationLoader from 'component/PaginationLoader';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { ClientDetailConsultationForm, ClientDetailConsultationFormTab, ClientformApi } from 'store/slices/clientformSlice';
import config from "../../../../config"
import ClientFormDetails from './ClientFormDetails';

const Forms = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.salonmodule.isAccess);
  const clientformApiStatus = useSelector((state) => state.clientform.isApiStatus);
  const clientformViews = useSelector((state) => state.clientform.isListView);
  const detail = useSelector((state) => state.client.isDetailData);
  const isclientDetailConsultationFormTab = useSelector((state) => state.clientform.isclientDetailConsultationFormTab);
  // console.log(clientformApiStatus.ClientformApi)
  // console.log(clientformApiStatus)

  useEffect(() => {
    dispatch(ClientformApi({ client_id: detail.id }));
  }, []);

  const fetchDataInvoiceList = () => {
    dispatch(ClientformApi({ client_id: detail.id, next_page_url: productViews.next_page_url }));
  };

  const [isFetching, setIsFetching] = useState(false);
  const loadMoreItemsList = () => {
    setIsFetching(true);
    dispatch(ClientformApi({ next_page_url: productViews && productViews.next_page_url, client_id: detail.id }));
    //mocking an API call
    setTimeout(() => {
      setIsFetching(false);
    }, 2000);
  };

  return (
    <div>
      <div className="drawer-header">
        <h2 className="mb-4 pe-md-5 mb-lg-5">{t("Forms")}</h2>
      </div>
      <div className="content-wrp" id="formlist">
        {clientformApiStatus && clientformApiStatus.ClientformApi === "succeeded" && clientformViews && clientformViews.data && clientformViews.data.length > 0 ? (
          <InfiniteScroll className="content-wrp row gx-0" dataLength={clientformViews.data.length} next={fetchDataInvoiceList} scrollableTarget="formlist" hasMore={clientformViews.next_page_url ? true : false} loader={<PaginationLoader />}>
            {Object.keys(clientformViews.data).map((item, i) => {
              let form = clientformViews && clientformViews.data && clientformViews.data[item].form ? clientformViews.data[item].form : "";
              let title = clientformViews.data[item].form && form.title ? form.title : "";
              let is_active_at = clientformViews && clientformViews.data && clientformViews.data[item].is_status_at ? clientformViews.data[item].is_status_at : "";
              let completedDate = moment(is_active_at,"YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY");
              let uid = clientformViews && clientformViews.data && clientformViews.data[item].form && form.uid ? form.uid : "";
              let form_answer = clientformViews && clientformViews.data && clientformViews.data[item].form_answer ? clientformViews.data[item].form_answer : "";
              return (
                <div className="text-decoration-none event-box-pink mb-3" href="" key={item && item.id ? item.id : i}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="left-clientforms-contents">
                      <h6 className="mb-1 color-wine fw-semibold">{t(title ? title :"")}</h6>
                      <h6 className="mb-1">{t(`Completed : ${completedDate ? completedDate : ""}`)}</h6>
                    </div>
                    <div className="right-clientforms-contents">
                      <a className="btn btn-outline btn-sm" onClick={()=>{
                          dispatch(ClientDetailConsultationFormTab("open"));
                          dispatch(ClientDetailConsultationForm(form_answer));
                        }}>{t("View Form")}</a>
                    </div>
                  </div>
                </div>
              )
            })}
            {!isFetching && clientformViews.next_page_url && (
              <div className="col-2 m-auto p-3 text-center">
                <button onClick={loadMoreItemsList} className="btn btn-primary">
                  {t("More")}
                </button>
              </div>
            )}
          </InfiniteScroll>
        ) : (
          <>
            {clientformApiStatus &&
              clientformApiStatus.ClientformApi === "loading" ? (
              <ApiStatus actionType='clientconsultationforms' />
            ) : (
              <div className="complete-box text-center d-flex flex-column justify-content-center mt-xl-4">
                <div className="complete-box-wrp text-center">
                  <img src={config.imagepath + "docs.png"} alt="" className="mb-md-4 mb-3" />
                  <h5 className="mb-2 fw-semibold">{t("There are no forms filled.")}</h5>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {isclientDetailConsultationFormTab && <ClientFormDetails/>}
    </div>
  )
}

export default Forms;