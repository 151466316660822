import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import addonApiController from "../../services/addon.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const AddOnServicesApi = createAsyncThunk("addon/addonservices", async (formValues, thunkAPI) => {
  try {
    const resposedata = await addonApiController
      .services(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addonservices"))
      .catch((error) => HandleError(thunkAPI, error, "addonservices"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AddOnServicePriceApi = createAsyncThunk("addon/addonserviceprice", async (formValues, thunkAPI) => {
  try {
    const resposedata = await addonApiController
      .serviceprice(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addonserviceprice"))
      .catch((error) => HandleError(thunkAPI, error, "addonserviceprice"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AddonStaffOptionsApi = createAsyncThunk("addon/addonstaffOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await addonApiController
      .staff(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addonstaffOptions"))
      .catch((error) => HandleError(thunkAPI, error, "addonstaffOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StaffAvailableTimeApi = createAsyncThunk("addon/StaffAvailableTime", async (formValues, thunkAPI) => {
  try {
    const resposedata = await addonApiController
      .staffavailabletime(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "StaffAvailableTime"))
      .catch((error) => HandleError(thunkAPI, error, "StaffAvailableTime"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const ResourceAvailableTimeApi = createAsyncThunk("addon/ResourceAvailableTime", async (formValues, thunkAPI) => {
  try {
    const resposedata = await addonApiController
      .resourceavailabletime(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "AddonResourceAvailableTime"))
      .catch((error) => HandleError(thunkAPI, error, "AddonResourceAvailableTime"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const AddOnResourceOptionsApi = createAsyncThunk("addon/resourceOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await addonApiController
      .addonserviceresourceitems(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addonresourceOptions"))
      .catch((error) => HandleError(thunkAPI, error, "addonresourceOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isAddOn: [], //{ serviceprice: "", services: [], staff: [], duration: "", cost: "" }
  isAddOnServices: [],
  isAddOnServicePrice: [],
  isAddonStaffOptions: [],
  isAddOnResourceOptions: [],
  isApiStatus: {
    AddOnServicesApi: "",
    AddOnServicePriceApi: "",
    AddOnResourceOptionsApi:""
  },
};

const addonSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    reset: () => initialState,
    AddOnData: (state, action) => {
      if (action.payload) {
        const { id, ...changes } = action.payload;
        const existingData = state.isAddOn.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        } else {
          state.isAddOn = [...state.isAddOn, action.payload];
        }
      } else {
        state.isAddOn = [];
      }
    },
    AddOnDataRemove: (state, action) => {
      const { id } = action.payload;
      state.isApiStatus = { ...state.isApiStatus, serviceDeleteApi: "succeeded" };
      const newisAddOn = state.isAddOn ? state.isAddOn.filter((item) => item.id != id) : state.isAddOn.filter((item) => item.id != id);
      state.isAddOn = newisAddOn.map((item, index) => ({ ...item, id: index + 1 }));
    },
  },
  extraReducers:(builder)=> {
    builder
    .addCase(AddOnServicesApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddOnServicesApi: "loading" };
    })
    .addCase(AddOnServicesApi.fulfilled, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddOnServicesApi: "succeeded" };
    })
    .addCase(AddOnServicesApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddOnServicesApi: "failed" };
      state.isAddOnServicePrice = [];
    })
    .addCase(AddOnServicePriceApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddOnServicePriceApi: "loading" };
    })
    .addCase(AddOnServicePriceApi.fulfilled, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddOnServicePriceApi: "succeeded" };
    })
    .addCase(AddOnServicePriceApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddOnServicePriceApi: "failed" };
      state.isAddOnServicePrice = [];
    })
    .addCase(AddonStaffOptionsApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddonStaffOptionsApi: "loading" };
    })
    .addCase(AddonStaffOptionsApi.fulfilled, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddonStaffOptionsApi: "succeeded" };
    })
    .addCase(AddonStaffOptionsApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddonStaffOptionsApi: "failed" };
    })
    .addCase(AddOnResourceOptionsApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddOnResourceOptionsApi: "loading" };
    })
    .addCase(AddOnResourceOptionsApi.fulfilled, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddOnResourceOptionsApi: "succeeded" };
    })
    .addCase(AddOnResourceOptionsApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, AddOnResourceOptionsApi: "failed" };
    })
    .addCase(StaffAvailableTimeApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableTimeApi: "loading" };
    })
    .addCase(StaffAvailableTimeApi.fulfilled, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableTimeApi: "succeeded" };
    })
    .addCase(StaffAvailableTimeApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableTimeApi: "failed" };
    });  
  },
});
// Action creators are generated for each case reducer function
export const { reset, AddOnData, AddOnDataRemove } = addonSlice.actions;
export default addonSlice.reducer;
