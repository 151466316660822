import customToast from "component/Toastr";
import moment from "moment";
import React, { useEffect } from "react";
import Moment from "react-moment";
import { AppointmentAvailableTime, AppointmentAvailableTimeLoader } from "store/slices/appointmentSlice";
import { StaffAvailableEndBusyTimeApi, StaffAvailableStartBusyTimeApi, StaffAvailableTimeApi } from "store/slices/staffSlice";

const GetAvailableTime = (values, dispatch) => {

  const formtype = values && values.formtype ? values.formtype : "";
  if (formtype && (formtype == "add_busytime" || formtype == "edit_busytime")) {
    if (values.staff_id && values.dateof) {
      if (values.availableBusyTime === "start") {
        dispatch(StaffAvailableStartBusyTimeApi({ ...values, roster_time: true, formtype })).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            const payloaddata = action.payload;
            if (payloaddata.time && payloaddata.time.length == 0) {
              if (payloaddata.appointment && payloaddata.appointment.length > 0) {
                customToast.success(`There is not enough time available for this service.`);
              } else if (payloaddata.addonservices && payloaddata.addonservices.length > 0) {
                customToast.success(`Add-on services not available at this time`);
              } else if (payloaddata.breaktime && payloaddata.breaktime.length > 0) {
                customToast.success(`Please choose another time as business is breaktime`);
              } else if (payloaddata.busytime && payloaddata.busytime.length > 0) {
                customToast.success(`This staff member is busy at this time`);
              } else if (payloaddata.booking && payloaddata.booking.length > 0) {
                customToast.success(`This staff member is on break at this time`);
              } else {
                customToast.success(`There is not enough time available for this service.`);
              }
            }
          } else if (action.meta.requestStatus === "rejected") {
            const status = action.payload && action.payload.status;
            const errors = action.payload && action.payload.message && action.payload.message.errors;
            const response = action.payload && action.payload.message && action.payload.message;
            if (status === 422) {
              const NotifyContent = () => {
                return (
                  <>
                    <p className="mb-2 text-light text-justify">{response && response.message}</p>
                    <ul className="list-unstyled">
                      {errors &&
                        Object.keys(errors).map((a, n) => {
                          if (errors[a].length > 0) {
                            return (
                              <>
                                {errors[a].map((as, ns) => {
                                  return (
                                    <li key={n + "-" + ns} className="text-light form-text text-start">
                                      {as}
                                    </li>
                                  );
                                })}
                              </>
                            );
                          }
                        })}
                    </ul>
                  </>
                );
              };
              customToast.error(<NotifyContent />);
            } else if (status === 410) {
              const NotifyContent = () => {
                return (
                  <>
                    <p className="mb-2 text-white text-justify">{response && response.message}</p>
                    {response && response.booked && (
                      <>
                        <p className="mb-2 text-white text-justify">{t("Staff are available at this time.")}</p>
                        {response && response.dateof && <Moment format="MMMM DD YYYY">{response.dateof}</Moment>}
                        <ul className="list-unstyled">
                          {response.booked.map((a, n) => (
                            <li key={n} className="text-light form-text">
                              <Moment format="hh:mm A">{response.dateof + "T" + a}</Moment>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </>
                );
              };
              customToast.error(<NotifyContent />);
            }
          }
        });
      }
      if (values.availableBusyTime === "end") {
        dispatch(StaffAvailableEndBusyTimeApi({ ...values, roster_time: true, formtype })).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            const payloaddata = action.payload;
            if (payloaddata.time && payloaddata.time.length == 0) {
              if (payloaddata.appointment && payloaddata.appointment.length > 0) {
                customToast.success(`There is not enough time available for this service.`);
              } else if (payloaddata.addonservices && payloaddata.addonservices.length > 0) {
                customToast.success(`Add-on services not available at this time`);
              } else if (payloaddata.breaktime && payloaddata.breaktime.length > 0) {
                customToast.success(`Please choose another time as business is breaktime`);
              } else if (payloaddata.busytime && payloaddata.busytime.length > 0) {
                customToast.success(`This staff member is busy at this time`);
              } else if (payloaddata.booking && payloaddata.booking.length > 0) {
                customToast.success(`This staff member is on break at this time`);
              } else {
                customToast.success(`There is not enough time available for this service.`);
              }
            }
          } else if (action.meta.requestStatus === "rejected") {
            const status = action.payload && action.payload.status;
            const errors = action.payload && action.payload.message && action.payload.message.errors;
            const response = action.payload && action.payload.message && action.payload.message;
            if (status === 422) {
              const NotifyContent = () => {
                return (
                  <>
                    <p className="mb-2 text-light text-justify">{response && response.message}</p>
                    <ul className="list-unstyled">
                      {errors &&
                        Object.keys(errors).map((a, n) => {
                          if (errors[a].length > 0) {
                            return (
                              <>
                                {errors[a].map((as, ns) => {
                                  return (
                                    <li key={n + "-" + ns} className="text-light form-text text-start">
                                      {as}
                                    </li>
                                  );
                                })}
                              </>
                            );
                          }
                        })}
                    </ul>
                  </>
                );
              };
              customToast.error(<NotifyContent />);
            } else if (status === 410) {
              const NotifyContent = () => {
                return (
                  <>
                    <p className="mb-2 text-white text-justify">{response && response.message}</p>
                    {response && response.booked && (
                      <>
                        <p className="mb-2 text-white text-justify">{t("Staff are available at this time.")}</p>
                        {response && response.dateof && <Moment format="MMMM DD YYYY">{response.dateof}</Moment>}
                        <ul className="list-unstyled">
                          {response.booked.map((a, n) => (
                            <li key={n} className="text-light form-text">
                              <Moment format="hh:mm A">{response.dateof + "T" + a}</Moment>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </>
                );
              };
              customToast.error(<NotifyContent />);
            }
          }
        });
      }
    } else {
      dispatch({ type: "staff/StaffAvailableTime/rejected" });
    }
  } else {
    if (values && values.service_id && values.staff_id && values.dateof) {
      if (formtype === "appointmentautofillForm") {
        dispatch(AppointmentAvailableTimeLoader(true));
      }
      dispatch(StaffAvailableTimeApi({ ...values, roster_time: true, formtype })).then((action) => {
        // console.log("action",JSON.stringify(action,null,2));
        // console.log("values", values)
        if (action.meta.requestStatus === "fulfilled") {
          if (formtype != "appointmentautofillForm") {
            const service_assign_resource = action && action.payload && action.payload.service_assign_resource ? action.payload.service_assign_resource : "";
            const start_datetime = action && action.payload && action.payload.start_datetime ? action.payload.start_datetime : "";
            const prev_start_datetime = values && values.prev_start_datetime ? values.prev_start_datetime : "";
            if (service_assign_resource) {
              let dateof = values && values.start_datetime ? values.start_datetime : "";
              let isGreaterCurrentDate = false
              if (!dateof) {
                let check_start_time = moment().format("HH:mm:ss");
                const isValidDate = values.dateof ? moment(values.dateof, "dddd, DD MMMM YYYY", true).isValid() : false;
                if (isValidDate) {
                  if (prev_start_datetime) {
                    const isSameDate = moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") === moment(prev_start_datetime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
                    isGreaterCurrentDate = moment().format("YYYY-MM-DD HH:mm:ss") < moment(prev_start_datetime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
                    check_start_time = isSameDate && !isGreaterCurrentDate ? moment().format("HH:mm:ss") : "00:00:00";
                    dateof = moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + check_start_time
                  } else {
                    let check_start_time = moment().format("HH:mm:ss");
                    const isSameDate = moment().format("YYYY-MM-DD") === moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD");
                    isGreaterCurrentDate = moment().format("YYYY-MM-DD HH:mm:ss") < moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD HH:mm:ss");
                    if (isSameDate && isGreaterCurrentDate) {
                      check_start_time = moment(values.dateof, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
                    } else if (isSameDate && !isGreaterCurrentDate) {
                      check_start_time = moment().format("HH:mm:ss");
                    } else {
                      check_start_time = "00:00:00";
                    }
                    dateof = moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + check_start_time
                  }
                }
              } else {
                let check_start_time = moment().format("HH:mm:ss");
                const isValidDate = dateof ? moment(dateof, "YYYY-MM-DD HH:mm:ss", true).isValid() : false;
                if (isValidDate) {
                  const isSameDate = moment().format("YYYY-MM-DD") === moment(dateof, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
                  isGreaterCurrentDate = moment().format("YYYY-MM-DD HH:mm:ss") < moment(dateof, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
                  if (isSameDate && isGreaterCurrentDate) {
                    check_start_time = moment(dateof, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
                  } else if (isSameDate && !isGreaterCurrentDate) {
                    check_start_time = moment().format("HH:mm:ss");
                  } else {
                    check_start_time = "00:00:00";
                  }
                  dateof = moment(dateof, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") + " " + check_start_time
                }
              }
              // let dateof = values && values.start_datetime ? values.start_datetime : check_start_time;
              // console.log('dateof11', values, dateof, start_datetime);
              if(values && (values.service_id && values.staff_id && values.duration && values.dateof && values.start_time)){
                if (!values.id && !isGreaterCurrentDate && dateof && dateof !== start_datetime) {
                  customToast.error("Any Resource is not available this time")
                }
              }
              // if (values && values.dateof && values.start_time) {
              //   let dateof = ""
              //   if (!values.start_time && values.start_datetime) {
              //     dateof = values && values.start_datetime ? moment(values.start_datetime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") : "";
              //   } else if (values.start_time && !values.start_datetime) {
              //     dateof = values && values.dateof ? moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + moment(values.start_time, "HH:mm").format("HH:mm:ss") : "";
              //   }
              //   console.log('dateof11', dateof, start_datetime);
              //   if (dateof && dateof !== start_datetime) {
              //     customToast.error("Any Resource is not available this time")
              //   }
              // }
            } else {
              if (!start_datetime) {
                customToast.error("Staff is not available this time")
              }
            }
          }

          const payloaddata = action.payload;
          dispatch(AppointmentAvailableTimeLoader(false));
          if (formtype === "appointmentautofillForm") {
            dispatch(AppointmentAvailableTime(payloaddata))
          }
          if (payloaddata.time && payloaddata.time.length == 0) {
            if (payloaddata.appointment && payloaddata.appointment.length > 0) {
              customToast.success(`There is not enough time available for this service.`);
            } else if (payloaddata.addonservices && payloaddata.addonservices.length > 0) {
              customToast.success(`Add-on services not available at this time`);
            } else if (payloaddata.breaktime && payloaddata.breaktime.length > 0) {
              customToast.success(`Please choose another time as business is breaktime`);
            } else if (payloaddata.busytime && payloaddata.busytime.length > 0) {
              customToast.success(`This staff member is busy at this time`);
            } else if (payloaddata.booking && payloaddata.booking.length > 0) {
              customToast.success(`This staff member is on break at this time`);
            } else {
              customToast.success(`There is not enough time available for this service.`);
            }
          }
        } else if (action.meta.requestStatus === "rejected") {
          const status = action.payload && action.payload.status;
          const errors = action.payload && action.payload.message && action.payload.message.errors;
          const response = action.payload && action.payload.message && action.payload.message;
          if (status === 422) {
            const NotifyContent = () => {
              return (
                <>
                  <p className="mb-2 text-light text-justify">{response && response.message}</p>
                  <ul className="list-unstyled">
                    {errors &&
                      Object.keys(errors).map((a, n) => {
                        if (errors[a].length > 0) {
                          return (
                            <>
                              {errors[a].map((as, ns) => {
                                return (
                                  <li key={n + "-" + ns} className="text-light form-text text-start">
                                    {as}
                                  </li>
                                );
                              })}
                            </>
                          );
                        }
                      })}
                  </ul>
                </>
              );
            };
            customToast.error(<NotifyContent />);
          } else if (status === 410) {
            const NotifyContent = () => {
              return (
                <>
                  <p className="mb-2 text-white text-justify">{response && response.message}</p>
                  {response && response.booked && (
                    <>
                      <p className="mb-2 text-white text-justify">{t("Staff are available at this time.")}</p>
                      {response && response.dateof && <Moment format="MMMM DD YYYY">{response.dateof}</Moment>}
                      <ul className="list-unstyled">
                        {response.booked.map((a, n) => (
                          <li key={n} className="text-light form-text">
                            <Moment format="hh:mm A">{response.dateof + "T" + a}</Moment>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </>
              );
            };
            customToast.error(<NotifyContent />);
          }
        }
      });
    } 
    // else if (values && values.listview === "BusyTime" && values.staff_id && values.dateof) {
    //   if (values.availableBusyTime === "start") {
    //     dispatch(StaffAvailableStartBusyTimeApi({ ...values, roster_time: true, formtype })).then((action) => {
    //       if (action.meta.requestStatus === "fulfilled") {
    //         const payloaddata = action.payload;
    //         if (payloaddata.time && payloaddata.time.length == 0) {
    //           if (payloaddata.appointment && payloaddata.appointment.length > 0) {
    //             customToast.success(`There is not enough time available for this service.`);
    //           } else if (payloaddata.addonservices && payloaddata.addonservices.length > 0) {
    //             customToast.success(`Add-on services not available at this time`);
    //           } else if (payloaddata.breaktime && payloaddata.breaktime.length > 0) {
    //             customToast.success(`Please choose another time as business is breaktime`);
    //           } else if (payloaddata.busytime && payloaddata.busytime.length > 0) {
    //             customToast.success(`This staff member is busy at this time`);
    //           } else if (payloaddata.booking && payloaddata.booking.length > 0) {
    //             customToast.success(`This staff member is on break at this time`);
    //           } else {
    //             customToast.success(`There is not enough time available for this service.`);
    //           }
    //         }
    //       } else if (action.meta.requestStatus === "rejected") {
    //         const status = action.payload && action.payload.status;
    //         const errors = action.payload && action.payload.message && action.payload.message.errors;
    //         const response = action.payload && action.payload.message && action.payload.message;
    //         if (status === 422) {
    //           const NotifyContent = () => {
    //             return (
    //               <>
    //                 <p className="mb-2 text-light text-justify">{response && response.message}</p>
    //                 <ul className="list-unstyled">
    //                   {errors &&
    //                     Object.keys(errors).map((a, n) => {
    //                       if (errors[a].length > 0) {
    //                         return (
    //                           <>
    //                             {errors[a].map((as, ns) => {
    //                               return (
    //                                 <li key={n + "-" + ns} className="text-light form-text text-start">
    //                                   {as}
    //                                 </li>
    //                               );
    //                             })}
    //                           </>
    //                         );
    //                       }
    //                     })}
    //                 </ul>
    //               </>
    //             );
    //           };
    //           customToast.error(<NotifyContent />);
    //         } else if (status === 410) {
    //           const NotifyContent = () => {
    //             return (
    //               <>
    //                 <p className="mb-2 text-white text-justify">{response && response.message}</p>
    //                 {response && response.booked && (
    //                   <>
    //                     <p className="mb-2 text-white text-justify">{t("Staff are available at this time.")}</p>
    //                     {response && response.dateof && <Moment format="MMMM DD YYYY">{response.dateof}</Moment>}
    //                     <ul className="list-unstyled">
    //                       {response.booked.map((a, n) => (
    //                         <li key={n} className="text-light form-text">
    //                           <Moment format="hh:mm A">{response.dateof + "T" + a}</Moment>
    //                         </li>
    //                       ))}
    //                     </ul>
    //                   </>
    //                 )}
    //               </>
    //             );
    //           };
    //           customToast.error(<NotifyContent />);
    //         }
    //       }
    //     });
    //   }
    //   if (values.availableBusyTime === "end") {
    //     dispatch(StaffAvailableEndBusyTimeApi({ ...values, roster_time: true, formtype })).then((action) => {
    //       if (action.meta.requestStatus === "fulfilled") {
    //         const payloaddata = action.payload;
    //         if (payloaddata.time && payloaddata.time.length == 0) {
    //           if (payloaddata.appointment && payloaddata.appointment.length > 0) {
    //             customToast.success(`There is not enough time available for this service.`);
    //           } else if (payloaddata.addonservices && payloaddata.addonservices.length > 0) {
    //             customToast.success(`Add-on services not available at this time`);
    //           } else if (payloaddata.breaktime && payloaddata.breaktime.length > 0) {
    //             customToast.success(`Please choose another time as business is breaktime`);
    //           } else if (payloaddata.busytime && payloaddata.busytime.length > 0) {
    //             customToast.success(`This staff member is busy at this time`);
    //           } else if (payloaddata.booking && payloaddata.booking.length > 0) {
    //             customToast.success(`This staff member is on break at this time`);
    //           } else {
    //             customToast.success(`There is not enough time available for this service.`);
    //           }
    //         }
    //       } else if (action.meta.requestStatus === "rejected") {
    //         const status = action.payload && action.payload.status;
    //         const errors = action.payload && action.payload.message && action.payload.message.errors;
    //         const response = action.payload && action.payload.message && action.payload.message;
    //         if (status === 422) {
    //           const NotifyContent = () => {
    //             return (
    //               <>
    //                 <p className="mb-2 text-light text-justify">{response && response.message}</p>
    //                 <ul className="list-unstyled">
    //                   {errors &&
    //                     Object.keys(errors).map((a, n) => {
    //                       if (errors[a].length > 0) {
    //                         return (
    //                           <>
    //                             {errors[a].map((as, ns) => {
    //                               return (
    //                                 <li key={n + "-" + ns} className="text-light form-text text-start">
    //                                   {as}
    //                                 </li>
    //                               );
    //                             })}
    //                           </>
    //                         );
    //                       }
    //                     })}
    //                 </ul>
    //               </>
    //             );
    //           };
    //           customToast.error(<NotifyContent />);
    //         } else if (status === 410) {
    //           const NotifyContent = () => {
    //             return (
    //               <>
    //                 <p className="mb-2 text-white text-justify">{response && response.message}</p>
    //                 {response && response.booked && (
    //                   <>
    //                     <p className="mb-2 text-white text-justify">{t("Staff are available at this time.")}</p>
    //                     {response && response.dateof && <Moment format="MMMM DD YYYY">{response.dateof}</Moment>}
    //                     <ul className="list-unstyled">
    //                       {response.booked.map((a, n) => (
    //                         <li key={n} className="text-light form-text">
    //                           <Moment format="hh:mm A">{response.dateof + "T" + a}</Moment>
    //                         </li>
    //                       ))}
    //                     </ul>
    //                   </>
    //                 )}
    //               </>
    //             );
    //           };
    //           customToast.error(<NotifyContent />);
    //         }
    //       }
    //     });
    //   }

    // } 
    else {
      dispatch({ type: "staff/StaffAvailableTime/rejected" });
    }
  }
};

const GetTotalDurationWithPadding = (durationWithPadding, addonservices, addanotherservices) => {
  const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
    // Check if sduration.durationWithPadding is a valid duration
    const duration = moment.duration(sduration.durationWithPadding);
    if (duration.isValid()) {
      const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
      return sum.add(sumDurationWithPadding);
    }
    return sum;
  }, moment.duration(0));


  const totalAddAnotherDurationWithPadding = addanotherservices.length > 0 && addanotherservices.reduce((sum, sduration) => {
    // Check if sduration.durationWithPadding is a valid duration
    const duration = moment.duration(sduration.durationWithPadding);
    if (duration.isValid()) {
      const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
      return sum.add(sumDurationWithPadding);
    }
    return sum;
  }, moment.duration(0));

  const duration1 = moment.duration(durationWithPadding);
  const duration2 = totalAddOnDurationWithPadding;
  const duration3 = totalAddAnotherDurationWithPadding;

  // Check if each duration is valid before using it
  const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
  const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;
  const minutes3 = duration3 && duration3.isValid() ? duration3.asMinutes() : 0;

  const totalMinutes = minutes1 + minutes2 + minutes3;
  if (totalMinutes > 0) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const sumDuration = moment({ hours, minutes });
    const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";
    // console.log(SumOfDuration);
    const durationMoment = moment.duration(SumOfDuration);

    return { totalHours: SumOfDuration, totalMinutes: totalMinutes, durationMoment }
  } else {
    return { totalHours: "", totalMinutes: "", durationMoment: "" }
  }
};
export { GetAvailableTime, GetTotalDurationWithPadding };
