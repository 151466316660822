import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import CanvaTemplateApiController from "../../services/canvatemplate.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const CanvaTemplateStoreApi = createAsyncThunk("canvatemplate/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await CanvaTemplateApiController.create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const CanvaTemplateUpdateApi = createAsyncThunk("canvatemplate/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await CanvaTemplateApiController.update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const CanvaTemplateListViewApi = createAsyncThunk("canvatemplate/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await CanvaTemplateApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CanvaTemplateDeleteApi = createAsyncThunk("canvatemplate/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await CanvaTemplateApiController.deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isCanvaTemplateModal: "",
  isCanvaTemplateSelected: "",
  isListView: [],
  isApiStatus: {
    CanvaTemplateStoreApi: "",
    CanvaTemplateUpdateApi: "",
    CanvaTemplateListViewApi: "",
    CanvaTemplateSuggetionListApi: "",
    CanvaTemplateDeleteApi: "",
  },
};

const canvaTemplateSlice = createSlice({
  name: "canvatemplate",
  initialState,
  reducers: {
    reset: () => initialState,
    CanvaTemplateModal: (state, action) => {
      state.isCanvaTemplateModal = action.payload;
    },
    CanvaTemplateSelected: (state, action) => {
      state.isCanvaTemplateSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CanvaTemplateStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CanvaTemplateStoreApi: "loading" };
      })
      .addCase(CanvaTemplateStoreApi.fulfilled, (state, action) => {
        // if (state.isListView && state.isListView.data) {
        //   state.isListView.data = [action.payload, ...state.isListView.data];
        // } else {
        //   state.isListView = { data: [action.payload] };
        // }
        state.isApiStatus = { ...state.isApiStatus, CanvaTemplateStoreApi: "succeeded" };
      })
      .addCase(CanvaTemplateStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CanvaTemplateStoreApi: "failed" };
      })
      .addCase(CanvaTemplateUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CanvaTemplateUpdateApi: "loading" };
      })
      .addCase(CanvaTemplateUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isListView.data.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, CanvaTemplateUpdateApi: "succeeded" };
      })
      .addCase(CanvaTemplateUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CanvaTemplateUpdateApi: "failed" };
      })
      .addCase(CanvaTemplateListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CanvaTemplateListViewApi: "loading" };
      })
      .addCase(CanvaTemplateListViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isListView.current_page ? state.isListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isListView && state.isListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, CanvaTemplateListViewApi: "succeeded" };
        state.isListView = action.payload;
      })
      .addCase(CanvaTemplateListViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CanvaTemplateListViewApi: "failed" };
        state.isListView = [];
      })
      .addCase(CanvaTemplateDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CanvaTemplateDeleteApi: "loading" };
      })
      .addCase(CanvaTemplateDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isApiStatus = { ...state.isApiStatus, CanvaTemplateDeleteApi: "succeeded" };
        state.isListView.data = state.isListView.data ? state.isListView.data.filter((item) => item.id != id) : [];
      })
      .addCase(CanvaTemplateDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CanvaTemplateDeleteApi: "failed" };
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, CanvaTemplateModal, CanvaTemplateSelected } = canvaTemplateSlice.actions;
export default canvaTemplateSlice.reducer;
