import config from "../config";
import { useTranslation } from "react-i18next";
import { SalonModule } from "pages";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { UpgradePlanModal } from "store/slices/salonSlice";
import { FreeTrialUpgradePackageTexts, StaffUpgradePackageTexts } from "store/slices/packageSlice";
import { staffUpgradePackage } from "store/slices/staffSlice";
// ==============================|| LOADER ||============================== //
const NoFeatureAccess = (props) => {
  SalonModule();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.salonmodule.isAccess);
  const screen = props && props.screen;
  const tabview = props && props.tabview;
  
  if (["add_button_staff_page"].includes(screen)) {
    return (
      <>
          <div className={tabview && tabview == "staff" ? "active" : ""} style={{ display: tabview && tabview == "staff" ? "block" : "none" }}>
            <a className="btn btn-primary add-service fw-bold me-md-3 me-1 add-new-btn px-lg-5" onClick={() => {
            dispatch(staffUpgradePackage("open"));
          }
          }>
              {t("Add New")}
            </a>
          </div>
      </>
    );
  }else if (["add_button"].includes(screen)) {
    return (
      <>
          <div className={tabview && tabview == "staff" ? "active" : ""} style={{ display: tabview && tabview == "staff" ? "block" : "none" }}>
            <a className="btn btn-primary add-service fw-bold me-md-3 me-1 add-new-btn px-lg-5" onClick={() => {
            dispatch(UpgradePlanModal("open"));
            dispatch(FreeTrialUpgradePackageTexts({isfreeTrialTitleTexts:"",isfreeTrialdescriptionTexts:""}));
            dispatch(StaffUpgradePackageTexts({isstaffTitleTexts:"Please upgrade your plan for more staff members.",isstaffdescriptionTexts:"You need to upgrade your plan to allow for additional staff members."}));
          }
          }>
              {t("Add New")}
            </a>
          </div>
      </>
    );
  } else if (["product_add", "product_edit"].includes(screen)) {
    return (
      <div className="alert mb-md-4 mb-3 d-flex align-items-xl-center align-items-start">
        <img src={config.imagepath + "alert.png"} className="me-2" alt="" />
        <span>
          You must <a className="" onClick={()=>{
                  navigate(config.basePath + "/plans");
                  dispatch(FreeTrialUpgradePackageTexts({isfreeTrialTitleTexts:"Want to upgrade your plan?",isfreeTrialdescriptionTexts:"Boost your business growth or add more staff with a plan upgrade."}));
                  dispatch(StaffUpgradePackageTexts({isstaffTitleTexts:"",isstaffdescriptionTexts:""}));
                }
                }>{t("Upgrade your plan")}</a> to be able to use stock management.
        </span>
      </div>
    );
  }else if (["staff_list_grid_box"].includes(screen)) {
    return (
      <>
          <a className="box-image-cover cursor-pointer" id="addstaff-member-link" onClick={() => {
             dispatch(staffUpgradePackage("open"));
          }
          }>
            <div className="tabs-image">
              <img src={config.imagepath + "tabs-image.png"} alt="" />
            </div>
            <div className="image-content">
              <h5>
                <i className="fal fa-plus me-2"></i> {t("Add Staff")}
              </h5>
            </div>
          </a>
      </>
    );
  } else if (["staff_list"].includes(screen)) {
    return (
      <>
          <a className="box-image-cover cursor-pointer" id="addstaff-member-link" onClick={() => {
            dispatch(UpgradePlanModal("open"));
            dispatch(FreeTrialUpgradePackageTexts({isfreeTrialTitleTexts:"",isfreeTrialdescriptionTexts:""}));
            dispatch(StaffUpgradePackageTexts({isstaffTitleTexts:"Please upgrade your plan for more staff members.",isstaffdescriptionTexts:"You need to upgrade your plan to allow for additional staff members."}));
          }
          }>
            <div className="tabs-image">
              <img src={config.imagepath + "tabs-image.png"} alt="" />
            </div>
            <div className="image-content">
              <h5>
                <i className="fal fa-plus me-2"></i> {t("Add Staff")}
              </h5>
            </div>
          </a>
      </>
    );
  }else if (["Everyday_Marketing","Consultation_Forms"].includes(screen)) {
    return (
      <div className="page-content nofeature-content">
        <div className="nofeature">
          <div className="complete-box text-center d-flex flex-column justify-content-center mt-xl-4 pt-10 bg-white">
            <div className="complete-box-wrp text-center">
              <img src={config.imagepath + "addphoto-box.png"} alt="" className="mb-md-4 mb-3" />
              <h5 className="mb-2 fw-semibold">
                {t("No Access.")}
                <a className="" onClick={()=>{
                  navigate(config.basePath + "/plans");
                  dispatch(FreeTrialUpgradePackageTexts({isfreeTrialTitleTexts:"Want to upgrade your plan?",isfreeTrialdescriptionTexts:"Boost your business growth or add more staff with a plan upgrade."}));
                  dispatch(StaffUpgradePackageTexts({isstaffTitleTexts:"",isstaffdescriptionTexts:""}));
                }
                }>
                  {t("Please upgrade plan")}
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="page-content nofeature-content">
        <div className="nofeature">
          <div className="complete-box text-center d-flex flex-column justify-content-center mt-xl-4 pt-10">
            <div className="complete-box-wrp text-center">
              <img src={config.imagepath + "addphoto-box.png"} alt="" className="mb-md-4 mb-3" />
              <h5 className="mb-2 fw-semibold">
                {t("No Access.")}{" "}
                <a className="" onClick={()=>{
                  navigate(config.basePath + "/plans");
                  dispatch(FreeTrialUpgradePackageTexts({isfreeTrialTitleTexts:"Want to upgrade your plan?",isfreeTrialdescriptionTexts:"Boost your business growth or add more staff with a plan upgrade."}));
                  dispatch(StaffUpgradePackageTexts({isstaffTitleTexts:"",isstaffdescriptionTexts:""}));
                }
                }>
                  {t("Please upgrade plan")}
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
NoFeatureAccess.propTypes = {
  screen: PropTypes.string,
  tabview: PropTypes.string,
  tabviewmarketing: PropTypes.string,
};
export default NoFeatureAccess;
