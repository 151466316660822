import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ucfirst } from "../../../helpers/Functions";
import config from "../../../config";

const FeatureList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isPackageView = props.isPackageView;
  const objectData = props.view;
  return (
    <>
      <div className="accordion" id="accordionExample">
        {objectData &&
          Object.keys(objectData).length > 0 &&
          Object.keys(objectData).map((item, i) => {
            let category_name = item;
            let featuredata = objectData && objectData[item];

            return (
              <div className="accordion-item mb-md-4 mb-3" key={i}>
                <h2 className="accordion-header" id={`heading${item}`}>
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${item}`} aria-expanded="false" aria-controls={`collapse${item}`}>
                    {ucfirst(category_name).replace(/_/g, " ")}
                  </button>
                </h2>
                <div id={`collapse${item}`} className={"accordion-collapse collapse "} aria-labelledby={`heading${item}`} data-bs-parent="#accordionExample">
                  <div className="accordion-body p-0 overflow-auto">
                    {featuredata && featuredata.length > 0 && (
                      <>
                        <table className="table mb-0 table-hover">
                          <thead className="services-table-data thead">
                            <tr>
                              <th>{t("Feature")}</th>
                              {isPackageView &&
                                isPackageView.length > 0 &&
                                isPackageView.map((ptitem, pt) => {
                                  return (
                                    <th key={i + "-" + pt} className="ps-0 text-center">
                                      <a className="service icon-custom-color">{ptitem.name}</a>
                                    </th>
                                  );
                                })}

                              {/* <th>
                                <a className="service">{t("The Essentials")}</a>
                              </th>
                              <th>
                                <a className="service">{t("Accelerate")}</a>
                              </th> */}
                            </tr>
                          </thead>
                          <tbody className="services-table-data tbody">
                            {featuredata.map((element, pf) => {
                              let packagefeatureaccess = element.packagefeatureaccess;
                              return (
                                <tr key={i + "-" + pf} className="sale-complete-link">
                                  <td className="" width={"50%"}>{element.name}</td>
                                  {isPackageView &&
                                    isPackageView.length > 0 &&
                                    isPackageView.map((ptypeelement, pt) => {
                                      let featureaccess = packagefeatureaccess.filter((accitem) => accitem.package_type_id == ptypeelement.id);
                                      if (featureaccess && featureaccess.length == 1) {
                                        let package_access = featureaccess[0].is_access;
                                        return (
                                          <td key={i + "-" + pf + "-" + pt} className="text-center">
                                            <a className="service icon-custom-color">{package_access ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times" aria-hidden="true"></i>}</a>
                                          </td>
                                        );
                                      }
                                    })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        {objectData.length <= 0 ? <div className="fw-bold p-3">{t("No data found")}</div> : ""}
      </div>
    </>
  );
};

FeatureList.propTypes = {
  view: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
  isPackageView: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
  // searchname: PropTypes.string,
  // id: PropTypes.string,
};

export default FeatureList;
