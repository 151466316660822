import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import packageApiController from "../../services/package.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const PackageViewApi = createAsyncThunk("package/packageview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await packageApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "packageview"))
      .catch((error) => HandleError(thunkAPI, error, "packageview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const PackageKeyFeaturesApi = createAsyncThunk("package/keyfeatures", async (formValues, thunkAPI) => {
  try {
    const resposedata = await packageApiController
      .packagekeyfeatures(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "keyfeatures"))
      .catch((error) => HandleError(thunkAPI, error, "keyfeatures"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const PackageFeatureViewApi = createAsyncThunk("package/packagefeatureview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await packageApiController
      .packagefeatureview(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "packagefeatureview"))
      .catch((error) => HandleError(thunkAPI, error, "packagefeatureview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const PackageStoreApi = createAsyncThunk("package/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await packageApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const PackagePaymentApi = createAsyncThunk("package/payment", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await packageApiController
      .packagepayment(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "payment"))
      .catch((error) => HandleError(thunkAPI, error, "payment"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const PackagePaymentFailedApi = createAsyncThunk("package/paymentdfailed", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await packageApiController
      .packagepaymentfailed(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "paymentdfailed"))
      .catch((error) => HandleError(thunkAPI, error, "paymentdfailed"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const PackageCreatePaymentIntentApi = createAsyncThunk("package/createPaymentIntent", async (formValues, thunkAPI) => {
  try {
    const resposedata = await packageApiController
      .createPaymentIntent(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "createPaymentIntent"))
      .catch((error) => HandleError(thunkAPI, error, "createPaymentIntent"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const PlanCancelApi = createAsyncThunk("package/plancancel", async (formValues, thunkAPI) => {
  try {
    const resposedata = await packageApiController
      .plancancel(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "plancancel"))
      .catch((error) => HandleError(thunkAPI, error, "plancancel"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const DefaultCardRemoveApi = createAsyncThunk("package/cardremove", async (formValues, thunkAPI) => {
  try {
    const resposedata = await packageApiController
      .cardremove(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "cardremove"))
      .catch((error) => HandleError(thunkAPI, error, "cardremove"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const PackageInvoiceApi = createAsyncThunk("package/invoice", async (formValues, thunkAPI) => {
  try {
    const resposedata = await packageApiController
      .packageinvoice(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "invoice"))
      .catch((error) => HandleError(thunkAPI, error, "invoice"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
const initialState = {
  isOpenSelectedPackage: "",
  isOpenMainPackage: "",
  isPackageView: [],
  isPackageFeatureView: [],
  isPackageManageData: "",
  isPackageName: "",
  isPackageCreatePaymentIntentApi: "",
  isPackagePaymentApi: "",
  isPackagePaymentFailedApi: "",
  isstaffUpgradePackageTexts: { isstaffTitleTexts: "", isstaffdescriptionTexts: "" },
  isFreeTrialUpgradePackageTexts: { isfreeTrialTitleTexts: "", isfreeTrialdescriptionTexts: "" },
  isPackageModal: "",
  isPlanCancel: "",
  isMaxStaffAlert: "",
  isDefaultRemove: "",
  isPackageKeyFeaturesList: [],
  isPackageInvoice: [],
  isApiStatus: {
    PackageViewApi: "",
    PackageFeatureViewApi: "",
    PackageCreatePaymentIntentApi: "",
    PackagePaymentApi: "",
    PackagePaymentFailedApi: "",
    PlanCancelApi: "",
    DefaultCardRemoveApi: "",
    PackageKeyFeaturesApi: "",
    PackageInvoiceApi: "",
  },
};

const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenSelectedPackage: (state, action) => {
      state.isOpenSelectedPackage = action.payload;
    },
    OpenMainPackage: (state, action) => {
      state.isOpenMainPackage = action.payload;
    },
    PackagePaymentsData: (state, action) => {
      state.isPackageManageData = action.payload;
    },
    PackagePaymentsDataName: (state, action) => {
      state.isPackageName = action.payload;
    },
    PackagePaymentModal: (state, action) => {
      state.isPackageModal = action.payload;
    },
    MaxStaffAlert: (state, action) => {
      state.isMaxStaffAlert = action.payload;
    },
    StaffUpgradePackageTexts: (state, action) => {
      state.isstaffUpgradePackageTexts = { ...state.isstaffUpgradePackageTexts, ...action.payload };
    },
    FreeTrialUpgradePackageTexts: (state, action) => {
      state.isFreeTrialUpgradePackageTexts = { ...state.isFreeTrialUpgradePackageTexts, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PackageViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackageViewApi: "loading" };
      })
      .addCase(PackageViewApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, PackageViewApi: "succeeded" };
        state.isPackageView = action.payload;
      })
      .addCase(PackageViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackageViewApi: "failed" };
        state.isPackageView = [];
      })
      .addCase(PackageKeyFeaturesApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackageKeyFeaturesApi: "loading" };
      })
      .addCase(PackageKeyFeaturesApi.fulfilled, (state, action) => {
        state.isPackageKeyFeaturesList = action.payload;
        state.isApiStatus = { ...state.isApiStatus, PackageKeyFeaturesApi: "succeeded" };
      })
      .addCase(PackageKeyFeaturesApi.rejected, (state) => {
        state.isPackageKeyFeaturesList = [];
        state.isApiStatus = { ...state.isApiStatus, PackageKeyFeaturesApi: "failed" };
      })
      .addCase(PackageFeatureViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackageFeatureViewApi: "loading" };
      })
      .addCase(PackageFeatureViewApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, PackageFeatureViewApi: "succeeded" };
        state.isPackageFeatureView = action.payload;
      })
      .addCase(PackageFeatureViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackageFeatureViewApi: "failed" };
        state.isPackageFeatureView = [];
      })
      .addCase(PackageStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackageStoreApi: "loading" };
      })
      .addCase(PackageStoreApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, PackageStoreApi: "succeeded" };
        // Handle the fulfilled action payload if needed
      })
      .addCase(PackageStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackageStoreApi: "failed" };
        // Handle the rejected action if needed
      })
      .addCase(PackageCreatePaymentIntentApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackageCreatePaymentIntentApi: "loading" };
      })
      .addCase(PackageCreatePaymentIntentApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, PackageCreatePaymentIntentApi: "succeeded" };
        state.isPackageCreatePaymentIntentApi = action.payload;
      })
      .addCase(PackageCreatePaymentIntentApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackageCreatePaymentIntentApi: "failed" };
        state.isPackageCreatePaymentIntentApi = [];
      })
      .addCase(PackagePaymentApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackagePaymentApi: "loading" };
      })
      .addCase(PackagePaymentApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, PackagePaymentApi: "succeeded" };
        state.isPackagePaymentApi = action.payload;
      })
      .addCase(PackagePaymentApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackagePaymentApi: "failed" };
        state.isPackagePaymentApi = [];
      })
      .addCase(PackagePaymentFailedApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackagePaymentFailedApi: "loading" };
      })
      .addCase(PackagePaymentFailedApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, PackagePaymentFailedApi: "succeeded" };
        state.isPackagePaymentFailedApi = action.payload;
      })
      .addCase(PackagePaymentFailedApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackagePaymentFailedApi: "failed" };
        state.isPackagePaymentFailedApi = [];
      })
      .addCase(PlanCancelApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PlanCancelApi: "loading" };
      })
      .addCase(PlanCancelApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, PlanCancelApi: "succeeded" };
        state.isPlanCancel = action.payload;
      })
      .addCase(PlanCancelApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PlanCancelApi: "failed" };
        state.isPlanCancel = [];
      })
      .addCase(DefaultCardRemoveApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, DefaultCardRemoveApi: "loading" };
      })
      .addCase(DefaultCardRemoveApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, DefaultCardRemoveApi: "succeeded" };
        state.isDefaultRemove = action.payload;
      })
      .addCase(DefaultCardRemoveApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, DefaultCardRemoveApi: "failed" };
        state.isDefaultRemove = [];
      })
      .addCase(PackageInvoiceApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackageInvoiceApi: "loading" };
      })
      .addCase(PackageInvoiceApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, PackageInvoiceApi: "succeeded" };
        state.isPackageInvoice = action.payload;
      })
      .addCase(PackageInvoiceApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, PackageInvoiceApi: "failed" };
        state.isPackageInvoice = "";
      });
  },
});

export const { reset, OpenSelectedPackage, OpenMainPackage, PackagePaymentsData, PackagePaymentsDataName, PackagePaymentModal, FreeTrialUpgradePackageTexts, StaffUpgradePackageTexts, MaxStaffAlert } = packageSlice.actions;
export default packageSlice.reducer;
