import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";
// import toQueryString from "to-querystring";

const API_URL = config.API_URL;

const view = (values) => {
  const result = values && values.result ? values.result : "";
  const action = `settings`;
  const data = {
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const mailchimpsubscribe = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/mailchimp/subscribe";
  // const action = "https://gmail.us18.list-manage.com/subscribe/post?u=738df287faf364f07c3d7433a&amp;id=238f6786b9";

  // const params = toQueryString(values);
  // const getAjaxUrl = (url) => url.replace("/post?", "/post-json?");
  // const url = getAjaxUrl(action) + "&" + params;

  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });

  // formData.append("auth_key", auth_key);
  // formData.append("action", action);
  // formData.append("salon_id", auth.user.salon_id);
  // return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};
const smsplaceholder = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const result = values && values.result ? values.result : "";
  const apicall = values && values.apicall ? values.apicall : "";
  const action = "settings/smsplaceholder";
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    option: values && values.option ? values.option : "",
    apicall: apicall,
  };
  return axios.post(API_URL + action, data);
};

const integrationdisconnect = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const result = values && values.result ? values.result : "";
  const apicall = values && values.apicall ? values.apicall : "";
  const action = "afterlogin/salons/disconnect";

  const data = {
    auth_key: auth_key,
    action: action,
    type: values && values.type ? values.type : "",
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};
const smscharactercounts = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const result = values && values.result ? values.result : "";
  const apicall = values && values.apicall ? values.apicall : "";
  const action = "settings/smscharacter";
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    option: values && values.option ? values.option : "",
    apicall: apicall,
  };
  return axios.post(API_URL + action, data);
};
const settingApiController = {
  view,
  mailchimpsubscribe,
  smsplaceholder,
  integrationdisconnect,
  smscharactercounts,
};
export default settingApiController;
