import PropTypes from "prop-types";
// import React from 'react';
// import { useSelector } from "react-redux";
// import { Navigate } from "react-router-dom";

// project imports
// import config from "../../config";

//-----------------------|| GUEST GUARD ||-----------------------//

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */
const CustomGuard = ({ children }) => {
  //   const account = useSelector((state) => state.auth);
  //   const { isLoggedIn } = account;
  // if (isLoggedIn) {
  //     return <Navigate to={config.defaultPath} />;
  // }
  return children;
};

CustomGuard.propTypes = {
  children: PropTypes.node,
};

export default CustomGuard;
