import React, { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import config from "../../config";
import { Helmet } from "react-helmet-async";
import { CustompagesApi } from "store/slices/custompagesSlice";
import { SettingsApi } from "store/slices/settingSlice";
import ProgressBar from "component/ProgressBar";
import { useSelector } from "react-redux";
import { StepResets } from "store/slices/signupSlice";
// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const isSignupStep = useSelector((state) => state.signup.isSignupStep);
  const totalSteps = 6;
  const progress = (isSignupStep / totalSteps) * 100;
  useEffect(() => {
    dispatch({ type: "signup/reset" });
    dispatch(CustompagesApi());
    dispatch(SettingsApi());
    //moment.tz.setDefault(currentUser.salon && currentUser.salon.timezone ? currentUser.salon.timezone : "UTC");
  }, []);
  const bookinpath = location && location.pathname.split("/");
  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href={config.baseUrl + "/css/style.css"} /> */}
        <link rel="stylesheet" href={"https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"} />
      </Helmet>
      {bookinpath.length > 0 && bookinpath.includes("booking") ? (
        <main>
          <div className="booking widget">
            <Outlet />
          </div>
        </main>
      ) : (
        <main>
          <div className="signup-page" style={{ backgroundImage: `url(${config.imagepath}signupimg.png)` }}>
          <div className={`signup-header bg-white ${isSignupStep && (isSignupStep > 1 && isSignupStep <= 6) ? "custom-signup-header" : ""}`}>
              <div className={`row align-items-center signup-header-paddings ${isSignupStep && (isSignupStep > 1 && isSignupStep <= 6)? "custom-padding-header" : ""}`}>
                <div className="signup-header-left col-auto">
                  <Link to={"/login"} className="signup-logo">
                    <img src={`${config.imagepath}beautilogo-new.png`} alt="" />
                  </Link>
                </div>
                <div className="signup-header-right col-auto ms-auto">
                  <p className="already-login">
                    {location && location.pathname === config.basePath + "/login" && (
                      <Link to={config.basePath + "/signup"} className="cursor-pointer" onClick={() => {
                        dispatch({ type: "signup/reset" });
                        dispatch(StepResets());
                      }}>
                        {t("Create Account")}
                      </Link>
                    )}
                    {location && location.pathname === config.basePath + "/signup" && (
                      <>
                        {t("Already have an account?")}
                        <Link to={config.basePath + "/login"} className="cursor-pointer" onClick={() => {
                        dispatch(StepResets());
                      }}>
                          {t("Login here")}.
                        </Link>
                      </>
                    )}
                    {location && location.pathname === config.basePath + "/forgotpassowrd" && (
                      <>
                        <Link to={config.basePath + "/login"} className="cursor-pointer ms-md-5">
                          {t("Login here")}.
                        </Link>
                      </>
                    )}
                  </p>
                </div>
              </div>
              {/* <ProgressBar currentStep={isSignupStep} totalStep={6} /> */}
              {isSignupStep && (isSignupStep > 1 && isSignupStep <= 6) ? (
                <div className="" style={{ borderBottom: `4px solid #fbd2c3`, width: `${progress}%` ,marginTop:"35px" }}></div>
              ) : ""}
            </div>
            <Outlet />
          </div>
        </main>
      )}
    </>
  );
};

export default MinimalLayout;
