import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";
import moment from "moment";

const API_URL = config.API_URL;

const staff = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["option","availebleTimeObj"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/addanother/staff`;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const services = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["isNotId"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/addanother/services`;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const serviceprice = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  // let service_id = values && values.service_id ? values.service_id : "";
  // let staff_id = values && values.service_id ? values.staff_id : "";
  // let add_on_service_id = values && values.add_on_service_id ? values.add_on_service_id : "";
  // let index_id = values && values.index_id ? values.index_id : "";
  // const action = `afterlogin/addon/serviceprice`;
  // const data = {
  //   auth_key: auth_key,
  //   action: action,
  //   service_id: service_id,
  //   staff_id: staff_id,
  //   add_on_service_id: add_on_service_id,
  //   index_id: index_id,
  // };
  const formData = new FormData();
  for (let value in values) {
    if (["working_hours"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/addanother/serviceprice`;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const staffavailabletime = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  // const formData = new FormData();
  // for (let value in values) {
  //   if (["dateof"].includes(value) && values[value]) {
  //     formData.append(value, moment(values[value], "dddd, DD MMMM YYYY").format("YYYY-MM-DD"));
  //   } else {
  //     formData.append(value, values[value]);
  //   }
  // }
  // const action = "afterlogin/staff/availabletime";
  // formData.append("auth_key", auth_key);
  // formData.append("action", action);
  // return axios.post(API_URL + action, formData, { headers: authHeader() });
  const service_id = values && values.service_id ? values.service_id : "";
  const staff_id = values && values.staff_id ? values.staff_id : "";
  const dateof = values && values.dateof ? moment(values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss") : "";
  const uid = values && values.uid ? values.uid : "1";
  const apicall = values && values.apicall ? values.apicall : "staff";
  const salon_id = auth && auth.user ? auth.user.salon_id : "";
  const duration = values && values.duration ? values.duration : "";
  const padding_time = values && values.padding_time ? values.padding_time : "";
  const durationWithPadding = values && values.durationWithPadding ? values.durationWithPadding : "";
  const parentAppointmentEndTime = values && values.parentAppointmentEndTime ? values.parentAppointmentEndTime : "";
  const parent_id = values && values.parent_id ? values.parent_id : "";
  const resource_item_id = values && values.resource_item_id ? values.resource_item_id : "";
  const id = values && values.id ? values.id : "";
  const roster_time = values && values.roster_time ? values.roster_time : "";
  const prevObj = values && values.prevObj ? values.prevObj : "";

  const action = `afterlogin/addanother/nextresourceitemtime`;
  // const action = `widget/staff/availabletime`;
  const data = {
    action: action,
    service_id: service_id,
    staff_id: staff_id,
    dateof: dateof,
    uid: uid,
    apicall: apicall,
    salon_id: salon_id,
    duration: duration,
    padding_time: padding_time,
    durationWithPadding: durationWithPadding,
    roster_time,
    parentAppointmentEndTime: parentAppointmentEndTime,
    appointment_id: id,
    parent_id: parent_id,
    id: id,
    resource_item_id: resource_item_id,
    prevObj
  };

  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const addanotherserviceresourceitems = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;

  const result = values && values.result ? values.result : "";
  const service_id = values && values.service_id ? values.service_id : "";
  const availebleTimeObj = values && values.availebleTimeObj ? values.availebleTimeObj : "";
  const isNotInResourceIds = values && values.isNotInResourceIds ? values.isNotInResourceIds : "";

  const action = "afterlogin/addanother/resourceitem";

  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false,
    pagetype: values && values.pagetype ? values.pagetype : '',
    service_id: values && values.service_id ? values.service_id : '',
    option: values && values.option ? values.option : "",
    formtype: values && values.formtype ? values.formtype : "",
    result: result,
    availebleTimeObj,
    isNotInResourceIds
  };

  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const addonApiController = {
  staff,
  services,
  serviceprice,
  staffavailabletime,
  addanotherserviceresourceitems
};
export default addonApiController;
