import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";
// import toQueryString from "to-querystring";

const API_URL = config.API_URL;

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const result = values && values.result ? values.result : "";
  const action = `afterlogin/smtp/view`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    option: values && values.option ? values.option : "",
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const update = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/smtp/update";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const testmail = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = "afterlogin/smtp/testmail";
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value] ? values[value] : "");
  }
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const smtpApiController = {
  view,
  update,
  testmail,
};

export default smtpApiController;
