import moment from "moment/moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ClientSubscriptionListViewApi, SubscriptionCancellationApi } from "store/slices/clientsubscriptionSlice";
import customToast from "component/Toastr";
import { swalConfirm } from "component/Sweatalert2";

const SubscriptionListview = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openModalTwo, setOpenModalTwo] = useState(false);
  const [loading,setLoading] = useState(false);
  const views = props.view;
  const objectData = views && views.data ? views.data : views;
  const tableCellStyle = {
    background: '#f9f6f4',
    padding: '4px',
    textAlign: 'center',
  };
  const headerCellStyle = {
    ...tableCellStyle, // Extend the common styles
    textAlign: 'left',
    fontWeight: 'bold',
  };
  const serviceCellStyle = {
    ...tableCellStyle,
    textAlign: 'left',
  }

  return (
    <div>
      {objectData &&
        Object.keys(objectData).map((item, i) => {
          let cartdata = objectData[item].cart;
          let subscriptionserviceitems = objectData[item].subscriptionservicesmanage;
          // let nextpayment = next_payment_date ? moment(next_payment_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : "";
          let cartsubscriptionitems = cartdata[0].subscription;
          let name = cartsubscriptionitems.name;
          let amount = cartsubscriptionitems.amount;
          let client_id = objectData[item].client_id;
          let sale_id = objectData[item].id;
          let subscription_id = cartsubscriptionitems.id;
          let is_subscription_active = cartdata[0].is_subscription_active;
          let is_subscription_cancel = cartdata[0].is_subscription_cancel;
          let lastpayment = objectData[item].lastpayment && objectData[item].lastpayment.length > 0 ? objectData[item].lastpayment : [];
          const oneOffAmount = cartdata && cartdata.length > 0 ? cartdata[0].one_off_amount || 0 : 0;
          const Frequency = cartdata && cartdata.length > 0 ? cartdata[0].subscription && cartdata[0].subscription.repeat_time_option || "" : "";
          const FormattedFrequency = Frequency ? Frequency.slice(0, -2) : "";
          const Duration = cartdata && cartdata.length > 0 ? cartdata[0].subscription && cartdata[0].subscription.repeat_time || "" : "";
          const no_of_payments = cartdata && cartdata.length > 0 ? cartdata[0].subscription && cartdata[0].subscription.no_of_payments || "" : "";
          const CommenceMentDate = cartdata && cartdata.length > 0 ? cartdata[0].subscription && cartdata[0].subscription.commencement_date ? moment(cartdata[0].subscription.commencement_date,"YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : "" : "";
          const Repeats = cartdata && cartdata.length > 0 ? cartdata[0].subscription && cartdata[0].subscription.repeats || "" : "";
          const NextPayment = lastpayment.length > 0 && lastpayment[0].next_payment_datetime ? lastpayment[0].next_payment_datetime : "";
          const client_signature = cartdata && cartdata.length > 0 && cartdata[0].storage_url && cartdata[0].client_signature ? `${cartdata[0].storage_url}/${cartdata[0].client_signature}` : "";
          const Status = objectData[item]?.status ? objectData[item]?.status : "";

          return (
            <a className="text-decoration-none event-box-pink mb-3">
              {openModalTwo && (
                <>
                  <div className={openModalTwo ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openModalTwo ? "block" : "none" }}>
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-body pb-0">
                          <div className="p-3">
                            <h5 className="mb-3 fw-semibold text-dark">{t("Subscription schedule info")}</h5>
                            <p className="fw-semibold mb-2">{t(`This subscription is set to activated on ${CommenceMentDate}`)}</p>
                          </div>
                          <div className="row mt-3 bg-cream p-4 justify-content-center">
                            
                            <div className="col-6">
                              <button className="btn btn-primary w-100" type="button" disabled={loading} onClick={() => {
                                setOpenModalTwo(false);
                              }}>
                                {t("OK")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {openModalTwo && <div className="modal-backdrop show"></div>}
                </>
              )}
              {openModal && (
                <>
                  <div className={openModal ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openModal ? "block" : "none" }}>
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-body pb-0">
                          <div className="p-3">
                            <h5 className="mb-3 fw-semibold text-dark">{t("Confirm subscription cancel")}</h5>
                            <p className="fw-semibold mb-2">{t("Are you sure you want to cancel this subscription?")}</p>
                          </div>
                          <div className="row mt-3 bg-cream p-4 justify-content-center">
                            <div className="col-6">
                              <button className="preview btn me-1 cursor-pointer btn-preview w-100" type="button" onClick={() => {
                                setOpenModal(false);
                              }}>
                                {t("Cancel")}
                              </button>
                            </div>
                            <div className="col-6">
                              <button className="btn btn-primary w-100" type="button" disabled={loading} onClick={() => {
                                setLoading(true);
                                dispatch(SubscriptionCancellationApi({ client_id: client_id, sale_id: sale_id, subscription_id: subscription_id })).then((action) => {
                                  if (action.meta.requestStatus === "fulfilled") {
                                    dispatch(ClientSubscriptionListViewApi({ client_id: client_id, subscription_id: subscription_id, sale_id: sale_id }));
                                    setLoading(false);
                                    setOpenModal(false);
                                    customToast.success(t("Your subscription plan cancelled."));
                                  }else{
                                    setLoading(false);
                                    customToast.error(t("Something went wrong."));
                                  }
                                })
                              }}>
                                {loading && <span className="spinner-border spinner-border-sm"></span>}
                                {t("Yes Cancel")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {openModal && <div className="modal-backdrop show"></div>}
                </>
              )}
              <h6 className="mb-4 color-wine fw-semibold d-flex justify-content-between align-items-start">
                {name}
                <div>
                  {is_subscription_cancel == "1" ?
                    (
                      <>
                        <span className="cancel-subscriptionlistview me-3 cursor-pointer cancelled-sub-status">
                          Cancelled
                        </span>
                      </>
                    ) : (
                      <span className="cancel-subscriptionlistview cancel-btn-sub me-3 cursor-pointer"
                        onClick={(e) => {
                          setOpenModal(true);
                          // let confirmbtn = swalConfirm(e.currentTarget, { title: t("Are you sure want to cancel this plan?"), message: "", confirmButtonText: t("Yes, Cancel it!") });
                          // if(confirmbtn == true){
                          //   dispatch(SubscriptionCancellationApi({ client_id: client_id, sale_id: sale_id, subscription_id: subscription_id })).then((action) => {
                          //     if (action.meta.requestStatus === "fulfilled") {
                          //       dispatch(ClientSubscriptionListViewApi({ client_id: client_id, subscription_id: subscription_id, sale_id: sale_id }));
                          //       customToast.success(t("Your Subscription plan cancelled."));
                          //     }
                          //   })
                          // }
                        }}>
                        Cancel
                      </span>
                    )
                  }
                  {is_subscription_active == "1" ? (
                    <>
                      <span className="active-subscriptionlistview cursor-pointer" onClick={() => {
                        if (is_subscription_cancel == "1" && is_subscription_active == "1") {
                          customToast.info(t("Your Subscription plan already cancelled and subscription will end at the subscription periods ends time."));
                        }
                      }}>
                        {Status ? Status : "Active"}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="active-subscriptionlistview cursor-pointer" onClick={() => {
                        setOpenModalTwo(true);
                      }}>
                        Info
                      </span>
                    </>
                  )
                  }
                </div>
              </h6>
              {objectData[item] && objectData[item].subscriptionservicesmanage && objectData[item].subscriptionservicesmanage.length > 0 ? (
                <div className="mb-2">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th style={headerCellStyle}>Services Included</th>
                      <th style={tableCellStyle}>Qty</th>
                      <th style={tableCellStyle}>Used</th>
                      <th style={tableCellStyle}>Available</th>
                    </tr>
                  </thead>
                  <tbody>
                    {objectData[item].subscriptionservicesmanage.map((item, i) => (
                      <tr key={item && item.id ? item.id : i} style={{ border: 'none' }}>
                        <td className="text-dark" style={serviceCellStyle}>{item.services.name}</td>
                        <td style={tableCellStyle}>{item.qty ? item.qty : 0}</td>
                        <td style={tableCellStyle}>{item.used_qty ? item.used_qty : 0}</td>
                        <td style={tableCellStyle}>{item.pending_qty ? item.pending_qty : 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              ) : ""}
              <h6 className="mb-1 subscriptionlistview-date d-flex justify-content-between align-items-baseline">
                <div className="row gy-2">
                  <div className="col-4">
                    <h6 className="mb-1 fw-semibold text-dark">
                      custom Amount
                      <br />
                      <span className="subscriptionlistview-offer-time w-50">
                        <div>${oneOffAmount}</div>
                      </span>
                    </h6>
                  </div>
                  <div className="col-4">
                    <h6 className="mb-1 fw-semibold text-dark">
                      Amount
                      <br />
                      <span className="subscriptionlistview-offer-time w-50">
                        <div>${amount ? amount : 0}</div>
                      </span>
                    </h6>
                  </div>
                  <div className="col-4">
                    <h6 className="mb-1 fw-semibold text-dark">
                      Frequency
                      <br />
                      <span className="subscriptionlistview-offer-time">{Frequency}</span>
                    </h6>
                  </div>
                  <div className="col-4">
                    <h6 className="mb-1 fw-semibold text-dark">
                      Duration
                      <br />
                      <span className="subscriptionlistview-offer-time">{no_of_payments ? no_of_payments : ""} {FormattedFrequency ? FormattedFrequency : ""}</span>
                    </h6>
                  </div>
                  <div className="col-4">
                    <h6 className="mb-1 fw-semibold text-dark">
                      Repeats
                      <br />
                      <span className="subscriptionlistview-offer-time">{Repeats}</span>
                    </h6>
                  </div>
                  <div className="col-4">
                    <h6 className="mb-1 fw-semibold text-dark">
                      Next Payment
                      <br />
                      <span className="subscriptionlistview-offer-time">{NextPayment ? moment(NextPayment).format('DD/MM/YYYY') : "n/a"}</span>
                    </h6>
                  </div>
                  <div className="col-12">
                    Signature
                    <div>

                    {client_signature ? (
                      <img src={client_signature} alt="client_signature" style={{ width:"150px",height:"auto" }}/>
                    ) : ""}
                    </div>
                  </div>
                </div>
              </h6>
              <div className="subscriptionlistview-date my-2 fw-semibold text-dark">
                Payment History
              </div>
              <h6 className="mb-1 subscriptionlistview-date d-flex justify-content-between align-items-baseline">
                <>
                  {lastpayment.length > 0 ? (
                    lastpayment.map((items) => {
                      // payment_date : "2024-01-31 18:19:13"
                      let payment_date = items && items.payment_date ? moment(items.payment_date, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY') : '';
                      let amount_paid = items && items.amount ? items.amount : 0;
                      let Status = items && items.status;
                      let badgeColor = items && items.status && items.status == "Paid" ? "paid" : "unpaid";
                      return (
                        <>
                          <div>
                            <span className="subscriptionlistview-offer-time">{payment_date}</span>
                          </div>
                          <div>
                            <span className="subscriptionlistview-offer-time">${amount_paid}</span>
                          </div>
                          <div>
                            <span className={`badge ${badgeColor} text-capitalize`}>{Status ? Status : "Paid"}</span>
                          </div>
                        </>
                      )
                    })
                  ) : ""}
                </>
              </h6>
            </a>
          );



        })}
    </div>
  );
};

export default SubscriptionListview;