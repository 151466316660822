import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/stafftarget/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);

  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const update = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/stafftarget/update/" + values.id;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  formData.append("staff_id", values.staff_id);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const viewTest2 = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const dropdown = values && values.dropdown ? values.dropdown : "";
  const service_id = values && values.service_id ? values.service_id : "";
  const pagetype = values && values.pagetype ? values.pagetype : "";
  const getRow = values && values.getRow ? values.getRow : "";

  let sortstring = "";
  if (sort) {
    let sortArray = [];
    let sortSubArray = [];
    Object.keys(sort).map(function (key, index) {
      if (Object.keys(sort[key]).length > 0) {
        Object.keys(sort[key]).map(function (subkey, subindex) {
          sortSubArray[subindex] = `sort[${key}.${subkey}]=${sort[key][subkey]}`;
        });
      }
      if (key != "supplier") {
        sortArray[index] = `sort[${key}]=${sort[key]}`;
      }
    });
    if (sortSubArray.length > 0) {
      let jsubsort = sortSubArray.join("&");
      sortstring = jsubsort;
    }
    if (sortArray.length > 0) {
      let jsort = sortArray.join("&");
      sortstring = jsort;
    }
  }
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
    if (values.maxstaffAlert) {
      pagination = false;
    }
  }
  const action = page ? `afterlogin/stafftarget/view?page=${page}&${sortstring}` : `afterlogin/stafftarget/view?${sortstring}`;
  let staffdata;
  if (dropdown) {
    staffdata = {
      auth_key: auth_key,
      action: action,
      pagination: false, //true or false
      id: "",
      result: result, //business_name,owner_name
      pagetype: pagetype,
      getRow: getRow,
      page_name: values && values.page_name ? values.page_name : "",
      singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : ""
    };
  } else {
    staffdata = {
      auth_key: auth_key,
      action: action,
      pagination: pagination, //true or false
      id: values && values.id ? values.id : "",
      result: result, //business_name,owner_name
      option: values && values.option ? values.option : "",
      service_id: service_id,
      pagetype: pagetype,
      page_name: values && values.page_name ? values.page_name : "",
      singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : ""
    };
  }
  const data = staffdata;
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const view = (values) => {
  // Get the authentication token from the Redux store
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;

  // Extract parameters from the 'values' object
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const staff_id = values && values.staff_id ? values.staff_id  :"";
  const daterange = values && values.daterange ? values.daterange  :"";
  const label = values && values.label ? values.label  :"";

  let pagination = true;

  // Check if pagination should be enabled based on the 'values' object
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }

  // Construct the 'action' URL based on the 'page' parameter
  const action = page ? `afterlogin/stafftarget/view?page=${page}` : `afterlogin/stafftarget/view`;

  // Create the 'data' object to be sent in the request
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: pagination,
    id: values && values.id ? values.id : "",
    staff_id: staff_id,
    daterange: daterange,
    label: label,
    result: result,
  };
  
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const stafflist = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const result = values && values.result ? values.result : "";
  const pagetype = values && values.pagetype ? values.pagetype : "";
  const getRow = values && values.getRow ? values.getRow : "";
  let pagination = false;
  
  const action = `afterlogin/staff/view`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false, //true or false
    id: "",
    result: result, //business_name,owner_name
    pagetype: pagetype,
    getRow: getRow,
    page_name: values && values.page_name ? values.page_name : "",
    singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : ""
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};


const deleted = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/stafftarget/delete/${values.id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const stafftargetApiController = {
  create,
  update,
  view,
  deleted,
  stafflist
};
export default stafftargetApiController;
