import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import acdemyApiController from "../../services/academy.service";
import academysubApiController from "../../services/academysub.service"
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";


export const AcademyGridViewApi = createAsyncThunk("academy/academylistview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await acdemyApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "academyview"))
      .catch((error) => HandleError(thunkAPI, error, "academy/view"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const SubAcademyGridViewApi = createAsyncThunk("subacademy/listview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await academysubApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "subacademyview"))
      .catch((error) => HandleError(thunkAPI, error, "subacademyview/view"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isTabView: "",
  isOpenAcademy: "",
  isOpenAcademySubPage: "",
  isOpenAcademyVideoPage: "",
  isSubVideoPage: "",
  isGridView: [],
  isSubAcademyGridView: [],
  isApiStatus: {
    AcademyGridViewApi: "",
    SubAcademyGridViewApi: "",
  },
};

const academySlice = createSlice({
  name: "academy",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenAcademy: (state, action) => {
      state.isOpenAcademy = action.payload;
    },
    OpenAcademySubPage: (state, action) => {
      state.isOpenAcademySubPage = action.payload;
    },
    academyvideodatas: (state, action) => {
      state.isSubVideoPage = action.payload;
    },
    OpenAcademyVideoPage: (state = initialState) => {
      state.isOpenAcademyVideoPage = "open";
    },
    CloseAcademyVideoPage: (state = initialState) => {
      state.isOpenAcademyVideoPage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AcademyGridViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AcademyGridViewApi: "loading" };
      })
      .addCase(AcademyGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isGridView.current_page || "";
        let new_current_page = action.payload.current_page || "";
        let viewdata = state.isGridView && state.isGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page !== new_current_page) {
          viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, AcademyGridViewApi: "succeeded" };
      })
      .addCase(AcademyGridViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AcademyGridViewApi: "failed" };
        state.isGridView = [];
      })
      .addCase(SubAcademyGridViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubAcademyGridViewApi: "loading" };
      })
      .addCase(SubAcademyGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isSubAcademyGridView.current_page || "";
        let new_current_page = action.payload.current_page || "";
        let viewdata = state.isSubAcademyGridView && state.isSubAcademyGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isSubAcademyGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page !== new_current_page) {
          viewdata && newviewdata ? (state.isSubAcademyGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, SubAcademyGridViewApi: "succeeded" };
      })
      .addCase(SubAcademyGridViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SubAcademyGridViewApi: "failed" };
        state.isSubAcademyGridView = [];
      });
  },
})



// Action creators are generated for each case reducer function
export const { reset, OpenAcademy, OpenAcademyVideoPage, CloseAcademyVideoPage, academyvideodatas } = academySlice.actions;
export default academySlice.reducer;
