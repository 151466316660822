import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";
import moment from "moment";

const API_URL = config.API_URL;

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const result = values && values.result ? values.result : "";
  const action = `afterlogin/salons/view`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    option: values && values.option ? values.option : "",
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const isexist = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const result = values && values.result ? values.result : "";

  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/salons/isexist";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const closeddate = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/salons/closeddate`;
  //Working hour Calender view parameter
  const start_date = values && values.start_date ? moment(values.start_date).format("YYYY-MM-DD HH:mm:ss") : "";
  const end_date = values && values.end_date ? moment(values.end_date).format("YYYY-MM-DD HH:mm:ss") : "";
  const timezone = values && values.timezone ? values.timezone : "";
  const type = values && values.type ? values.type : "";
  const staff_id = values && values.staff_id ? values.staff_id : "";
  const showdate = values && values.showdate ? moment(values.showdate).format("YYYY-MM-DD HH:mm:ss") : "";
  const showstartdate = values && values.showstartdate ? moment(values.showstartdate).format("YYYY-MM-DD HH:mm:ss") : "";
  const showenddate = values && values.showenddate ? moment(values.showenddate).format("YYYY-MM-DD HH:mm:ss") : "";
  const data = {
    auth_key: auth_key,
    action: action,
    id: values && values.id ? values.id : "",
    start_date: start_date,
    end_date: end_date,
    timezone: timezone,
    type: type,
    staff_id: staff_id,
    showdate: showdate,
    showstartdate: showstartdate,
    showenddate: showenddate,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const featureaccess = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/salons/featureaccess`;
  //Working hour Calender view parameter
  // featureaccess
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const activeplan = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/salons/activeplan`;
  //Working hour Calender view parameter
  // featureaccess
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const countable = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = "afterlogin/salons/countable";
  const data = {
    auth_key: auth_key,
    action: action,
    type: values && values.type,
    one_off_campaign_type:values && values.one_off_campaign_type ? values.one_off_campaign_type : "",
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const mailchimpsubscribe = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = "afterlogin/mailchimpmarketing/salonsubscribe";
  const data = {
    auth_key: auth_key,
    action: action,
    id: values && values.id ? values.id : "",
    salon_id: values && values.salon_id ? values.salon_id : ""
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const smsalert = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = "afterlogin/salons/emptysmsalert";
  const data = {
    auth_key: auth_key,
    action: action,
    id: values && values.id ? values.id : "",
    salon_id: values && values.salon_id ? values.salon_id : ""
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const salonApiController = {
  view,
  isexist,
  closeddate,
  featureaccess,
  activeplan,
  countable,
  mailchimpsubscribe,
  smsalert
};
export default salonApiController;
