import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const upcomingappointment = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";

  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/dashboard/upcomingappointment?page=${page}` : `afterlogin/dashboard/upcomingappointment`;
  const data = {
      auth_key: auth_key,
      action: action,
      pagination: pagination, //true or false
      id: values && values.id ? values.id : "",
      result: result, //business_name,owner_name
      option: values && values.option ? values.option : "",
    };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const dashboardview = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const staff_id = values && values.staff_id ? values.staff_id  :"";
  const daterange = values && values.daterange ? values.daterange  :"";
  const label = values && values.label ? values.label  :"";
  const action = `afterlogin/dashboard/view`;
  
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: true, //true or false
    staff_id: staff_id,
    daterange: daterange,
    label: label,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const dashboardApiController = {
  upcomingappointment,
  dashboardview,
};

export default dashboardApiController;
