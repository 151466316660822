import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import PaginationLoader from "component/PaginationLoader";
import { ClientVoucherListViewApi } from "store/slices/clientvoucherSlice";
import config from "../../../../config";
import moment from "moment";
import ApiStatus from "component/ApiStatus";

const Voucher = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.salonmodule.isAccess);
  const voucherApiStatus = useSelector((state) => state.clientvoucher.isApiStatus);
  const voucherViews = useSelector((state) => state.clientvoucher.isListView);
  const detail = useSelector((state) => state.client.isDetailData);
  const client_id = detail.id;
  // console.log("voucherViews",voucherViews);
  useEffect(() => {
    dispatch(ClientVoucherListViewApi({ client_id: client_id }));
  }, []);
  const fetchDataVoucherList = () => {
    dispatch(ClientVoucherListViewApi({ client_id: client_id, id: detail.id, next_page_url: voucherViews.next_page_url }));
  };
  const [isFetching, setIsFetching] = useState(false);
  const loadMoreItemsList = () => {
    setIsFetching(true);
    dispatch(ClientVoucherListViewApi({ next_page_url: voucherViews && voucherViews.next_page_url, client_id: detail.id }));
    //mocking an API call
    setTimeout(() => {
      setIsFetching(false);
    }, 2000);
  };
  return (
    <>
      {voucherApiStatus && voucherApiStatus.ClientVoucherListViewApi === "succeeded" && voucherViews && voucherViews.data && voucherViews.data.length > 0 ? (
        <InfiniteScroll className="row gx-0" dataLength={voucherViews.data.length} next={fetchDataVoucherList} scrollableTarget="voucherlist" hasMore={voucherViews.next_page_url ? true : false} loader={<PaginationLoader />}>
          {Object.keys(voucherViews.data).map((item, i) => {
            let code = voucherViews.data[item].code;
            let expiry_at = voucherViews.data[item] && voucherViews.data[item].expiry_at ? moment(voucherViews.data[item].expiry_at, 'YYYY-MM-DD HH:mm:ss') : "";
            let issued_at = voucherViews.data[item] && voucherViews.data[item].created_at ? moment(voucherViews.data[item].created_at, 'YYYY-MM-DD HH:mm:ss') : "";
            let is_expired = expiry_at && moment().isAfter(expiry_at) ? 1 : 0;
            let amount = voucherViews.data[item].amount;
            let remaining_balance = voucherViews.data[item].remaining_balance;
            let toname = voucherViews.data[item].first_name + " " + voucherViews.data[item].last_name;
            let voucher_name = voucherViews.data[item].voucher && voucherViews.data[item].voucher.name ? voucherViews.data[item].voucher.name : "";
            
            const ServiceVoucher = voucherViews.data[item].service ? voucherViews.data[item].service : "";
            return (
              <div className="mb-lg-2" key={item && item.id ? item.id : i}>
                <a className="text-decoration-none event-box-pink mb-3 cursor-default">
                  <h6 className="mb-1 color-wine fw-semibold d-flex justify-content-between align-items-start">
                    {t("Code") + " : " + code}
                    {is_expired == 1 || remaining_balance == 0 ? (
                      <span className="btn btn-dark btn-sm fw-bold p-0">{t("Expired")}</span>
                    ) : (
                      <span className="btn btn-primary btn-sm fw-bold p-0">{t("Active")}</span>
                    )}
                  </h6>
                  {issued_at ? <h6 className="mb-1">{t("Issued {{date}}", { date: issued_at.format("Do MMMM YYYY") })}</h6> : ""}
                  {expiry_at ? <h6 className="mb-1">{t("Expires {{date}}", { date: expiry_at.format("Do MMMM YYYY") })}</h6> : ""}
                  <div className="row">
                    <h6 className="col-md-12 mb-1">
                      <b>{t("Name")}:</b> {voucher_name}
                    </h6>
                  </div>
                  <div className="row">
                    {/* <h6 className="col-md-6 mb-1">
                        <b>From:</b> Jo Smith
                      </h6> */}
                    <h6 className="col-md-6 mb-1">
                      <b>{t("To")}:</b> {toname}
                    </h6>
                  </div>
                  <div>
                    <div className="row">
                      <h6 className="col-6 mb-1">
                        <b>{t("Initial Value")}</b>
                      </h6>
                      <h6 className="col-6 mb-1 text-end">${amount}</h6>
                    </div>
                    <div className="row">
                      <h6 className="col-6 mb-0">
                        <b>{t("Balance")}</b>
                      </h6>
                      <h6 className="col-6 mb-0 text-end">
                        <b>${remaining_balance}</b>
                      </h6>
                      {ServiceVoucher && ServiceVoucher.name ? (
                        <h6 className="col-md-12 mb-1">
                          Service: <b>{` ${ServiceVoucher?.name}`}</b>
                        </h6>
                      ) : ""}
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
          {!isFetching && voucherViews.next_page_url && (
            <div className="col-2 m-auto p-3 text-center">
              <button onClick={loadMoreItemsList} className="btn btn-primary">
                {t("More")}
              </button>
            </div>
          )}
        </InfiniteScroll>
      ) : (
        <>
          {voucherApiStatus &&
            voucherApiStatus.ClientVoucherListViewApi === "loading" ? (
            <ApiStatus actionType="clientvoucherview" />
          ) : (
            <div className="complete-box text-center d-flex flex-column justify-content-center mt-xl-4">
              <div className="complete-box-wrp text-center">
                <img src={config.imagepath + "nots.png"} alt="" className="mb-md-4 mb-3" />
                <h5 className="mb-2 fw-semibold">{t("There are no vouchers available.")}</h5>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Voucher;
