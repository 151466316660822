import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import clientformApiController from "services/clientforms.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const ClientformApi = createAsyncThunk("clientform/listview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientformApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "listview"))
      .catch((error) => HandleError(thunkAPI, error, "listview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isListView: [],
  isclientDetailConsultationFormTab: "",
  isclientDetailConsultationForm: "",
  isApiStatus: {
    ClientformApi: "",
  },
};

const clientformSlice = createSlice({
  name: "clientform",
  initialState,
  reducers: {
    reset: () => initialState,
    ClientDetailConsultationForm: (state, action) => {
      state.isclientDetailConsultationForm = action.payload;
    },
    ClientDetailConsultationFormTab: (state, action) => {
      state.isclientDetailConsultationFormTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ClientformApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientformApi: "loading" };
      })
      .addCase(ClientformApi.fulfilled, (state, action) => {
        let old_current_page = state.isListView.current_page ? state.isListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isListView && state.isListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, ClientformApi: "succeeded" };
        state.isListView = action.payload;
      })
      .addCase(ClientformApi.rejected, (state) => {
        state.isListView = [];
        state.isApiStatus = { ...state.isApiStatus, ClientformApi: "failed" };
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, ClientDetailConsultationForm, ClientDetailConsultationFormTab } = clientformSlice.actions;
export default clientformSlice.reducer;
