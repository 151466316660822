// ==============================|| MAIN NAVBAR / HEADER ||============================== //
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ucfirst, ucwords } from "helpers/Functions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import config from "../../../config";
import ProfileSection from "./ProfileSection";
import { useTranslation } from "react-i18next";
import SearchSection from "./SearchSection";
// import { staffTabView } from "store/slices/staffSlice";
import { toggleSidebar, toggleSidebarbigScreen } from "store/slices/sidebarToggleSlice";
import { DropDownToggle, resetDropdownToggle } from "store/slices/dropdownToggleSlice";
import { NotificationListApi, UpcomingNotifyDetailDrawer, UpcomingNotifyDetailTab } from "store/slices/notificationSlice";
import NotificationDrawer from "./NotificationDrawer";
import ClockLoader from "react-spinners/ClockLoader";
import { BetaAdBanner } from "store/slices/headerSlice";

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const showSidebar = useSelector((state) => state.sidebar.showSidebar);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const MarketingSelectedView = useSelector((state) => state.marketing.isSelectedView);
  const isDropdownToggle = useSelector((state) => state.dropdown.isDropdownToggle);
  const isUpcomingNotifyDetailDrawer = useSelector((state) => state.notification.isUpcomingNotifyDetailDrawer);
  const isNotificationListView = useSelector((state) => state.notification.isNotificationListView);
  const isNotificationCount = useSelector((state) => state.notification.isNotificationCount);
  const isApiStatus = useSelector((state) => state.notification.isApiStatus);
  // const lastItem = location.pathname.substring(thePath.lastIndexOf('/') + 1)
  const lastSegment = location.pathname.split("/").pop();
  const document_title = lastSegment;
  const notificationTabs = useSelector((state) => state.notification.isUpcomingNotifyDetailTab);
  const notificationCount = isNotificationCount && !isNaN(isNotificationCount.count) ? isNotificationCount.count : 0;
  const isBetaAdBanner = useSelector((state) => state.header.isBetaAdBanner);

  useEffect(() => {
    dispatch(NotificationListApi());
  }, [notificationTabs, showSidebar]);


  useEffect(() => {
    document.title = document_title ? ucwords(document_title.replaceAll("-", " ")) : "Beauti";
  }, [document_title]);

  return (
    <>
      <header>
        {/* {isBetaAdBanner && location.pathname == "/calendar" ? (
          <div className="d-flex align-items-center justify-content-center resource-beta-version-ads-header">
            <i className="far fa-exclamation-circle text-white me-2" style={{ fontSize:"18px" }}></i>
            <span><b>Resources Beta Release - </b> We invite you to try out our Resources beta release! Now you can book resources with appointments. Send your feedback to feedback@beautisoftware.com</span>
            <i className="fas fa-times text-white ms-auto cursor-pointer" onClick={() => dispatch(BetaAdBanner(false))}></i>
          </div>
        ) : ""} */}
        <div className="d-flex align-items-center justify-content-between custom-header-padding-with-ad">
          <div className="left-col d-flex align-items-center">
            <a
              className={`d-lg-none d-inline mobile-menu-icon p-0 me-4 ${showSidebar ? '' : 'open'}`}
              onClick={() => dispatch(toggleSidebar())}
            >
              <span></span>
              <span></span>
              <span></span>
            </a>
            {/* <a className="d-lg-none d-inline mobile-menu-icon pe-2" onClick={()=>dispatch(toggleSidebar())}>
              <img src={config.imagepath + "favicon.png"} alt="" />
            </a> */}
            {lastSegment === "appointment-campaign" && MarketingSelectedView ? <h2 className="page-title mb-0">{ucwords(MarketingSelectedView.title)}</h2> : <h2 className="page-title mb-0">{ucwords(t(document_title.replaceAll("-", " ")))}</h2>}
          </div>
          <div className="rigt-col d-flex align-items-center">
            <SearchSection />
            {/* <Link to={config.basePath + "settings?tab=ClientNotifications"} className="ms-lg-4 ms-2">
              <img src={config.imagepath + "bell.png"} alt="" />
            </Link> */}
            <div className="position-relative">
              <a className="ms-lg-4 ms-2"
                onClick={() => {
                  dispatch(NotificationListApi()).then((action) => {
                    dispatch(UpcomingNotifyDetailDrawer("open"));
                    dispatch(UpcomingNotifyDetailTab("all_notification"));
                  })
                }}>
                <img src={config.imagepath + "bell.png"} alt="" style={{ width: '20px', height: '20px' }} />
                {isApiStatus && isApiStatus.NotificationListApi === "loading" ? (
                  <div className="position-absolute top-0 start-100 translate-middle p-0 m-0">
                    <ClockLoader color="#8c1c4d" size={"17px"} />
                  </div>
                ) : (
                  notificationCount > 0 ? (
                    <span className={`position-absolute top-0 start-100 translate-middle p-1 bg-primary border border-light rounded-circle text-light custom-notification-badges ${notificationCount && notificationCount > 99 ? "custom-notification-hw-adjust" : ""}`}>
                      {notificationCount !== undefined && notificationCount !== null ? notificationCount > 99 ? "99+" : notificationCount : 0}
                    </span>
                  ) : ""
                )}
              </a>
            </div>
            {/* <Link to={config.basePath + "/settings"} className="ms-lg-4 ms-2">
              <img src={config.imagepath + "setting-icon.png"} alt="" />
            </Link> */}
            <div className="position-relative ms-lg-4 ms-2">
              <a onClick={() => dispatch(DropDownToggle())}>
                <img src={config.imagepath + "setting-icon.png"} alt="" />
              </a>
              {isDropdownToggle && (
                <>
                  <div className="backdrop show" onClick={() => dispatch(resetDropdownToggle())}></div>
                  <div className="dropdown-menu-show no-left-zero">
                    {role_id !== 8 && (
                      <>
                        <Link to={config.basePath + 'settings?tab=logindetails'} className="dropdown-item" onClick={() => dispatch(resetDropdownToggle())}>
                          Login Details
                        </Link>
                        <Link to={config.basePath + 'settings?tab=myplan'} className="dropdown-item" onClick={() => dispatch(resetDropdownToggle())}>
                          My Plan
                        </Link>
                      </>
                    )}
                    <Link to={config.basePath + 'settings?tab=SMSUsage'} className="dropdown-item" onClick={() => dispatch(resetDropdownToggle())}>
                      SMS Usage
                    </Link>
                  </div>
                </>
              )}
            </div>
            <ProfileSection />
          </div>
        </div>
      </header>
      {isUpcomingNotifyDetailDrawer && <NotificationDrawer />}
    </>
  );
};

export default Header;
