import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import { checkobject } from "helpers/Functions";
import rosterApiController from "../../services/roster.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const rosterStoreApi = createAsyncThunk("roster/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await rosterApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const rosterUpdateApi = createAsyncThunk("roster/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await rosterApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const rosterListViewApi = createAsyncThunk("roster/listview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await rosterApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "listview"))
      .catch((error) => HandleError(thunkAPI, error, "listview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const rosterOptions = createAsyncThunk("roster/rosterOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await rosterApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "rosterOptions"))
      .catch((error) => HandleError(thunkAPI, error, "rosterOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const rosterDetailApi = createAsyncThunk("roster/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await rosterApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const rosterDeleteApi = createAsyncThunk("roster/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await rosterApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const DefaultStaffHourApi = createAsyncThunk("roster/DefaultStaffHourApi", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await rosterApiController
      .defaultstaffhour(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "DefaultStaffHourApi"))
      .catch((error) => HandleError(thunkAPI, error, "DefaultStaffHourApi"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

const initialState = {
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isDeleteModal: "",
  isListView: [],
  isStaffFilter: "",
  isStaffDetailsData: "",
  isDefaultStaffHour: "",
  isApiStatus: {
    rosterStoreApi: "",
    rosterUpdateApi: "",
    rosterListViewApi: "",
    rosterDeleteApi: "",
    DefaultStaffHourApi: "",
  },
};

const rosterSlice = createSlice({
  name: "roster",
  initialState,
  reducers: {
    reset: () => initialState,
    openAddRosterForm: (state = initialState, action) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = action.payload;
    },
    closeAddRosterForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = "";
    },
    openEditRosterForm: (state = initialState, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = action.payload;
    },
    closeEditRosterForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
    },
    openDeleteModal: (state, action) => {
      state.isDeleteModal = action.payload;
    },
    closeDeleteModal: (state) => {
      state.isDeleteModal = "";
    },
    staffFilter: (state, action) => {
      state.isStaffFilter = action.payload;
    },
    sendRosterdata: (state, action) => {
      state.isStaffDetailsData = action.payload;
    },
    resetStaffFilter: (state) => {
      state.isStaffFilter = "";
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(rosterStoreApi.pending, (state) => {
      state.isApiStatus.rosterStoreApi = 'loading';
    })
    .addCase(rosterStoreApi.fulfilled, (state) => {
      state.isApiStatus.rosterStoreApi = 'succeeded';
    })
    .addCase(rosterStoreApi.rejected, (state) => {
      state.isApiStatus.rosterStoreApi = 'failed';
    })
    .addCase(rosterUpdateApi.pending, (state) => {
      state.isApiStatus.rosterUpdateApi = 'loading';
    })
    .addCase(rosterUpdateApi.fulfilled, (state) => {
      state.isApiStatus.rosterUpdateApi = 'succeeded';
    })
    .addCase(rosterUpdateApi.rejected, (state) => {
      state.isApiStatus.rosterUpdateApi = 'failed';
    })
    .addCase(rosterListViewApi.pending, (state) => {
      state.isApiStatus.rosterListViewApi = 'loading';
    })
    .addCase(rosterListViewApi.fulfilled, (state, action) => {
      state.isApiStatus.rosterListViewApi = 'succeeded';
      if (checkobject(action.payload) === true) {
        state.isListView = [action.payload];
      } else {
        let old_current_page = state.isListView.current_page ? state.isListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isListView && state.isListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isListView = action.payload;
      }
    })
    .addCase(rosterListViewApi.rejected, (state) => {
      state.isApiStatus.rosterListViewApi = 'failed';
      state.isListView = [];
    })
    .addCase(rosterDeleteApi.pending, (state) => {
      state.isApiStatus.rosterDeleteApi = 'loading';
    })
    .addCase(rosterDeleteApi.fulfilled, (state) => {
      state.isApiStatus.rosterDeleteApi = 'succeeded';
    })
    .addCase(rosterDeleteApi.rejected, (state) => {
      state.isApiStatus.rosterDeleteApi = 'failed';
    })
    .addCase(DefaultStaffHourApi.pending, (state) => {
      state.isApiStatus.DefaultStaffHourApi = 'loading';
    })
    .addCase(DefaultStaffHourApi.fulfilled, (state, action) => {
      state.isApiStatus.DefaultStaffHourApi = 'succeeded';
      state.isDefaultStaffHour = action.payload;
    })
    .addCase(DefaultStaffHourApi.rejected, (state) => {
      state.isApiStatus.DefaultStaffHourApi = 'failed';
    });
  },
});
// Action creators are generated for each case reducer function
export const { reset, openAddRosterForm, closeAddRosterForm, openEditRosterForm, closeEditRosterForm, openDeleteModal, closeDeleteModal, staffFilter, resetStaffFilter, sendRosterdata } = rosterSlice.actions;
export default rosterSlice.reducer;
