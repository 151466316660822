import moment from "moment";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import config from "../config";

export const ucfirst = (str) => {
  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};
export const ucwords = (str) => {
  if (str.length) {
    const words = str.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  }
  return str;
};

export const checkobject = (objValue) => {
  return objValue && typeof objValue === "object" && objValue.constructor === Object;
};

export const ellipseText = function (text, limit) {
  if (text.length > limit) {
    let ext = text.split(".").pop();
    for (let i = limit; i > 0; i--) {
      if (text.charAt(i) === " " && (text.charAt(i - 1) != "," || text.charAt(i - 1) != "." || text.charAt(i - 1) != ";")) {
        return text.substring(0, i) + "..." + ext;
      }
    }
    return text.substring(0, limit) + "..." + ext;
  } else return text;
};

// userPermissions ,currentUser && currentUser.is_owner,currentUser && currentUser.role_id);
export const usercheckAccess = (action, currentUser) => {
  // console.log("actioncurrentUser",(currentUser.is_owner === 1),currentUser)
  if (currentUser && (currentUser.is_owner === 1 || currentUser.role_id == 8)) {
    return true; // Admin user, access is always granted
  }

  if (currentUser && (currentUser.useraccess && Array.isArray(currentUser.useraccess))) {
    // Find the permission object that matches the action and has is_access = 1
    const permission = currentUser && currentUser.useraccess.find(permission => {
      // console.log("permission",permission)
      return (
        permission &&
        permission.userpermission &&
        permission.userpermission.action === action &&
        permission.is_access === 1
      );
    });

    if (permission) {
      return true; // Access is granted
    }
  }

  return false; // Return false if userPermissions is invalid or access is not granted
};





export const checkaccess = (obj) => {
  let isaccess = false;
  const currentUser = obj.role_id;
  let hideControllerAction = ["dashboard", "staff", "reports"];
  if (currentUser.is_owner === 0 && (currentUser.role_id === 5 || currentUser.role_id === 8) && (obj.module_id || obj.controller)) {
    let salonaccess = obj.access
      .filter((list) => list.module_id === obj.module_id || list.controller === obj.controller)
      .map((list) => {
        if (currentUser.role_id === 8) {
          if (list.controller && hideControllerAction.includes(list.controller)) {
            return false;
          } else {
            return true;
          }
        } else if (currentUser.role_id != 8 && list.permission && list.permission.includes(obj.name)) {
          return true;
        }
      });
    var filtered = salonaccess.filter(function (x) {
      return x !== undefined;
    });
    // console.log(filtered);
    if (filtered.length > 0) {
      isaccess = true;
    } else if (currentUser.role_id === 8) {
      isaccess = true;
    }
  }
  if (currentUser.role_id === 5 && currentUser.is_owner === 1) {
    isaccess = true;
  }
  if (obj.module_id === 1) {
    isaccess = true;
  }
  return isaccess;
};

export const featureaccess = (obj) => {
  let isaccess = false;
  const trial_mode_access_from_config = config.trial_mode_access_from_config;
  const feature_access_data = obj.featureaccess && obj.featureaccess.feature_access_data;
  const is_trial = obj.featureaccess && obj.featureaccess.is_trial;
  if (obj && obj.name) {
    let salonaccess = feature_access_data && feature_access_data.length > 0 && feature_access_data.filter((list) => list === obj.name);
    // .map((list) => {
    //   if (list.permission && list.permission.includes(obj.name)) {
    //     return true;
    //   }
    // });
    var filtered =
      salonaccess &&
      salonaccess.filter(function (x) {
        return x !== undefined;
      });
    if (filtered && filtered.length > 0) {
      isaccess = true;
    }
    if (is_trial == 1 && trial_mode_access_from_config.includes(obj.name)) {
      isaccess = true;
    }
  }
  return isaccess;
};

export const MinutesToHours = (minutes) => {
  var Hours = Math.floor(minutes / 60);
  var minutes = minutes % 60;
  if (Hours < 10) {
    Hours = "0" + Hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return Hours + ":" + minutes;
};

export const HoursToMinutes = (timeInHour) => {
  var timeParts = timeInHour.split(":");
  return Number(timeParts[0]) * 60 + Number(timeParts[1]);
};

export const getHours = (time, format) => {
  if (format === "H:m") {
    time = HoursToMinutes(time);
  }
  var Hours = Math.floor(time / 60);
  var minutes = time % 60;
  if (Hours < 10) {
    Hours = "0" + Hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return Hours ? Hours : "";
};

export const getMinutes = (time, format) => {
  if (format === "H:m") {
    time = HoursToMinutes(time);
  }
  var Hours = Math.floor(time / 60);
  var minutes = time % 60;
  if (Hours < 10) {
    Hours = "0" + Hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return minutes ? minutes : "";
};

export const uniqueArrayofObject = (arr, keyProps) => {
  const kvArray = arr.map((entry) => {
    const key = keyProps.map((k) => entry[k]).join("|");
    return [key, entry];
  });
  const map = new Map(kvArray);
  return Array.from(map.values());
};

export const authuser = () => {
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  return currentUser;
};
export const localtimezone = () => {
  return authuser() ? authuser().salon.timezone : "";
};

export const MomentFormat = (date = "", format = "YYYY-MMMM-DD") => {
  return moment(date).format(format);
};

export const MomentFormatWithLocalTimezone = (date = "", format = "YYYY-MMMM-DD") => {
  return moment(date).format(format).tz(localtimezone());
};

export const TimePickerDropdown = () => {
  var x = 15; //minutes interval
  var times = []; // time array
  var tt = 0; // start time
  var ap = ["am", "pm"]; // AM-PM
  //loop to increment the time and push results in array
  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    var mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] = ("0" + (hh % 12)).slice(-2) + ":" + ("0" + mm).slice(-2) + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }

  const timepickerData = times.map((item) => {
    let time12hlabels = item;
    if (item == "00:00am") {
      time12hlabels = "12:00am";
    } else if (item == "00:00pm") {
      time12hlabels = "12:00pm";
    } else if (item == "00:15am") {
      time12hlabels = "12:15am";
    } else if (item == "00:15pm") {
      time12hlabels = "12:15pm";
    } else if (item == "00:30am") {
      time12hlabels = "12:30am";
    } else if (item == "00:30pm") {
      time12hlabels = "12:30pm";
    } else if (item == "00:45am") {
      time12hlabels = "12:45am";
    } else if (item == "00:45pm") {
      time12hlabels = "12:45pm";
    }
    return { id: time12hlabels, value: time12hlabels };
  });
  return timepickerData;
};
export const TimePickerDropdownBetween = (business_start_time, business_end_time) => {
  var x = 15; //minutes interval
  var times = []; // time array
  var tt = 0; // start time
  var ap = ["am", "pm"]; // AM-PM
  //loop to increment the time and push results in array
  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    var mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] = ("0" + (hh % 12)).slice(-2) + ":" + ("0" + mm).slice(-2) + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }
  var timesdata = [];
  times.map((time12h) => {
    const convertTime12to24 = moment(time12h, "hh:mma").format("HH:mm:ss");
    let business_start_time24 = business_start_time ? moment(business_start_time).format("HH:mm:ss") : "";
    let business_end_time24 = business_start_time ? moment(business_end_time).format("HH:mm:ss") : "";
    if (business_start_time24 && business_end_time24 && business_start_time24 <= convertTime12to24 && business_end_time24 >= convertTime12to24) {
      let time12hid = time12h;
      if (time12h == "00:00am") {
        time12hid = "12:00am";
      } else if (time12h == "00:00pm") {
        time12hid = "12:00pm";
      } else if (time12h == "00:15am") {
        time12hid = "12:15am";
      } else if (time12h == "00:15pm") {
        time12hid = "12:15pm";
      } else if (time12h == "00:30am") {
        time12hid = "12:30am";
      } else if (time12h == "00:30pm") {
        time12hid = "12:30pm";
      } else if (time12h == "00:45am") {
        time12hid = "12:45am";
      } else if (time12h == "00:45pm") {
        time12hid = "12:45pm";
      }
      timesdata.push({ id: time12hid, value: time12hid });
    }
  });
  return timesdata;
};

export const TimesList = (start, end, minutes_step) => {
  let dateStart = moment(`2017-08-30T${start}:00`);
  let dateEnd = moment(`2017-08-30T${end}:00`).subtract(minutes_step, "minutes");
  let times = [];
  times.push(dateStart.format("HH:mm"));
  while (times[times.length - 1] < dateEnd.format("HH:mm")) {
    times.push(dateStart.add(minutes_step, "minutes").format("HH:mm"));
  }
  return times;
};

// export const CompareArray = (arr, key, callback) => arr.reduce((prev, curr) => (callback(prev[key], curr[key]) ? prev : curr), {})[key];
export const CompareArray = (arr, key, callback) => {
  return arr && arr.reduce((prev, curr) => {
    if (prev[key] === undefined || curr[key] === undefined) {
      // Handle undefined values as needed, e.g., treat them as equal
      return callback(prev[key], curr[key]) ? prev : curr;
    }

    return callback(prev[key], curr[key]) ? prev : curr;
  }, {})[key];
};
export const decrypted = (payloaddecrypt) => {
  if (payloaddecrypt) {
    let payload = payloaddecrypt; //laravel side encrypted script (decrypt string)
    let key = config.app_key;
    let encryptStr = CryptoJS.enc.Base64.parse(payload);
    let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
    encryptData = JSON.parse(encryptData);
    let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
    var decrypted = CryptoJS.AES.decrypt(encryptData.value, CryptoJS.enc.Base64.parse(key), {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
    return decrypted;
  } else {
    return "";
  }
};

export function stripeAmountFormatToLocal(amount, currency = "") {
  if (currency && ['bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw'].includes(currency.toLowerCase())) {
    return Math.ceil(amount).toLocaleString();
  } else {
    return (amount / 100).toLocaleString(undefined, { minimumFractionDigits: 2 });
  }
}

export function stripeAmountFormat(amount, currency = "") {
  if (currency && ['bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw'].includes(currency.toLowerCase())) {
    return Math.ceil(amount).toLocaleString();
  } else {
    return (amount * 100).toLocaleString(undefined, { minimumFractionDigits: 0 });
  }
}

const Functions = {};

export default Functions;
