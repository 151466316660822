import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import categoryApiController from "../../services/category.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const categoryStoreApi = createAsyncThunk("category/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await categoryApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const categoryUpdateApi = createAsyncThunk("category/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await categoryApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const categoryListViewApi = createAsyncThunk("category/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await categoryApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const categoryOptions = createAsyncThunk("category/categoryOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await categoryApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "categoryOptions"))
      .catch((error) => HandleError(thunkAPI, error, "categoryOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const categoryDetailApi = createAsyncThunk("category/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await categoryApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const categoryDeleteApi = createAsyncThunk("category/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await categoryApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const categorySuggetionListApi = createAsyncThunk("category/suggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await categoryApiController
      .suggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedDetailModal: "",
  isopenServiceAddCategoryForm: "",
  isListView: [],
  isSuggetionListView: [],
  isDetailData: "",
  isSearchList: "",
  isSearchName: "",
  isCategoryOption: [],
  isApiStatus: {
    categoryStoreApi: "",
    categoryUpdateApi: "",
    categoryListViewApi: "",
    categorySuggetionListApi: "",
    categoryDetailApi: "",
    categoryDeleteApi: "",
    categoryOptions: "",
  },
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    reset: () => initialState,
    openAddCategoryForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = "open";
    },
    closeAddCategoryForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = "";
    },
    openServiceAddCategoryForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isopenServiceAddCategoryForm = "open";
    },
    closeServiceAddCategoryForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isopenServiceAddCategoryForm = "";
    },
    openEditCategoryForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "open";
    },
    closeEditCategoryForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
    },
    openCategoryDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "open";
    },
    closeCategoryDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "";
    },
    openCategorySearchList: (state) => {
      state.isSearchList = "open";
    },
    closeCategorysearchList: (state) => {
      state.isSearchList = "";
    },
    categorySearchName: (state, action) => {
      state.isSearchName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(categoryStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categoryStoreApi: "loading" };
      })
      .addCase(categoryStoreApi.fulfilled, (state, action) => {
        if (state.isListView && state.isListView.data) {
          state.isListView.data = [action.payload, ...state.isListView.data];
        } else {
          state.isListView = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, categoryStoreApi: "succeeded" };
      })
      .addCase(categoryStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categoryStoreApi: "failed" };
      })
      .addCase(categoryUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categoryUpdateApi: "loading" };
      })
      .addCase(categoryUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isListView.data.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, categoryUpdateApi: "succeeded" };
      })
      .addCase(categoryUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categoryUpdateApi: "failed" };
      })
      .addCase(categoryListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categoryListViewApi: "loading" };
      })
      .addCase(categoryListViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isListView.current_page ? state.isListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isListView && state.isListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, categoryListViewApi: "succeeded" };
        state.isListView = action.payload;
      })
      .addCase(categoryListViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categoryListViewApi: "failed" };
        state.isListView = [];
      })
      .addCase(categorySuggetionListApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categorySuggetionListApi: "loading" };
      })
      .addCase(categorySuggetionListApi.fulfilled, (state, action) => {
        let old_current_page = state.isSuggetionListView.current_page ? state.isSuggetionListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isSuggetionListView && state.isSuggetionListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isSuggetionListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isSuggetionListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, categorySuggetionListApi: "succeeded" };
        state.isSuggetionListView = action.payload;
      })
      .addCase(categorySuggetionListApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categorySuggetionListApi: "failed" };
        state.isSuggetionListView = [];
      })
      .addCase(categoryDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categoryDetailApi: "loading" };
      })
      .addCase(categoryDetailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, categoryDetailApi: "succeeded" };
        state.isDetailData = action.payload;
      })
      .addCase(categoryDetailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categoryDetailApi: "failed" };
        state.isDetailData = "";
      })
      .addCase(categoryDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categoryDeleteApi: "loading" };
      })
      .addCase(categoryDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isApiStatus = { ...state.isApiStatus, categoryDeleteApi: "succeeded" };
        state.isListView.data = state.isListView.data ? state.isListView.data.filter((item) => item.id != id) : [];
      })
      .addCase(categoryDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categoryDeleteApi: "failed" };
      })
      .addCase(categoryOptions.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categoryOptions: "loading" };
      })
      .addCase(categoryOptions.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, categoryOptions: "succeeded" };
        state.isCategoryOption = action.payload;
      })
      .addCase(categoryOptions.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, categoryOptions: "failed" };
        state.isCategoryOption = [];
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, openAddCategoryForm, closeAddCategoryForm, openEditCategoryForm, closeEditCategoryForm, openCategoryDetailModal, closeCategoryDetailModal, openCategorySearchList, closeCategorysearchList, categorySearchName, openServiceAddCategoryForm, closeServiceAddCategoryForm } = categorySlice.actions;
export default categorySlice.reducer;
