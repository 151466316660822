import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import consultatioformlinkApiController from "services/consultationformlink.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const consultationformDetailApi = createAsyncThunk("consultationform/view", async (formValues, thunkAPI) => {
  try {
    const resposedata = await consultatioformlinkApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "consultationform"))
      .catch((error) => HandleError(thunkAPI, error, "consultationform"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isConsultationStep: 1,
  isConsultationStepName: "",
  isDetailData: "",
  isApiStatus: {
    consultationformDetailApi: "",
    consultationformDetailApi: "",
  },
};

const consultationformSlice = createSlice({
  name: "consultationform",
  initialState,
  reducers: {
    reset: () => initialState,
    PreviewStep: (state, action) => {
      state.isConsultationStep = action.payload - 1;
    },
    NextStep: (state, action) => {
      state.isConsultationStep = action.payload + 1;
    },
    ConsultationStepName: (state, action) => {
      state.isConsultationStepName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(consultationformDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, consultationformDetailApi: "loading" };
      })
      .addCase(consultationformDetailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, consultationformDetailApi: "succeeded" };
        state.isDetailData = action.payload;
      })
      .addCase(consultationformDetailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, consultationformDetailApi: "failed" };
        state.isDetailData = "";
      });
  },
});

export const { ConsultationStepName, PreviewStep, NextStep } = consultationformSlice.actions;
export default consultationformSlice.reducer;
