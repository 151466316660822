import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import config from "../../../../config"
import { TaxValueApi } from "store/slices/taxSlice";
import { ActiveBtnState, ClientInvoiceRefundData, OpenClientInvoiceDetails, OpenClientInvoiceRefundModal, OpenPartialPaidInvoice, RefundStripeToken } from "store/slices/clientinvoiceSlice";
import { useTranslation } from "react-i18next";
import RefundFromInvoiceModal from "./RefundFromInvoiceModal";
import getStripeClient from "pages/getStripeClient";
import { Elements } from "@stripe/react-stripe-js";
import PayByStripeModalClient from "./PayByStripeModalClient";

const PartialPaidInvoice = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [totaltaxAmount, setTotalTaxAmounts] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const auth = useSelector((state) => state.auth);
    const stripePromise = getStripeClient();
    const currentUser = auth.user;
    const rightDrawerOpened = useSelector((state) => state.clientinvoice.isOpenPartialPaidInvoice);
    const isSaleCompletedData = useSelector((state) => state.clientinvoice.isClientInvoiceDetailsTabData);
    let TaxInvoiceNumber = isSaleCompletedData && isSaleCompletedData.invoice_number;
    let InvoiceDate = isSaleCompletedData && isSaleCompletedData.invoicedate;
    const formattedInvoiceDate = InvoiceDate ? moment(InvoiceDate, "YYYY-MM-DD").format('Do MMMM YYYY') : "";
    const ClientData = isSaleCompletedData && isSaleCompletedData.client;
    let client_first_name = isSaleCompletedData && isSaleCompletedData.client && ClientData.first_name;
    let client_last_name = isSaleCompletedData && isSaleCompletedData.client && ClientData.last_name;
    const clientFullName = client_first_name ? client_first_name + " " + client_last_name : "";
    const appointmentsArray = isSaleCompletedData && isSaleCompletedData.appointments ? isSaleCompletedData.appointments : [];
    const InvoiceTax = useSelector((state) => state.tax.isTaxValue);
    let taxPercentage = InvoiceTax && InvoiceTax.percentage;
    let total_pay = isSaleCompletedData && isSaleCompletedData.total_pay;
    const lastPayments = isSaleCompletedData && isSaleCompletedData?.lastpayment && isSaleCompletedData.lastpayment.length > 0 ? isSaleCompletedData.lastpayment[0] : [];
    

    useEffect(() => {
        dispatch(TaxValueApi());
    }, []);

    return (
        <div className={"drawer client-invoice-drawer border-start " + rightDrawerOpened}>
            <div className="drawer-wrp position-relative">
                <div className="drawer-header">
                    <h2 className="mb-4 pe-md-5 pe-3">Invoice</h2>
                    <a href="#" className="close" onClick={() => {
                        dispatch(OpenPartialPaidInvoice(""));
                    }}>
                        <img src={config.imagepath + "close-icon.svg"} alt="" />
                    </a>
                    <hr />
                </div>
                <div className="drawer-body d-flex flex-wrap">
                    {openModal && (
                        <>
                            <div className={openModal ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openModal ? "block" : "none" }}>
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body pb-0">
                                            <div className="p-3">
                                                <h5 className="mb-3 fw-semibold text-dark">{t("Refund Warning")}</h5>
                                                <p className="mb-3 fw-semibold">{t("Full Amount is Already Refunded For This Appointments")}</p>
                                            </div>
                                            <div className="row mt-3 bg-cream p-4 justify-content-center">
                                                <div className="col-6">
                                                    <button className="btn btn-primary w-100" type="button" onClick={() => {
                                                        setOpenModal(false);
                                                    }}>
                                                        {t("OK")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {openModal && <div className="modal-backdrop show"></div>}
                        </>
                    )}
                    <ul className="sale-comlete-data list-unstyled">
                        <li>
                            <h5 className="mb-1 fw-semibold">Tax Invoice {TaxInvoiceNumber ? TaxInvoiceNumber : "#0001"}</h5>
                            <p className="mb-0">{formattedInvoiceDate}</p>
                        </li>
                        <hr />
                        <li>
                            <label>
                                Invoice to:
                                <label>
                                    <h6 className="mb-0">{clientFullName}</h6>
                                </label>
                            </label>
                        </li>
                        <hr />
                        {appointmentsArray && appointmentsArray.length > 0 ? (
                            Object.keys(appointmentsArray).map((item, i) => {
                                let appointmentsObjects = appointmentsArray[item] && appointmentsArray[item].appointments && appointmentsArray[item].appointments.length > 0 ? appointmentsArray[item].appointments : [];
                                let ServiceName = appointmentsArray[item] && appointmentsArray[item].service && appointmentsArray[item].service.name;
                                let StaffData = appointmentsArray[item] && appointmentsArray[item].staff;
                                let StaffFirstName = appointmentsArray[item] && StaffData && StaffData.first_name;
                                let StaffLastName = appointmentsArray[item] && StaffData && StaffData.last_name;
                                let ServiceCosts = appointmentsArray[item] && appointmentsArray[item].cost;
                                let staffFullName = appointmentsArray[item] && StaffData ? StaffFirstName + " " + StaffLastName : "";
                                let serviceStartTimes = appointmentsArray[item] && appointmentsArray[item].start_time ? appointmentsArray[item].start_time : "";
                                let serviceStart = moment(serviceStartTimes, "HH:mm:ss").format("hh:mm A");
                                let serviceEndsTimes = appointmentsArray[item] && appointmentsArray[item].end_time ? appointmentsArray[item].end_time : "";
                                let serviceEnds = moment(serviceEndsTimes, "HH:mm:ss").format("hh:mm A");
                                let totalpriceTax = appointmentsArray[item] && appointmentsArray[item].sale && appointmentsArray[item].sale.totalprice ? taxPercentage ? appointmentsArray[item].sale.totalprice / taxPercentage : "" : "";
                                let tax = taxPercentage && total_pay ? total_pay / taxPercentage : "";
                                let deposit = appointmentsArray[item] && appointmentsArray[item].deposit ? appointmentsArray[item].deposit : "0";
                                let appointmentTime = appointmentsArray[item] && appointmentsArray[item].dateof ? moment(appointmentsArray[item].dateof, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
                                let cost = appointmentsArray[item] && appointmentsArray[item].cost ? appointmentsArray[item].cost : "0";
                                const AddOnServiceArray = appointmentsArray[item] && appointmentsArray[item].addonservices ? appointmentsArray[item].addonservices : [];
                                const AddAnotherServiceArray = appointmentsArray[item] && appointmentsArray[item].addanotherservices ? appointmentsArray[item].addanotherservices : [];
                                let TotalServiceCosts = appointmentsArray[item] &&
                                    (appointmentsArray[item].cost || 0) +
                                    (appointmentsArray[item].addanotherservices && appointmentsArray[item].addanotherservices.length > 0
                                        ? appointmentsArray[item].addanotherservices.reduce((sum, item) => {
                                            return sum + parseFloat(item.cost);
                                        }, 0)
                                        : 0) +
                                    (appointmentsArray[item].addonservices && appointmentsArray[item].addonservices.length > 0
                                        ? appointmentsArray[item].addonservices.reduce((sum, item) => {
                                            return sum + parseFloat(item.cost);
                                        }, 0)
                                        : 0)
                                const depositPaid = appointmentsArray[item] && appointmentsArray[item].payment.length > 0 && appointmentsArray[item].payment.filter((payment) => payment.type === "Deposit").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
                                const depositRefund = appointmentsArray[item] && appointmentsArray[item].payment.length > 0 && appointmentsArray[item].payment.filter((payment) => payment.type === "DepositRefund").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
                                const depositBalance = depositPaid >= depositRefund ? depositPaid - depositRefund : 0;
                                const Balance = TotalServiceCosts > 0 ? TotalServiceCosts - depositBalance : 0;
                                let taxAmounts = taxPercentage && TotalServiceCosts ? TotalServiceCosts / taxPercentage : "";
                                return (
                                    <React.Fragment key={i}>
                                        <li className="mb-3">
                                            <div className="row gx-1 justify-content-between">
                                                <div className="col-10">
                                                    <label htmlFor="">{ServiceName}</label>
                                                    <h6 className="mb-1">With {staffFullName} on {appointmentTime} from {serviceStart + " - " + serviceEnds}</h6>
                                                    <span>Quantity: 1</span>
                                                </div>
                                                <label className="col-2 text-end">${cost}</label>
                                            </div>
                                        </li>
                                        {AddOnServiceArray && AddOnServiceArray.length > 0 ? (
                                            <>
                                                {/* <hr /> */}
                                                {/* <li className="mb-3">
                                                    <div className="row gx-1 justify-content-between">
                                                        <div className="col-12">
                                                            <label htmlFor="">Add On Services</label>
                                                        </div>
                                                    </div>
                                                </li> */}
                                                {AddOnServiceArray.map((item, i) => {
                                                    const addonServiceName = item && item.service && item.service.name;
                                                    let addonstaffFullName = item && item.staff && (item.staff.first_name && item.staff.last_name) ? item.staff.first_name + " " + item.staff.last_name : "";
                                                    let addonserviceStartTimes = item && item.start_time ? item.start_time : "";
                                                    let addonserviceStart = moment(addonserviceStartTimes, "HH:mm:ss").format("hh:mm A");
                                                    let addonserviceEndsTimes = item && item.end_time ? item.end_time : "";
                                                    let addonserviceEnds = moment(addonserviceEndsTimes, "HH:mm:ss").format("hh:mm A");
                                                    let addonappointmentTime = item && item.dateof ? moment(item.dateof, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
                                                    let addonTotalPay = item && item.cost ? item.cost : "";

                                                    return (
                                                        <React.Fragment key={i}>
                                                            <hr />
                                                            <li className="mb-3">
                                                                <div className="row gx-1 justify-content-between">
                                                                    <div className="col-10">
                                                                        <label htmlFor="">{addonServiceName ? addonServiceName : ""}</label>
                                                                        <h6 className="mb-1">With {addonstaffFullName} on {addonappointmentTime} from {addonserviceStart + " - " + addonserviceEnds}</h6>
                                                                        <span>Quantity: 1</span>
                                                                    </div>
                                                                    <label className="col-2 text-end">${addonTotalPay}</label>
                                                                </div>
                                                            </li>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </>
                                        ) : ""}
                                        {AddAnotherServiceArray && AddAnotherServiceArray.length > 0 ? (
                                            <>
                                                {/* <hr />
                                                <li className="mb-3">
                                                    <div className="row gx-1 justify-content-between">
                                                        <div className="col-12">
                                                            <label htmlFor="">Add Another Service</label>
                                                        </div>
                                                    </div>
                                                </li> */}
                                                {AddAnotherServiceArray.map((item, i) => {
                                                    const addanotherServiceName = item && item.service && item.service.name;
                                                    let addanotherstaffFullName = item && item.staff && (item.staff.first_name && item.staff.last_name) ? item.staff.first_name + " " + item.staff.last_name : "";
                                                    let addanotherserviceStartTimes = item && item.start_time ? item.start_time : "";
                                                    let addanotherserviceStart = moment(addanotherserviceStartTimes, "HH:mm:ss").format("hh:mm A");
                                                    let addanotherserviceEndsTimes = item && item.end_time ? item.end_time : "";
                                                    let addanotherserviceEnds = moment(addanotherserviceEndsTimes, "HH:mm:ss").format("hh:mm A");
                                                    let addanotherappointmentTime = item && item.dateof ? moment(item.dateof, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
                                                    let addanotherTotalPay = item && item.cost ? item.cost : "";

                                                    return (
                                                        <React.Fragment key={i}>
                                                            <hr />
                                                            <li className="mb-3">
                                                                <div className="row gx-1 justify-content-between">
                                                                    <div className="col-10">
                                                                        <label htmlFor="">{addanotherServiceName ? addanotherServiceName : ""}</label>
                                                                        <h6 className="mb-1">With {addanotherstaffFullName} on {addanotherappointmentTime} from {addanotherserviceStart + " - " + addanotherserviceEnds}</h6>
                                                                        <span>Quantity: 1</span>
                                                                    </div>
                                                                    <label className="col-2 text-end">${addanotherTotalPay}</label>
                                                                </div>
                                                            </li>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </>
                                        ) : ""}
                                        {currentUser && currentUser.salon && currentUser.salon.tax && currentUser.salon.tax.name === "Non-GST" ? "" : (
                                            <li className="my-3">
                                                <div className="row gx-1 justify-content-between">
                                                    <div className="col-10">
                                                        <h6 className="mb-0">Includes GST of</h6>
                                                    </div>
                                                    <h6 className="mb-0 col-2 text-end">${taxAmounts ? parseFloat(taxAmounts).toFixed(2) : "0"}</h6>
                                                </div>
                                            </li>
                                        )}
                                        <li className="total py-3">
                                            <div className="row gx-1 justify-content-between">
                                                <div className="col-10">
                                                    <h6 className="mb-0">Total Cost</h6>
                                                </div>
                                                <h6 className="mb-0 col-2 text-end">${TotalServiceCosts ? TotalServiceCosts : "0"}</h6>
                                            </div>
                                        </li>
                                        {/* {lastPayments && lastPayments.id && (
                                            <>
                                                <li className="py-3">
                                                    <div className="row gx-1 justify-content-between">
                                                        <div className="col-10">
                                                            <h6 className="mb-0">{`Payment By ${lastPayments.paidby ? lastPayments.paidby : ""}`}</h6>
                                                        </div>
                                                        <h6 className="mb-0 col-2 text-end">${deposit}</h6>
                                                    </div>
                                                </li>
                                                <hr className="my-0" />
                                            </>
                                        )} */}
                                        {appointmentsArray[item] && appointmentsArray[item].payment && appointmentsArray[item].payment.length > 0 ? (
                                            appointmentsArray[item].payment.map((paymentItem,i) => {
                                                const paidByStatus = paymentItem && paymentItem.paidby ? paymentItem.paidby : "";
                                                const amount = paymentItem && paymentItem.amount ? paymentItem.amount : "";
                                                const paidDate = paymentItem && paymentItem.created_at ? moment(paymentItem.created_at, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("DD/MM/YYYY") : "";
                                                if (paymentItem && paymentItem.type === "Deposit") {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <li className="py-3">
                                                                <div className="row gx-1 justify-content-between">
                                                                    <div className="col-10">
                                                                        <h6 className="mb-0">{`Deposit Paid by ${paidByStatus} ${paidDate}`}</h6>
                                                                    </div>
                                                                    <h6 className="mb-0 col-2 text-end">${amount}</h6>
                                                                </div>
                                                            </li>
                                                            <hr className="my-0" />
                                                        </React.Fragment>
                                                    )
                                                } else {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <li className="py-3">
                                                                <div className="row gx-1 justify-content-between">
                                                                    <div className="col-10">
                                                                        <h6 className="mb-0">{`Refund By ${paidByStatus} ${paidDate}`}</h6>
                                                                    </div>
                                                                    <h6 className="mb-0 col-2 text-end">${amount}</h6>
                                                                </div>
                                                            </li>
                                                            <hr className="my-0" />
                                                        </React.Fragment>
                                                    )
                                                }
                                            })
                                        ) : ""}
                                        <li className="py-3">
                                            <div className="row gx-1 justify-content-between">
                                                <div className="col-10">
                                                    <h6 className="mb-0">Balance</h6>
                                                </div>
                                                <h6 className="mb-0 col-2 text-end">${(TotalServiceCosts ?? 0) - (depositPaid ?? 0)}</h6>
                                                {/* <h6 className="mb-0 col-2 text-end">${TotalServiceCosts - depositPaid + depositRefund}</h6> */}
                                                {/* <h6 className="mb-0 col-2 text-end">${Balance}</h6> */}
                                            </div>
                                        </li>
                                        {depositRefund && depositRefund > 0 ? (
                                            <>
                                                <hr className="my-0" />
                                                <li className="py-3">
                                                    <div className="row gx-1 justify-content-between">
                                                        <label className="mb-0 fw-normal col-10">Refund issued</label>
                                                        <label className="mb-0 fw-normal col-2 text-end">${depositRefund ? depositRefund : 0}</label>
                                                    </div>
                                                </li>
                                            </>
                                        ) : ""}
                                        {depositBalance && depositBalance > 0 ? (
                                            <li className="my-2">
                                            <div className="row gx-1 justify-content-between">
                                                <button
                                                    type="button"
                                                    className="btn btn-apricots cursor-pointer"
                                                    onClick={() => {
                                                        if (depositBalance && depositBalance > 0) {
                                                            dispatch(RefundStripeToken(""));
                                                            dispatch(ActiveBtnState(""));
                                                            dispatch(ClientInvoiceRefundData({ isSaleCompletedData: isSaleCompletedData, AppointmentsData: appointmentsArray[item], balance: depositBalance }))
                                                            dispatch(OpenClientInvoiceRefundModal("open"));
                                                        } else {
                                                            setOpenModal(true);
                                                        }
                                                    }}
                                                >
                                                    {t("Refund")}
                                                </button>
                                            </div>
                                        </li>
                                        ) : ""}
                                    </React.Fragment>
                                )
                            })
                        ) : null}
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default PartialPaidInvoice;



