import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import salonApiController from "services/salon.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const SalonDetailApi = createAsyncThunk("salon/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await salonApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const IsExistApi = createAsyncThunk("salon/isexist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await salonApiController
      .isexist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "isexist"))
      .catch((error) => HandleError(thunkAPI, error, "isexist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClosedDateApi = createAsyncThunk("salon/closeddateapi", async (formValues, thunkAPI) => {
  try {
    const resposedata = await salonApiController
      .closeddate(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "closeddateapi"))
      .catch((error) => HandleError(thunkAPI, error, "closeddateapi"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const FeatureAccessApi = createAsyncThunk("salon/salonfeatureaccess", async (formValues, thunkAPI) => {
  try {
    const resposedata = await salonApiController
      .featureaccess(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "salonfeatureaccess"))
      .catch((error) => HandleError(thunkAPI, error, "salonfeatureaccess"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ActivePlanApi = createAsyncThunk("salon/salonactiveplan", async (formValues, thunkAPI) => {
  try {
    const resposedata = await salonApiController
      .activeplan(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "salonactiveplan"))
      .catch((error) => HandleError(thunkAPI, error, "salonactiveplan"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const CountableApi = createAsyncThunk("salon/countable", async (formValues, thunkAPI) => {
  try {
    const resposedata = await salonApiController
      .countable(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "countable"))
      .catch((error) => HandleError(thunkAPI, error, "countable"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const MailChimpSubscriptionApi = createAsyncThunk("salon/mailchimpsubscribe", async (formValues, thunkAPI) => {
  try {
    const resposedata = await salonApiController
      .mailchimpsubscribe(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "salonmailchimpsubscribe"))
      .catch((error) => HandleError(thunkAPI, error, "salonmailchimpsubscribe"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const EmptySmsAlertApi = createAsyncThunk("salon/smsalert", async (formValues, thunkAPI) => {
  try {
    const resposedata = await salonApiController
      .smsalert(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "smsalert"))
      .catch((error) => HandleError(thunkAPI, error, "smsalert"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isDetailData: "",
  isExistError: { email: "", business_name: "", business_phone_number: "" },
  isClosedDate: [],
  isFeatureAccess: [],
  isActivePlan: "",
  isUpgradePlanModal: "",
  isComingSoonAlertModal:"",
  isCountable:"",
  isSmsAlertData:"",
  isApiStatus: {
    SalonDetailApi: "",
    IsExistApi: "",
    ClosedDateApi: "",
    FeatureAccessApi: "",
    ActivePlanApi: "",
    CountableApi:"",
    MailChimpSubscriptionApi:"",
    EmptySmsAlertApi:""
  },
};

const salonSlice = createSlice({
  name: "salon",
  initialState,
  reducers: {
    reset: () => initialState,
    ExistError: (state, action) => {
      state.isExistError = { ...state.isExistError, ...action.payload };
    },
    UpgradePlanModal: (state, action) => {
      state.isUpgradePlanModal = action.payload;
    },
    ComingSoonAlertModal: (state, action) => {
      state.isComingSoonAlertModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(SalonDetailApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SalonDetailApi: "loading" };
    })
    .addCase(SalonDetailApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, SalonDetailApi: "succeeded" };
      state.isDetailData = action.payload;
    })
    .addCase(SalonDetailApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SalonDetailApi: "failed" };
      state.isDetailData = "";
    })
    .addCase(IsExistApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, IsExistApi: "loading" };
    })
    .addCase(IsExistApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, IsExistApi: "succeeded" };
      state.isExistApi = action.payload;
    })
    .addCase(IsExistApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, IsExistApi: "failed" };
    })
    .addCase(ClosedDateApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, ClosedDateApi: "loading" };
    })
    .addCase(ClosedDateApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, ClosedDateApi: "succeeded" };
      state.isClosedDate = action.payload;
    })
    .addCase(ClosedDateApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, ClosedDateApi: "failed" };
      state.isClosedDate = "";
    })
    .addCase(FeatureAccessApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, FeatureAccessApi: "loading" };
    })
    .addCase(FeatureAccessApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, FeatureAccessApi: "succeeded" };
      state.isFeatureAccess = action.payload;
    })
    .addCase(FeatureAccessApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, FeatureAccessApi: "failed" };
      state.isFeatureAccess = "";
    })
    .addCase(ActivePlanApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, ActivePlanApi: "loading" };
    })
    .addCase(ActivePlanApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, ActivePlanApi: "succeeded" };
      state.isActivePlan = action.payload;
    })
    .addCase(ActivePlanApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, ActivePlanApi: "failed" };
      state.isActivePlan = "";
    })
    .addCase(CountableApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, CountableApi: "loading" };
    })
    .addCase(CountableApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, CountableApi: "succeeded" };
      state.isCountable = action.payload;
    })
    .addCase(CountableApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, CountableApi: "failed" };
    })
    .addCase(MailChimpSubscriptionApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, MailChimpSubscriptionApi: "loading" };
    })
    .addCase(MailChimpSubscriptionApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, MailChimpSubscriptionApi: "succeeded" };
    })
    .addCase(MailChimpSubscriptionApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, MailChimpSubscriptionApi: "failed" };
    })
    .addCase(EmptySmsAlertApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, EmptySmsAlertApi: "loading" };
    })
    .addCase(EmptySmsAlertApi.fulfilled, (state, action) => {
      state.isSmsAlertData = action.payload;
      state.isApiStatus = { ...state.isApiStatus, EmptySmsAlertApi: "succeeded" };
    })
    .addCase(EmptySmsAlertApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, EmptySmsAlertApi: "failed" };
      state.isSmsAlertData = "";
    });
  },
});
// Action creators are generated for each case reducer function
export const { reset, ExistError, UpgradePlanModal,ComingSoonAlertModal } = salonSlice.actions;
export default salonSlice.reducer;
