import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import voucherApiController from "../../services/voucher.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const VoucherStoreApi = createAsyncThunk("voucher/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await voucherApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const VoucherUpdateApi = createAsyncThunk("voucher/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await voucherApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const VoucherGridViewApi = createAsyncThunk("voucher/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await voucherApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const VoucherOptions = createAsyncThunk("voucher/VoucherOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await voucherApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "VoucherOptions"))
      .catch((error) => HandleError(thunkAPI, error, "VoucherOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const VoucherDetailApi = createAsyncThunk("voucher/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await voucherApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const VoucherDeleteApi = createAsyncThunk("voucher/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await voucherApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const VoucherSuggetionListApi = createAsyncThunk("voucher/suggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await voucherApiController
      .suggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const VoucherServiceListApi = createAsyncThunk("voucher/servicelist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await voucherApiController
      .serviceslist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "voucherservicelist"))
      .catch((error) => HandleError(thunkAPI, error, "voucherservicelist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const VoucherServiceCostApi = createAsyncThunk("voucher/servicecost", async (formValues, thunkAPI) => {
  try {
    const resposedata = await voucherApiController
      .serviceprice(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "voucherservicecost"))
      .catch((error) => HandleError(thunkAPI, error, "voucherservicecost"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


const initialState = {
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedDetailModal: "",
  isGridView: [],
  isSuggetionListView: [],
  isDetailData: "",
  isSearchList: "",
  isSearchName: "",
  isvoucherOption: [],
  isVoucherPreview: [],
  isVoucherServiceList: [],
  isOpenVoucherImportModal: "",
  isVoucherServiceCost:"",
  isApiStatus: {
    VoucherStoreApi: "",
    VoucherUpdateApi: "",
    VoucherGridViewApi: "",
    VoucherDetailApi: "",
    VoucherDeleteApi: "",
    VoucherOptions: "",
    VoucherSuggetionListApi: "",
    VoucherServiceListApi: "",
    VoucherSuggetionListApi: "",
    VoucherServiceCostApi:""
  },
};

const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenAddVoucherForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = "open";
    },
    CloseAddVoucherForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = "";
    },
    OpenEditVoucherForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "open";
    },
    CloseEditVoucherForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
    },
    OpenVoucherDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "open";
    },
    CloseVoucherDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "";
    },
    setVoucherPreview: (state, action) => {
      state.isVoucherPreview = action.payload;
    },
    openVoucherSearchList: (state) => {
      state.isSearchList = "open";
    },
    closeVoucherSearchList: (state) => {
      state.isSearchList = "";
    },
    voucherSearchName: (state, action) => {
      state.isSearchName = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(VoucherStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, VoucherStoreApi: "loading" };
      })
      .addCase(VoucherStoreApi.fulfilled, (state, action) => {
        if (state.isGridView && state.isGridView.data) {
          state.isGridView.data = [action.payload, ...state.isGridView.data];
        } else {
          state.isGridView = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, VoucherStoreApi: "succeeded" };
      })
      .addCase(VoucherStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, VoucherStoreApi: "failed" };
      })
      .addCase(VoucherUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, VoucherUpdateApi: "loading" };
      })
      .addCase(VoucherUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isGridView.data.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, VoucherUpdateApi: "succeeded" };
      })
      .addCase(VoucherUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, VoucherUpdateApi: "failed" };
      })
      // Continue adding cases for other actions
      .addCase(VoucherGridViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, VoucherGridViewApi: "loading" };
      })
      .addCase(VoucherGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isGridView.current_page ? state.isGridView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isGridView && state.isGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, VoucherGridViewApi: "succeeded" };
        state.isGridView = action.payload;
      })
      .addCase(VoucherGridViewApi.rejected, (state) => {
        state.isGridView = [];
        state.isApiStatus = { ...state.isApiStatus, VoucherGridViewApi: "failed" };
      })
      .addCase(VoucherDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, VoucherDetailApi: "loading" };
      })
      .addCase(VoucherDetailApi.fulfilled, (state, action) => {
        state.isDetailData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, VoucherDetailApi: "succeeded" };
      })
      .addCase(VoucherDetailApi.rejected, (state) => {
        state.isDetailData = "";
        state.isApiStatus = { ...state.isApiStatus, VoucherDetailApi: "failed" };
      })
      .addCase(VoucherDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, VoucherDeleteApi: "loading" };
      })
      .addCase(VoucherDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isGridView.data = state.isGridView.data ? state.isGridView.data.filter((item) => item.id != id) : state.isGridView.filter((item) => item.id != id);
        state.isApiStatus = { ...state.isApiStatus, VoucherDeleteApi: "succeeded" };
      })
      .addCase(VoucherDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, VoucherDeleteApi: "failed" };
      })
      .addCase(VoucherOptions.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, VoucherOptions: "loading" };
      })
      .addCase(VoucherOptions.fulfilled, (state, action) => {
        state.isvoucherOption = action.payload;
        state.isApiStatus = { ...state.isApiStatus, VoucherOptions: "succeeded" };
      })
      .addCase(VoucherOptions.rejected, (state) => {
        state.isvoucherOption = [];
        state.isApiStatus = { ...state.isApiStatus, VoucherOptions: "failed" };
      })
      .addCase(VoucherSuggetionListApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, VoucherSuggetionListApi: "loading" };
      })
      .addCase(VoucherSuggetionListApi.fulfilled, (state, action) => {
        let old_current_page = state.isSuggetionListView.current_page ? state.isSuggetionListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isSuggetionListView && state.isSuggetionListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isSuggetionListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isSuggetionListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isSuggetionListView = action.payload;
        state.isApiStatus = { ...state.isApiStatus, VoucherSuggetionListApi: "succeeded" };
      })
      .addCase(VoucherSuggetionListApi.rejected, (state) => {
        state.isSuggetionListView = [];
        state.isApiStatus = { ...state.isApiStatus, VoucherSuggetionListApi: "failed" };
      })
      .addCase(VoucherServiceCostApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, VoucherServiceCostApi: "loading" };
      })
      .addCase(VoucherServiceCostApi.fulfilled, (state, action) => {
        state.isVoucherServiceCost = action.payload;
        state.isApiStatus = { ...state.isApiStatus, VoucherServiceCostApi: "succeeded" };
      })
      .addCase(VoucherServiceCostApi.rejected, (state) => {
        state.isVoucherServiceCost = "";
        state.isApiStatus = { ...state.isApiStatus, VoucherServiceCostApi: "failed" };
      })
      .addCase(VoucherServiceListApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, VoucherServiceListApi: "loading" };
      })
      .addCase(VoucherServiceListApi.fulfilled, (state, action) => {
        state.isVoucherServiceList = action.payload;
        state.isApiStatus = { ...state.isApiStatus, VoucherServiceListApi: "succeeded" };
      })
      .addCase(VoucherServiceListApi.rejected, (state) => {
        state.isVoucherServiceList = [];
        state.isApiStatus = { ...state.isApiStatus, VoucherServiceListApi: "failed" };
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, OpenAddVoucherForm, CloseAddVoucherForm, OpenEditVoucherForm, CloseEditVoucherForm, OpenVoucherDetailModal, CloseVoucherDetailModal, openVoucherSearchList, closeVoucherSearchList, voucherSearchName, setVoucherPreview } = voucherSlice.actions;
export default voucherSlice.reducer;
