import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import clientApiController from "../../services/client.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const ClientStoreApi = createAsyncThunk("client/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const ClientUpdateApi = createAsyncThunk("client/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const ClientListViewApi = createAsyncThunk("client/listview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "listview"))
      .catch((error) => HandleError(thunkAPI, error, "listview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientGridViewApi = createAsyncThunk("client/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientDetailApi = createAsyncThunk("client/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientDeleteApi = createAsyncThunk("client/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientSuggetionListApi = createAsyncThunk("client/suggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .suggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientImportApi = createAsyncThunk("client/Clientimport", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .clientimport(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Clientimport"))
      .catch((error) => HandleError(thunkAPI, error, "Clientimport"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientExportApi = createAsyncThunk("client/Clientexport", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .clientexport(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Clientexport"))
      .catch((error) => HandleError(thunkAPI, error, "Clientexport"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientSyncWithMailChimpsApi = createAsyncThunk("client/ClientSyncMailchimp", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .clientmailchimpsync(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "ClientSyncMailchimp"))
      .catch((error) => HandleError(thunkAPI, error, "ClientSyncMailchimp"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientVoucherImportApi = createAsyncThunk("voucher/Voucherimport", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .clientvoucherimport(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Voucherimport"))
      .catch((error) => HandleError(thunkAPI, error, "Voucherimport"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientSampleVoucherApi = createAsyncThunk("voucher/Vouchersample", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .clientvouchersample(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Vouchersample"))
      .catch((error) => HandleError(thunkAPI, error, "Vouchersample"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientVoucherExportApi = createAsyncThunk("voucher/voucherexport", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientApiController
      .clientvoucherexports(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "voucherexport"))
      .catch((error) => HandleError(thunkAPI, error, "voucherexport"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


const initialState = {
  isTabView: "grid",
  isFromOpenAddClientForm: "",
  isOpenedAddForm: "",
  isOpenedDetailModal: "",
  isGridView: [],
  isListView: [],
  isSuggetionListView: [],
  isDetailData: "",
  isClientDetailTab: "",
  isSort: "",
  isSearchList: "",
  isSearchName: "",
  isSearchObj: "",
  isClientDataToSale: "",
  isSetClientAppointmentCalendar: "",
  isOpenClientInvoiceImportModal: "",
  isOpenClientVoucherImportModal: "",
  isApiStatus: {
    ClientStoreApi: "",
    ClientUpdateApi: "",
    ClientGridViewApi: "",
    ClientListViewApi: "",
    ClientSuggetionListApi: "",
    ClientDetailApi: "",
    ClientDeleteApi: "",
    ClientImportApi: "",
    ClientExportApi: "",
    ClientSyncWithMailChimpsApi: "",
    ClientVoucherImportApi: "",
    ClientSampleVoucherApi: "",
    ClientVoucherExportApi: ""
  },
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    reset: () => initialState,
    ClientTabListView: (state) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "";
      state.isTabView = "list";
    },
    ClientTabGridView: (state) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "";
      state.isTabView = "grid";
    },
    FromOpenAddClientForm: (state, action) => {
      state.isOpenedDetailModal = "";
      state.isFromOpenAddClientForm = action.payload;
    },
    ResetFromOpenAddClientForm: (state, action) => {
      state.isOpenedDetailModal = "";
      state.isFromOpenAddClientForm = "";
    },
    OpenAddClientForm: (state, action) => {
      state.isOpenedDetailModal = "";
      state.isOpenedAddForm = action.payload;
    },
    CloseAddClientForm: (state, action) => {
      state.isOpenedDetailModal = "";
      state.isOpenedAddForm = action.payload;
    },
    OpenClientDetailModal: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = action.payload;
    },
    CloseClientDetailModal: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = action.payload;
    },
    ClientDetailTab: (state, action) => {
      state.isClientDetailTab = action.payload;
    },
    ClientSort: (state, action) => {
      let sort = state.isSort ? state.isSort : {};
      state.isSort = Object.assign(sort, action.payload);
    },
    ClientSortRemove: (state) => {
      state.isSort = "";
    },
    OpenClientSearchList: (state) => {
      state.isSearchList = "open";
    },
    CloseClientSearchList: (state) => {
      state.isSearchList = "";
    },
    ClientSearchName: (state, action) => {
      state.isSearchName = action.payload;
    },
    ClientSearchObj: (state, action) => {
      state.isSearchObj = action.payload;
    },
    ClientDataToSale: (state, action) => {
      state.isClientDataToSale = action.payload;
    },
    SetClientAppointmentCalendar: (state, action) => {
      state.isSetClientAppointmentCalendar = action.payload;
    },
    OpenClientInvoiceImportModal: (state, action) => {
      state.isOpenClientInvoiceImportModal = action.payload;
    },
    OpenClientVoucherImportModal: (state, action) => {
      state.isOpenClientVoucherImportModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ClientStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientStoreApi: "loading" };
      })
      .addCase(ClientStoreApi.fulfilled, (state, action) => {
        if (state.isGridView && state.isGridView.data) {
          state.isGridView.data = [action.payload, ...state.isGridView.data];
        } else {
          state.isGridView = { data: [action.payload] };
        }
        if (state.isListView && state.isListView.data) {
          state.isListView.data = [action.payload, ...state.isListView.data];
        } else {
          state.isListView = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, ClientStoreApi: "succeeded" };
      })
      .addCase(ClientStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientStoreApi: "failed" };
      })
      .addCase(ClientUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientUpdateApi: "loading" };
      })
      .addCase(ClientUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        let isGridView = state.isGridView && state.isGridView.data ? state.isGridView.data : state.isGridView;
        let isListView = state.isListView && state.isListView.data ? state.isListView.data : state.isListView;
        const existingGridData = isGridView ? isGridView.find((event) => event.id === id) : "";
        const existingListData = isListView ? isListView.find((event) => event.id === id) : "";
        if (existingGridData) {
          Object.keys(changes).map((keyName) => {
            existingGridData[keyName] = changes[keyName];
          });
        }
        if (existingListData) {
          Object.keys(changes).map((keyName) => {
            existingListData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, ClientUpdateApi: "succeeded" };
      })
      .addCase(ClientUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientUpdateApi: "failed" };
      })
      .addCase(ClientGridViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientGridViewApi: "loading" };
      })
      .addCase(ClientGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isGridView.current_page ? state.isGridView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isGridView && state.isGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, ClientGridViewApi: "succeeded" };
        state.isGridView = action.payload;
      })
      .addCase(ClientGridViewApi.rejected, (state) => {
        state.isGridView = [];
        state.isApiStatus = { ...state.isApiStatus, ClientGridViewApi: "failed" };
      })
      .addCase(ClientListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientListViewApi: "loading" };
      })
      .addCase(ClientListViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isListView.current_page ? state.isListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isListView && state.isListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isListView = action.payload;
        state.isApiStatus = { ...state.isApiStatus, ClientListViewApi: "succeeded" };
      })
      .addCase(ClientListViewApi.rejected, (state) => {
        state.isListView = [];
        state.isApiStatus = { ...state.isApiStatus, ClientListViewApi: "failed" };
      })

      .addCase(ClientSuggetionListApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientSuggetionListApi: "loading" };
      })
      .addCase(ClientSuggetionListApi.fulfilled, (state, action) => {
        let old_current_page = state.isSuggetionListView.current_page ? state.isSuggetionListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isSuggetionListView && state.isSuggetionListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isSuggetionListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isSuggetionListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isSuggetionListView = action.payload;
        state.isApiStatus = { ...state.isApiStatus, ClientSuggetionListApi: "succeeded" };
      })
      .addCase(ClientSuggetionListApi.rejected, (state) => {
        state.isSuggetionListView = [];
        state.isApiStatus = { ...state.isApiStatus, ClientSuggetionListApi: "failed" };
      })
      .addCase(ClientDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientDetailApi: "loading" };
      })
      .addCase(ClientDetailApi.fulfilled, (state, action) => {
        state.isDetailData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, ClientDetailApi: "succeeded" };
      })
      .addCase(ClientDetailApi.rejected, (state) => {
        state.isDetailData = "";
        state.isApiStatus = { ...state.isApiStatus, ClientDetailApi: "failed" };
      })
      .addCase(ClientDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientDeleteApi: "loading" };
      })
      .addCase(ClientDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isGridView.data = state.isGridView.data ? state.isGridView.data.filter((item) => item.id != id) : state.isGridView.filter((item) => item.id != id);
        state.isListView.data = state.isListView.data ? state.isListView.data.filter((item) => item.id != id) : state.isListView.filter((item) => item.id != id);
        state.isApiStatus = { ...state.isApiStatus, ClientDeleteApi: "succeeded" };
      })
      .addCase(ClientDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientDeleteApi: "failed" };
      })
      .addCase(ClientImportApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientImportApi: "loading" };
      })
      .addCase(ClientImportApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientImportApi: "succeeded" };
      })
      .addCase(ClientImportApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientImportApi: "failed" };
      })
      .addCase(ClientExportApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientExportApi: "loading" };
      })
      .addCase(ClientExportApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientExportApi: "succeeded" };
      })
      .addCase(ClientExportApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientExportApi: "failed" };
      })
      .addCase(ClientSyncWithMailChimpsApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientSyncWithMailChimpsApi: "loading" };
      })
      .addCase(ClientSyncWithMailChimpsApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientSyncWithMailChimpsApi: "succeeded" };
      })
      .addCase(ClientSyncWithMailChimpsApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientSyncWithMailChimpsApi: "failed" };
      })
      .addCase(ClientVoucherImportApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientVoucherImportApi: "loading" };
      })
      .addCase(ClientVoucherImportApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, ClientVoucherImportApi: "succeeded" };
      })
      .addCase(ClientVoucherImportApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientVoucherImportApi: "failed" };
      })
      .addCase(ClientSampleVoucherApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientSampleVoucherApi: "loading" };
      })
      .addCase(ClientSampleVoucherApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, ClientSampleVoucherApi: "succeeded" };
      })
      .addCase(ClientSampleVoucherApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientSampleVoucherApi: "failed" };
      })
      .addCase(ClientVoucherExportApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientVoucherExportApi: "loading" };
      })
      .addCase(ClientVoucherExportApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, ClientVoucherExportApi: "succeeded" };
      })
      .addCase(ClientVoucherExportApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientVoucherExportApi: "failed" };
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, ClientTabListView, ClientTabGridView, FromOpenAddClientForm, OpenAddClientForm, CloseAddClientForm, OpenClientDetailModal, CloseClientDetailModal, ClientDetailTab, ClientSort, ClientSortRemove, OpenClientSearchList, CloseClientSearchList, ClientSearchName, ClientSearchObj, ClientDataToSale, SetClientAppointmentCalendar, OpenClientInvoiceImportModal, ResetFromOpenAddClientForm, OpenClientVoucherImportModal } = clientSlice.actions;
export default clientSlice.reducer;
