import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import taxApiController from "../../services/tax.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const usersAdapter = createEntityAdapter();

export const taxOptions = createAsyncThunk("tax/taxOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await taxApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "taxOptions"))
      .catch((error) => HandleError(thunkAPI, error, "taxOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const TaxValueApi = createAsyncThunk("tax/taxValue", async (formValues, thunkAPI) => {
  try {
    const resposedata = await taxApiController
      .taxvalue(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "taxValue"))
      .catch((error) => HandleError(thunkAPI, error, "taxValue"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isTaxOption: [],
  isTaxValue: [],
  isApiStatus: {
    taxOptions: "",
    TaxValueApi: "",
  },
};

const taxSlice = createSlice({
  name: "tax",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(taxOptions.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, taxOptions: "loading" };
      })
      .addCase(taxOptions.fulfilled, (state, action) => {
        state.isTaxOption = action.payload;
        state.isApiStatus = { ...state.isApiStatus, taxOptions: "succeeded" };
      })
      .addCase(taxOptions.rejected, (state) => {
        state.isTaxOption = [];
        state.isApiStatus = { ...state.isApiStatus, taxOptions: "failed" };
      })
      .addCase(TaxValueApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, TaxValueApi: "loading" };
      })
      .addCase(TaxValueApi.fulfilled, (state, action) => {
        state.isTaxValue = action.payload;
        state.isApiStatus = { ...state.isApiStatus, TaxValueApi: "succeeded" };
      })
      .addCase(TaxValueApi.rejected, (state) => {
        state.isTaxValue = [];
        state.isApiStatus = { ...state.isApiStatus, TaxValueApi: "failed" };
      });
  }
});
// Action creators are generated for each case reducer function
export const { reset } = taxSlice.actions;
export default taxSlice.reducer;
