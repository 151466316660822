import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import usermoduleApiController from "../../services/usermodule.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

//Sidemenu module list
export const usermoduleListViewApi = createAsyncThunk("usermodule/modulelistview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await usermoduleApiController
      .moduleview(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "modulelistview"))
      .catch((error) => HandleError(thunkAPI, error, "modulelistview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//staff permission access list api
export const usermoduleAccessViewApi = createAsyncThunk("usermodule/accesslistview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await usermoduleApiController
      .accessview(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "accesslistview"))
      .catch((error) => HandleError(thunkAPI, error, "accesslistview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const userModuleAccessUpdateApi = createAsyncThunk("usermodule/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await usermoduleApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

const initialState = {
  isListView: [],
  isAccessView: [],
  isAccess: [],
  isStaffPermissionSet: [],
  isDefaultChecked: true,
  isApiStatus: { usermoduleListViewApi: "", userModuleAccessUpdateApi: "" },
};

const usermoduleSlice = createSlice({
  name: "usermodule",
  initialState,
  reducers: {
    reset: () => initialState,
    setDefaultAccessChecked: (state, action) => {
      state.isDefaultChecked = action.payload;
    },
    userModuleAccessAction: (state, action) => {
      const { id, ...changes } = action.payload;
      const existingData = state.isAccessView.find((event) => event.id === id);
      if (existingData) {
        Object.keys(changes).map((keyName) => {
          existingData[keyName] = changes[keyName];
        });
      }
      // state.isAddonServices = action.payload;
    },
    setStaffPermissions: (state, action) => {
      state.isStaffPermissionSet = action.payload;
    },
    SelectedStaffPermissions: (state, action) => {
      state.isStaffPermissionSet = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(usermoduleListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, usermoduleListViewApi: "loading" };
      })
      .addCase(usermoduleListViewApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, usermoduleListViewApi: "succeeded" };
        state.isListView = action.payload;
      })
      .addCase(usermoduleListViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, usermoduleListViewApi: "failed" };
        state.isListView = [];
      })
      .addCase(usermoduleAccessViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, usermoduleAccessViewApi: "loading" };
      })
      .addCase(usermoduleAccessViewApi.fulfilled, (state, action) => {
        state.isAccessView = action.payload;
      })
      .addCase(usermoduleAccessViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, usermoduleAccessViewApi: "failed" };
        state.isAccessView = [];
      })
      .addCase(userModuleAccessUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userModuleAccessUpdateApi: "loading" };
      })
      .addCase(userModuleAccessUpdateApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userModuleAccessUpdateApi: "succeeded" };
      })
      .addCase(userModuleAccessUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userModuleAccessUpdateApi: "failed" };
      });
  }
});
// Action creators are generated for each case reducer function
export const { reset, userModuleAccessAction, setStaffPermissions, SelectedStaffPermissions, setDefaultAccessChecked } = usermoduleSlice.actions;
export default usermoduleSlice.reducer;
