import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import everydaymarketingcampaignApiController from "services/everydaymarketingcampaign.service";
import everydaymarketingcategoryApiController from "services/everydayMarketingcategory.service";
import everydaymarketingtemplateApiController from "services/everydaymarketingtemplate.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";


export const everydaymarketCategoryViewApi = createAsyncThunk("everydaymarket/everydaymarketlistview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await everydaymarketingcategoryApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "everydaymarketview"))
      .catch((error) => HandleError(thunkAPI, error, "everydaymarket/view"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const everydaymarketingcampaignApi = createAsyncThunk("subeverydaymarket/subeverydaymarketlistview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await everydaymarketingcampaignApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "subeverydaymarketview"))
      .catch((error) => HandleError(thunkAPI, error, "subeverydaymarket/view"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const everydaymarketingtemplateApi = createAsyncThunk("everydaymarkettemplate/everydaymarkettemplatelistview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await everydaymarketingtemplateApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "everydaymarkettemplateview"))
      .catch((error) => HandleError(thunkAPI, error, "everydaymarkettemplate/view"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});



const initialState = {
  isTabView: "",
  isOpenEverydaymarketBannerPage: "",
  iseverydaymarketingcategory: [],
  iseverydaymarketingcampaign: [],
  iseverydaymarketingtemplate: [],
  iseverydaymarketsdatas: "",
  issubeverydaymarketsdatas: "",
  isApiStatus: {
    everydaymarketCategoryViewApi: "",
    everydaymarketingcampaignApi: "",
    everydaymarketingtemplateApi: "",
  },
};

const everydaymarketingSlice = createSlice({
  name: "everydaymarketing",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenEverydaymarketBannerPage: (state, action) => {
      state.isOpenEverydaymarketBannerPage = action.payload;
    },
    Getacademydatas: (state, action) => {
      state.iseverydaymarketsdatas = action.payload;
    },
    Getsubacademydatas: (state, action) => {
      state.issubeverydaymarketsdatas = action.payload;
    },
    OpenEveryDayMarketingUpgradeAccountPage: (state = initialState) => {
      state.isOpenAcademyVideoPage = "open";
    },
    CloseEveryDayMarketingUpgradeAccountPage: (state = initialState) => {
      state.isOpenAcademyVideoPage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(everydaymarketCategoryViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, everydaymarketCategoryViewApi: "loading" };
      })
      .addCase(everydaymarketCategoryViewApi.fulfilled, (state, action) => {
        let old_current_page = state.iseverydaymarketingcategory.current_page ? state.iseverydaymarketingcategory.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.iseverydaymarketingcategory && state.iseverydaymarketingcategory.data;
        let newviewdata = action.payload && action.payload.data;
        state.iseverydaymarketingcategory = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.iseverydaymarketingcategory.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, everydaymarketCategoryViewApi: "succeeded" };
      })
      .addCase(everydaymarketCategoryViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, everydaymarketCategoryViewApi: "failed" };
        state.iseverydaymarketingcategory = [];
      })
      .addCase(everydaymarketingcampaignApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, everydaymarketingcampaignApi: "loading" };
      })
      .addCase(everydaymarketingcampaignApi.fulfilled, (state, action) => {
        let old_current_page = state.iseverydaymarketingcampaign.current_page ? state.iseverydaymarketingcampaign.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.iseverydaymarketingcampaign && state.iseverydaymarketingcampaign.data;
        let newviewdata = action.payload && action.payload.data;
        state.iseverydaymarketingcampaign = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.iseverydaymarketingcampaign.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, everydaymarketingcampaignApi: "succeeded" };
      })
      .addCase(everydaymarketingcampaignApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, everydaymarketingcampaignApi: "failed" };
        state.iseverydaymarketingcampaign = [];
      })
      .addCase(everydaymarketingtemplateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, everydaymarketingtemplateApi: "loading" };
      })
      .addCase(everydaymarketingtemplateApi.fulfilled, (state, action) => {
        let old_current_page = state.iseverydaymarketingtemplate.current_page ? state.iseverydaymarketingtemplate.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.iseverydaymarketingtemplate && state.iseverydaymarketingtemplate.data;
        let newviewdata = action.payload && action.payload.data;
        state.iseverydaymarketingtemplate = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.iseverydaymarketingtemplate.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, everydaymarketingtemplateApi: "succeeded" };
      })
      .addCase(everydaymarketingtemplateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, everydaymarketingtemplateApi: "failed" };
        state.iseverydaymarketingtemplate = [];
      });
  },
})



// Action creators are generated for each case reducer function
export const { reset, OpenEverydaymarketBannerPage, Getacademydatas, Getsubacademydatas, OpenEveryDayMarketingUpgradeAccountPage, CloseEveryDayMarketingUpgradeAccountPage } = everydaymarketingSlice.actions;
export default everydaymarketingSlice.reducer;
