import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const settingPlanhistory = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const result = values && values.result ? values.result : "";
    const apicall = values && values.apicall ? values.apicall : "";
    const action = "afterlogin/print/acs_invoice";
    
    const data = {
      auth_key: auth_key,
      action: action,
      client_id: values && values.client_id ? values.client_id : "",
      invoice_for : values && values.invoice_for ? values.invoice_for : "",
      salon_id : values && values.salon_id ? values.salon_id : "",
      salon_subscription_plan_id : values && values.salon_subscription_plan_id ? values.salon_subscription_plan_id : "",
      Accept: "application/pdf",
    };
    return axios.post(API_URL + action, data, { headers: authHeader() });
  };

const clientAppointmentTabInvoice = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const result = values && values.result ? values.result : "";
    const apicall = values && values.apicall ? values.apicall : "";
    const action = "afterlogin/print/acs_invoice";
    
    const data = {
      auth_key: auth_key,
      action: action,
      client_id: values && values.client_id ? values.client_id : "",
      invoice_for : values && values.invoice_for ? values.invoice_for : "",
      appointment_id : values && values.appointment_id ? values.appointment_id : "",
      Accept: "application/pdf",
    };
    return axios.post(API_URL + action, data, { headers: authHeader() });
  };

const clientdetail = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const result = values && values.result ? values.result : "";
    const apicall = values && values.apicall ? values.apicall : "";
    const action = "afterlogin/print/acs_invoice";
    
    const data = {
      auth_key: auth_key,
      action: action,
      client_id: values && values.client_id ? values.client_id : "",
      invoice_for : values && values.invoice_for ? values.invoice_for : "",
      appointment_id : values && values.appointment_id ? values.appointment_id : "",
      Accept: "application/pdf",
    };
    return axios.post(API_URL + action, data, { headers: authHeader() });
  };

const clientDetailAppointmentPrint = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const result = values && values.result ? values.result : "";
    const apicall = values && values.apicall ? values.apicall : "";
    const action = "afterlogin/print/acs_invoice";
    
    const data = {
      auth_key: auth_key,
      action: action,
      client_id: values && values.client_id ? values.client_id : "",
      invoice_for : values && values.invoice_for ? values.invoice_for : "",
      appointment_id : values && values.appointment_id ? values.appointment_id : "",
      Accept: "application/pdf",
    };
    return axios.post(API_URL + action, data, { headers: authHeader() });
  };

const clientinvoicesprint = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const result = values && values.result ? values.result : "";
    const apicall = values && values.apicall ? values.apicall : "";
    const action = "afterlogin/print/acs_invoice";
    
    const data = {
      auth_key: auth_key,
      action: action,
      pagination:false,
      client_id: values && values.client_id ? values.client_id : "",
      invoice_for : values && values.invoice_for ? values.invoice_for : "",
      appointment_id : values && values.appointment_id ? values.appointment_id : "",
      Accept: "application/pdf",
    };
    return axios.post(API_URL + action, data, { headers: authHeader() });
  };

const saleVoucherPrint = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const result = values && values.result ? values.result : "";
    const apicall = values && values.apicall ? values.apicall : "";
    const action = "afterlogin/print/acs_invoice";
    
    const data = {
      auth_key: auth_key,
      action: action,
      pagination:false,
      invoice_for : values && values.invoice_for ? values.invoice_for : "",
      client_id : values && values.client_id ? values.client_id : "",
      sale_id : values && values.sale_id ? values.sale_id : "",
      salon_id : values && values.salon_id ? values.salon_id : "",
      appointment_id : values && values.appointment_id ? values.appointment_id : "",
      voucher_to_id:values && values.voucher_to_id ? values.voucher_to_id : "",
      Accept: "application/pdf",
    };
    return axios.post(API_URL + action, data, { headers: authHeader() });
  };

const calendarPrinter = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const result = values && values.result ? values.result : "";
    const apicall = values && values.apicall ? values.apicall : "";
    const action = "afterlogin/print/acs_invoice";
    
    const data = {
      auth_key: auth_key,
      action: action,
      pagination:false,
      client_id: values && values.client_id ? values.client_id : "",
      invoice_for : values && values.invoice_for ? values.invoice_for : "",
      appointment_id : values && values.appointment_id ? values.appointment_id : "",
      Accept: "application/pdf",
    };
    return axios.post(API_URL + action, data, { headers: authHeader() });
  };

  const calendarappointmentprints = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const formData = new FormData();
    for (let value in values) {
      if (["isRangeInfo"].includes(value) && values[value] && typeof values[value] === "object") {
        formData.append(value, JSON.stringify(values[value]));
      } else {
        formData.append(value, values[value]);
      }
    }
    const action = `afterlogin/print/calendarprint`;
    formData.append("auth_key", auth_key);
    formData.append("action", action);
    return axios.post(API_URL + action, formData, { headers: authHeader() });
  };


const reportPrinter = (values) => {
    const auth = store.getState().auth;
    const auth_key = auth.user.auth_key;
    const result = values && values.result ? values.result : "";
    const apicall = values && values.apicall ? values.apicall : "";
    const action = "afterlogin/print/beauti_report";
    
    const data = {
      auth_key: auth_key,
      action: action,
      pagination:false,
      ScreenReport : values && values.ScreenReport ? values.ScreenReport : "",
      staff_id : values && values.staff_id ? values.staff_id : "",
      daterange : values && values.daterange ? values.daterange : "",
      client_id: values && values.client_id ? values.client_id : "",
      invoice_for : values && values.invoice_for ? values.invoice_for : "",
      appointment_id : values && values.appointment_id ? values.appointment_id : "",
      month : values && values.month ? values.month : "",
      Accept: "application/pdf",
    };
    return axios.post(API_URL + action, data, { headers: authHeader() });
  };




const printerApiController = {
    settingPlanhistory,
    clientAppointmentTabInvoice,
    clientdetail,
    clientDetailAppointmentPrint,
    clientinvoicesprint,
    saleVoucherPrint,
    calendarPrinter,
    reportPrinter,
    calendarappointmentprints
};
export default printerApiController;