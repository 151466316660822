import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
import StripoEmailApiController from "services/stripoemail.service";
export const usersAdapter = createEntityAdapter();

export const StripoEmailGetApi = createAsyncThunk("stripo/emailbuilder", async (formValues, thunkAPI) => {
  try {
    const resposedata = await StripoEmailApiController.stripoemailget(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "stripoemailbuilder"))
      .catch((error) => HandleError(thunkAPI, error, "stripoemailbuilder"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StripoEmailSaveApi = createAsyncThunk("stripo/emailbuildersave", async (formValues, thunkAPI) => {
  try {
    const resposedata = await StripoEmailApiController.stripoemailsave(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "stripoemailbuildersave"))
      .catch((error) => HandleError(thunkAPI, error, "stripoemailbuildersave"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isstripoBuilderSave: "",
  isstripoBuilderGet: "",
  isstripoEmailBuilderModal: "",
  isstripoEmailBuilderHTML: "",
  isStripoFormLink: "",
  isStripoHtmlAndCssGet: "",
  isStripoBookingLink: "",
  isApiStatus: {
    StripoEmailGetApi: "",
    StripoEmailSaveApi: "",
  },
};

const StripoEmailSlice = createSlice({
  name: "stripoemail",
  initialState,
  reducers: {
    reset: () => initialState,
    StripoEmailBuilderModal: (state, action) => {
      state.isstripoEmailBuilderModal = action.payload;
    },
    StripoEmailBuilderSave: (state, action) => {
      state.isstripoEmailBuilderHTML = action.payload;
    },
    StripoBookingLink: (state, action) => {
      state.isStripoBookingLink = action.payload;
    },
    StripoFormLink: (state, action) => {
      state.isStripoFormLink = action.payload;
    },
    StripoHtmlAndCssGet: (state, action) => {
      state.isStripoHtmlAndCssGet = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(StripoEmailGetApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripoEmailGetApi: "loading" };
      })
      .addCase(StripoEmailGetApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, StripoEmailGetApi: "succeeded" };
        state.isstripoBuilderGet = action.payload;
      })
      .addCase(StripoEmailGetApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripoEmailGetApi: "failed" };
        state.isstripoBuilderGet = [];
      })
      .addCase(StripoEmailSaveApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripoEmailSaveApi: "loading" };
      })
      .addCase(StripoEmailSaveApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, StripoEmailSaveApi: "succeeded" };
        state.isstripoBuilderSave = action.payload;
      })
      .addCase(StripoEmailSaveApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, StripoEmailSaveApi: "failed" };
        state.isstripoBuilderSave = [];
      });
  }
});

// Action creators are generated for each case reducer function
export const { reset, StripoEmailBuilderModal, StripoEmailBuilderSave, StripoBookingLink, StripoFormLink, StripoHtmlAndCssGet } = StripoEmailSlice.actions;
export default StripoEmailSlice.reducer;

