import React, { useState } from 'react';
import config from "../../../../../config";
import moment from 'moment';
import { ClientDetailApi, ClientDetailTab, OpenClientDetailModal } from 'store/slices/clientSlice';
import { useSelector, useDispatch } from 'react-redux';
import { ClientVoucherListViewApi } from 'store/slices/clientvoucherSlice';
import { ClientMembershipListViewApi } from 'store/slices/clientmembershipSlice';
import { ClientnoteGridViewApi } from 'store/slices/clientnoteSlice';
import { ClientdocumentGridViewApi } from 'store/slices/clientdocumentSlice';
import { ClientInvoiceListViewApi } from 'store/slices/clientinvoiceSlice';
import { ClientphotoGridViewApi } from 'store/slices/clientphotoSlice';
import { AddAppointmentForm, AppointmentDetailModal, ClientAppointmentListViewApi, RescheduleAppointmentForm, ShowLoadingSpinner, appointmentDetailApi, appointmentListViewApi } from 'store/slices/appointmentSlice';
import { ClientchatGridViewApi } from 'store/slices/clientChatSlice';
import { NotificationListApi, NotificationToCalendar, NotificationUpdateApi, UpcomingNotifyDetailDrawer } from 'store/slices/notificationSlice';
import { useLocation, useNavigate } from 'react-router';
import MoonLoader from "react-spinners/MoonLoader";
import { calendarRangeInfo, calendarStaffList } from 'store/slices/calendarSlice';
import { usercheckAccess } from 'helpers/Functions';
import { AddBusytimeForm, EditBusytimeForm, busytimeListViewApi } from 'store/slices/busytimeSlice';
import { RosterStaffApi, StaffAwayApi, StaffworkinghoursApi } from 'store/slices/staffSlice';
import { ClosedDateApi } from 'store/slices/salonSlice';
import { sweatalert } from 'component/Sweatalert2';
import customToast from 'component/Toastr';
import { useTranslation } from 'react-i18next';


const AllNotificationList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [loader, setLoader] = useState({});
  const views = props && props.view;
  const objectData = views && views.data ? views.data : views;
  const notificationTabs = useSelector((state) => state.notification.isUpcomingNotifyDetailTab);
  const isClientDetailTab = useSelector((state) => state.client.isClientDetailTab);
  const isRangeInfo = useSelector((state) => state.calendar.isRangeInfo);
  const calendarTab = useSelector((state) => state.calendar.isTabView);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const single_staff_access = usercheckAccess("calendar_view", currentUser);
  const singleStaffAccess = role_id === 8 ? '' : (single_staff_access && currentUser && currentUser.is_owner === 0) ? 1 : '';

  return (
    <div>
      {objectData && objectData.length > 0 ? (
        <>
          {Object.keys(objectData).map((item, index) => {
            const id = objectData[item] && objectData[item].id ? objectData[item].id : "";
            const profilePhotoUrl = objectData[item] && objectData[item].appointment && objectData[item].appointment.client && objectData[item].appointment.client.profile_photo_url ? objectData[item].appointment.client.profile_photo_url : "";
            const profilePhotoUrlMessage = objectData[item] && objectData[item].clientchat && objectData[item].clientchat.client && objectData[item].clientchat.client.profile_photo_url ? objectData[item].clientchat.client.profile_photo_url : "";
            const message_client_first_name = objectData[item] && objectData[item].clientchat && objectData[item].clientchat.client && objectData[item].clientchat.client.first_name ? objectData[item].clientchat.client.first_name : "";
            const message_client_last_name = objectData[item] && objectData[item].clientchat && objectData[item].clientchat.client && objectData[item].clientchat.client.last_name ? objectData[item].clientchat.client.last_name : "";
            const first_name = objectData[item] && objectData[item].appointment && objectData[item].appointment.client && objectData[item].appointment.client.first_name ? objectData[item].appointment.client.first_name : "";
            const last_name = objectData[item] && objectData[item].appointment && objectData[item].appointment.client && objectData[item].appointment.client.last_name ? objectData[item].appointment.client.last_name : "";
            const iconUrl = objectData[item] && objectData[item].type && objectData[item].type === "online_booking" ? "eye-round.png" : objectData[item] && objectData[item].type && objectData[item].type === "message" ? "reply-round.png" : "";
            const datedifference = objectData[item] && objectData[item].created_at ? moment(objectData[item].created_at, "YYYY-MM-DD hh:mm:ss").fromNow() : "";
            const dateFormat = objectData[item] && objectData[item].created_at ? moment(objectData[item].created_at, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY") : "";
            const dateof = objectData[item] && objectData[item].appointment && objectData[item].appointment.dateof ? moment(objectData[item].appointment.dateof, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
            const service_name = objectData[item] && objectData[item].appointment && objectData[item].appointment.service && objectData[item].appointment.service.name ? objectData[item].appointment.service.name : "";
            const appointment_time = objectData[item] && objectData[item].appointment && (objectData[item].appointment.start_time && objectData[item].appointment.end_time) ? (`${moment(objectData[item].appointment.start_time, "HH:mm:ss").format("HH:mm A")} - ${moment(objectData[item].appointment.end_time, "HH:mm:ss").format("HH:mm A")}`) : "";
            const messages = objectData[item] && objectData[item].clientchat && objectData[item].clientchat.message ? objectData[item].clientchat.message.replace(/\r?\n|\r/g, ' ') : "";
            const is_seen = objectData[item] && objectData[item].is_seen == 0 ? 0 : 1;
            const staff_first_name = objectData[item] && objectData[item].appointment && objectData[item].appointment.staff && objectData[item].appointment.staff.first_name ? objectData[item].appointment.staff.first_name : "";
            const staff_last_name = objectData[item] && objectData[item].appointment && objectData[item].appointment.staff && objectData[item].appointment.staff.last_name ? objectData[item].appointment.staff.last_name : "";
            const message_client_id = objectData[item] && objectData[item].clientchat && objectData[item].clientchat.client && objectData[item].clientchat.client.id ? objectData[item].clientchat.client.id : "";
            const staffData = objectData[item] && objectData[item].appointment && objectData[item].appointment.staff ? objectData[item].appointment.staff : "";
            const staffId = objectData[item] && objectData[item].appointment && objectData[item].appointment.staff_id ? objectData[item].appointment.staff_id : "";
            let notification_appointment_id = objectData[item] && objectData[item].appointment && objectData[item].appointment.id ? objectData[item].appointment.id : "";
            let notification_client_id = objectData[item] && objectData[item].appointment && objectData[item].appointment.client && objectData[item].appointment.client.id ? objectData[item].appointment.client.id : "";
            let notification_showdate = objectData[item] && objectData[item].appointment && objectData[item].appointment.dateof ? objectData[item].appointment.dateof : "";
            let notification_start_date = objectData[item] && objectData[item].appointment && objectData[item].appointment.start_datetime ? objectData[item].appointment.start_datetime : "";
            let notification_end_date = objectData[item] && objectData[item].appointment && objectData[item].appointment.end_datetime ? objectData[item].appointment.end_datetime : "";

            return (
              <div className="box-image-cover notification-boxs w-100 mx-0 py-md-4 py-3 text-start" style={{ background: `${is_seen == 1 ? "#fff" : "#f9f6f4"}`,marginBottom:`${index === objectData.length - 1 ? "43px" : "0px"}` }} key={index}>
                <div className="row align-items-center">
                  <div className="col-xxl-9 col-md-8 mb-md-0 mb-2">
                    <div className="d-flex align-items-center">
                      <div className="text-center">
                        {objectData[item] && objectData[item].type && objectData[item].type === "online_booking" ? (
                          <>
                            {profilePhotoUrl ? (
                              <img src={profilePhotoUrl} alt="" className="rounded-circle wh-40 profile-image-obj mwh-40" />
                            ) : (
                              <div className="user-initial text-uppercase profile-image-height wh-40 mwh-40">{first_name && last_name ? first_name.charAt(0) + "" + last_name.charAt(0) : ""}</div>
                            )}
                          </>
                        ) : (
                          <>
                            {profilePhotoUrlMessage ? (
                              <img src={profilePhotoUrlMessage} alt="" className="rounded-circle wh-40 profile-image-obj mwh-40" />
                            ) : (
                              <div className="user-initial text-uppercase profile-image-height wh-40 mwh-40">{message_client_first_name && message_client_last_name ? message_client_first_name.charAt(0) + "" + message_client_last_name.charAt(0) : ""}</div>
                            )}
                          </>
                        )}
                      </div>
                      <div className="ps-3">
                        <div>
                          {objectData[item] && objectData[item].type && objectData[item].type === "online_booking" ? (
                            <h6 className='fw-semibold mb-0'>{`${first_name} ${last_name}`}</h6>
                          ) : (
                            <h6 className='fw-semibold mb-0'>{`${message_client_first_name} ${message_client_last_name}`}</h6>
                          )}
                        </div>
                        {objectData[item] && objectData[item].type && objectData[item].type === "online_booking" ? (
                          <div>
                            <p className='fw-semibold mb-0'>{`${service_name} with ${staff_first_name + " " + staff_last_name}`}</p>
                            <p className='fw-semibold mb-0'>{dateof}</p>
                            <div>
                              <span className='text-dark'>{appointment_time}</span>
                            </div>
                            <div>
                              <span className='color-wine'>{datedifference}</span>
                            </div>
                            {objectData[item] && objectData[item].appointment && objectData[item].appointment.status === "Cancelled" ? (
                            <div>
                              <p className='mb-0 text-danger'>{t("The appointment has been canceled.")}</p>
                            </div>
                            ) : ""}
                          </div>
                        ) : (
                          <div>
                            <p className='fw-semibold mb-0'>{messages}</p>
                            <div>
                              <span className='color-wine'>{datedifference}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-md-4 ps-md-0 d-flex justify-content-end align-items-center">
                    {objectData[item] && objectData[item].appointment && objectData[item].appointment.status === "Cancelled" ? null : (
                      <>
                        {iconUrl ? (
                          <span
                            onClick={async () => {
                              setLoader((prevLoader) => ({ ...prevLoader, [index]: true })); // Set loader to true for the clicked index
                              dispatch(NotificationUpdateApi({ id, is_seen: 1 })).then((action) => {
                                if (action.meta.requestStatus === "fulfilled") {
                                  dispatch(NotificationListApi());
                                }
                              });
                              if (objectData[item] && objectData[item].type && objectData[item].type === "online_booking") {
                                dispatch(NotificationToCalendar(objectData[item]));
                                if (location && location.pathname === "/calendar") {
                                  try {
                                    dispatch(ShowLoadingSpinner(true));
                                    dispatch(AddAppointmentForm(""));
                                    dispatch(AddBusytimeForm(""));
                                    dispatch(EditBusytimeForm(""));
                                    dispatch(RescheduleAppointmentForm(""));
                                    dispatch(
                                      appointmentDetailApi({
                                        id: notification_appointment_id,
                                        client_id: notification_client_id,
                                        showdate: notification_showdate,
                                        showstartdate: notification_start_date,
                                        showenddate: notification_end_date,
                                        page_name: "calendar",
                                        singlestaffaccess: singleStaffAccess
                                      }),
                                    ).then((action) => {
                                      setLoader((prevLoader) => ({ ...prevLoader, [index]: false }));
                                      if (action.meta.requestStatus === "fulfilled") {
                                        dispatch(AppointmentDetailModal(true));
                                        setTimeout(() => {
                                          dispatch(AppointmentDetailModal("open"));
                                        }, config.modalOpenTimeOut);
                                      } else if (action.meta.requestStatus === "rejected") {
                                        if (action.payload.status === 422) {
                                          let error = action.payload;
                                          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                                          sweatalert({
                                            title: message.errors.document[0],
                                            text: message.errors.document,
                                            icon: "error",
                                          });
                                        }
                                      }
                                    })
                                  } catch (error) {
                                    console.log(error);
                                    customToast.error('Sorry there was a problem, please refresh your browser');
                                    dispatch(UpcomingNotifyDetailDrawer(""));
                                    setLoader((prevLoader) => ({ ...prevLoader, [index]: false })); // Set loader back to false after the action is completed
                                    dispatch(ShowLoadingSpinner(false));
                                  }
                                } else {
                                  setTimeout(() => {
                                    setLoader((prevLoader) => ({ ...prevLoader, [index]: false })); // Set loader back to false after the action is completed
                                    navigate(config.basePath + "/calendar");
                                    dispatch(UpcomingNotifyDetailDrawer(""));
                                  }, 1000)
                                }
                              } else if (objectData[item] && objectData[item].type && objectData[item].type === "message") {
                                dispatch(ClientDetailTab("chatmessages"));
                                dispatch(ClientDetailApi({ id: message_client_id })).then((action) => {
                                  setLoader((prevLoader) => ({ ...prevLoader, [index]: false })); // Set loader back to false after the action is completed
                                  dispatch(UpcomingNotifyDetailDrawer(""));
                                  if (action.meta.requestStatus === "fulfilled") {
                                    dispatch(ClientchatGridViewApi({ client_id: message_client_id }));
                                    dispatch(OpenClientDetailModal(true));
                                    setTimeout(() => {
                                      dispatch(OpenClientDetailModal("open"));
                                    }, config.modalOpenTimeOut);
                                  }
                                });
                              }
                            }}>
                            {loader[index] ? (
                              <MoonLoader color="#8c1c4d" size="22px" />
                            ) : (
                              <img src={config.imagepath + iconUrl} alt="" className="rounded-circle wh-25 profile-image-obj" />
                            )}
                          </span>
                        ) : ""}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </>
      ) : (
        <div className="complete-box text-center d-flex flex-column justify-content-center mt-5">
          <div className="complete-box-wrp text-center">
            {/* <img src={config.imagepath + "bellnotifications.png"} alt="" className="mb-md-4 mb-3" /> */}
            <img src={config.imagepath + "notification-bell.png"} alt="" className="mb-md-4 mb-3" />
            <h5 className="mb-2 fw-semibold" style={{ color: 'rgb(151,136,136)' }}>{t("No notification found")}</h5>
          </div>
        </div>
      )}
    </div>
  )
}

export default AllNotificationList;