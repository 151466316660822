import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import chartApiController from "services/chart.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const usersAdapter = createEntityAdapter();

export const LineChartApi = createAsyncThunk("chart/LineChartApi", async (formValues, thunkAPI) => {
  try {
    const resposedata = await chartApiController
      .line(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "LineChartApi"))
      .catch((error) => HandleError(thunkAPI, error, "LineChartApi"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isLineChart: "",
  isStaffFilter: "",
  isApiStatus: {
    LineChartApi: "",
  },
};

const chartSlice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    reset: () => initialState,
    ChartStaffFilter: (state, action) => {
      state.isStaffFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LineChartApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, LineChartApi: "loading" };
      })
      .addCase(LineChartApi.fulfilled, (state, action) => {
        state.isLineChart = action.payload;
        state.isApiStatus = { ...state.isApiStatus, LineChartApi: "succeeded" };
      })
      .addCase(LineChartApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, LineChartApi: "failed" };
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, ChartStaffFilter } = chartSlice.actions;
export default chartSlice.reducer;
