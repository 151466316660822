import axios from "axios";
// import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

// const common = () => {
//   const auth = store.commonState().auth;
//   const auth_key = auth.user.auth_key;
//   const action = `afterlogin/user`;
//   const data = {
//     action: action,
//     auth_key,
//   };
//   return axios.post(API_URL + action, data, { headers: authHeader() });
// };

const timezone = () => {
  const action = "common/timezone";
  var data = {
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const sampleClientExport = (values) => {
  const action = "common/sampleclientexport";
  return axios.post(API_URL + action, {
    action: action,
  });
};

const sampleProductExport = (values) => {
  const action = "common/sampleproductexport";
  const data = {
    salon_id:values && values.id ? values.id : ""
  };
  return axios.post(API_URL + action,data, {
    action: action,
  });
};

const whentosend = (values) => {
  const formData = new FormData();
  for (let value in values) {
    if (["option"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "common/whentosend";
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const checkuser = (values) => {
  const formData = new FormData();
  for (let value in values) {
    if (["formpreview"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "common/checkuser";
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};


const commonApiController = {
  timezone,
  sampleClientExport,
  sampleProductExport,
  whentosend,
  checkuser
};

export default commonApiController;
