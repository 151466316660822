import moment from "moment";
import SaleEmailInvoiceForm from "pages/sales/Form/SaleEmailInvoiceForm";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ActiveBtnStateClientAppointments, GetTotalDepositAmount, OpenClientRefundSelection, StripeTokenClientAppointments, openPastInvoiceDrawer } from "store/slices/clientappointmentinvoiceSlice";
import { clientAppointmentTabInvoiceApi } from "store/slices/printerSlice";
import config from "../../../../config"
import { TaxValueApi } from "store/slices/taxSlice";
import { useTranslation } from "react-i18next";

const Appointmentinvoice = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState(false);
  const currentUser = auth.user;
  const rightDrawerOpened = useSelector((state) => state.clientpastinvoice.isPastInvoiceDrawer);
  const isSaleCompletedData = useSelector((state) => state.clientpastinvoice.PastInvoiceDataview);
  const isSaleCompletedDataSaleCarts = useSelector((state) => state.appointment.isClientInvoiceSaleCart);
  const InvoiceTax = useSelector((state) => state.tax.isTaxValue);
  let taxPercentage = InvoiceTax && InvoiceTax.percentage;
  let start_times_hour = isSaleCompletedData.start_time;
  let start_time = moment(start_times_hour, "HH:mm:ss").format("hh:mm A");
  let end_times_hour = isSaleCompletedData.end_time;
  let end_time = moment(end_times_hour, "HH:mm:ss").format("hh:mm A");
  let date = isSaleCompletedData.dateof;
  let client = isSaleCompletedData.client;
  let first_name = client.first_name;
  let last_name = client.last_name;
  let services = isSaleCompletedData.service;
  let servicename = services.name;
  let staffdetails = isSaleCompletedData.staff;
  let staff_first_name = staffdetails.first_name;
  let staff_last_name = staffdetails.last_name;
  let service_price = isSaleCompletedData.cost;
  let gst = 0;
  let sale = isSaleCompletedData.sale;
  let invoicedate = sale.invoicedate;
  let totalprice = sale.totalprice;
  let total_pay = sale.total_pay;
  let Balance = 0;
  let saledata = isSaleCompletedData.sale;
  let invoicenumber = saledata.invoice_number;
  let client_id = isSaleCompletedData && isSaleCompletedData.client && isSaleCompletedData.client.id;
  let appointment_id = isSaleCompletedData && isSaleCompletedData.id;
  const salePaid = isSaleCompletedData && isSaleCompletedData.payment.length > 0 && isSaleCompletedData.payment.filter((payment) => payment.type === "Sale").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
  const depositPaid = isSaleCompletedData && isSaleCompletedData.payment.length > 0 && isSaleCompletedData.payment.filter((payment) => payment.type === "Deposit").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
  const depositRefund = isSaleCompletedData && isSaleCompletedData.payment.length > 0 && isSaleCompletedData.payment.filter((payment) => payment.type === "DepositRefund").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
  const depositBalance = salePaid + depositPaid - depositRefund != null && salePaid + depositPaid - depositRefund !== "" && salePaid + depositPaid - depositRefund > 0 ? salePaid + depositPaid - depositRefund : 0;
  let TotalServiceCosts = isSaleCompletedData &&
    (isSaleCompletedData.cost || 0) +
    (isSaleCompletedData.addanotherservices && isSaleCompletedData.addanotherservices.length > 0
      ? isSaleCompletedData.addanotherservices.reduce((sum, item) => {
        return sum + parseFloat(item.cost);
      }, 0)
      : 0) +
    (isSaleCompletedData.addonservices && isSaleCompletedData.addonservices.length > 0
      ? isSaleCompletedData.addonservices.reduce((sum, item) => {
        return sum + parseFloat(item.cost);
      }, 0)
      : 0)
  let tax = taxPercentage ? TotalServiceCosts / taxPercentage : "";
  const BalanceShows = TotalServiceCosts - depositBalance;
  const BalanceViews = (TotalServiceCosts || 0) - (salePaid || 0) - (depositPaid || 0);

  useEffect(() => {
    dispatch(TaxValueApi());
  }, []);

  return (
    <div className={"drawer client-invoice-drawer " + rightDrawerOpened}>
      <div className="drawer-wrp position-relative">
        <div className="drawer-header">
          <h2 className="mb-4 pe-md-5 pe-3">Invoice</h2>
          <a href="#" className="close" onClick={() => {
            dispatch(openPastInvoiceDrawer(""))
          }}>
            <img src={config.imagepath + "close-icon.svg"} alt="" />
          </a>
        </div>
        <div className="drawer-body d-flex flex-wrap">
          {openModal && (
            <>
              <div className={openModal ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openModal ? "block" : "none" }}>
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body pb-0">
                      <div className="p-3">
                        <h5 className="mb-3 fw-semibold text-dark">{t("Refund Warning")}</h5>
                        <p className="mb-3 fw-semibold">{t("Full Amount is Already Refunded For This Appointments")}</p>
                      </div>
                      <div className="row mt-3 bg-cream p-4 justify-content-center">
                        <div className="col-6">
                          <button className="btn btn-primary w-100" type="button" onClick={() => {
                            setOpenModal(false);
                          }}>
                            {t("OK")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {openModal && <div className="modal-backdrop show"></div>}
            </>
          )}
          <ul className="sale-comlete-data list-unstyled">
            <li>
              <h5 className="mb-1 fw-semibold">Tax Invoice {invoicenumber ? invoicenumber : "#0001"}</h5>
              <p className="mb-0">{invoicedate}</p>
            </li>
            <hr />
            <li>
              <label>
                Invoice to:
                <label>
                  <h6 className="mb-0">{first_name + " " + last_name}</h6>
                </label>
              </label>
            </li>
            <hr />
            <li>
              <div className="row gx-1 justify-content-between">
                <div className="col-10">
                  <label htmlFor="">{servicename}</label>
                  <h6 className="mb-1">With {staff_first_name + " " + staff_last_name} from {start_time + " - " + end_time}</h6>
                  <span>Quantity: 1</span>
                </div>
                <label className="col-2 text-end">${service_price}</label>
              </div>
            </li>
            <hr />
            {isSaleCompletedData && isSaleCompletedData.addonservices.length > 0 ? (
              isSaleCompletedData.addonservices.map((addon, i) => {
                let serviceData = addon.service;
                let costPrice = addon.cost;
                let serviceName = serviceData.name;
                let staffData = addon.staff;
                let staffFullName = staffData.first_name + " " + staffData.last_name;
                let serviceStartTimes = addon.start_time;
                let serviceStart = moment(serviceStartTimes, "HH:mm:ss").format("hh:mm A");
                let serviceEndsTimes = addon.end_time;
                let serviceEnds = moment(serviceEndsTimes, "HH:mm:ss").format("hh:mm A");
                return (
                  <div key={addon && addon.id ? addon.id : i}>
                    <li>
                      <div className="row gx-1 justify-content-between">
                        <div className="col-10">
                          <label htmlFor="">{serviceName}</label>
                          <h6 className="mb-1">With {staffFullName} from {serviceStart + " - " + serviceEnds}</h6>
                          <span>Quantity: 1</span>
                        </div>
                        <label className="col-2 text-end">${costPrice}</label>
                      </div>
                    </li>
                  </div>
                )
              })
            ) : null}
            {isSaleCompletedData && isSaleCompletedData.addanotherservices && isSaleCompletedData.addanotherservices.length > 0 ? (
              <>
                {isSaleCompletedData.addanotherservices.map((item, i) => {
                  const addanotherServiceName = item && item.service && item.service.name;
                  let addanotherstaffFullName = item && item.staff && (item.staff.first_name && item.staff.last_name) ? item.staff.first_name + " " + item.staff.last_name : "";
                  let addanotherserviceStartTimes = item && item.start_time ? item.start_time : "";
                  let addanotherserviceStart = moment(addanotherserviceStartTimes, "HH:mm:ss").format("hh:mm A");
                  let addanotherserviceEndsTimes = item && item.end_time ? item.end_time : "";
                  let addanotherserviceEnds = moment(addanotherserviceEndsTimes, "HH:mm:ss").format("hh:mm A");
                  let addanotherappointmentTime = item && item.dateof ? moment(item.dateof, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
                  let addanotherTotalPay = item && item.cost ? item.cost : "";

                  return (
                    <React.Fragment key={i}>
                      <hr />
                      <li className="mb-3">
                        <div className="row gx-1 justify-content-between">
                          <div className="col-10">
                            <label htmlFor="">{addanotherServiceName ? addanotherServiceName : ""}</label>
                            <h6 className="mb-1">With {addanotherstaffFullName} on {addanotherappointmentTime} from {addanotherserviceStart + " - " + addanotherserviceEnds}</h6>
                            <span>Quantity: 1</span>
                          </div>
                          <label className="col-2 text-end">${addanotherTotalPay}</label>
                        </div>
                      </li>
                    </React.Fragment>
                  )
                })}
              </>
            ) : ""}
            {currentUser && currentUser.salon && currentUser.salon.tax && currentUser.salon.tax.name === "Non-GST" ? "" : (
              <li>
                <div className="row gx-1 justify-content-between">
                  <div className="col-10">
                    <h6 className="mb-3">Includes GST of</h6>
                  </div>
                  <h6 className="mb-0 col-2 text-end">${tax ? parseFloat(tax).toFixed(2) : "0"}</h6>
                </div>
              </li>
            )}
            <li className="total">
              <div className="row gx-1 justify-content-between my-3">
                <label className="mb-0 col-10">Total AUD</label>
                {/* <label className="mb-0 col-2 text-end">${total_pay}</label> */}
                <label className="mb-0 col-2 text-end">${TotalServiceCosts ? TotalServiceCosts : 0}</label>
              </div>
            </li>
            <li>
              {isSaleCompletedData && isSaleCompletedData.sale && isSaleCompletedData.sale.membership_service_usable_credit && parseFloat(isSaleCompletedData.sale.membership_service_usable_credit) > 0 && (
                <>
                  <li className="py-3">
                    <div className="row gx-1 justify-content-between">
                      <div className="col-10">
                        <h6 className="mb-0">Package Credit Used</h6>
                      </div>
                      <h6 className="mb-0 col-2 text-end">${isSaleCompletedData.sale.membership_service_usable_credit ? isSaleCompletedData.sale.membership_service_usable_credit : 0}</h6>
                    </div>
                  </li>
                  <hr className="my-0" />
                </>
              )}
              {isSaleCompletedData && isSaleCompletedData.sale && isSaleCompletedData.sale.voucher_discount && parseFloat(isSaleCompletedData.sale.voucher_discount) > 0 && (
                <>
                  <li className="py-3">
                    <div className="row gx-1 justify-content-between">
                      <div className="col-10">
                        <h6 className="mb-0">Voucher Applied</h6>
                      </div>
                      <h6 className="mb-0 col-2 text-end">${isSaleCompletedData.sale.voucher_discount ? isSaleCompletedData.sale.voucher_discount : 0}</h6>
                    </div>
                  </li>
                  <hr className="my-0" />
                </>
              )}
              {isSaleCompletedData && isSaleCompletedData.payment && isSaleCompletedData.payment.length > 0 ? (
                isSaleCompletedData.payment.map((paymentItem,i) => {
                  const paidByStatus = paymentItem && paymentItem.paidby ? paymentItem.paidby : "";
                  const amount = paymentItem && paymentItem.amount ? paymentItem.amount : "";
                  const paidDate = paymentItem && paymentItem.created_at ? moment(paymentItem.created_at, "YYYY-MM-DDTHH:mm:ss.SSSSZ").format("DD/MM/YYYY") : "";
                  if (paymentItem && paymentItem.type === "Deposit") {
                    return (
                      <React.Fragment key={i}>
                        <li className="py-3">
                          <div className="row gx-1 justify-content-between">
                            <div className="col-10">
                              <h6 className="mb-0">{`Deposit Paid by ${paidByStatus} ${paidDate}`}</h6>
                            </div>
                            <h6 className="mb-0 col-2 text-end">${amount}</h6>
                          </div>
                        </li>
                        <hr className="my-0" />
                      </React.Fragment>
                    )
                  } else if (paymentItem && paymentItem.type === "Sale") {
                    return (
                      <React.Fragment key={i}>
                        <li className="py-3">
                          <div className="row gx-1 justify-content-between">
                            <div className="col-10">
                              <h6 className="mb-0">{`Sale Paid By ${paidByStatus} ${paidDate}`}</h6>
                            </div>
                            <h6 className="mb-0 col-2 text-end">${amount}</h6>
                          </div>
                        </li>
                        <hr className="my-0" />
                      </React.Fragment>
                    )
                  }else if (paymentItem && paymentItem.type === "DepositRefund") {
                    return (
                      <React.Fragment key={i}>
                        <li className="py-3">
                          <div className="row gx-1 justify-content-between">
                            <div className="col-10">
                              <h6 className="mb-0">{`Refund By ${paidByStatus} ${paidDate}`}</h6>
                            </div>
                            <h6 className="mb-0 col-2 text-end">${amount}</h6>
                          </div>
                        </li>
                        <hr className="my-0" />
                      </React.Fragment>
                    )
                  } else {
                    return (
                      <React.Fragment key={i}>
                        <li className="py-3">
                          <div className="row gx-1 justify-content-between">
                            <div className="col-10">
                              <h6 className="mb-0">{`Paid By ${paidByStatus} ${paidDate}`}</h6>
                            </div>
                            <h6 className="mb-0 col-2 text-end">${amount}</h6>
                          </div>
                        </li>
                        <hr className="my-0" />
                      </React.Fragment>
                    )
                  }
                })
              ) : ""}
            </li>
            <li className="py-3">
              <div className="row gx-1 justify-content-between">
                <label className="mb-0 fw-normal col-10">Balance</label>
                <label className="mb-0 fw-normal col-2 text-end">${BalanceViews ? BalanceViews : 0}</label>
              </div>
            </li>
            {depositRefund && depositRefund > 0 ? (
              <>
                <hr className="my-0" />
                <li className="py-3">
                  <div className="row gx-1 justify-content-between">
                    <label className="mb-0 fw-normal col-10">Refund issued</label>
                    <label className="mb-0 fw-normal col-2 text-end">${depositRefund ? depositRefund : 0}</label>
                  </div>
                </li>
              </>
            ) : ""}
            {depositBalance && depositBalance > 0 ? (
              <li className="my-2">
                <div className="row gx-1 justify-content-between">
                  <button
                    type="button"
                    className="btn btn-apricots cursor-pointer"
                    onClick={() => {
                      if (depositBalance && depositBalance > 0) {
                        dispatch(GetTotalDepositAmount(depositBalance))
                        dispatch(StripeTokenClientAppointments(""));
                        dispatch(ActiveBtnStateClientAppointments(""));
                        dispatch(OpenClientRefundSelection("open"));
                      } else {
                        setOpenModal(true)
                      }
                    }}
                  >
                    {t("Refund")}
                  </button>
                </div>
              </li>
            ) : ""}
          </ul>
          <div className="d-flex mt-auto flex-nowrap align-items-end mt-auto">
            <div className="me-3 mb-1">
              <a href="#" className="btn btn-dark ms-3 p-3 fo"
                onClick={() => {
                  dispatch(clientAppointmentTabInvoiceApi({ invoice_for: "client_paid_appointment", client_id: client_id, appointment_id: appointment_id })).then((action) => {
                    setLoading(true)
                    if (action.meta.requestStatus === "fulfilled") {
                      setLoading(false)
                      let fileURL = 'data:application/pdf;base64,' + action.payload.html;
                      let fileName = 'invoice.pdf';
                      let anchorlink = document.createElement('a');
                      anchorlink.href = fileURL;
                      anchorlink.download = fileName;
                      anchorlink.setAttribute('target', '_blank');
                      anchorlink.click();
                      var string = doc.output('datauristring');
                      var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>";
                      var newWindow = window.open();
                      newWindow.document.open();
                      newWindow.document.write(iframe);
                      newWindow.document.close();
                      var pdf_newTab = window.open("");
                      pdf_newTab.document.write(
                        "<html><head><title>" + encodeURIComponent(fileName) + "</title></head><body><iframe title='MY title' width='100%' height='100%' src='" + fileURL + "'></iframe></body></html>"
                      );
                    }
                  })
                }}
              >
                Print
              </a>
            </div>
            <div className="">
              <SaleEmailInvoiceForm isSaleCompletedData={saledata} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Appointmentinvoice;



