import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
import generalLoginApiController from "services/generalLogin.service";

export const GeneralLoginStoreApi = createAsyncThunk("generallogin/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await generalLoginApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const GeneralLoginDetailApi = createAsyncThunk("generallogin/view", async (formValues, thunkAPI) => {
  try {
    const resposedata = await generalLoginApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "generallogindetail"))
      .catch((error) => HandleError(thunkAPI, error, "generallogindetail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isGeneralLoginCreate: [],
  isDetailData: "",
  isApiStatus: {
    GeneralLoginStoreApi: "",
    GeneralLoginDetailApi: "",
  }
}

export const generalloginSlice = createSlice({
  name: "generallogin",
  initialState,
  reducers: {
    reset: () => initialState,

  },
  extraReducers: (builder) => {
    builder
      .addCase(GeneralLoginStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, GeneralLoginStoreApi: "loading" };
      })
      .addCase(GeneralLoginStoreApi.fulfilled, (state, action) => {
        if (state.isGeneralLoginCreate && state.isGeneralLoginCreate.data) {
          state.isGeneralLoginCreate.data = [action.payload, ...state.isGeneralLoginCreate.data];
        } else {
          state.isGeneralLoginCreate = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, GeneralLoginStoreApi: "succeeded" };
      })
      .addCase(GeneralLoginStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, GeneralLoginStoreApi: "failed" };
      })
      .addCase(GeneralLoginDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, GeneralLoginDetailApi: "loading" };
      })
      .addCase(GeneralLoginDetailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, GeneralLoginDetailApi: "succeeded" };
        state.isDetailData = action.payload;
      })
      .addCase(GeneralLoginDetailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, GeneralLoginDetailApi: "failed" };
        state.isDetailData = "";
      })
  },
});

export const { reset } = generalloginSlice.actions;
export default generalloginSlice.reducer;