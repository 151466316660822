import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import clientnoteApiController from "../../services/clientnote.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const clientnoteStoreApi = createAsyncThunk("clientnote/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await clientnoteApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const clientnoteUpdateApi = createAsyncThunk("clientnote/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await clientnoteApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const ClientnoteGridViewApi = createAsyncThunk("clientnote/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientnoteApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const clientnoteDetailApi = createAsyncThunk("clientnote/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientnoteApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const clientnoteDeleteApi = createAsyncThunk("clientnote/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientnoteApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const clientnoteSuggetionListApi = createAsyncThunk("clientnote/suggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientnoteApiController
      .suggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientNotesImportApi = createAsyncThunk("client/Clientnoteimport", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientnoteApiController
      .clientnoteimport(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Clientnoteimport"))
      .catch((error) => HandleError(thunkAPI, error, "Clientnoteimport"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SampleClientNotesApi = createAsyncThunk("client/Clientnotesample", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientnoteApiController
      .samplenoteexports(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Clientnotesample"))
      .catch((error) => HandleError(thunkAPI, error, "Clientnotesample"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ClientNotesExportApi = createAsyncThunk("client/Clientnoteexport", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientnoteApiController
      .clientnoteexport(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Clientnoteexport"))
      .catch((error) => HandleError(thunkAPI, error, "Clientnoteexport"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isGridView: [],
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isNoteDrawer: "",
  isDetailData: "",
  isApiStatus: {
    clientnoteStoreApi: "",
    clientnoteUpdateApi: "",
    ClientnoteGridViewApi: "",
    clientnoteDeleteApi: "",
    clientnoteDetailApi: "",
    ClientNotesImportApi: "",
    SampleClientNotesApi: "",
    ClientNotesExportApi: ""
  },
};

const clientnoteSlice = createSlice({
  name: "clientnote",
  initialState,
  reducers: {
    reset: () => initialState,
    openNoteDrawer: (state) => {
      state.isNoteDrawer = "open";
      // state.isOpenedAddForm = "";
      // state.isOpenedEditForm = "";
    },
    closeNoteDrawer: (state) => {
      state.isNoteDrawer = "";
      // state.isOpenedAddForm = "";
      // state.isOpenedEditForm = "";
    },
    openAddNoteForm: (state = initialState) => {
      state.isOpenedAddForm = "open";
      state.isOpenedEditForm = "";
    },
    closeAddNoteForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
    },
    openEditNoteForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "open";
    },
    closeEditNoteForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clientnoteStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientnoteStoreApi: "loading" };
      })
      .addCase(clientnoteStoreApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientnoteStoreApi: "succeeded" };
      })
      .addCase(clientnoteStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientnoteStoreApi: "failed" };
      })
      .addCase(clientnoteUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientnoteUpdateApi: "loading" };
      })
      .addCase(clientnoteUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        let isGridView = state.isGridView && state.isGridView.data ? state.isGridView.data : state.isGridView;
        const existingGridData = isGridView ? isGridView.find((event) => event.id === id) : "";
        if (existingGridData) {
          Object.keys(changes).map((keyName) => {
            existingGridData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, clientnoteUpdateApi: "succeeded" };
      })
      .addCase(clientnoteUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientnoteUpdateApi: "failed" };
      })
      .addCase(ClientnoteGridViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientnoteGridViewApi: "loading" };
      })
      .addCase(ClientnoteGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isGridView.current_page ? state.isGridView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isGridView && state.isGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isGridView = action.payload;
        state.isApiStatus = { ...state.isApiStatus, ClientnoteGridViewApi: "succeeded" };
      })
      .addCase(ClientnoteGridViewApi.rejected, (state) => {
        state.isGridView = [];
        state.isApiStatus = { ...state.isApiStatus, ClientnoteGridViewApi: "failed" };
      })
      .addCase(clientnoteDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientnoteDeleteApi: "loading" };
      })
      .addCase(clientnoteDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isGridView.data = state.isGridView.data ? state.isGridView.data.filter((item) => item.id != id) : state.isGridView.filter((item) => item.id != id);
        state.isApiStatus = { ...state.isApiStatus, clientnoteDeleteApi: "succeeded" };
      })
      .addCase(clientnoteDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientnoteDeleteApi: "failed" };
      })
      .addCase(clientnoteDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, clientnoteDetailApi: "loading" };
      })
      .addCase(clientnoteDetailApi.fulfilled, (state, action) => {
        state.isDetailData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, clientnoteDetailApi: "succeeded" };
      })
      .addCase(clientnoteDetailApi.rejected, (state) => {
        state.isDetailData = "";
        state.isApiStatus = { ...state.isApiStatus, clientnoteDetailApi: "failed" };
      })
      .addCase(ClientNotesImportApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientNotesImportApi: "loading" };
      })
      .addCase(ClientNotesImportApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientNotesImportApi: "succeeded" };
      })
      .addCase(ClientNotesImportApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientNotesImportApi: "failed" };
      })
      .addCase(SampleClientNotesApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SampleClientNotesApi: "loading" };
      })
      .addCase(SampleClientNotesApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SampleClientNotesApi: "succeeded" };
      })
      .addCase(SampleClientNotesApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SampleClientNotesApi: "failed" };
      })
      .addCase(ClientNotesExportApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientNotesExportApi: "loading" };
      })
      .addCase(ClientNotesExportApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientNotesExportApi: "succeeded" };
      })
      .addCase(ClientNotesExportApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientNotesExportApi: "failed" };
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, openNoteDrawer, closeNoteDrawer, openAddNoteForm, closeAddNoteForm, openEditNoteForm, closeEditNoteForm } = clientnoteSlice.actions;
export default clientnoteSlice.reducer;
