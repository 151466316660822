import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/birthdaycampaign/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const update = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const clickEvent = values && values.clickEvent;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value] ? values[value] : "");
  }
  let action = "afterlogin/birthdaycampaign/update/" + values.id;
  if (clickEvent && clickEvent === "statusupdate") {
    action = "afterlogin/birthdaycampaign/isactive/" + values.id;
  }
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const birthday_type = values && values.birthday_type ? values.birthday_type : "";
  // console.log(values);
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/birthdaycampaign/view?page=${page}` : `afterlogin/birthdaycampaign/view`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: pagination, //true or false
    id: values && values.id ? values.id : "",
    client_id: values && values.client_id ? values.client_id : "",
    result: result, //business_name,owner_name
    birthday_type: birthday_type, //business_name,owner_name
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const deleted = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/birthdaycampaign/delete/${values.id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const services = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  let birthday_campaign_id = values && values.birthday_campaign_id ? values.birthday_campaign_id : "";
  const action = page ? `afterlogin/birthdaycampaign/services?page=${page}&birthday_campaign_id=${birthday_campaign_id}` : `afterlogin/birthdaycampaign/services?birthday_campaign_id=${birthday_campaign_id}`;
  const data = {
    auth_key: auth_key,
    action: action
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const sendemail = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = 'afterlogin/birthdaycampaign/sendemail';
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value] ? values[value] : "");
  }
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const BirthdayCampaignApiController = {
  create,
  update,
  view,
  deleted,
  services,
  sendemail
};
export default BirthdayCampaignApiController;
