import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import clientinvoiceApiController from "../../services/clientinvoice.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const ClientInvoiceListViewApi = createAsyncThunk("clientinvoice/listview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await clientinvoiceApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "listview"))
      .catch((error) => HandleError(thunkAPI, error, "listview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isListView: [],
  isOpenClientInvoiceDetailsTab: "",
  isClientInvoiceDetailsTabData: "",
  isOpenClientInvoiceRefundModal: "",
  isOpenClientInvoiceStripePayModalClient: "",
  isOpenStripeRefundCompletedSaleModal: "",
  isCompletedSaleDataForRefund: "",
  isdepositBalance: "",
  isSaleCompletedData: "",
  AppointmentsData: "",
  appointmentsArray: "",
  isOpenPartialPaidInvoice: "",
  isActiveBtnState: "",
  isRefundStripeToken: "",
  isOpenStripeRefundModal: "",
  isRefundBalance: "",
  isTaxAmountClientPaidInvoice: "",
  isClientMemberShipTotalPay: "",
  totalServiceCosts: "",
  isFullCartData: "",
  isApiStatus: {
    ClientInvoiceListViewApi: "",
  },
};

const clientinvoiceSlice = createSlice({
  name: "clientinvoice",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenClientInvoiceDetails: (state, action) => {
      state.isOpenClientInvoiceDetailsTab = action.payload;
    },
    GetClientDetailTabInvoiceData: (state, action) => {
      state.isClientInvoiceDetailsTabData = action.payload;
    },
    OpenClientInvoiceRefundModal: (state, action) => {
      state.isOpenClientInvoiceRefundModal = action.payload;
    },
    OpenClientInvoiceStripePayModalClient: (state, action) => {
      state.isOpenClientInvoiceStripePayModalClient = action.payload;
    },
    ClientInvoiceRefundData: (state, action) => {
      state.isSaleCompletedData = action.payload.isSaleCompletedData;
      state.AppointmentsData = action.payload.AppointmentsData;
      state.isRefundBalance = action.payload.balance;
    },
    OpenPartialPaidInvoice: (state, action) => {
      state.isOpenPartialPaidInvoice = action.payload;
    },
    ActiveBtnState: (state, action) => {
      state.isActiveBtnState = action.payload;
    },
    RefundStripeToken: (state, action) => {
      state.isRefundStripeToken = action.payload;
    },
    OpenStripeRefundModal: (state, action) => {
      state.isOpenStripeRefundModal = action.payload;
    },
    OpenStripeRefundCompletedSaleModal: (state, action) => {
      state.isOpenStripeRefundCompletedSaleModal = action.payload;
    },
    CompletedSaleDataForRefund: (state, action) => {
      state.isCompletedSaleDataForRefund = action.payload.isSaleCompletedData;
      state.appointmentsArray = action.payload.appointmentsArray;
      state.isdepositBalance = action.payload.isdepositBalance;
      state.isFullCartData = action.payload.FullCartData;
    },
    TaxAmountClientPaidInvoice: (state, action) => {
      state.isTaxAmountClientPaidInvoice = action.payload.taxAmounts;
      state.totalServiceCosts = action.payload.totalServiceCosts;
    },
    ClientMemberShipTotalPay: (state, action) => {
      state.isClientMemberShipTotalPay = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ClientInvoiceListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientInvoiceListViewApi: "loading" };
      })
      .addCase(ClientInvoiceListViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isListView.current_page ? state.isListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isListView && state.isListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, ClientInvoiceListViewApi: "succeeded" };
        state.isListView = action.payload;
      })
      .addCase(ClientInvoiceListViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ClientInvoiceListViewApi: "failed" };
        state.isListView = [];
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, openNoteDrawer, closeNoteDrawer, openAddNoteForm, closeAddNoteForm, openEditNoteForm, closeEditNoteForm, OpenClientInvoiceDetails, GetClientDetailTabInvoiceData, OpenClientInvoiceRefundModal, OpenClientInvoiceStripePayModalClient, ClientInvoiceRefundData, OpenPartialPaidInvoice, ActiveBtnState, RefundStripeToken, OpenStripeRefundModal, OpenStripeRefundCompletedSaleModal, CompletedSaleDataForRefund, TaxAmountClientPaidInvoice, ClientMemberShipTotalPay } = clientinvoiceSlice.actions;
export default clientinvoiceSlice.reducer;
