import { loadStripe } from "@stripe/stripe-js";
import config from "./../config";
import { useSelector } from "react-redux";

// Initialize a variable to hold the Stripe.js promise.
let stripePromise;

// Create a function to get the Stripe client.
const getStripeClient = () => {
  // Get the 'auth' and 'isBookingApi' values from the Redux store.
  const auth = useSelector((state) => state.auth);
  const isBookingApi = useSelector((state) => state.booking.isBookingApi);

  // Initialize a variable to hold the Stripe publishable key.
  let publicKey = "";

  // Determine the Stripe publishable key based on conditions.
  if (auth && auth.user) {
    const currentUser = auth.user;
    const email = currentUser.email;
    if (email && config.stripe_test_email.includes(email)) {
      publicKey = "pk_test_51Ko2rOSFsrov7HTSarhjgTN7nrsYLEVvOKqhMM3lq8b4ZOdIbP5Pj7TgUbZxr9C3apilJAmeSvEH9HcwLWbj6Nw400febhX2Ig";//client_owner_test_key
    } else {
      publicKey =
        currentUser && currentUser.salon && currentUser.salon.stripe_publishable_key
          ? currentUser.salon.stripe_publishable_key
          : "";
    }
  } else if (isBookingApi) {
    publicKey =
      isBookingApi.salon && isBookingApi.salon.stripe_publishable_key
        ? isBookingApi.salon.stripe_publishable_key
        : "";
  }
  // console.log('publicKey', publicKey)
  // Assign the Stripe publishable key to a variable.
  const stripe_publishable_key = publicKey;
  // console.log('publicKey', stripe_publishable_key);
  // const stripe_publishable_key = "pk_test_51Ko2rOSFsrov7HTSarhjgTN7nrsYLEVvOKqhMM3lq8b4ZOdIbP5Pj7TgUbZxr9C3apilJAmeSvEH9HcwLWbj6Nw400febhX2Ig";
  // const stripe_secrete_key = "sk_test_51Ko2rOSFsrov7HTSJAkhuTXyQiUGw5kfiU67lVR7riELEoXvcoUI6duFWM6djjYVNwmvGMec5OhyVeZyy5X3eRcj00r1l2zaoX";

  // If the Stripe promise hasn't been initialized yet, load Stripe with the key.
  if (!stripePromise) {
    stripePromise = loadStripe(stripe_publishable_key);
  }

  // Return the Stripe promise.
  return stripePromise;
};

export default getStripeClient;
