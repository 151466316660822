import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ClientDetailConsultationFormTab } from 'store/slices/clientformSlice';
import config from "../../../../../config";
import SignaturePad from 'react-signature-pad-wrapper';

const ClientFormDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rightDrawerOpened = useSelector((state) => state.clientform.isclientDetailConsultationFormTab);
  const isclientDetailConsultationForm = useSelector((state) => state.clientform.isclientDetailConsultationForm);
  const signatureRef = useRef();
  const [signatureImage, setSignatureImage] = useState(null);

  const handleSaveSignature = () => {
    if (signatureRef.current) {
      const signatureDataUrl = signatureRef.current.toDataURL();
      setSignatureImage(signatureDataUrl);
    }
  };

  return (
    <div className={"drawer addnote-drawer p-0 " + rightDrawerOpened}>
      <div className="drawer-wrp position-relative">
        <div className="drawer-header">
          <h2 className="mt-3 ms-3 pe-md-5 pe-3">{t("Consultation Forms")}</h2>
          <a href="#" className="close mt-2 me-4" onClick={() => {
            dispatch(ClientDetailConsultationFormTab(""));
          }}>
            <img src={config.imagepath + "close-icon.svg"} alt="" />
          </a>
        </div>
        <div className="drawer-body">
          <div className="row mx-4 mb-5">
            {isclientDetailConsultationForm && Object.keys(isclientDetailConsultationForm).map((item, i) => {
              let answer = isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].answer ? isclientDetailConsultationForm[item].answer : "";
              let question = isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].question ? isclientDetailConsultationForm[item].question : "";
              return (
                <div key={item && item.id ? item.id : i} className='col-12'>
                  <div className="row">
                    {isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].form_element_type && isclientDetailConsultationForm[item].form_element_type.name ? (
                      <div className="col-8 w-100 fw-semibold mb-2 ms-0 ps-0">
                        <h6 className='fw-bold mb-0'>{isclientDetailConsultationForm[item].form_element_type.name}</h6>
                      </div>
                    ) : null}
                    {isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].question ? (
                      <div className="col-8 w-100 fw-semibold mb-2 ps-0">
                        {isclientDetailConsultationForm[item].question}
                      </div>
                    ) : null}
                    {isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].answer ? (
                      <>
                        <div className="mb-2 col-8 form-preview-width-control w-100">
                          {isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].form_type === "multicheckbox" ? isclientDetailConsultationForm[item].answer.split(",").map((str) => <div className='mb-2'><input type="checkbox" className="form-check-input me-2" name="add_on_all" checked={true} disabled={true} /><span className="fw-semibold">{str}</span></div>) : null}
                          {isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].form_type === "checkbox" ? <div className='mb-2'><input type="checkbox" className="form-check-input me-2" name="add_on_all" checked={true} disabled={true} value={isclientDetailConsultationForm[item].answer} />{isclientDetailConsultationForm[item].answer}</div> : null}
                          {isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].form_type === "radio" ? <div className='mb-2'><input type="radio" className="form-check-input me-2" name="add_on_all" checked={true} disabled={true} value={isclientDetailConsultationForm[item].answer} />{isclientDetailConsultationForm[item].answer}</div> : null}
                        </div>
                        {isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].answer && isclientDetailConsultationForm[item].form_type !== "file" ? (
                          <div className={`mb-2 col-8 w-100 form-preview-width-control ${isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].form_type === "text" || isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].form_type === "date" ? "form-control" : ""} ${isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].form_type === "select" ? "formpreview-select-form" : ""} ${isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].form_type === "textarea" ? "formpreview-textarea-form" : ""}`}>
                            {isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].form_type === "multicheckbox" || isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].form_type === "checkbox" || isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].form_type === "radio" ? null : isclientDetailConsultationForm[item].answer}
                          </div>
                        ) : null}
                        {isclientDetailConsultationForm[item] && isclientDetailConsultationForm[item].form_type === "file" ? (
                          <div className="signature-pad-wrapper">
                            <img src={`${isclientDetailConsultationForm[item].storage_url}/signature/image/${isclientDetailConsultationForm[item].answer}`} alt="Signature" />
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientFormDetails;