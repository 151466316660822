import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;


const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const appointment_type = values && values.appointment_type ? values.appointment_type : "";
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/client/clientsubscription?page=${page}` : `afterlogin/client/clientsubscription`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: pagination, //true or false
    id: values && values.id ? values.id : "",
    client_id: values && values.client_id ? values.client_id : "",
    result: result, //business_name,owner_name
    appointment_type: appointment_type, //business_name,owner_name
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};


const cancelsubscription = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;

  // const action = "afterlogin/client/clientsubscriptioncancel";
  // const data = {
  //   auth_key: auth_key,
  //   action: action,
  //   sale_id: values && values.sale_id ? values.sale_id : "",
  //   client_id: values && values.client_id ? values.client_id : "",
  //   subscription_id: values && values.subscription_id ? values.subscription_id : "",
  // };
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/client/clientsubscriptioncancel";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};



const clientsubscriptionApiController = {
  view,
  cancelsubscription,
};
export default clientsubscriptionApiController;
