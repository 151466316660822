import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import ReviewCampaignApiController from "../../services/reviewcampaign.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const ReviewCampaignStoreApi = createAsyncThunk("reviewcampaign/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await ReviewCampaignApiController.create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const ReviewCampaignUpdateApi = createAsyncThunk("reviewcampaign/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await ReviewCampaignApiController.update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const ReviewCampaignListViewApi = createAsyncThunk("reviewcampaign/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await ReviewCampaignApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ReviewCampaignOptions = createAsyncThunk("reviewcampaign/ReviewCampaignOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await ReviewCampaignApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "ReviewCampaignOptions"))
      .catch((error) => HandleError(thunkAPI, error, "ReviewCampaignOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ReviewCampaignDetailApi = createAsyncThunk("reviewcampaign/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await ReviewCampaignApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ReviewCampaignDeleteApi = createAsyncThunk("reviewcampaign/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await ReviewCampaignApiController.deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ReviewServicesApi = createAsyncThunk("reviewcampaign/services", async (formValues, thunkAPI) => {
  try {
    const resposedata = await ReviewCampaignApiController.services(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "services"))
      .catch((error) => HandleError(thunkAPI, error, "services"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ReviewCampaignSendEmailApi = createAsyncThunk("reviewcampaign/sendemail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await ReviewCampaignApiController.sendemail(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "sendemail"))
      .catch((error) => HandleError(thunkAPI, error, "sendemail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isReviewType: "",
  isOpenedTypeForm: "",
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedSendEmailForm: "",
  isOpenedDetailModal: "",
  isListView: [],
  isSuggetionListView: [],
  isReviewCampaignServices: [],
  isDetailData: "",
  isSearchList: "",
  isSearchName: "",
  isEmailPreview: "",
  isSmsPreview: "",
  isReviewCampaignSelectedType: "",
  isReviewServices: "",
  isReviewSendEmail: "",
  isOpenedSendEmailObj: "",
  isReviewCampaignSave: "",
  isApiStatus: {
    ReviewCampaignStoreApi: "",
    ReviewCampaignUpdateApi: "",
    ReviewCampaignListViewApi: "",
    ReviewCampaignSuggetionListApi: "",
    ReviewCampaignDetailApi: "",
    ReviewCampaignDeleteApi: "",
    ReviewCampaignOptions: "",
    ReviewServicesApi: "",
    ReviewCampaignSendEmailApi: "",
  },
};

const ReviewCampaignSlice = createSlice({
  name: "reviewcampaign",
  initialState,
  reducers: {
    reset: () => initialState,
    ReviewCampaignType: (state, action) => {
      state.isReviewType = action.payload;
    },
    ReviewCampaignSelectedType: (state, action) => {
      state.isReviewCampaignSelectedType = action.payload;
    },
    ReviewCampaignFormModal: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedTypeForm = action.payload;
    },
    AddReviewCampaignFormModal: (state, action) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = action.payload;
    },
    EditReviewCampaignFormModal: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = action.payload;
    },
    SetEmailPreview: (state, action) => {
      state.isEmailPreview = action.payload;
    },
    SetSmsPreview: (state, action) => {
      state.isSmsPreview = action.payload;
    },
    SendEmailReviewCampaignFormModal: (state, action) => {
      state.isOpenedSendEmailForm = action.payload;
    },
    SendEmailReviewCampaignObjModal: (state, action) => {
      state.isOpenedSendEmailObj = action.payload;
    },
    ReviewCampaignSave: (state, action) => {
      state.isReviewCampaignSave = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ReviewCampaignStoreApi.pending, (state) => {
        state.isApiStatus.ReviewCampaignStoreApi = 'loading';
      })
      .addCase(ReviewCampaignStoreApi.fulfilled, (state, action) => {
        if (state.isListView && state.isListView.data) {
          state.isListView.data = [action.payload, ...state.isListView.data];
        } else {
          state.isListView = { data: [action.payload] };
        }
        state.isApiStatus.ReviewCampaignStoreApi = 'succeeded';
      })
      .addCase(ReviewCampaignStoreApi.rejected, (state) => {
        state.isApiStatus.ReviewCampaignStoreApi = 'failed';
      })
      .addCase(ReviewCampaignUpdateApi.pending, (state) => {
        state.isApiStatus.ReviewCampaignUpdateApi = 'loading';
      })
      .addCase(ReviewCampaignUpdateApi.fulfilled, (state, action) => {
        const updatedItem = action.payload ? action.payload : "";
        if(updatedItem && state.isListView.data && state.isListView.data.length > 0){
          const index = state.isListView.data.findIndex(item => item.id === updatedItem.id);
          if (index !== -1) {
            state.isListView.data[index] = updatedItem;
          }
        }
        state.isApiStatus = { ...state.isApiStatus, ReviewCampaignUpdateApi: "succeeded" };
      })
      .addCase(ReviewCampaignUpdateApi.rejected, (state) => {
         state.isApiStatus = { ...state.isApiStatus, ReviewCampaignUpdateApi: "failed" };
      })
      .addCase(ReviewCampaignListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ReviewCampaignListViewApi: "loading" };
      })
      .addCase(ReviewCampaignListViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isListView.current_page ? state.isListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isListView && state.isListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, ReviewCampaignListViewApi: "succeeded" };
      })
      .addCase(ReviewCampaignListViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ReviewCampaignListViewApi: "failed" };
        state.isListView = [];
      })
      .addCase(ReviewCampaignDetailApi.pending, (state) => {
        state.isApiStatus.ReviewCampaignDetailApi = 'loading';
      })
      .addCase(ReviewCampaignDetailApi.fulfilled, (state, action) => {
        state.isApiStatus.ReviewCampaignDetailApi = 'succeeded';
        state.isDetailData = action.payload;
      })
      .addCase(ReviewCampaignDetailApi.rejected, (state) => {
        state.isApiStatus.ReviewCampaignDetailApi = 'failed';
        state.isDetailData = "";
      })
      .addCase(ReviewCampaignDeleteApi.pending, (state) => {
        state.isApiStatus.ReviewCampaignDeleteApi = 'loading';
      })
      .addCase(ReviewCampaignDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isApiStatus.ReviewCampaignDeleteApi = 'succeeded';
        state.isListView.data = state.isListView.data ? state.isListView.data.filter((item) => item.id != id) : [];
      })
      .addCase(ReviewCampaignDeleteApi.rejected, (state) => {
        state.isApiStatus.ReviewCampaignDeleteApi = 'failed';
      })
      .addCase(ReviewServicesApi.pending, (state) => {
        state.isApiStatus.ReviewServicesApi = 'loading';
      })
      .addCase(ReviewServicesApi.fulfilled, (state, action) => {
        state.isApiStatus.ReviewServicesApi = 'succeeded';
        state.isReviewCampaignServices = action.payload;
      })
      .addCase(ReviewServicesApi.rejected, (state) => {
        state.isApiStatus.ReviewServicesApi = 'failed';
        state.isReviewCampaignServices = [];
      })
      .addCase(ReviewCampaignSendEmailApi.pending, (state) => {
        state.isApiStatus.ReviewCampaignSendEmailApi = 'loading';
      })
      .addCase(ReviewCampaignSendEmailApi.fulfilled, (state, action) => {
        state.isApiStatus.ReviewCampaignSendEmailApi = 'succeeded';
        state.isReviewSendEmail = action.payload;
      })
      .addCase(ReviewCampaignSendEmailApi.rejected, (state) => {
        state.isApiStatus.ReviewCampaignSendEmailApi = 'failed';
        state.isReviewSendEmail = "";
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, ReviewCampaignType, ReviewCampaignFormModal, AddReviewCampaignFormModal, EditReviewCampaignFormModal, SetEmailPreview, SetSmsPreview, ReviewCampaignSelectedType, SendEmailReviewCampaignFormModal, SendEmailReviewCampaignObjModal, ReviewCampaignSave } = ReviewCampaignSlice.actions;
export default ReviewCampaignSlice.reducer;
