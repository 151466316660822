import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  isPastInvoiceDrawer: "",
  PastInvoiceDataview: "",
  isOpenClientRefundSelection:"",
  isOpenClientRefundInvoiceStripe:"",
  isOpenRefundInvoice:"",
  isRefundInvoiceData:"",
  isGetTotalDepositAmount:"",
  isActiveBtnStateClientAppointments:"",
  isStripeTokenClientAppointments:""
};

const clientappointmentinvoiceSlice = createSlice({
  name: "clientpastinvoice",
  initialState,
  reducers: {
    reset: () => initialState,
    openPastInvoiceDrawer: (state,action) => {
      state.isPastInvoiceDrawer = action.payload;
    },
    PastInvoiceData: (state,action) => {
      state.PastInvoiceDataview = action.payload;
    },
    OpenRefundInvoice: (state,action) => {
      state.isOpenRefundInvoice = action.payload;
    },
    RefundInvoiceData: (state,action) => {
      state.isRefundInvoiceData = action.payload;
    },
    OpenClientRefundInvoiceStripe: (state,action) => {
      state.isOpenClientRefundInvoiceStripe = action.payload;
    },
    OpenClientRefundSelection: (state,action) => {
      state.isOpenClientRefundSelection = action.payload;
    },
    ActiveBtnStateClientAppointments: (state,action) => {
      state.isActiveBtnStateClientAppointments = action.payload;
    },
    StripeTokenClientAppointments: (state,action) => {
      state.isStripeTokenClientAppointments = action.payload;
    },
    GetTotalDepositAmount: (state,action) => {
      state.isGetTotalDepositAmount = action.payload;
    },
  },
});


// Action creators are generated for each case reducer function
export const { reset, openPastInvoiceDrawer,PastInvoiceData,OpenRefundInvoice,OpenClientRefundInvoiceStripe,OpenClientRefundSelection,RefundInvoiceData,ActiveBtnStateClientAppointments,StripeTokenClientAppointments,GetTotalDepositAmount } = clientappointmentinvoiceSlice.actions;
export default clientappointmentinvoiceSlice.reducer;
