import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ClientphotoGridViewApi, OpenClientProfileAddModal, OpenCommentModal, clientphotoStoreApi } from "store/slices/clientphotoSlice";
import { InputField, TextareaField } from "component/form/Field";
import * as Yup from "yup";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import config from "../../../../config";
import yupconfig from "../../../../yupconfig";
import PropTypes from "prop-types";
import ImageUpload from "component/form/ImageUpload";
import customToast from "component/Toastr";

const ClientProfilePhotoAddForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const auth = useSelector((state) => state.auth);
    const currentUser = auth.user;
    const rightDrawerOpened = useSelector((state) => state.clientphoto.isOpenClientProfileAddModal);
    const isCommentData = useSelector((state) => state.clientphoto.isCommentData);
    const Client = props && props.client ? props.client : "";

    const initialValues = {
        client_id: Client?.id || "",
        comment: "",
        myFiles: []
    };

    const validationSchema = Yup.object().shape({
            myFiles: Yup.array()
              .min(1, "image is required")
              .required("Please upload at least one image"),
    });

    const handleClientPhotoSubmit = (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
            dispatch(clientphotoStoreApi(values)).then((action) => {
                if (action.meta.requestStatus === "fulfilled") {
                    dispatch(ClientphotoGridViewApi({ client_id: Client?.id }));
                    dispatch(OpenClientProfileAddModal(""));
                    customToast.success(t("Uploaded successfully"));
                } else if (action.meta.requestStatus === "rejected") {
                    const status = action.payload && action.payload.status;
                    const errors = action.payload && action.payload.message && action.payload.message.errors;
                    const response = action.payload && action.payload.message && action.payload.message;
                    if (status === 422) {
                        const NotifyContent = () => {
                            return (
                                <>
                                    <p className="mb-2 text-light text-justify">{response && response.message}</p>
                                    <ul className="list-unstyled">
                                        {errors &&
                                            Object.keys(errors).map((a, n) => {
                                                if (errors[a].length > 0) {
                                                    return (
                                                        <React.Fragment key={n}>
                                                            {errors[a].map((as, ns) => {
                                                                return (
                                                                    <li key={n + "-" + ns} className="text-light form-text text-start">
                                                                        {as}
                                                                    </li>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                    );
                                                }
                                                return null;
                                            })}
                                    </ul>
                                </>
                            );
                        };
                        customToast.error(<NotifyContent />);
                    } else if (status === 410) {
                        customToast.error("Failed to update");
                    }
                }
            });
        } catch (err) {
            setErrors(err.message);
            setStatus({ success: false });
            setLoading(false);
        }

    }

    const handleFileChange = async (event, formik) => {
        const files = Array.from(event.currentTarget.files);

        formik.setFieldValue('myFiles', files);
    };


    return (
        <div>
            <div className={rightDrawerOpened === "open" ? "modal fade show" : "modal fade"} id="add-emailverification111" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: rightDrawerOpened === "open" ? "block" : "none" }}>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleClientPhotoSubmit}>
                    {(formik) => {

                        return (
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content ">
                                    <div className="modal-body pb-0">
                                        <form noValidate onSubmit={formik.handleSubmit}>
                                            <div className="p-3">
                                                <h5 className="mb-3 fw-semibold text-dark">{t("Add Photo")}</h5>
                                                <div className="col-12 mb-3">
                                                    <div className="w-100">
                                                        <label className="w-100">
                                                            <input
                                                                type="file"
                                                                id="file"
                                                                name="myFiles"
                                                                // multiple
                                                                style={{ display: 'none' }}
                                                                accept="image/*"
                                                                onChange={(e) => handleFileChange(e, formik)}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            <span className="w-100 photo-upload-btn" >{t('Select Photo To Upload')}</span>
                                                        </label>
                                                    </div>
                                                    <div>

                                                    </div>
                                                    <ErrorMessage name="myFiles" component="div" className="text-danger" />
                                                </div>
                                                {formik.values.myFiles.length > 0 ? (
                                                    <>
                                                        <div className="client-profile-images">
                                                            {formik.values.myFiles.length > 0 && formik.values.myFiles.map((filedata, index) => (
                                                                <div className="">
                                                                    <a className="" style={{ border: "1px solid #d9d9d9", borderRadius: "6px", padding: "4px" }}>
                                                                        <img src={URL.createObjectURL(filedata)} alt="" style={{ width: '50px', height: '50px', objectFit: "cover" }} />
                                                                    </a>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                ) : ""}
                                                <div className="col-md-12">
                                                    <TextareaField type="text" name="comment" placeholder={t("Enter Notes")} value={formik.values.comment} label={t("Notes (Optional)")} controlId="clientphotoForm-comment" />
                                                </div>
                                            </div>
                                            <div className="row mt-3 bg-cream p-4 justify-content-center">
                                                <div className="col-6">
                                                    <button className="preview btn me-1 cursor-pointer btn-preview w-100" onClick={() => {
                                                        dispatch(OpenClientProfileAddModal(""));
                                                    }}>
                                                        {t("Cancel")}
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                                                        {loading && <span className="spinner-border spinner-border-sm"></span>}
                                                        {t("Save")}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Formik>
            </div>
            {rightDrawerOpened === "open" && <div className="modal-backdrop show"></div>}
        </div>
    )
};

ClientProfilePhotoAddForm.propTypes = {
    props: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
};

export default ClientProfilePhotoAddForm;