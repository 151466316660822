import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import academyArticleApiController from "services/academyarticle.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const AcademyArticleGridViewApi = createAsyncThunk("academycategory/listview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await academyArticleApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "academycategoryview"))
      .catch((error) => HandleError(thunkAPI, error, "academycategory/view"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isOpenAcademyVideoPage: "",
  isVideoDatas: "",
  isGridView: [],
  isApiStatus: {
    AcademyArticleGridViewApi: "",
  },
};

const academyarticleSlice = createSlice({
  name: "academyarticle",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenAcademyVideoPage: (state = initialState) => {
      state.isOpenAcademyVideoPage = "open";
    },
    CloseAcademyVideoPage: (state = initialState) => {
      state.isOpenAcademyVideoPage = "";
    },
    academyvideodatas: (state, action) => {
      state.isVideoDatas = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AcademyArticleGridViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AcademyArticleGridViewApi: "loading" };
      })
      .addCase(AcademyArticleGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isGridView.current_page || "";
        let new_current_page = action.payload.current_page || "";
        let viewdata = state.isGridView && state.isGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page !== new_current_page) {
          viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, AcademyArticleGridViewApi: "succeeded" };
      })
      .addCase(AcademyArticleGridViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AcademyArticleGridViewApi: "failed" };
        state.isGridView = [];
      });
  },
})



// Action creators are generated for each case reducer function
export const { reset, OpenAcademy, OpenAcademyVideoPage, CloseAcademyVideoPage, academyvideodatas } = academyarticleSlice.actions;
export default academyarticleSlice.reducer;
