import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OpenBillingDetailEditForm } from 'store/slices/packageplandetailsSlice';
import * as Yup from "yup";
import { Formik, FieldArray, Field } from "formik";
import config from "../../../config";
import yupconfig from "../../../yupconfig";
import { InputField, MapAddressField } from "component/form/Field";
import SpinLoader from 'component/SpinLoader';
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import { billingDetailApi, BillingStoreApi } from 'store/slices/billingAddressSlice';
import customToast from 'component/Toastr';
import { swalErrorHtml } from 'component/Sweatalert2';
import { GetUser } from 'store/slices/authSlice';
import { OpenCustomAlerts } from 'store/slices/commonSlice';


const BillingDetailsEditForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();
    const rightDrawerOpened = useSelector((state) => state.packageplan.isopenBillingDetailEditForm);
    const detail = useSelector((state) => state.billing.isDetailData);
    useEffect(() => {
        dispatch(billingDetailApi())
    }, []);
    const initialValues = {
        business_name: "",
        first_name: "",
        last_name: "",
        address: "",
        street: "",
        suburb: "",
        state: "",
        postcode: "",
    };
    const validationSchema = Yup.object().shape({
        business_name: Yup.string().trim().max(100).label(t("Business Name")).required(),
        first_name: Yup.string().trim().max(100).label(t("First Name")).required(),
        last_name: Yup.string().trim().max(100).label(t("Last Name")).required(),
        address: Yup.string().trim().label(t("Address")),
        street: Yup.string().trim().label(t("Street")),
        suburb: Yup.string().trim().label(t("Suburb")),
        state: Yup.string().trim().label(t("State")),
        postcode: Yup.string().trim().max(12).label(t("Postcode")),
    })
    yupconfig();
    const handleBillingAddressSubmit = (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        setLoading(true);
        try {
            dispatch(BillingStoreApi(values)).then((action) => {
                if (action.meta.requestStatus === "fulfilled") {
                    setStatus({ success: true });
                    setLoading(false);
                    dispatch(OpenBillingDetailEditForm(""));
                    dispatch(GetUser());
                    customToast.success(t("Billing Address Updated Successfully"));
                    dispatch(billingDetailApi());
                } else if (action.meta.requestStatus === "rejected") {
                    const status = action.payload && action.payload.status;
                    const errors = action.payload && action.payload.message && action.payload.message.errors;
                    const response = action.payload && action.payload.message && action.payload.message;
                    if (status === 422) {
                        dispatch(
                            OpenCustomAlerts({
                                isOpen: "open",
                                errors: (
                                    <>
                                      <p className="mb-2 text-danger salert text-justify">{response && response.message}</p>
                                      <ul className="list-unstyled">
                                        {errors &&
                                          Object.keys(errors).map((a, n) => {
                                            if (errors[a].length > 0) {
                                              return (
                                                <>
                                                  {errors[a].map((as, ns) => {
                                                    return (
                                                      <li key={n + "-" + ns} className="text-danger salert form-text text-start">
                                                        {as}
                                                      </li>
                                                    );
                                                  })}
                                                </>
                                              );
                                            }
                                          })}
                                      </ul>
                                    </>
                                  )
                            })
                        );
                        setErrors(errors);
                    }
                    setLoading(false);
                    setStatus({ success: false });
                    setSubmitting(false);
                }
            });
        } catch (err) {
            setErrors(err.message);
            setStatus({ success: false });
            setLoading(false);
        }
    }
    return (
        <>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleBillingAddressSubmit}>
                {(formik) => {
                    useEffect(() => {
                        if (detail) {
                          const fields = ["id","business_name","first_name", "last_name","address", "street", "suburb", "state", "postcode"];
                          fields.forEach((field) => {
                              let detail_field = detail[field] ? detail[field] : "";
                              formik.setFieldValue(field, formik.values[field] ? formik.values[field] : detail_field, false);
                          });
                        }
                      }, [detail]);
                    return (
                        <div className={(rightDrawerOpened ? "full-screen-drawer p-0 " : "") + rightDrawerOpened} id="addproduct-drawer">
                            <div className="drawer-wrp position-relative">
                                <div className="drawer-header px-md-4 px-3 py-3 d-flex flex-wrap align-items-center">
                                    <h3 className="mb-0 fw-semibold">{t("Update Billing Address")}</h3>
                                    <div className="ms-auto">
                                        <a className="close btn btn-cancel me-1 cursor-pointer" onClick={() => {
                                            dispatch(OpenBillingDetailEditForm(""));
                                        }}>
                                            {t("Cancel")}
                                        </a>
                                    </div>
                                </div>
                                <div className="drawer-body px-4 pt-2">
                                    <form noValidate onSubmit={formik.handleSubmit}>
                                        <LoadScript googleMapsApiKey={config.GOOGLE_API_KEY} libraries={["places"]} loadingElement={<div className="loader-spinner-center"><SpinLoader /></div>}>
                                            <div className="row">
                                                <div className="col-xl-4 col-sm-6 m-auto">
                                                    <div className="col-md-12 mb-4">
                                                        <InputField type="text" name="business_name" value={formik.values.business_name} label={t("Business Name")} controlId="businessForm-business_name" />
                                                    </div>
                                                    <div className="col-md-12 mb-4">
                                                        <InputField type="text" name="first_name" value={formik.values.first_name} label={t("First Name")} controlId="clientForm-first_name" />
                                                    </div>
                                                    <div className="col-md-12 mb-4">
                                                        <InputField type="text" name="last_name" value={formik.values.last_name} label={t("Last Name")} controlId="clientForm-last_name" />
                                                    </div>
                                                    <div className="col-md-12 mb-4">
                                                        <StandaloneSearchBox
                                                            onLoad={ref => inputRef.current = ref}
                                                            onPlacesChanged={(e) => {
                                                                const [place] = inputRef.current.getPlaces();
                                                                let streetAddress = "";
                                                                if (place) {
                                                                    formik.setFieldValue("address", place.formatted_address);
                                                                    for (var i = 0; i < place.address_components.length; i++) {
                                                                        for (var j = 0; j < place.address_components[i].types.length; j++) {
                                                                            if (place.address_components[i].types[j] == "country") {
                                                                                formik.setFieldValue("state", place.address_components[i].long_name);
                                                                            } else if (place.address_components[i].types[j] == "postal_code") {
                                                                                formik.setFieldValue("postcode", place.address_components[i].long_name);
                                                                            } else if (place.address_components[i].types[j] == "street_number") {
                                                                                streetAddress = place.address_components[i].long_name;
                                                                            } else if (place.address_components[i].types[j] == "route") {
                                                                                streetAddress += (streetAddress.length != 0) ? ' ' + place.address_components[i].long_name : place.address_components[i].long_name;
                                                                                formik.setFieldValue("street", streetAddress);
                                                                            } else if (place.address_components[i].types[j] == "locality") {
                                                                                formik.setFieldValue("suburb", place.address_components[i].long_name);
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <MapAddressField name="address" label={t("Address")} value={formik.values.address} placeholder={t("Start typing address")} controlId="clientForm-address" />
                                                        </StandaloneSearchBox>
                                                    </div>
                                                    <div className="col-md-12 mb-4">
                                                        <InputField type="text" name="street" value={formik.values.street} label={t("Street")} controlId="clientForm-street" />
                                                    </div>
                                                    <div className="row gx-2">
                                                        <div className="col-sm-5 mb-3">
                                                            <InputField type="text" name="suburb" value={formik.values.suburb} label={t("Suburb")} controlId="clientForm-suburb" />
                                                        </div>
                                                        <div className="col-sm-4 col-6 mb-3">
                                                            <InputField type="text" name="state" value={formik.values.state} label={t("State")} controlId="clientForm-state" />
                                                        </div>
                                                        <div className="col-sm-3 col-6 mb-3">
                                                            <InputField type="text" name="postcode" value={formik.values.postcode} label={t("Postcode")} controlId="clientForm-postcode" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-2">
                                                        <button type="submit" className="btn btn-primary w-100 btn-lg" disabled={loading}>
                                                            {loading && <span className="spinner-border spinner-border-sm"></span>}
                                                            {t("Update Billing Address")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </LoadScript>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Formik>
        </>
    )
}

export default BillingDetailsEditForm;