import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import creditcardupdateApiController from "services/creditcardupdate.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const CreditCardStoreApi = createAsyncThunk("creditcard/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await creditcardupdateApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});


export const creditcardDetailApi = createAsyncThunk("creditcard/view", async (formValues, thunkAPI) => {
  try {
    const resposedata = await creditcardupdateApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "creditcarddetail"))
      .catch((error) => HandleError(thunkAPI, error, "creditcarddetail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CreditCardUpdateApi = createAsyncThunk("creditcard/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await creditcardupdateApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

// export const creditcardDeleteApi = createAsyncThunk("creditcard/delete", async (formValues, thunkAPI) => {
//     try {
//       const resposedata = await creditcardupdateApiController
//         .deleted(formValues, thunkAPI)
//         .then((response) => HandleResponse(thunkAPI, response, "delete"))
//         .catch((error) => HandleError(thunkAPI, error, "delete"));
//       return resposedata;
//     } catch (error) {
//       const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   });

const initialState = {
  isCreditCardUpdates: [],
  isCreditCardDetailData: "",
  isCreditCardViewData: "",
  isApiStatus: {
    CreditCardStoreApi: "",
    CreditCardUpdateApi: "",
    creditcardDeleteApi: "",
    creditcardDetailApi: "",
  }
}

export const creditcardSlice = createSlice({
  name: "creditcard",
  initialState,
  reducers: {
    reset: () => initialState,

  },
  extraReducers: (builder) => {
    builder
      .addCase(CreditCardStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CreditCardStoreApi: "loading" };
      })
      .addCase(CreditCardStoreApi.fulfilled, (state, action) => {
        if (state.isCreditCardUpdates && state.isCreditCardUpdates.data) {
          state.isCreditCardUpdates.data = [action.payload, ...state.isCreditCardUpdates.data];
        } else {
          state.isCreditCardUpdates = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, CreditCardStoreApi: "succeeded" };
      })
      .addCase(CreditCardStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CreditCardStoreApi: "failed" };
      })
      .addCase(creditcardDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, creditcardDetailApi: "loading" };
      })
      .addCase(creditcardDetailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, creditcardDetailApi: "succeeded" };
        state.isCreditCardViewData = action.payload;
      })
      .addCase(creditcardDetailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, creditcardDetailApi: "failed" };
        state.isCreditCardViewData = "";
      })
      .addCase(CreditCardUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CreditCardUpdateApi: "loading" };
      })
      .addCase(CreditCardUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isCreditCardUpdates && state.isCreditCardUpdates.data && state.isCreditCardUpdates.data.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, CreditCardUpdateApi: "succeeded" };
      })
      .addCase(CreditCardUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CreditCardUpdateApi: "failed" };
      });
  },
});

export const { reset } = creditcardSlice.actions;
export default creditcardSlice.reducer;