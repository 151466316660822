import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";
import moment from "moment";

const API_URL = config.API_URL;

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/salonsubscriptionplan/view?page=${page}` : `afterlogin/salonsubscriptionplan/view?`;
  const data = {
      auth_key: auth_key,
      action: action,
      // pagination: pagination,
      pagination: pagination, //true or false
      id: values && values.id ? values.id : "",
      result: result, //business_name,owner_name
    };
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};
const paymentshistory = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const type = values && values.type ? values.type : "";
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/salons/paymenthistory?page=${page}` : `afterlogin/salons/paymenthistory?`;
  const data = {
      auth_key: auth_key,
      action: action,
      // pagination: pagination,
      type:type,
      pagination: false, //true or false
      id: values && values.id ? values.id : "",
      result: result, //business_name,owner_name
    };
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const salonsubscriptionplanApiController = {
  view,
  paymentshistory
};
export default salonsubscriptionplanApiController;
