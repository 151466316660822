import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/reviewcampaign/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const update = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const clickEvent = values && values.clickEvent;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value] ? values[value] : "");
  }
  let action = "afterlogin/reviewcampaign/update/" + values.id;
  if (clickEvent && clickEvent === "statusupdate") {
    action = "afterlogin/reviewcampaign/isactive/" + values.id;
  }
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const review_type = values && values.review_type ? values.review_type : "";
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/reviewcampaign/view?page=${page}` : `afterlogin/reviewcampaign/view`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: pagination, //true or false
    id: values && values.id ? values.id : "",
    client_id: values && values.client_id ? values.client_id : "",
    result: result, //business_name,owner_name
    review_type: review_type, //business_name,owner_name
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const deleted = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/reviewcampaign/delete/${values.id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const services = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  let review_campaign_id = values && values.review_campaign_id ? values.review_campaign_id : "";
  const action = page ? `afterlogin/reviewcampaign/services?page=${page}&review_campaign_id=${review_campaign_id}` : `afterlogin/reviewcampaign/services?review_campaign_id=${review_campaign_id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const sendemail = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = "afterlogin/reviewcampaign/sendemail";
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value] ? values[value] : "");
  }
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const ReviewCampaignApiController = {
  create,
  update,
  view,
  deleted,
  services,
  sendemail,
};
export default ReviewCampaignApiController;
