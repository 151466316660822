import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import config from "../../../../config"
import { TaxValueApi } from "store/slices/taxSlice";
import { ActiveBtnState, ClientInvoiceRefundData, OpenClientInvoiceDetails, OpenClientInvoiceRefundModal, OpenPartialPaidInvoice, RefundStripeToken } from "store/slices/clientinvoiceSlice";
import { useTranslation } from "react-i18next";
import getStripeClient from "pages/getStripeClient";
import { Elements } from "@stripe/react-stripe-js";
import { ActiveBtnStateClientAppointments, GetTotalDepositAmount, OpenClientRefundSelection, OpenRefundInvoice, StripeTokenClientAppointments } from "store/slices/clientappointmentinvoiceSlice";

const PartialPaidInvoiceAppointments = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth);
    const stripePromise = getStripeClient();
    const currentUser = auth.user;
    const rightDrawerOpened = useSelector((state) => state.clientpastinvoice.isOpenRefundInvoice);
    const isRefundInvoiceData = useSelector((state) => state.clientpastinvoice.isRefundInvoiceData);
    const isSaleCompletedData = useSelector((state) => state.clientinvoice.isClientInvoiceDetailsTabData);
    let TaxInvoiceNumber = isRefundInvoiceData && isRefundInvoiceData.sale && isRefundInvoiceData.sale.invoice_number ? isRefundInvoiceData.sale.invoice_number : "";
    let InvoiceDate = isRefundInvoiceData && isRefundInvoiceData.sale && isRefundInvoiceData.sale.invoicedate ? isRefundInvoiceData.sale.invoicedate : "";
    const formattedInvoiceDate = InvoiceDate ? moment(InvoiceDate, "YYYY-MM-DD").format('Do MMMM YYYY') : "";
    const clientFullName = isRefundInvoiceData && isRefundInvoiceData.client && (isRefundInvoiceData.client.first_name && isRefundInvoiceData.client.last_name) ? isRefundInvoiceData.client.first_name + " " + isRefundInvoiceData.client.last_name : "";
    const InvoiceTax = useSelector((state) => state.tax.isTaxValue);
    let taxPercentage = InvoiceTax && InvoiceTax.percentage;
    let total_pay = isRefundInvoiceData && isRefundInvoiceData.cost;
    // const lastPayments = isRefundInvoiceData && isRefundInvoiceData?.lastpayment && isSaleCompletedData.lastpayment.length > 0 ? isSaleCompletedData.lastpayment[0] : [];
    let ServiceName = isRefundInvoiceData && isRefundInvoiceData.service && isRefundInvoiceData.service.name ? isRefundInvoiceData.service.name : "";
    let staffFullName = isRefundInvoiceData && isRefundInvoiceData.staff && (isRefundInvoiceData.staff.first_name && isRefundInvoiceData.staff.last_name) ? isRefundInvoiceData.staff.first_name + " " + isRefundInvoiceData.staff.last_name : "";
    let ServiceCosts = isRefundInvoiceData && isRefundInvoiceData.cost ? isRefundInvoiceData.cost : "";
    let serviceStartTimes = isRefundInvoiceData && isRefundInvoiceData.start_time ? isRefundInvoiceData.start_time : "";
    let serviceStart = moment(serviceStartTimes, "HH:mm:ss").format("hh:mm A");
    let serviceEndsTimes = isRefundInvoiceData && isRefundInvoiceData.end_time ? isRefundInvoiceData.end_time : "";
    let serviceEnds = moment(serviceEndsTimes, "HH:mm:ss").format("hh:mm A");
    let totalpriceTax = isRefundInvoiceData && isRefundInvoiceData.sale && isRefundInvoiceData.sale.totalprice ? taxPercentage ? isRefundInvoiceData.sale.totalprice / taxPercentage : "" : "";
    let deposit = isRefundInvoiceData && isRefundInvoiceData.sale && isRefundInvoiceData.sale.totaldeposit ? isRefundInvoiceData.sale.totaldeposit : "0";
    let appointmentTime = isRefundInvoiceData && isRefundInvoiceData.dateof ? moment(isRefundInvoiceData.dateof, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
    const PrimaryServiceCost = isRefundInvoiceData && isRefundInvoiceData.cost;
    let TotalServiceCosts = isRefundInvoiceData &&
        (isRefundInvoiceData.cost || 0) +
        (isRefundInvoiceData.addanotherservices && isRefundInvoiceData.addanotherservices.length > 0
            ? isRefundInvoiceData.addanotherservices.reduce((sum, item) => {
                return sum + parseFloat(item.cost);
            }, 0)
            : 0) +
        (isRefundInvoiceData.addonservices && isRefundInvoiceData.addonservices.length > 0
            ? isRefundInvoiceData.addonservices.reduce((sum, item) => {
                return sum + parseFloat(item.cost);
            }, 0)
            : 0)
    let tax = taxPercentage && TotalServiceCosts ? TotalServiceCosts / taxPercentage : "";
    const depositPaid = isRefundInvoiceData && isRefundInvoiceData.payment.length > 0 && isRefundInvoiceData.payment.filter((payment) => payment.type === "Deposit").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
    const depositRefund = isRefundInvoiceData && isRefundInvoiceData.payment.length > 0 && isRefundInvoiceData.payment.filter((payment) => payment.type === "DepositRefund").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
    const depositBalance = depositPaid >= depositRefund ? depositPaid - depositRefund : 0;
    const Balance = TotalServiceCosts > 0 ? TotalServiceCosts - depositBalance : 0;

    useEffect(() => {
        dispatch(TaxValueApi());
    }, []);

    return (
        <div className={"drawer client-invoice-drawer border-start " + rightDrawerOpened}>
            <div className="drawer-wrp position-relative">
                <div className="drawer-header">
                    <h2 className="mb-4 pe-md-5 pe-3">Invoice</h2>
                    <a href="#" className="close" onClick={() => {
                        dispatch(OpenRefundInvoice(""));
                    }}>
                        <img src={config.imagepath + "close-icon.svg"} alt="" />
                    </a>
                </div>
                <hr />
                <div className="drawer-body d-flex flex-wrap">
                    <ul className="sale-comlete-data list-unstyled">
                        <li>
                            <h5 className="mb-1 fw-semibold">Tax Invoice {TaxInvoiceNumber ? TaxInvoiceNumber : "#0001"}</h5>
                            <p className="mb-0">{formattedInvoiceDate}</p>
                        </li>
                        <hr />
                        <li>
                            <label>
                                Invoice to:
                                <label>
                                    <h6 className="mb-0">{clientFullName}</h6>
                                </label>
                            </label>
                        </li>
                        <hr />
                        <li className="mb-3">
                            <div className="row gx-1 justify-content-between">
                                <div className="col-10">
                                    <label htmlFor="">{ServiceName}</label>
                                    <h6 className="mb-1">With {staffFullName} on {appointmentTime} from {serviceStart + " - " + serviceEnds}</h6>
                                    <span>Quantity: 1</span>
                                </div>
                                <label className="col-2 text-end">${total_pay ? total_pay : "0"}</label>
                            </div>
                        </li>
                        {isRefundInvoiceData && isRefundInvoiceData.addonservices && isRefundInvoiceData.addonservices.length > 0 ? (
                            <>
                                {/* <hr />
                                <li className="mb-3">
                                    <div className="row gx-1 justify-content-between">
                                        <div className="col-12">
                                            <label htmlFor="">Add On Services</label>
                                        </div>
                                    </div>
                                </li> */}
                                {isRefundInvoiceData.addonservices.map((item, i) => {
                                    const addonServiceName = item && item.service && item.service.name;
                                    let addonstaffFullName = item && item.staff && (item.staff.first_name && item.staff.last_name) ? item.staff.first_name + " " + item.staff.last_name : "";
                                    let addonserviceStartTimes = item && item.start_time ? item.start_time : "";
                                    let addonserviceStart = moment(addonserviceStartTimes, "HH:mm:ss").format("hh:mm A");
                                    let addonserviceEndsTimes = item && item.end_time ? item.end_time : "";
                                    let addonserviceEnds = moment(addonserviceEndsTimes, "HH:mm:ss").format("hh:mm A");
                                    let addonappointmentTime = item && item.dateof ? moment(item.dateof, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
                                    let addonTotalPay = item && item.cost ? item.cost : "";

                                    return (
                                        <React.Fragment key={i}>
                                            <hr />
                                            <li className="mb-3">
                                                <div className="row gx-1 justify-content-between">
                                                    <div className="col-10">
                                                        <label htmlFor="">{addonServiceName ? addonServiceName : ""}</label>
                                                        <h6 className="mb-1">With {addonstaffFullName} on {addonappointmentTime} from {addonserviceStart + " - " + addonserviceEnds}</h6>
                                                        <span>Quantity: 1</span>
                                                    </div>
                                                    <label className="col-2 text-end">${addonTotalPay}</label>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    )
                                })}
                            </>
                        ) : ""}
                        {isRefundInvoiceData && isRefundInvoiceData.addanotherservices && isRefundInvoiceData.addanotherservices.length > 0 ? (
                            <>
                                {/* <hr />
                                <li className="mb-3">
                                    <div className="row gx-1 justify-content-between">
                                        <div className="col-12">
                                            <label htmlFor="">Add Another Service</label>
                                        </div>
                                    </div>
                                </li> */}
                                {isRefundInvoiceData.addanotherservices.map((item, i) => {
                                    const addanotherServiceName = item && item.service && item.service.name;
                                    let addanotherstaffFullName = item && item.staff && (item.staff.first_name && item.staff.last_name) ? item.staff.first_name + " " + item.staff.last_name : "";
                                    let addanotherserviceStartTimes = item && item.start_time ? item.start_time : "";
                                    let addanotherserviceStart = moment(addanotherserviceStartTimes, "HH:mm:ss").format("hh:mm A");
                                    let addanotherserviceEndsTimes = item && item.end_time ? item.end_time : "";
                                    let addanotherserviceEnds = moment(addanotherserviceEndsTimes, "HH:mm:ss").format("hh:mm A");
                                    let addanotherappointmentTime = item && item.dateof ? moment(item.dateof, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
                                    let addanotherTotalPay = item && item.cost ? item.cost : "";

                                    return (
                                        <React.Fragment key={i}>
                                            <hr />
                                            <li className="mb-3">
                                                <div className="row gx-1 justify-content-between">
                                                    <div className="col-10">
                                                        <label htmlFor="">{addanotherServiceName ? addanotherServiceName : ""}</label>
                                                        <h6 className="mb-1">With {addanotherstaffFullName} on {addanotherappointmentTime} from {addanotherserviceStart + " - " + addanotherserviceEnds}</h6>
                                                        <span>Quantity: 1</span>
                                                    </div>
                                                    <label className="col-2 text-end">${addanotherTotalPay}</label>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    )
                                })}
                            </>
                        ) : ""}
                        {currentUser && currentUser.salon && currentUser.salon.tax && currentUser.salon.tax.name === "Non-GST" ? "" : (

                            <li className="my-3">
                                <div className="row gx-1 justify-content-between">
                                    <div className="col-10">
                                        <h6 className="mb-0">Includes GST of</h6>
                                    </div>
                                    <h6 className="mb-0 col-2 text-end">${tax ? parseFloat(tax).toFixed(2) : "0"}</h6>
                                </div>
                            </li>
                        )}
                        <li className="total py-3">
                            <div className="row gx-1 justify-content-between">
                                <div className="col-10">
                                    <h6 className="mb-0">Total Cost</h6>
                                </div>
                                <h6 className="mb-0 col-2 text-end">
                                    $
                                    {isRefundInvoiceData &&
                                        (isRefundInvoiceData.cost || 0) + // Static cost (default to 0 if not present)
                                        (isRefundInvoiceData.addanotherservices && isRefundInvoiceData.addanotherservices.length > 0
                                            ? isRefundInvoiceData.addanotherservices.reduce((sum, item) => {
                                                return sum + parseFloat(item.cost);
                                            }, 0)
                                            : 0) +
                                        (isRefundInvoiceData.addonservices && isRefundInvoiceData.addonservices.length > 0
                                            ? isRefundInvoiceData.addonservices.reduce((sum, item) => {
                                                return sum + parseFloat(item.cost);
                                            }, 0)
                                            : 0)}
                                </h6>
                                {/* <h6 className="mb-0 col-2 text-end">${ServiceCosts ? ServiceCosts : "0"}</h6> */}
                            </div>
                        </li>
                        {/* {isRefundInvoiceData && isRefundInvoiceData.lastpayment && isRefundInvoiceData.lastpayment.paidby && (
                            <>
                                <li className="py-3">
                                    <div className="row gx-1 justify-content-between">
                                        <div className="col-10">
                                            <h6 className="mb-0">{`Payment By ${isRefundInvoiceData.lastpayment.paidby ? isRefundInvoiceData.lastpayment.paidby : ""}`}</h6>
                                        </div>
                                        <h6 className="mb-0 col-2 text-end">${deposit}</h6>
                                    </div>
                                </li>
                                <hr className="my-0" />
                            </>
                        )} */}
                        {isRefundInvoiceData && isRefundInvoiceData.payment && isRefundInvoiceData.payment.length > 0 ? (
                            isRefundInvoiceData.payment.map((paymentItem) => {
                                const paidByStatus = paymentItem && paymentItem.paidby ? paymentItem.paidby : "";
                                const amount = paymentItem && paymentItem.amount ? paymentItem.amount : "";
                                if (paymentItem && paymentItem.type === "Deposit") {
                                    return (
                                        <>
                                            <li className="py-3">
                                                <div className="row gx-1 justify-content-between">
                                                    <div className="col-10">
                                                        <h6 className="mb-0">{`Deposit Paid by ${paidByStatus}`}</h6>
                                                    </div>
                                                    <h6 className="mb-0 col-2 text-end">${amount}</h6>
                                                </div>
                                            </li>
                                            <hr className="my-0" />
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <li className="py-3">
                                                <div className="row gx-1 justify-content-between">
                                                    <div className="col-10">
                                                        <h6 className="mb-0">{`Refund By ${paidByStatus}`}</h6>
                                                    </div>
                                                    <h6 className="mb-0 col-2 text-end">${amount}</h6>
                                                </div>
                                            </li>
                                            <hr className="my-0" />
                                        </>
                                    )
                                }
                            })
                        ) : ""}
                        {/* {isRefundInvoiceData && isRefundInvoiceData.sale && isRefundInvoiceData.sale.refund && isRefundInvoiceData.sale.refund.length > 0 ? (
                            isRefundInvoiceData.sale.refund.map((salepaymentItem)=>{
                                console.log("paymentItem",salepaymentItem)
                                const paidByStatus = salepaymentItem && salepaymentItem.paidby ? salepaymentItem.paidby : "";
                                const amount = salepaymentItem && salepaymentItem.amount ? salepaymentItem.amount : "";
                                if(salepaymentItem && salepaymentItem.type === "Deposit"){
                                    return(
                                        <>
                                        <li className="py-3">
                                            <div className="row gx-1 justify-content-between">
                                                <div className="col-10">
                                                    <h6 className="mb-0">{`Deposit Paid by ${paidByStatus}`}</h6>
                                                </div>
                                                <h6 className="mb-0 col-2 text-end">${amount}</h6>
                                            </div>
                                        </li>
                                        <hr className="my-0" />
                                    </>
                                    )
                                }else{
                                    return (
                                        <>
                                        <li className="py-3">
                                            <div className="row gx-1 justify-content-between">
                                                <div className="col-10">
                                                    <h6 className="mb-0">{`Paid By ${paidByStatus}`}</h6>
                                                </div>
                                                <h6 className="mb-0 col-2 text-end">${amount}</h6>
                                            </div>
                                        </li>
                                        <hr className="my-0" />
                                        </>
                                    )
                                }
                            })
                        ):""} */}
                        <li className="py-3">
                                <div className="row gx-1 justify-content-between">
                                    <div className="col-10">
                                        <h6 className="mb-0">Balance</h6>
                                    </div>
                                    <h6 className="mb-0 col-2 text-end">${(TotalServiceCosts ?? 0) - (depositPaid ?? 0)}</h6>
                                    {/* <h6 className="mb-0 col-2 text-end">${TotalServiceCosts - depositPaid + depositRefund}</h6> */}
                                    {/* <h6 className="mb-0 col-2 text-end">${Balance}</h6> */}
                                </div>
                            </li>
                        {depositRefund && depositRefund > 0 ? (
                            <>
                                <hr className="my-0" />
                                <li className="py-3">
                                    <div className="row gx-1 justify-content-between">
                                        <label className="mb-0 fw-normal col-10">Refund issued</label>
                                        <label className="mb-0 fw-normal col-2 text-end">${depositRefund ? depositRefund : 0}</label>
                                    </div>
                                </li>
                            </>
                        ) : ""}
                        {depositBalance && depositBalance > 0 ? (
                            <li className="my-2">
                                <div className="row gx-1 justify-content-between">
                                    <button
                                        type="button"
                                        className="btn btn-apricots cursor-pointer"
                                        onClick={() => {
                                            dispatch(GetTotalDepositAmount(depositBalance))
                                            dispatch(StripeTokenClientAppointments(""));
                                            dispatch(ActiveBtnStateClientAppointments(""));
                                            dispatch(OpenClientRefundSelection("open"));
                                        }}
                                    >
                                        {t("Refund")}
                                    </button>
                                </div>
                            </li>
                        ) : ""}
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default PartialPaidInvoiceAppointments;



