import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import printerApiController from "services/printer.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const CalendarAppointmentPrintApi = createAsyncThunk("printer/calendar-appointment-print", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await printerApiController
      .calendarappointmentprints(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "calendar-appointment-print"))
      .catch((error) => HandleError(thunkAPI, error, "calendar-appointment-print"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});
export const settingPlanhistoryApi = createAsyncThunk("printer/planhistory", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await printerApiController
      .settingPlanhistory(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "settingplanhistory"))
      .catch((error) => HandleError(thunkAPI, error, "settingplanhistory"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const clientAppointmentTabInvoiceApi = createAsyncThunk("printer/appointmenthistory", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await printerApiController
      .clientAppointmentTabInvoice(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "clientappointmenthistory"))
      .catch((error) => HandleError(thunkAPI, error, "clientappointmenthistory"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const clientDetailPrinterApi = createAsyncThunk("printer/details", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await printerApiController
      .clientdetail(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "clientdetails"))
      .catch((error) => HandleError(thunkAPI, error, "clientdetails"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const clientDetailAppointmentPrintApi = createAsyncThunk("printer/appointment", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await printerApiController
      .clientDetailAppointmentPrint(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "clientappointment"))
      .catch((error) => HandleError(thunkAPI, error, "clientappointment"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const clientDetailInvoicePrintApi = createAsyncThunk("printer/invoices", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await printerApiController
      .clientinvoicesprint(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "clientinvoices"))
      .catch((error) => HandleError(thunkAPI, error, "clientinvoices"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const saleVoucherPrintApi = createAsyncThunk("printer/vouchersale", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await printerApiController
      .saleVoucherPrint(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "salevoucher"))
      .catch((error) => HandleError(thunkAPI, error, "salevoucher"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const calendarPrintApi = createAsyncThunk("printer/printercalendar", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await printerApiController
      .calendarPrinter(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "calendarprinter"))
      .catch((error) => HandleError(thunkAPI, error, "calendarprinter"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const reportPrintApi = createAsyncThunk("printer/printerreport", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await printerApiController
      .reportPrinter(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "calendarprinter"))
      .catch((error) => HandleError(thunkAPI, error, "calendarprinter"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});


const initialState = {
  issettingPlanhistoryApi: "",
  isclientAppointmentTabInvoice: "",
  isclientDetailprinterApi: "",
  issaleVoucherPrint: "",
  issettingPlanhistoryApi: "",
  iscalendarPrinter: "",
  isreportPrinter: "",
  isclientDetailAppointmentPrint:[],
  isCalendarAppointmentPrint:[],
  isApiStatus: {
    settingPlanhistoryApi: "",
    clientAppointmentTabInvoiceApi: "",
    clientDetailPrinterApi: "",
    clientDetailAppointmentPrintApi: "",
    saleVoucherPrintApi: "",
    calendarPrintApi: "",
    reportPrintApi: "",
    CalendarAppointmentPrintApi:""
  },
};

const printerSlice = createSlice({
  name: "printer",
  initialState,
  reducers: {
    reset: () => initialState,

  },
  extraReducers: (builder) => {
    builder
      // Add your extra reducers here using the addCase method
      .addCase(CalendarAppointmentPrintApi.pending, (state) => {
        state.isApiStatus.CalendarAppointmentPrintApi = "loading";
      })
      .addCase(CalendarAppointmentPrintApi.fulfilled, (state, action) => {
        state.isCalendarAppointmentPrint = action.payload;
        state.isApiStatus.CalendarAppointmentPrintApi = "succeeded";
      })
      .addCase(CalendarAppointmentPrintApi.rejected, (state) => {
        state.isCalendarAppointmentPrint = [];
        state.isApiStatus.CalendarAppointmentPrintApi = "failed";
      })
      .addCase(settingPlanhistoryApi.pending, (state) => {
        state.isApiStatus.settingPlanhistoryApi = "loading";
      })
      .addCase(settingPlanhistoryApi.fulfilled, (state, action) => {
        state.issettingPlanhistoryApi = action.payload;
        state.isApiStatus.settingPlanhistoryApi = "succeeded";
      })
      .addCase(settingPlanhistoryApi.rejected, (state) => {
        state.issettingPlanhistoryApi = [];
        state.isApiStatus.settingPlanhistoryApi = "failed";
      })
      .addCase(clientAppointmentTabInvoiceApi.pending, (state) => {
        state.isApiStatus.clientAppointmentTabInvoiceApi = "loading";
      })
      .addCase(clientAppointmentTabInvoiceApi.fulfilled, (state, action) => {
        state.isclientAppointmentTabInvoice = action.payload;
        state.isApiStatus.clientAppointmentTabInvoiceApi = "succeeded";
      })
      .addCase(clientAppointmentTabInvoiceApi.rejected, (state) => {
        state.isclientAppointmentTabInvoice = [];
        state.isApiStatus.clientAppointmentTabInvoiceApi = "failed";
      })
      .addCase(clientDetailPrinterApi.pending, (state) => {
        state.isApiStatus.clientDetailPrinterApi = "loading";
      })
      .addCase(clientDetailPrinterApi.fulfilled, (state, action) => {
        state.isclientDetailprinterApi = action.payload;
        state.isApiStatus.clientDetailPrinterApi = "succeeded";
      })
      .addCase(clientDetailPrinterApi.rejected, (state) => {
        state.isclientDetailprinterApi = [];
        state.isApiStatus.clientDetailPrinterApi = "failed";
      })
      .addCase(saleVoucherPrintApi.pending, (state) => {
        state.isApiStatus.saleVoucherPrintApi = "loading";
      })
      .addCase(saleVoucherPrintApi.fulfilled, (state, action) => {
        state.issaleVoucherPrint = action.payload;
        state.isApiStatus.saleVoucherPrintApi = "succeeded";
      })
      .addCase(saleVoucherPrintApi.rejected, (state) => {
        state.issaleVoucherPrint = [];
        state.isApiStatus.saleVoucherPrintApi = "failed";
      })
      .addCase(calendarPrintApi.pending, (state) => {
        state.isApiStatus.calendarPrintApi = "loading";
      })
      .addCase(calendarPrintApi.fulfilled, (state, action) => {
        state.iscalendarPrinter = action.payload;
        state.isApiStatus.calendarPrintApi = "succeeded";
      })
      .addCase(calendarPrintApi.rejected, (state) => {
        state.iscalendarPrinter = [];
        state.isApiStatus.calendarPrintApi = "failed";
      })
      .addCase(reportPrintApi.pending, (state) => {
        state.isApiStatus.reportPrintApi = "loading";
      })
      .addCase(reportPrintApi.fulfilled, (state, action) => {
        state.isreportPrinter = action.payload;
        state.isApiStatus.reportPrintApi = "succeeded";
      })
      .addCase(reportPrintApi.rejected, (state) => {
        state.isreportPrinter = [];
        state.isApiStatus.reportPrintApi = "failed";
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset } = printerSlice.actions;
export default printerSlice.reducer;