import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
import businessEmailVerifyApiController from "services/businesEmailVerification.service";

export const BusinessEmailVerifyApi = createAsyncThunk("businessEmail/verify", async (formValues, thunkAPI) => {
  try {
    const resposedata = await businessEmailVerifyApiController
      .emailverifys(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "verify"))
      .catch((error) => HandleError(thunkAPI, error, "verify"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


const initialState = {
  isLoading: false,
  isOpenClientSideEmailVerification: "",
  isOtpVerificationsData: "",
  isSetLoadingsApi: false,
  isApiStatus: {
    BusinessEmailVerifyApi: ""
  },
};

const emailverificationSlice = createSlice({
  name: "emailverification",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenClientSideEmailVerification: (state, action) => {
      state.isOpenClientSideEmailVerification = action.payload;
    },
    OtpVerificationsData: (state, action) => {
      state.isOtpVerificationsData = action.payload;
    },
    verificationLoadingFalse: (state, action) => {
      state.isOtpVerificationsData = action.payload;
    },
    setLoadingsApi: (state, action) => {
      state.isSetLoadingsApi = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(BusinessEmailVerifyApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BusinessEmailVerifyApi: "loading" };
      })
      .addCase(BusinessEmailVerifyApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BusinessEmailVerifyApi: "succeeded" };
      })
      .addCase(BusinessEmailVerifyApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BusinessEmailVerifyApi: "failed" };
      });
  },
});

export const { reset, OpenClientSideEmailVerification, OtpVerificationsData, verificationLoadingFalse, setLoadingsApi } = emailverificationSlice.actions;
export default emailverificationSlice.reducer;
