import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import notificationApiController from "../../services/notification.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const NotifyDetailListViewApi = createAsyncThunk("notification/NotifyListView", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await notificationApiController
      .notifylistview(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "NotifyListView"))
      .catch((error) => HandleError(thunkAPI, error, "NotifyListView"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const NotifyDetailUpdateApi = createAsyncThunk("notification/detailupdate", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await notificationApiController
      .notifyupdate(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const NotifyDetailActiveApi = createAsyncThunk("notification/isactive", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await notificationApiController
      .notifyisactive(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "isactive"))
      .catch((error) => HandleError(thunkAPI, error, "isactive"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const NotificationListApi = createAsyncThunk("notification/lists", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await notificationApiController
      .notificationlist(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "notificationlists"))
      .catch((error) => HandleError(thunkAPI, error, "notificationlists"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const NotificationUpdateApi = createAsyncThunk("notification/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await notificationApiController
      .notificationupdate(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "notificationupdate"))
      .catch((error) => HandleError(thunkAPI, error, "notificationupdate"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const notificationMarkAllAsReadApi = createAsyncThunk("notification/markasread", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await notificationApiController
      .markasread(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "notificationmarkasread"))
      .catch((error) => HandleError(thunkAPI, error, "notificationmarkasread"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const notificationClearAllApi = createAsyncThunk("notification/clearall", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await notificationApiController
      .clearall(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "notificationclearall"))
      .catch((error) => HandleError(thunkAPI, error, "notificationclearall"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const notificationCountApi = createAsyncThunk("notification/count", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await notificationApiController
      .notificationcounts(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "notificationclearall"))
      .catch((error) => HandleError(thunkAPI, error, "notificationclearall"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const NotifyDetailApi = createAsyncThunk("notification/notifydetails", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await notificationApiController
      .notifydetails(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "notifydetails"))
      .catch((error) => HandleError(thunkAPI, error, "notifydetails"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

const initialState = {
  isOpenNotificationForm: "",
  isOpenNotificaitonSmsForm: "",
  isNotifyDetail: "",
  isNotifyPreview: "",
  isNotifySmsPreview: "",
  isNotifyDetailListView: "",
  isUpcomingNotifyDetailDrawer: "",
  isUpcomingNotifyDetailTab: "",
  isNotificationListView: [],
  isNotificationToCalendar: "",
  isNotificationCount: "",
  isNotifyDetail: "",
  isApiStatus: {
    NotifyDetailListViewApi: "",
    NotifyDetailUpdateApi: "",
    NotifyDetailActiveApi: "",
    NotificationListApi: "",
    NotificationUpdateApi: "",
    notificationMarkAllAsReadApi: "",
    notificationClearAllApi: "",
    notificationCountApi: "",
    NotifyDetailApi: ""
  },
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenNotificationForm: (state, action) => {
      state.isOpenNotificationForm = action.payload;
    },
    OpenNotificaitonSmsForm: (state, action) => {
      state.isOpenNotificaitonSmsForm = action.payload;
    },
    NotifyDetail: (state, action) => {
      state.isNotifyDetail = action.payload;
    },
    SetNotifyPreview: (state, action) => {
      state.isNotifyPreview = action.payload;
    },
    SetNotifySmsPreview: (state, action) => {
      state.isNotifySmsPreview = action.payload;
    },
    UpcomingNotifyDetailDrawer: (state, action) => {
      state.isUpcomingNotifyDetailDrawer = action.payload;
    },
    UpcomingNotifyDetailTab: (state, action) => {
      state.isUpcomingNotifyDetailTab = action.payload;
    },
    NotificationToCalendar: (state, action) => {
      state.isNotificationToCalendar = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(NotifyDetailListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, NotifyDetailListViewApi: "loading" };
      })
      .addCase(NotifyDetailListViewApi.fulfilled, (state, action) => {
        state.isNotifyDetailListView = action.payload;
        state.isApiStatus = { ...state.isApiStatus, NotifyDetailListViewApi: "succeeded" };
      })
      .addCase(NotifyDetailListViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, NotifyDetailListViewApi: "failed" };
      })
      .addCase(NotifyDetailUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, NotifyDetailUpdateApi: "loading" };
      })
      .addCase(NotifyDetailUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isNotifyDetailListView.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, NotifyDetailUpdateApi: "succeeded" };
      })
      .addCase(NotifyDetailUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, NotifyDetailUpdateApi: "failed" };
      })
      .addCase(NotifyDetailActiveApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, NotifyDetailActiveApi: "loading" };
      })
      .addCase(NotifyDetailActiveApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isNotifyDetailListView.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, NotifyDetailActiveApi: "succeeded" };
      })
      .addCase(NotifyDetailActiveApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, NotifyDetailActiveApi: "failed" };
      })
      .addCase(NotificationListApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, NotificationListApi: "loading" };
      })
      .addCase(NotificationListApi.fulfilled, (state, action) => {
        let old_current_page = state.isNotificationListView.current_page ? state.isNotificationListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isNotificationListView && state.isNotificationListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isNotificationListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isNotificationListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, NotificationListApi: "succeeded" };
        state.isNotificationListView = action.payload;
      })
      .addCase(NotificationListApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, NotificationListApi: "failed" };
        state.isNotificationListView = [];
      })
      .addCase(NotificationUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, NotificationUpdateApi: "loading" };
      })
      .addCase(NotificationUpdateApi.fulfilled, (state, action) => {
        const actions = action.payload;
        state.isApiStatus = { ...state.isApiStatus, NotificationUpdateApi: "succeeded" };
      })
      .addCase(NotificationUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, NotificationUpdateApi: "failed" };
      })
      .addCase(notificationMarkAllAsReadApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, notificationMarkAllAsReadApi: "loading" };
      })
      .addCase(notificationMarkAllAsReadApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, notificationMarkAllAsReadApi: "succeeded" };
      })
      .addCase(notificationMarkAllAsReadApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, notificationMarkAllAsReadApi: "failed" };
      })
      .addCase(notificationClearAllApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, notificationClearAllApi: "loading" };
      })
      .addCase(notificationClearAllApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, notificationClearAllApi: "succeeded" };
      })
      .addCase(notificationClearAllApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, notificationClearAllApi: "failed" };
      })
      .addCase(notificationCountApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, notificationCountApi: "loading" };
      })
      .addCase(notificationCountApi.fulfilled, (state, action) => {
        state.isNotificationCount = action.payload;
        state.isApiStatus = { ...state.isApiStatus, notificationCountApi: "succeeded" };
      })
      .addCase(notificationCountApi.rejected, (state) => {
        state.isNotificationCount = "";
        state.isApiStatus = { ...state.isApiStatus, notificationCountApi: "failed" };
      })
      .addCase(NotifyDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, NotifyDetailApi: "loading" };
      })
      .addCase(NotifyDetailApi.fulfilled, (state, action) => {
        state.isNotifyDetail = action.payload;
        state.isApiStatus = { ...state.isApiStatus, NotifyDetailApi: "succeeded" };
      })
      .addCase(NotifyDetailApi.rejected, (state) => {
        state.isNotifyDetail = "";
        state.isApiStatus = { ...state.isApiStatus, NotifyDetailApi: "failed" };
      })
  },
});
// Action creators are generated for each case reducer function
export const { reset, OpenNotificationForm, OpenNotificaitonSmsForm, NotifyDetail, SetNotifyPreview, SetNotifySmsPreview, UpcomingNotifyDetailTab, UpcomingNotifyDetailDrawer, NotificationToCalendar } = notificationSlice.actions;
export default notificationSlice.reducer;
