import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commonApiController from "services/common.service";
// import { setMessage } from "./message";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const SampleClientExportApi = createAsyncThunk("common/SampleClientExport", async (formValues, thunkAPI) => {
  try {
    const resposedata = await commonApiController
      .sampleClientExport(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "SampleClientExport"))
      .catch((error) => HandleError(thunkAPI, error, "SampleClientExport"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SampleProductExportApi = createAsyncThunk("common/SampleProductExport", async (formValues, thunkAPI) => {
  try {
    const resposedata = await commonApiController
      .sampleProductExport(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "SampleProductExport"))
      .catch((error) => HandleError(thunkAPI, error, "SampleProductExport"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const TimezoneApi = createAsyncThunk("common/timezone", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await commonApiController
      .timezone(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "timezone"))
      .catch((error) => HandleError(thunkAPI, error, "timezone"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const WhenToSendApi = createAsyncThunk("common/whentosend", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await commonApiController
      .whentosend(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "whentosend"))
      .catch((error) => HandleError(thunkAPI, error, "whentosend"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const checkUserApi = createAsyncThunk("consultationform/checkuser", async (formValues, thunkAPI) => {
  try {
    const resposedata = await commonApiController
      .checkuser(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "checkuser"))
      .catch((error) => HandleError(thunkAPI, error, "checkuser"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isTimezone: "",
  isSampleClientExport: "",
  isSampleProductExport: "",
  isWhenToSend: "",
  isCheckUser: "",
  isValidatationAlertPopups: "",
  isopenAppointmentConfirmationAlerts: "",
  isAppointmentConfirmationStatus: "",
  isOpenImportErrorLog: "",
  isImportErrorLogTableData: "",
  isComingSoonPopUpAlerts: "",
  isComingSoonPopUpAlertsTextsData: "",
  isOpenCustomAlerts: "",
  customAlertErrors: null,
  customAlertResponse: null,
  isApiStatus: {
    TimezoneApi: "",
    SampleClientExportApi: "",
    SampleProductExportApi: "",
    WhenToSendApi: "",
  },
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenValidationAlerts: (state, action) => {
      state.isValidatationAlertPopups = action.payload;
    },
    OpenAppointmentConfirmationAlerts: (state, action) => {
      state.isopenAppointmentConfirmationAlerts = action.payload;
    },
    AppointmentConfirmationStatus: (state, action) => {
      state.isAppointmentConfirmationStatus = action.payload;
    },
    OpenImportErrorLog: (state, action) => {
      state.isOpenImportErrorLog = action.payload;
    },
    ImportErrorLogTableData: (state, action) => {
      state.isImportErrorLogTableData = action.payload;
    },
    ComingSoonPopUpAlerts: (state, action) => {
      state.isComingSoonPopUpAlerts = action.payload;
    },
    ComingSoonPopUpAlertsTextsData: (state, action) => {
      state.isComingSoonPopUpAlertsTextsData = action.payload;
    },
    OpenCustomAlerts: (state, action) => {
      state.isOpenCustomAlerts = action.payload.isOpen;
      state.customAlertErrors = action.payload.errors;
      state.customAlertResponse = action.payload.response;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(TimezoneApi.fulfilled, (state, action) => {
        state.isTimezone = action.payload;
      })
      .addCase(SampleClientExportApi.fulfilled, (state, action) => {
        state.isSampleClientExport = action.payload;
      })
      .addCase(SampleClientExportApi.rejected, (state) => {
        state.isSampleClientExport = "";
      })
      .addCase(SampleProductExportApi.fulfilled, (state, action) => {
        state.isSampleProductExport = action.payload;
      })
      .addCase(SampleProductExportApi.rejected, (state) => {
        state.isSampleProductExport = "";
      })
      .addCase(WhenToSendApi.fulfilled, (state, action) => {
        state.isWhenToSend = action.payload;
      })
      .addCase(WhenToSendApi.rejected, (state) => {
        state.isWhenToSend = "";
      })
      .addCase(checkUserApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, checkUserApi: "loading" };
      })
      .addCase(checkUserApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, checkUserApi: "succeeded" };
        state.isCheckUser = action.payload;
      })
      .addCase(checkUserApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, checkUserApi: "failed" };
        state.isCheckUser = "";
      });
  },
});

export const { reset, OpenValidationAlerts, OpenAppointmentConfirmationAlerts, AppointmentConfirmationStatus, OpenImportErrorLog, ImportErrorLogTableData, ComingSoonPopUpAlerts, ComingSoonPopUpAlertsTextsData, OpenCustomAlerts } = commonSlice.actions;
export default commonSlice.reducer;
