import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import BirthdayCampaignApiController from "../../services/birthdaycampaign.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const BirthdayCampaignStoreApi = createAsyncThunk("birthdaycampaign/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await BirthdayCampaignApiController.create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const BirthdayCampaignUpdateApi = createAsyncThunk("birthdaycampaign/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await BirthdayCampaignApiController.update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const BirthdayCampaignListViewApi = createAsyncThunk("birthdaycampaign/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await BirthdayCampaignApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BirthdayCampaignOptions = createAsyncThunk("birthdaycampaign/BirthdayCampaignOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await BirthdayCampaignApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "BirthdayCampaignOptions"))
      .catch((error) => HandleError(thunkAPI, error, "BirthdayCampaignOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BirthdayCampaignDetailApi = createAsyncThunk("birthdaycampaign/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await BirthdayCampaignApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BirthdayCampaignDeleteApi = createAsyncThunk("birthdaycampaign/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await BirthdayCampaignApiController.deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BirthdayServicesApi = createAsyncThunk("birthdaycampaign/services", async (formValues, thunkAPI) => {
  try {
    const resposedata = await BirthdayCampaignApiController.services(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "services"))
      .catch((error) => HandleError(thunkAPI, error, "services"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const BirthdayCampaignSendEmailApi = createAsyncThunk("birthdaycampaign/sendemail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await BirthdayCampaignApiController.sendemail(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "sendemail"))
      .catch((error) => HandleError(thunkAPI, error, "sendemail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


const initialState = {
  isBirthdayType: "",
  isOpenedTypeForm: "",
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedSendEmailForm: "",
  isOpenedDetailModal: "",
  isListView: [],
  isSuggetionListView: [],
  isDetailData: "",
  isSearchList: "",
  isSearchName: "",
  isEmailPreview: "",
  isSmsPreview: "",
  isBirthdayCampaignSelectedType: "",
  isBirthdayServices: [],
  isBirthdaySendEmail: "",
  isOpenedSendEmailObj: "",
  isBirthdayValuesSave: "",
  isApiStatus: {
    BirthdayCampaignStoreApi: "",
    BirthdayCampaignUpdateApi: "",
    BirthdayCampaignListViewApi: "",
    BirthdayCampaignSuggetionListApi: "",
    BirthdayCampaignDetailApi: "",
    BirthdayCampaignDeleteApi: "",
    BirthdayCampaignOptions: "",
    BirthdayServicesApi: "",
    BirthdayCampaignSendEmailApi: "",
  },
};

const BirthdayCampaignSlice = createSlice({
  name: "birthdaycampaign",
  initialState,
  reducers: {
    reset: () => initialState,
    BirthdayCampaignType: (state, action) => {
      state.isBirthdayType = action.payload;
    },
    BirthdayCampaignSelectedType: (state, action) => {
      state.isBirthdayCampaignSelectedType = action.payload;
    },
    BirthdayCampaignFormModal: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedTypeForm = action.payload;
    },
    AddBirthdayCampaignFormModal: (state, action) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = action.payload;
    },
    EditBirthdayCampaignFormModal: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = action.payload;
    },
    SendEmailBirthdayCampaignFormModal: (state, action) => {
      state.isOpenedSendEmailForm = action.payload;
    },
    SendEmailBirthdayCampaignObjModal: (state, action) => {
      state.isOpenedSendEmailObj = action.payload;
    },
    SetEmailPreview: (state, action) => {
      state.isEmailPreview = action.payload;
    },
    SetSmsPreview: (state, action) => {
      state.isSmsPreview = action.payload;
    },
    BirthdayValuesSave: (state, action) => {
      state.isBirthdayValuesSave = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(BirthdayCampaignStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignStoreApi: "loading" };
      })
      .addCase(BirthdayCampaignStoreApi.fulfilled, (state, action) => {
        if (state.isListView && state.isListView.data) {
          state.isListView.data = [action.payload, ...state.isListView.data];
        } else {
          state.isListView = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignStoreApi: "succeeded" };
      })
      .addCase(BirthdayCampaignStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignStoreApi: "failed" };
      })
      .addCase(BirthdayCampaignUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignUpdateApi: "loading" };
      })
      .addCase(BirthdayCampaignUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isListView.data.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignUpdateApi: "succeeded" };
      })
      .addCase(BirthdayCampaignUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignUpdateApi: "failed" };
      })
      .addCase(BirthdayCampaignListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignListViewApi: "loading" };
      })
      .addCase(BirthdayCampaignListViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isListView.current_page ? state.isListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isListView && state.isListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignListViewApi: "succeeded" };
        state.isListView = action.payload;
      })
      .addCase(BirthdayCampaignListViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignListViewApi: "failed" };
        state.isListView = [];
      })
      .addCase(BirthdayCampaignDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignDetailApi: "loading" };
      })
      .addCase(BirthdayCampaignDetailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignDetailApi: "succeeded" };
        state.isDetailData = action.payload;
      })
      .addCase(BirthdayCampaignDetailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignDetailApi: "failed" };
        state.isDetailData = "";
      })
      .addCase(BirthdayCampaignDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignDeleteApi: "loading" };
      })
      .addCase(BirthdayCampaignDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignDeleteApi: "succeeded" };
        state.isListView.data = state.isListView.data ? state.isListView.data.filter((item) => item.id != id) : [];
      })
      .addCase(BirthdayCampaignDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignDeleteApi: "failed" };
      })
      .addCase(BirthdayServicesApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayServicesApi: "loading" };
      })
      .addCase(BirthdayServicesApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayServicesApi: "succeeded" };
        state.isBirthdayServices = action.payload;
      })
      .addCase(BirthdayServicesApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayServicesApi: "failed" };
        state.isBirthdayServices = [];
      })
      .addCase(BirthdayCampaignSendEmailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignSendEmailApi: "loading" };
      })
      .addCase(BirthdayCampaignSendEmailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignSendEmailApi: "succeeded" };
        state.isBirthdaySendEmail = action.payload;
      })
      .addCase(BirthdayCampaignSendEmailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, BirthdayCampaignSendEmailApi: "failed" };
        state.isBirthdaySendEmail = "";
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, BirthdayCampaignType, BirthdayCampaignFormModal, AddBirthdayCampaignFormModal, EditBirthdayCampaignFormModal, SetEmailPreview, SetSmsPreview, BirthdayCampaignSelectedType, SendEmailBirthdayCampaignFormModal, SendEmailBirthdayCampaignObjModal, BirthdayValuesSave } = BirthdayCampaignSlice.actions;
export default BirthdayCampaignSlice.reducer;
