import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";
import moment from "moment";

const API_URL = config.API_URL;

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const everyday_category_id = values && values.everyday_category_id ? values.everyday_category_id : "";
  const everyday_campaign_id = values && values.everyday_campaign_id ? values.everyday_campaign_id : "";


  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/everydaymarketingtemplate/view?page=${page}` : `afterlogin/everydaymarketingtemplate/view`;
  const data = {
      auth_key: auth_key,
      action: action,
      pagination: pagination, //true or false
      id: values && values.id ? values.id : "",
      result: result, //business_name,owner_name
      option: values && values.option ? values.option : "",
      everyday_category_id:everyday_category_id,
      everyday_campaign_id: everyday_campaign_id,
    };
 
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const everydaymarketingtemplateApiController = {
  view,
};
export default everydaymarketingtemplateApiController;
