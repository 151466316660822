import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";
import moment from "moment";

const API_URL = config.API_URL;

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const result = values && values.result ? values.result : "row_array";
  const action = `afterlogin/formpreview/view`;

  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false, //true or false
    id: values && values.id ? values.id : "",
    result: result,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const consultatioformlinkApiController = {
  view,
};

export default consultatioformlinkApiController;
