import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import { css } from 'glamor';

export const Notify = (props) => {
  // const options = {
  //   position: "bottom-center",
  //   autoClose: false,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  //   newestOnTop: true,
  //   theme: "dark",
  // };
  let text = props.text ? props.text : "";
  let type = props.type ? props.type : "";
  // toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
  if (type === "success") {
    toast.success(text, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnFocusLoss: true,
    });
  } else if (type === "warning") {
    toast.warn(text);
  } else if (type === "error") {
    toast.error(text);
  } else if (type === "info") {
    toast.info(text);
  }
};

const customToast = {
  success(msg, options = {}) {
    return toast.success(msg, {
      ...options,
      className: "toast-success-container toast-success-container-after",
      autoClose: 5000,
      hideProgressBar: false,
      pauseOnFocusLoss: true,
      position: "bottom-center",
    });
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      className: "toast-error-container toast-error-container-after",
      autoClose: 5000,
      hideProgressBar: false,
      pauseOnFocusLoss: true,
      position: "bottom-center",
      pauseOnHover: true,
    });
  },
  info(msg, options = {}) {
    return toast.info(msg, {
      ...options,
      className: "toast-info-container toast-info-container-after",
      autoClose: 5000,
      hideProgressBar: false,
      pauseOnFocusLoss: true,
      position: "bottom-center",
    });
  },
  warning(msg, options = {}) {
    return toast.warn(msg, {
      ...options,
      className: "toast-warn-container toast-warn-container-after",
      autoClose: 5000,
      hideProgressBar: false,
      pauseOnFocusLoss: true,
      position: "bottom-center",
    });
  },
  dismiss(toastId) {
    return toast.dismiss(toastId);
  },
  errorwithlink(msg, linkText, linkTo, options = {}) {
    const toastOptions = {
      ...options,
      className: 'toast-error-container toast-error-container-after',
      autoClose: 5000,
      hideProgressBar: false,
      pauseOnFocusLoss: true,
      position: "bottom-center",
    };

    toast.error(
      <div>
        {msg}
        <Link className="text-decoration-underline" to={linkTo}>{linkText}</Link>
      </div>,
      toastOptions
    );
  },
  successwithlink(msg, onClicksText, onClickfunction, options = {}) {
    const toastOptions = {
      ...options,
      className: 'toast-success-container toast-success-container-after',
      autoClose: false,
      hideProgressBar: false,
      pauseOnFocusLoss: true,
      position: "bottom-center",
      toastId: "linkToast"
    };

    toast.success(
      <div className="text-white">
        {msg}
        <a className="text-decoration-underline texts-lights-pinks" onClick={onClickfunction}>{onClicksText}</a>
      </div>,
      toastOptions
    );
  },
  withprogessbar(msg, options = {}) {
    const toastOptions = {
      ...options,
      className: 'toast-success-container toast-success-container-after',
      autoClose: false, // Set autoClose based on closeToastFunction
      hideProgressBar: true,
      pauseOnFocusLoss: true,
      position: "bottom-center",
      icon: false,
      toastId: "progressBarToast"
    };

    toast.success(
      <div className="custom-toasts-notification-progress">
        <span>{msg}</span>
        <div className="progress my-2">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated progess-bar-custom-toast"
            role="progressbar"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{
              width: "100%",
              backgroundImage:
                "linear-gradient(45deg, #FBD2C3 25%, transparent 25%, transparent 50%, #FBD2C3 50%, #FBD2C3 75%, transparent 75%, transparent)",
              backgroundColor: "#f9f6f4",
            }}
          ></div>
        </div>
      </div>,
      {
        ...toastOptions,
      }
    );
  },
};
export default customToast;
