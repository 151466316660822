import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import smsusesApiController from "../../services/smsuses.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const SmsUsesStoreApi = createAsyncThunk("smsuses/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await smsusesApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const SmsUsesOptionsApi = createAsyncThunk("smsuses/options", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await smsusesApiController
      .options(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "options"))
      .catch((error) => HandleError(thunkAPI, error, "options"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const SmsPaymentApi = createAsyncThunk("package/payment", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await smsusesApiController
      .smspayment(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "payment"))
      .catch((error) => HandleError(thunkAPI, error, "payment"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const SmsPaymentFailedApi = createAsyncThunk("package/paymentdfailed", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await smsusesApiController
      .smspaymentfailed(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "paymentdfailed"))
      .catch((error) => HandleError(thunkAPI, error, "paymentdfailed"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});


export const smsDefaultCardSelectApi = createAsyncThunk("smsdefaultcard/list", async (formValues, thunkAPI) => {
  try {
    const resposedata = await smsusesApiController
      .smsDefaultList(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "smsdefaultcard"))
      .catch((error) => HandleError(thunkAPI, error, "smsdefaultcard"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isSmsUsesOptions: "",
  isListView: "",
  isSmsPaymentApi: "",
  isSmsPaymentFailedApi: "",
  isDefaultCardSelect:"",
  isApiStatus: {
    SmsUsesStoreApi: "",
    SmsUsesOptionsApi: "",
    SmsPaymentApi: "",
    SmsPaymentFailedApi: "",
    smsDefaultCardSelectApi: "",
  },
};

const smsusesSlice = createSlice({
  name: "smsuses",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
    .addCase(SmsUsesOptionsApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SmsUsesOptionsApi: "loading" };
    })
    .addCase(SmsUsesOptionsApi.fulfilled, (state, action) => {
      state.isSmsUsesOptions = action.payload;
      state.isApiStatus = { ...state.isApiStatus, SmsUsesOptionsApi: "succeeded" };
    })
    .addCase(SmsUsesOptionsApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SmsUsesOptionsApi: "failed" };
    })
    .addCase(SmsUsesStoreApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SmsUsesStoreApi: "loading" };
    })
    .addCase(SmsUsesStoreApi.fulfilled, (state, action) => {
      if (state.isListView && state.isListView.data) {
        state.isListView.data = [action.payload, ...state.isListView.data];
      } else {
        state.isListView = { data: [action.payload] };
      }
      state.isApiStatus = { ...state.isApiStatus, SmsUsesStoreApi: "succeeded" };
    })
    .addCase(SmsUsesStoreApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SmsUsesStoreApi: "failed" };
    })
    .addCase(SmsPaymentApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SmsPaymentApi: "loading" };
    })
    .addCase(SmsPaymentApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, SmsPaymentApi: "succeeded" };
      state.isSmsPaymentApi = action.payload;
    })
    .addCase(SmsPaymentApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SmsPaymentApi: "failed" };
      state.isSmsPaymentApi = [];
    })
    .addCase(SmsPaymentFailedApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SmsPaymentFailedApi: "loading" };
    })
    .addCase(SmsPaymentFailedApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, SmsPaymentFailedApi: "succeeded" };
      state.isSmsPaymentFailedApi = action.payload;
    })
    .addCase(smsDefaultCardSelectApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, smsDefaultCardSelectApi: "loading" };
    })
    .addCase(smsDefaultCardSelectApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, smsDefaultCardSelectApi: "succeeded" };
      state.isDefaultCardSelect = action.payload;
    })
    .addCase(smsDefaultCardSelectApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, smsDefaultCardSelectApi: "failed" };
      state.isDefaultCardSelect = "";
    });
  },
});
// Action creators are generated for each case reducer function
export const { reset } = smsusesSlice.actions;
export default smsusesSlice.reducer;
