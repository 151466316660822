import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const ScreenReport = values && values.ScreenReport ? values.ScreenReport : "";
  const daterange = values && values.daterange ? values.daterange : "";
  const staff_id = values && values.staff_id ? values.staff_id : "";
  const supplier_id = values && values.supplier_id ? values.supplier_id : "";
  const service_id = values && values.service_id ? values.service_id : "";
  const product_id = values && values.product_id ? values.product_id : "";
  const month = values && values.month ? values.month : "";
  const sort = values && values.sort && values.sort ? values.sort : "";

  let sortstring = "";
  if (sort) {
    let sortArray = [];
    let sortSubArray = [];
    Object.keys(sort).map(function (key, index) {
      if (Object.keys(sort[key]).length > 0) {
        Object.keys(sort[key]).map(function (subkey, subindex) {
          sortSubArray[subindex] = `sort[${key}.${subkey}]=${sort[key][subkey]}`;
        });
      }
      if (key != "category") {
        sortArray[index] = `sort[${key}]=${sort[key]}`;
      }
    });
    if (sortSubArray.length > 0) {
      let jsubsort = sortSubArray.join("&");
      sortstring = jsubsort;
    }
    if (sortArray.length > 0) {
      let jsort = sortArray.join("&");
      sortstring = jsort;
    }
  }

  let pagination = values && values.pagination ? values.pagination : "";

  if (pagination) {
    pagination = values && values.option ? false : true;
  } else {
    pagination = false;
  }

  if (ScreenReport === "gift_vouchers") {
    pagination = true;
  }
  const action = page ? `afterlogin/report/view?page=${page}&${sortstring}` : `afterlogin/report/view?${sortstring}`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: values && values.id ? false : pagination, //true or false
    result: result, //business_name,owner_name
    ScreenReport: ScreenReport,
    daterange: daterange,
    staff_id: staff_id,
    supplier_id: supplier_id,
    service_id: service_id,
    product_id: product_id,
    sort: sort,
    month: month,
  };
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const printout = () => {

};

const moreclientbyproducts = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/report/clientcartproducts";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const reportApiController = {
  view,
  printout,
  moreclientbyproducts
};
export default reportApiController;
