import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const max_staff_member = values && values.max_staff_member ? values.max_staff_member : "";
  const action = `afterlogin/package/view`;
  const data = {
    auth_key: auth_key,
    action: action,
    max_staff_member: max_staff_member,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};
const packagefeatureview = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const max_staff_member = values && values.max_staff_member ? values.max_staff_member : "";
  const action = `afterlogin/package/packagefeatureview`;
  const data = {
    auth_key: auth_key,
    action: action,
    max_staff_member: max_staff_member,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["BookingSelectedServicesData", "BookingSelectedMembershipData", "paymentIntent"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "afterlogin/package/store";
  const salon_id = values && values.salon_id ? values.salon_id : "";
  formData.append("salon_id", salon_id);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const createPaymentIntent = (values) => {
  const action = `afterlogin/package/createpaymentintent`;

  const data = {
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};
const packagepayment = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["paymentIntent", "PaymentMethodData"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/package/packagepayment`;
  formData.append("action", action);
  formData.append("auth_key", auth_key);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const packagepaymentfailed = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["error"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/package/packagepaymentfailed`;
  formData.append("action", action);
  formData.append("auth_key", auth_key);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const plancancel = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;

  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/package/btnplancancel";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const cardremove = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/stripe/defaultcardremove`;

  const data = {
    auth_key: auth_key,
    action: action,
    id: values && values.id ? values.id : "",
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const packagekeyfeatures = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/package/packagekeyfeatures`;
  const max_staff_member = values && values.max_staff_member ? values.max_staff_member : "";
  const data = {
    auth_key: auth_key,
    action: action,
    max_staff_member: max_staff_member,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const packageinvoice = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;

  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/package/packageinvoice";
  formData.append("auth_key", auth_key);
  formData.append("action", action);

  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const packageApiController = {
  view,
  packagefeatureview,
  create,
  createPaymentIntent,
  packagepayment,
  packagepaymentfailed,
  plancancel,
  cardremove,
  packagekeyfeatures,
  packageinvoice
};
export default packageApiController;
