import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import custompagesApiController from "../../services/custompages.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const CustompagesApi = createAsyncThunk("custompages/view", async (formValues, thunkAPI) => {
  try {
    const resposedata = await custompagesApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "view"))
      .catch((error) => HandleError(thunkAPI, error, "view"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isPages: "",
  isTabView: "",
  isOpenedMailchimpForm: "",
  isApiStatus: {
    CustompagesApi: "",
  },
};

const custompagesSlice = createSlice({
  name: "custompages",
  initialState,
  reducers: {
    reset: () => initialState,
    custompagesTabGridView: (state, action) => {
      state.isTabView = action.payload;
    },
    OpenMailchimpForm: (state, action) => {
      state.isOpenedMailchimpForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CustompagesApi.pending, (state) => {
        // Handle pending state if needed
      })
      .addCase(CustompagesApi.fulfilled, (state, action) => {
        state.isPages = action.payload;
      })
      .addCase(CustompagesApi.rejected, (state) => {
        // Handle rejected state if needed
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, custompagesTabGridView, OpenMailchimpForm } = custompagesSlice.actions;
export default custompagesSlice.reducer;
