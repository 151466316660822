import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
import AuthService from "../../services/auth.service";
import userApiController from "../../services/user.service";

export const register = createAsyncThunk("auth/register", async ({ username, email, password }, thunkAPI) => {
  try {
    const response = await AuthService.register(username, email, password)
      .then((response) => HandleResponse(thunkAPI, response, "register"))
      .catch((error) => HandleError(thunkAPI, error, "register"));
    thunkAPI.dispatch(setMessage(response.data.message));
    return response.data;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage([message]));
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const login = createAsyncThunk("auth/login", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await AuthService.login(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "login"))
      .catch((error) => HandleError(thunkAPI, error, "login"));
    if (resposedata && resposedata.payload && resposedata.payload !== undefined && !resposedata.payload.status) {
      const user = await AuthService.getUser({ auth_key: resposedata.payload.auth_key, token: resposedata.payload.token })
        .then((response) => HandleResponse(thunkAPI, response, "getuser"))
        .catch((error) => HandleError(thunkAPI, error, "getuser"));

      if (user && user.payload && user.payload !== undefined && !user.payload.status) {
        return thunkAPI.fulfillWithValue({ isLoggedIn: true, user: user.payload, token: resposedata.payload.token, navigate: resposedata.payload.navigate });
      } else {
        return user;
      }
    }
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const TwoFactorLoginApi = createAsyncThunk("auth/twofactor", async (formValues, thunkAPI) => {
  try {
    const resposedata = await AuthService
      .twofactorauthentications(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "user"))
      .catch((error) => HandleError(thunkAPI, error, "user"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const SalonSelectionApi = createAsyncThunk("auth/salonselection", async (formValues, thunkAPI) => {
  try {
    const resposedata = await AuthService
      .salonselection(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "user"))
      .catch((error) => HandleError(thunkAPI, error, "user"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const Logout = createAsyncThunk("auth/logout", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await AuthService.logout()
      .then((response) => HandleResponse(thunkAPI, response, "logout"))
      .catch((error) => HandleError(thunkAPI, error, "logout"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const GetUser = createAsyncThunk("auth/user", async (formValues, thunkAPI) => {
  try {
    const resposedata = await userApiController
      .getUser(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "user"))
      .catch((error) => HandleError(thunkAPI, error, "user"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const Forgotpassowrdsubmit = createAsyncThunk("auth/forgotpassowrdsubmit", async (formValues, thunkAPI) => {
  try {
    const resposedata = await AuthService.forgotpassowrdsubmit(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ForgotPasswordSalonApi = createAsyncThunk("forgotpassword/select", async (formValues, thunkAPI) => {
  try {
    const resposedata = await AuthService
      .forgotPasswordSalonSelect(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "forgotpasswordselect"))
      .catch((error) => HandleError(thunkAPI, error, "forgotpasswordselect"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  token: "",
  navigate: "",
  isLoggedIn: false,
  isInitialized: false,
  isOpenAdminSalonModal: false,
  isOpenSalonSelectionPopup: "",
  user: null,
  admindata: null,
  isAdminSalonFormValues: null,
  isOpenTwoFactorAuthentications: "",
  isSalonOption: [],
  isLoginRelatedData: "",
  isSalonSelectedMetaData: "",
  isForgotPasswordSalonData: [],
  isOpenForgotPasswordSalonSelect: "",
  isForgotpasswordSalonSelectionData: "",
  isApiStatus: {
    register: "",
    login: "",
    GetUser: "",
    Forgotpassowrdsubmit: "",
    TwoFactorLoginApi: "",
    SalonSelectionApi: "",
    ForgotPasswordSalonApi: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authlogout: () => { },
    OpenAdminSalonModal: (state, action) => {
      state.isOpenAdminSalonModal = action.payload;
    },
    AdminSalonFormValues: (state, action) => {
      state.isAdminSalonFormValues = action.payload;
    },
    AdminData: (state, action) => {
      state.admindata = action.payload;
    },
    OpenTwoFactorAuthentications: (state, action) => {
      state.isOpenTwoFactorAuthentications = action.payload;
    },
    TwoFactorAuthenticationsData: (state, action) => {
      state.isLoginRelatedData = action.payload;
    },
    OpenSalonSelectionPopup: (state, action) => {
      state.isOpenSalonSelectionPopup = action.payload;
    },
    SalonSelectedMetaData: (state, action) => {
      state.isSalonSelectedMetaData = action.payload;
    },
    OpenForgotPasswordSalonSelect: (state, action) => {
      state.isOpenForgotPasswordSalonSelect = action.payload;
    },
    ForgotpasswordSalonSelectionData: (state, action) => {
      state.isForgotpasswordSalonSelectionData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.fulfilled, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(register.rejected, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.navigate = action.payload.navigate;
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.isInitialized = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.token = "";
        state.user = null;
        const status = action.payload && action.payload.status;
        const response = action.payload && action.payload.message && action.payload.message;
        if (status === 410) {
          const users = response && response.users ? response.users : "";
          const adminEmail = response && response.email ? response.email : "";
          if (users && adminEmail) {
            state.admindata = response;
          }
        }
      })
      .addCase(Logout.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.token = "";
        state.user = null;
        state.admindata = null;
        state.isAdminSalonFormValues = null;
      })
      .addCase(Logout.rejected, (state) => {
        state.isLoggedIn = false;
        state.token = "";
        state.user = null;
        state.admindata = null;
        state.isAdminSalonFormValues = null;
      })
      .addCase(GetUser.pending, () => { })
      .addCase(GetUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(GetUser.rejected, (state) => {
        state.user = null;
      })
      .addCase(Forgotpassowrdsubmit.pending, () => { })
      .addCase(Forgotpassowrdsubmit.fulfilled, () => { })
      .addCase(Forgotpassowrdsubmit.rejected, () => { })
      .addCase(TwoFactorLoginApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, TwoFactorLoginApi: "loading" };
      })
      .addCase(TwoFactorLoginApi.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.isApiStatus = { ...state.isApiStatus, TwoFactorLoginApi: "succeeded" };
      })
      .addCase(TwoFactorLoginApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, TwoFactorLoginApi: "failed" };
      })
      .addCase(SalonSelectionApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SalonSelectionApi: "loading" };
      })
      .addCase(SalonSelectionApi.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.isSalonOption = action.payload;
        state.isApiStatus = { ...state.isApiStatus, SalonSelectionApi: "succeeded" };
      })
      .addCase(SalonSelectionApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, SalonSelectionApi: "failed" };
      })
      .addCase(ForgotPasswordSalonApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, ForgotPasswordSalonApi: "loading" };
      })
      .addCase(ForgotPasswordSalonApi.fulfilled, (state, action) => {
        state.isForgotPasswordSalonData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, ForgotPasswordSalonApi: "succeeded" };
      })
      .addCase(ForgotPasswordSalonApi.rejected, (state) => {
        state.isForgotPasswordSalonData = [];
        state.isApiStatus = { ...state.isApiStatus, ForgotPasswordSalonApi: "failed" };
      });
  },
});

export const { authlogout, OpenAdminSalonModal, AdminSalonFormValues, AdminData, OpenTwoFactorAuthentications, TwoFactorAuthenticationsData, OpenSalonSelectionPopup, SalonSelectedMetaData, OpenForgotPasswordSalonSelect, ForgotpasswordSalonSelectionData } = authSlice.actions;
export default authSlice.reducer;
