import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const notifylistview = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/notifydetail/view`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false, //true or false
    notifydata:values.notifydata
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const notifyupdate = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["gender"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, values[value].value);
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = values.id ? "afterlogin/notifydetail/update/" + values.id : "afterlogin/notifydetail/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const notifyisactive = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = "afterlogin/notifydetail/isactive/" + values.id;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false, //true or false
    notifydata:values.notifydata
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const notificationlist = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/notification/view?page=${page}` : `afterlogin/notification/view`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: pagination,
    result: result, //business_name,owner_name
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const notificationupdate = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/notification/update/" + values.id;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const clearall = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/notification/deleteall";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const markasread = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/notification/readall";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const notificationcounts = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/notification/notificationcount";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const notifydetails = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/notifydetail/view`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false, //true or false
    notifydata:values.notifydata,
    id:values && values.id ? values.id : ""
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const notificationApiController = {
  notifylistview,
  notifyupdate,
  notifyisactive,
  notificationlist,
  notificationupdate,
  clearall,
  markasread,
  notificationcounts,
  notifydetails
};

export default notificationApiController;
