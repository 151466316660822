import { renderToString } from "react-dom/server";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const sweatalert = (props) => {
  Swal.fire({
    title: props.title,
    text: props.text,
    icon: props.icon,
  });
};

const swalSuccess = (props, option) => {
  Swal.fire({
    title: props.title,
    text: props.text,
    icon: "success",
  });
};

const swalError = (props) => {
  Swal.fire({
    title: props.title,
    text: props.text,
    icon: "error",
  });
};

const swalErrorHtml = (props) => {
  // const Alert = withReactContent(Swal);
  Swal.fire({
    title: props.title,
    html: renderToString(props.html),
    // icon: "error",
  });
};
const swalConfirmSubmit = async (props, option) => {
  const sweetConfirm = await Swal.fire({
    title: `Are you sure?<h6 class="mb-0 mt-2">${props.title}</h6>`,
    text: props.message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: props.confirmButtonText,
    customClass: "customswal",
    allowEscapeKey: true,
    allowOutsideClick: false,
    reverseButtons: true,
  }).then((result) => {
    if (result.value) {
      return true;
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      return false;
    }
  });
  return sweetConfirm;
};

const swalConfirm = (btn, props, option) => {
  if (btn.getAttribute("confirmOK") === "1" || btn.getAttribute("confirmOK") === 1) {
    btn.setAttribute("confirmOK", "0");
    return true;
  }
  Swal.fire({
    title: `Are you sure?<h6 class="mb-0 mt-2">${props.title}</h6>`,
    text: props.message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: props.confirmButtonText,
    customClass: "customswal",
    allowEscapeKey: true,
    allowOutsideClick: false,
    reverseButtons: true,
  }).then((result) => {
    if (result.value) {
      btn.setAttribute("confirmOK", "1");
      if (props.message === "statusupdate") {
        const event = new Event("change", { bubbles: true });
        btn.dispatchEvent(event);
      } else {
        btn.click();
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      btn.setAttribute("confirmOK", "0");
    }
  });
  return false;
};

const swalConfirmDeletes = async (btn, props) => {
  if (btn.getAttribute("confirmOK") === "1" || btn.getAttribute("confirmOK") === 1) {
    btn.setAttribute("confirmOK", "0");
    return true;
  }

  const result = await Swal.fire({
    title: `Are you sure?<h6 class="mb-0 mt-2">${props.title}</h6>`,
    text: props.message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: props.confirmButtonText,
    customClass: "customswal",
    allowEscapeKey: true,
    allowOutsideClick: false,
    reverseButtons: true,
  });

  if (result.isConfirmed) {
    btn.setAttribute("confirmOK", "1");
    if (props.message === "statusupdate") {
      const event = new Event("change", { bubbles: true });
      btn.dispatchEvent(event);
    } else {
      btn.click();
    }
    return true;
  } else {
    btn.setAttribute("confirmOK", "0");
    return false;
  }
};

const swalConfirmInput = (btn, props, option) => {
  if (btn.getAttribute("confirmOK") === "1" || btn.getAttribute("confirmOK") === 1) {
    btn.setAttribute("confirmOK", "0");
    return true;
  }
  Swal.fire({
    title: `<h5 class="mb-0">${props.title}</h5>`,
    text: props.message,
    input: "select",
    inputOptions: props.options,
    inputValue: "",
    inputAttributes: {
      required: "true",
    },
    inputPlaceholder: "Please Select Reason For Cancellation",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: props.confirmButtonText,
    customClass: {
      container: "customswal",
      validationMessage: "d-none",
    },
    reverseButtons: true,
  }).then((result) => {
    if (result.value) {
      btn.setAttribute("confirmOK", "1");
      btn.setAttribute("reason", result.value);
      if (props.message === "statusupdate") {
        const event = new Event("change", { bubbles: true });
        btn.dispatchEvent(event);
      } else {
        btn.click();
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      btn.setAttribute("confirmOK", "0");
      btn.setAttribute("reason", "");
    }
  });
  return false;
};
// const defaultParams = {
//   title: '',
//   text: '',
//   type: null,
//   allowOutsideClick: false,
//   showConfirmButton: true,
//   showCancelButton: false,
//   closeOnConfirm: true,
//   closeOnCancel: true,
//   confirmButtonText: 'OK',
//   confirmButtonColor: '#8CD4F5',
//   cancelButtonText: 'Cancel',
//   imageUrl: null,
//   imageSize: null,
//   timer: null,
//   customClass: '',
//   html: false,
//   animation: true,
//   allowEscapeKey: true,
//   inputType: 'text',
//   inputPlaceholder: '',
//   inputValue: '',
//   showLoaderOnConfirm: false
// };

export { sweatalert, swalSuccess, swalError, swalConfirm, swalConfirmInput, swalErrorHtml, swalConfirmSubmit,swalConfirmDeletes };
