import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import config from "../../../../config";
import { store } from "store";
import { Logout } from "store/slices/authSlice";
import { ProfileDropdownMenu, resetDropdownToggle } from "store/slices/dropdownToggleSlice";
import { persistStore } from 'redux-persist';
import ClockLoader from "react-spinners/ClockLoader";

const ProfileSection = () => {
  const [LoadingsApi,setLoadingsApi] = useState(false);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let profile_photo_url = currentUser.profile_photo_url;
  const role_id = currentUser && currentUser.role_id;
  const isProfileDropdownMenu = useSelector((state) => state.dropdown.isProfileDropdownMenu);

  const handleLogout = () => {
    // dispatch({ type: 'auth/logout'});
    setLoadingsApi(true);
    
    if (store) {
      persistStore(store).purge();
      setTimeout(()=>{
        setLoadingsApi(false);
        dispatch(resetDropdownToggle())
        dispatch(Logout());
      },1500);
    } else {
      setLoadingsApi(false);
      dispatch({ type: "auth/logout/fulfilled" });
    }
  };

  const business_name = currentUser.salon ? currentUser.salon.business_name : "";
  const truncatedName = business_name.length > 35 ? business_name.substring(0, 35) + '...' : business_name;

  return (
    <>
      <div className="dropdown">
        <div className="position-relative ms-lg-4 ms-2">
          <Link onClick={() => dispatch(ProfileDropdownMenu())}>
            {profile_photo_url ? (
              <img src={profile_photo_url} alt="" className="rounded-circle wh-40 profile-image-obj" />
            ) : (
              <div className="user-initial text-uppercase profile-image-height wh-40">{currentUser.first_name.charAt(0) + "" + currentUser.last_name.charAt(0)}</div>
            )}
            <span className="user-status online"></span>
          </Link>
          {isProfileDropdownMenu && (
            <>
              <div className="backdrop show" onClick={() => dispatch(resetDropdownToggle())}></div>
              <div className="dropdown-menu-show no-left-zero custom-dropdown-profile-width">
                <a className="dropdown-item cursor-auto">
                  {currentUser.first_name + " " + currentUser.last_name} <br />
                  {business_name && (
                    <span><b>({truncatedName})</b></span>
                  )}
                </a>
                {role_id == 8 ? (
                  <a className="dropdown-item cursor-auto" onClick={() => dispatch(resetDropdownToggle())}>{currentUser.email}</a>
                ) : (
                  <Link to={config.basePath + "settings?tab=logindetails"} className="dropdown-item" onClick={() => dispatch(resetDropdownToggle())}>{currentUser.email}</Link>
                )}
                <a className="dropdown-item cursor-pointer text-bold d-flex align-items-center" onClick={handleLogout}>
                  {LoadingsApi ? (<ClockLoader color="#8c1c4d" size="16px" className="d-inline-block me-2" />) : (<i className="fas fa-sign-out-alt me-2"></i>)}
                  {t("Log Out")}
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileSection;
