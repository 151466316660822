import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import mailchimpApiController from "../../services/mailchimp.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const MailchimpUpdateKeyApi = createAsyncThunk("mailchimp/mailchimpupdatekey", async (formValues, thunkAPI) => {
  try {
    const resposedata = await mailchimpApiController
      .mailchimpupdatekey(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "mailchimpupdatekey"))
      .catch((error) => HandleError(thunkAPI, error, "mailchimpupdatekey"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const MailchimpSubscribeApi = createAsyncThunk("mailchimp/mailchimpsubscribe", async (formValues, thunkAPI) => {
  try {
    const resposedata = await mailchimpApiController
      .mailchimpsubscribe(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "mailchimpsubscribe"))
      .catch((error) => HandleError(thunkAPI, error, "mailchimpsubscribe"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const MailchimpCampaignCreateApi = createAsyncThunk("mailchimp/MailchimpCampaignCreateApi", async (formValues, thunkAPI) => {
  try {
    const resposedata = await mailchimpApiController
      .mailchimpCampaignCreate(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "MailchimpCampaignCreateApi"))
      .catch((error) => HandleError(thunkAPI, error, "MailchimpCampaignCreateApi"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const MailchimpCampaignDeleteApi = createAsyncThunk("mailchimp/MailchimpCampaignDeleteApi", async (formValues, thunkAPI) => {
  try {
    const resposedata = await mailchimpApiController
      .mailchimpCampaignDelete(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "MailchimpCampaignDeleteApi"))
      .catch((error) => HandleError(thunkAPI, error, "MailchimpCampaignDeleteApi"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isMailchimp: "",
  isMailchimpSubscribe: "",
  isMailchimpCampaignCreate: "",
  isMailchimpCampaignDelete: "",
  isApiStatus: {
    MailchimpUpdateKeyApi: "",
    MailchimpSubscribeApi: "",
    MailchimpCampaignCreateApi: "",
    MailchimpCampaignDeleteApi: "",
  },
};

const mailchimpSlice = createSlice({
  name: "mailchimp",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenMailchimpForm: (state, action) => {
      state.isOpenedMailchimpForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(MailchimpUpdateKeyApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MailchimpUpdateKeyApi: "loading" };
      })
      .addCase(MailchimpUpdateKeyApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, MailchimpUpdateKeyApi: "succeeded" };
        state.isMailchimp = action.payload;
      })
      .addCase(MailchimpUpdateKeyApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MailchimpUpdateKeyApi: "failed" };
        state.isMailchimp = "";
      })
      .addCase(MailchimpSubscribeApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MailchimpSubscribeApi: "loading" };
      })
      .addCase(MailchimpSubscribeApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, MailchimpSubscribeApi: "succeeded" };
        state.isMailchimpSubscribe = action.payload;
      })
      .addCase(MailchimpSubscribeApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MailchimpSubscribeApi: "failed" };
      })
      .addCase(MailchimpCampaignCreateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MailchimpCampaignCreateApi: "loading" };
      })
      .addCase(MailchimpCampaignCreateApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, MailchimpCampaignCreateApi: "succeeded" };
        state.isMailchimpCampaignCreate = action.payload;
      })
      .addCase(MailchimpCampaignCreateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MailchimpCampaignCreateApi: "failed" };
      })
      .addCase(MailchimpCampaignDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MailchimpCampaignDeleteApi: "loading" };
      })
      .addCase(MailchimpCampaignDeleteApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, MailchimpCampaignDeleteApi: "succeeded" };
        state.isMailchimpCampaignDelete = action.payload;
      })
      .addCase(MailchimpCampaignDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MailchimpCampaignDeleteApi: "failed" };
      });

  },
});
// Action creators are generated for each case reducer function
export const { reset, OpenMailchimpForm } = mailchimpSlice.actions;
export default mailchimpSlice.reducer;
