import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from 'moment';
// import PropTypes from "prop-types";
import { swalConfirm } from "../../../../component/Sweatalert2";
import { ClientchatGridViewApi, clientchatDeleteApi, closeChatDrawer, openAddChatForm, clientchatDetailApi } from "store/slices/clientChatSlice";
import config from "../../../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import PaginationLoader from "component/PaginationLoader";
import ClientChatForm from "./ClientChatForm";
import ApiStatus from "component/ApiStatus";
import customToast from "component/Toastr";
import { ClientDetailTab } from "store/slices/clientSlice";

const Chats = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.salonmodule.isAccess);
  const detail = useSelector((state) => state.client.isDetailData);
  const chatViews = useSelector((state) => state.clientchat.isGridView);
  const chatApiStatus = useSelector((state) => state.clientchat.isApiStatus);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [doubleClickedIndexes, setDoubleClickedIndexes] = useState({}); 

  useEffect(() => {
    let timeoutId = null;

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWindowWidth(window.innerWidth);
      }, 200);
    };

    window.addEventListener("resize", handleResize, { passive: true });

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  const handleDoubleClick = (id) => {
    setDoubleClickedIndexes((prevIds) => {
      const updatedIds = { ...prevIds };
      if (updatedIds[id]) {
        updatedIds[id] = "";
      } else {
        updatedIds[id] = true;
      }
      return updatedIds;
    });
  };

  const handleDeleteMessage = (id) => {
    dispatch(clientchatDeleteApi({ id: id })).then((action) => {
      if (action.meta.requestStatus === "fulfilled") {
        dispatch(ClientchatGridViewApi({ client_id: detail.id, next_page_url: chatViews.next_page_url }));
      } else if (action.meta.requestStatus === "rejected") {
        customToast.error("Failed to delete chat")
      }
    });
  };

  const fetchChatGridList = () => {
    // console.log("fetched grids")
    dispatch(ClientchatGridViewApi({ client_id: detail.id, next_page_url: chatViews.next_page_url }));
  };

  const [isFetching, setIsFetching] = useState(false);
  const loadMoreItemsList = () => {
    setIsFetching(true);
    dispatch(ClientchatGridViewApi({ next_page_url: chatViews && chatViews.next_page_url, client_id: detail.id }));
    setTimeout(() => {
      setIsFetching(false);
    }, 1500);
  };
  // console.log('chatViews',chatViews.current_page)
  return (
    <>
      {detail && detail.country_code && detail.phone_number ? (
        <div className='w-100'>
          <div className="drawer-header custom-drawer-chat-msg my-auto" style={{ borderBottom: "1px solid #eee" }}>
            <h2 className="my-4 mx-4">{t("Messages")}</h2>
          </div>
          {detail && (detail.country_code && detail.phone_number) && (
            <div className="position-relative">
              <div className="floating-label-chat-msg">
                <p className="subtitle mb-0 mx-4">
                  {detail && detail.country_code ? detail.country_code : ""}{" "}{detail && detail.phone_number ? detail.phone_number : ""}
                </p>
              </div>
            </div>
          )}
          <div className="content-wrp content-chat-msg px-4 pt-3" id="chatmsg-client" style={{
            overflow: 'auto',
            display: 
            chatApiStatus && chatApiStatus.ClientchatGridViewApi === "loading" ? "flex" : 
            chatViews && chatViews.data && chatViews.data.length <= 0 && windowWidth && windowWidth > 1200 ? "unset" : 
            chatViews && chatViews.data && chatViews.data.length <= 0 && windowWidth && windowWidth <= 1200 ? "block" : 
            chatViews.current_page === 1 ? 'flex' : 'block',
            flexDirection: 'column-reverse',
          }}>
            {chatApiStatus &&
              chatApiStatus.ClientchatGridViewApi === "succeeded" &&
              chatViews && chatViews.data && chatViews.data.length > 0 ? (
              <InfiniteScroll
                className="row gx-0"
                dataLength={chatViews.data.length}
                inverse={true}
                next={fetchChatGridList}
                scrollableTarget="chatmsg-client"
                hasMore={chatViews.next_page_url ? true : false}
                style={{ 
                  display: 'flex', 
                  flexDirection: 'column-reverse', 
                  overflow: chatViews.next_page_url ? "auto" : "inherit" }}
                loader={<PaginationLoader />}
              >

                {Object.keys(chatViews.data).map((item, i) => {
                  let id = chatViews.data[item].id;
                  //   let client_id = chatViews.data[item].client_id;
                  let sender_receiver = chatViews.data[item].sender_receiver;
                  let chat = chatViews.data[item].message;
                  let created_at = chatViews.data[item].created_at;
                  // console.log("msg", chat)
                  return (
                    <div
                      key={i}
                      className={`message-box ${sender_receiver === 1 ? "right" : "left"}`}
                      onDoubleClick={() => handleDoubleClick(id)}
                      style={{
                        background: doubleClickedIndexes[id] ? "#ADD8E6" : "transparent"
                      }}
                    >
                      <div
                        className={`message ${sender_receiver === 1 ? "right" : "left"}`}
                        style={{
                          backgroundColor: sender_receiver === 1 ? "#f9f6f4" : "#feeef3",
                          position: "relative",
                          whiteSpace: "pre-line",
                          textAlign: "left"
                        }}
                      >
                        {chat}
                        {doubleClickedIndexes[id] && (
                          <div
                            className="delete-icon"
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                            }}
                            onClick={() => handleDeleteMessage(id)}
                          >
                            <i className="fas fa-times p-0" style={{ fontSize: "22px", fontWeight: "300", color: "#8c1c4d" }}></i>
                          </div>
                        )}
                      </div>
                      <div className="message-date">
                        {created_at ? moment(created_at).format('DD MMM YYYY, h:mm A') : ''}
                      </div>
                    </div>
                  );
                })}
                {!isFetching && chatViews.next_page_url && (
                  <div className="col-2 m-auto p-3 text-center">
                    <button onClick={loadMoreItemsList} className="btn btn-primary">
                      {t("More")}
                    </button>
                  </div>
                )}
              </InfiniteScroll>
            ) : (
              <>
                {chatApiStatus &&
                  chatApiStatus.ClientchatGridViewApi === "loading" ? (
                  <ApiStatus actionType="chatapiloading" />
                ) : (
                  <div className="complete-box text-center bg-pink d-flex flex-column justify-content-center my-md-5 my-4 bg-white">
                    <div className="complete-box-wrp text-center ">
                      <img src={config.imagepath + "user-girls.png"} alt="" className="mb-md-4 mb-3" />
                      <h4 className="mb-2 fw-semibold">
                        {t("No message history")}
                        <br /> {t("found")}.
                        <br />
                      </h4>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="drawer-footer chat-messages-drawer-footer overflow-x-hidden pt-0">
            <ClientChatForm />
          </div>
        </div>
      ) : (
        <div className="complete-box text-center bg-pink d-flex flex-column justify-content-center my-md-5 my-4 bg-white">
          <div className="complete-box-wrp text-center ">
            <img src={config.imagepath + "user-girls.png"} alt="" className="mb-md-4 mb-3" />
            <h4 className="mb-2 fw-semibold">
              {t("No mobile numbers have been")}
              <br /> {t("added yet")}.
              <br />
              <a className="add-subscription text-danger" onClick={() => dispatch(ClientDetailTab("clientdetail"))}>
                {t("Please add one.")}
              </a>
            </h4>
          </div>
        </div>
      )}
    </>
  );
};

export default Chats;