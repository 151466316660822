import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userApiController from "services/user.service";
import HandleError from "store/HandleError";
import HandleResponse from "store/HandleResponse";
// import custompagesApiController from "../../services/custompages.service";
// import HandleError from "../HandleError";
// import HandleResponse from "../HandleResponse";

// export const CustompagesApi = createAsyncThunk("custompages/view", async (formValues, thunkAPI) => {
//   try {
//     const resposedata = await custompagesApiController
//       .view(formValues, thunkAPI)
//       .then((response) => HandleResponse(thunkAPI, response, "view"))
//       .catch((error) => HandleError(thunkAPI, error, "view"));
//     return resposedata;
//   } catch (error) {
//     const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//     return thunkAPI.rejectWithValue(message);
//   }
// });

export const UserUpdateApi = createAsyncThunk("credential/userupdate", async (formValues, thunkAPI) => {
  try {
    const resposedata = await userApiController
      .userupdate(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "userupdate"))
      .catch((error) => HandleError(thunkAPI, error, "userupdate"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ChangeEmailApi = createAsyncThunk("credential/changeemail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await userApiController
      .changeemail(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "changeemail"))
      .catch((error) => HandleError(thunkAPI, error, "changeemail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ChangePasswordApi = createAsyncThunk("credential/changepassword", async (formValues, thunkAPI) => {
  try {
    const resposedata = await userApiController
      .changepassword(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "changepassword"))
      .catch((error) => HandleError(thunkAPI, error, "changepassword"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isEmailEditForm: "",
  isPasswordEditForm: "",
  isUserUpdate: "",
  isChangeEmail: "",
  isChangePassword: "",
  isApiStatus: {
    CustompagesApi: "",
    UserUpdateApi: "",
    ChangeEmailApi: "",
    ChangePasswordApi: "",
  },
};

const credentialSlice = createSlice({
  name: "credential",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenEmailEditForm: (state, action) => {
      state.isEmailEditForm = action.payload;
    },
    OpenPasswordEditForm: (state, action) => {
      state.isPasswordEditForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UserUpdateApi.pending, (state) => { })
      .addCase(UserUpdateApi.fulfilled, (state, action) => {
        state.isUserUpdate = action.payload;
      })
      .addCase(UserUpdateApi.rejected, (state) => { })
      .addCase(ChangeEmailApi.pending, (state) => { })
      .addCase(ChangeEmailApi.fulfilled, (state, action) => {
        state.isChangeEmail = action.payload;
      })
      .addCase(ChangeEmailApi.rejected, (state) => {
        state.isChangeEmail = "";
      })
      .addCase(ChangePasswordApi.pending, (state) => { })
      .addCase(ChangePasswordApi.fulfilled, (state, action) => {
        state.isChangePassword = action.payload;
      })
      .addCase(ChangePasswordApi.rejected, (state) => {
        state.isChangePassword = "";
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, OpenEmailEditForm, OpenPasswordEditForm } = credentialSlice.actions;
export default credentialSlice.reducer;
