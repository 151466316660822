import React, { useState, useEffect } from "react";
import { SalonModule } from "pages";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import config from "../../../config";
import PackagePaymentForm from "./PackagePaymentForm";
import getStripe from "pages/getStripe";
import { Elements } from "@stripe/react-stripe-js";
import { PackagePaymentModal } from "store/slices/packageSlice";

const PackagePayment = () => {
  SalonModule();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const stripePromise = getStripe();
  const rightDrawerOpened = useSelector((state) => state.package.isPackageModal);
  return (
    <div className={"full-screen-drawer p-0 package-selects-drawer " + rightDrawerOpened} id="package-selects">
      <div className="drawer-wrp position-relative">
        <div className="drawer-header px-md-4 px-3 py-3 d-flex flex-wrap align-items-center">
          <h3 className="mb-0 fw-semibold">{t("Activate your Account")}</h3>
          <div className="ms-auto">
            <a className="close btn btn-cancel me-1 cursor-pointer" onClick={() => dispatch(PackagePaymentModal(""))}>
              {t("Cancel")}
            </a>
          </div>
        </div>
        <div className="drawer-body">
          <div className="">
            <div className="plane-selection-head-wrapper h-100">
              <div className="package-image-position-basic">
                <div className="package-image-displays-positions">
                  {/* <img src={config.imagepath + ""} alt="image" className="img-fluid" /> */}
                </div>
                <div className="col-12 page-package-desc">
                  <h5 className="mb-1">Complete payment to activate your account.</h5>
                  <p>Activate your account now. You can upgrade or downgrade your account at any time.</p>
                </div>
                <Elements stripe={stripePromise}>
                  <PackagePaymentForm />
                </Elements>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagePayment;
