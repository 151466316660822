import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const line = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  const daterange = values && values.daterange ? values.daterange.split(" - ") : "";
  for (let value in values) {
    if (["label"].includes(value)) {
      let label = "Today";
      if (daterange && daterange[0] == daterange[1]) {
        label = "Today";
      } else {
        label = values[value] ? values[value] : "Today";
      }
      formData.append(value, label);
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/chart/line`;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const chartApiController = {
  line,
};
export default chartApiController;
