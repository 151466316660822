import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import pricetierApiController from "../../services/pricetier.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const pricetierStoreApi = createAsyncThunk("pricetier/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await pricetierApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const pricetierUpdateApi = createAsyncThunk("pricetier/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await pricetierApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const pricetierGridViewApi = createAsyncThunk("pricetier/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await pricetierApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const pricetierOptions = createAsyncThunk("pricetier/pricetierOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await pricetierApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "pricetierOptions"))
      .catch((error) => HandleError(thunkAPI, error, "pricetierOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const pricetierDetailApi = createAsyncThunk("pricetier/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await pricetierApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const pricetierDeleteApi = createAsyncThunk("pricetier/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await pricetierApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedDetailModal: "",
  isGridView: [],
  isDetailData: "",
  isSearchList: "",
  isSearchName: "",
  ispriceTierServicePriceModal: "",
  isPriceTierOption: [],
  ispriceTierNameService: "",
  isApiStatus: {
    pricetierStoreApi: "",
    pricetierUpdateApi: "",
    pricetierGridViewApi: "",
    pricetierDetailApi: "",
    pricetierDeleteApi: "",
    pricetierOptions: "",
  },
};

const pricetierSlice = createSlice({
  name: "pricetier",
  initialState,
  reducers: {
    reset: () => initialState,
    openAddPriceTierForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = "open";
    },
    closeAddPriceTierForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = "";
    },
    openEditPriceTierForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "open";
    },
    closeEditPriceTierForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
    },
    openPriceTierDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "open";
    },
    closePriceTierDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "";
    },
    openPriceTierSearchList: (state) => {
      state.isSearchList = "open";
    },
    closePriceTiersearchList: (state) => {
      state.isSearchList = "";
    },
    priceTierSearchName: (state, action) => {
      state.isSearchName = action.payload;
    },
    priceTierServicePriceModal: (state, action) => {
      state.ispriceTierServicePriceModal = action.payload;
    },
    priceTierNameService: (state, action) => {
      state.ispriceTierNameService = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(pricetierStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierStoreApi: "loading" };
      })
      .addCase(pricetierStoreApi.fulfilled, (state, action) => {
        if (state.isGridView && state.isGridView.data) {
          state.isGridView.data = [action.payload, ...state.isGridView.data];
        } else {
          state.isGridView = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, pricetierStoreApi: "succeeded" };
      })
      .addCase(pricetierStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierStoreApi: "failed" };
      })
      .addCase(pricetierUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierUpdateApi: "loading" };
      })
      .addCase(pricetierUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isGridView.data.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, pricetierUpdateApi: "succeeded" };
      })
      .addCase(pricetierUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierUpdateApi: "failed" };
      })
      .addCase(pricetierGridViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierGridViewApi: "loading" };
      })
      .addCase(pricetierGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isGridView.current_page ? state.isGridView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isGridView && state.isGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isGridView = action.payload;
        state.isApiStatus = { ...state.isApiStatus, pricetierGridViewApi: "succeeded" };
      })
      .addCase(pricetierGridViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierGridViewApi: "failed" };
        state.isGridView = [];
      })
      .addCase(pricetierDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierDetailApi: "loading" };
      })
      .addCase(pricetierDetailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierDetailApi: "succeeded" };
        state.isDetailData = action.payload;
      })
      .addCase(pricetierDetailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierDetailApi: "failed" };
        state.isDetailData = "";
      })
      .addCase(pricetierDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierDeleteApi: "loading" };
      })
      .addCase(pricetierDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isApiStatus = { ...state.isApiStatus, pricetierDeleteApi: "succeeded" };
        state.isGridView.data = state.isGridView.data ? state.isGridView.data.filter((item) => item.id != id) : state.isGridView.filter((item) => item.id != id);
      })
      .addCase(pricetierDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierDeleteApi: "failed" };
      })
      .addCase(pricetierOptions.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierOptions: "loading" };
      })
      .addCase(pricetierOptions.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierOptions: "succeeded" };
        state.isPriceTierOption = action.payload;
      })
      .addCase(pricetierOptions.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, pricetierOptions: "failed" };
        state.isPriceTierOption = [];
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, openAddPriceTierForm, closeAddPriceTierForm, openEditPriceTierForm, closeEditPriceTierForm, openPriceTierDetailModal, closePriceTierDetailModal, openPriceTierSearchList, closePriceTiersearchList, priceTierSearchName, priceTierServicePriceModal, priceTierNameService } = pricetierSlice.actions;
export default pricetierSlice.reducer;
