import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import config from "../../../../config";
import { ClientSearchName, ClientSuggetionListApi, CloseClientSearchList, OpenClientSearchList, SearchBox } from "store/slices/headerSlice";

import InfiniteScroll from "react-infinite-scroll-component";
import PaginationLoader from "component/PaginationLoader";
import ClientSuggetionListView from "./List/ClientSuggetionListView";
import ClientDetailModal from "pages/clients/Detail";
import BeatLoader from "react-spinners/BeatLoader";

const SearchSection = () => {
  // const auth = useSelector((state) => state.auth);
  // const currentUser = auth.user;
  // const role_id = currentUser && currentUser.role_id;
  // const is_owner = currentUser && currentUser.is_owner;
  // const access = useSelector((state) => state.salonmodule.isAccess);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isSearchbox = useSelector((state) => state.header.isSearchBox);
  //client search
  const isSearchList = useSelector((state) => state.header.isSearchList);
  const isSearchName = useSelector((state) => state.header.isSearchName);
  const isSuggetionListView = useSelector((state) => state.header.isSuggetionListView);
  const SuggetionApiStatus = useSelector((state) => state.header.isApiStatus);
  const clientIsOpenedDetailModal = useSelector((state) => state.client.isOpenedDetailModal);

  useEffect(() => {
    dispatch(SearchBox(""));
  }, []);

  const fetchDataSuggetionList = () => {
    dispatch(ClientSuggetionListApi({ next_page_url: isSuggetionListView.next_page_url, q: isSearchName }));
  };

  const handleClickSearch = (e) => {
    let q = e.currentTarget.value;
    if (q && q.length > 0) {
      dispatch(OpenClientSearchList());
      dispatch(ClientSuggetionListApi({ q: q }));
    }
  };
  const handleKeyUpSearch = (e) => {
    let q = e.currentTarget.value;
    dispatch(ClientSearchName(q));
    if (q && q.length > 0) {
      dispatch(OpenClientSearchList());
      dispatch(ClientSuggetionListApi({ q: q }));
    } else {
      dispatch(CloseClientSearchList());
    }
  };
  const handleCloseSearch = () => {
    dispatch(ClientSearchName(""));
    dispatch(CloseClientSearchList());
    dispatch(SearchBox(""));
  };
  const handleOnBlur = () => {
    // setTimeout(() => {
    //   dispatch(CloseClientSearchList());
    // }, 200);
  };
  return (
    <>
      <div className="search">
        {(!isSearchbox || isSearchbox == undefined || isSearchbox == "") && (
          <a className="search-icon" onClick={() => dispatch(SearchBox("open"))}>
            <img src={config.imagepath + "search.png"} alt="" />
          </a>
        )}
        {isSearchbox && (
          <div className={"search-wrapper " + isSearchbox}>
            <form>
              <div className="searchbox">
                <i className="fa fa-search"></i>
                <input type="text" className="form-control search-input" placeholder={t("Search Clients")} value={isSearchName} onInput={(e) => dispatch(ClientSearchName(e.target.value))} onClick={handleClickSearch} onKeyUp={handleKeyUpSearch} onBlur={handleOnBlur} />
                <a className="close cursor-pointer" style={{ display: isSearchName ? "block" : "block" }} onClick={handleCloseSearch}>
                  <i className="fal fa-times"></i>
                </a>
              </div>
              <div className={"search-result dropdown-box " + isSearchList}>
                <InfiniteScroll className="" dataLength={isSuggetionListView && isSuggetionListView.data && isSuggetionListView.data.length ? isSuggetionListView.data.length : "0"} next={fetchDataSuggetionList} scrollableTarget="search-content" hasMore={isSuggetionListView.next_page_url ? true : false}>
                  {SuggetionApiStatus && SuggetionApiStatus.ClientSuggetionListApi === "loading" ? (
                    <BeatLoader color="#8c1c4d" className="text-center" />
                  ) : (
                    <ul className="p-0 m-0 list-unstyled">
                      <ClientSuggetionListView view={isSuggetionListView} page="header" />
                    </ul>
                  )}
                </InfiniteScroll>
              </div>
            </form>
          </div>
        )}
      </div>
      {clientIsOpenedDetailModal ? <ClientDetailModal /> : ""}
    </>
  );
};

export default SearchSection;
