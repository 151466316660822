import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (values) => {
  const action = API_URL + "login";
  if (values && values.token) {
    const token = values.token;
    return axios.post(action, {
      action: "login",
      token,
    });
  } else {
    const formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }
    formData.append('action',action)
    return axios.post(action, formData);
  }
};

const twofactorauthentications = (values) => {
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "beforelogin/twofactorlogin";
  return axios.post(API_URL + action, formData);
};

const salonselection = (values) => {
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "beforelogin/salonoptions";
  return axios.post(API_URL + action, formData);
};

const logout = () => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  return axios.post(
    API_URL + "afterlogin/logout",
    {
      action: "logout",
      auth_key,
    },
    { headers: authHeader() },
  );
};

const getUser = (authenticate) => {
  const token = authenticate.token;
  const auth_key = authenticate.auth_key;
  const action = "afterlogin/user";
  const data = {
    action: action,
    auth_key,
  };
  return axios.post(API_URL + action, data, { headers: authHeader({ token: token }) });
};

const forgotpassowrdsubmit = (values) => {
  const action = "beforelogin/forgotpassword";
  return axios.post(API_URL + action, {
    action: "afterlogin/forgotpassword",
    email: values.email,
    salon_id : values && values.salon_id ? values.salon_id : "",
  });
};

const forgotPasswordSalonSelect = (values) => {
  const formData = new FormData();
  for (let value in values) {
      formData.append(value, values[value]);
  }
  const action = "beforelogin/forgotpasswordsalonoptions";
  return axios.post(API_URL + action, formData);
};

const AuthService = {
  register,
  login,
  logout,
  getUser,
  forgotpassowrdsubmit,
  twofactorauthentications,
  salonselection,
  forgotPasswordSalonSelect
};
export default AuthService;
