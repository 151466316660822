import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import membershipApiController from "../../services/membership.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const MembershipStoreApi = createAsyncThunk("membership/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await membershipApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const MembershipUpdateApi = createAsyncThunk("membership/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await membershipApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const MembershipGridViewApi = createAsyncThunk("membership/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await membershipApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const MembershipOptions = createAsyncThunk("membership/MembershipOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await membershipApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "MembershipOptions"))
      .catch((error) => HandleError(thunkAPI, error, "MembershipOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const MembershipDetailApi = createAsyncThunk("membership/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await membershipApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const MembershipDeleteApi = createAsyncThunk("membership/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await membershipApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const MembershipServiceApi = createAsyncThunk("membership/services", async (formValues, thunkAPI) => {
  try {
    const resposedata = await membershipApiController
      .services(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "services"))
      .catch((error) => HandleError(thunkAPI, error, "services"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const MembershipServiceCartApi = createAsyncThunk("membership/membershipservicecart", async (formValues, thunkAPI) => {
  try {
    const resposedata = await membershipApiController
      .services(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "membershipservicecart"))
      .catch((error) => HandleError(thunkAPI, error, "membershipservicecart"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const MembershipSuggetionListApi = createAsyncThunk("membership/suggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await membershipApiController
      .suggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedDetailModal: "",
  isGridView: [],
  isSuggetionListView: [],
  isDetailData: "",
  isSearchList: "",
  isSearchName: "",
  isSearchObj: "",
  isMembershipOption: [],
  isServices: [],
  isMembershipServices: [],
  isSort: [],
  isApiStatus: {
    MembershipStoreApi: "",
    MembershipUpdateApi: "",
    MembershipGridViewApi: "",
    MembershipDetailApi: "",
    MembershipDeleteApi: "",
    MembershipOptions: "",
    MembershipServiceApi: "",
    MembershipServiceCartApi: "",
    MembershipSuggetionListApi: "",
  },
};

const membershipSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {
    reset: () => initialState,
    OpenAddMembershipForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = "open";
    },
    CloseAddMembershipForm: (state = initialState) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = "";
    },
    OpenEditMembershipForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "open";
    },
    CloseEditMembershipForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
    },
    OpenMembershipDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "open";
    },
    CloseMembershipDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedDetailModal = "";
    },
    MembershipServiceRemoveToCart: (state, action) => {
      const { id } = action.payload;
      state.isMembershipServices = state.isMembershipServices ? state.isMembershipServices.filter((item) => item.id != id) : [];
    },
    EditMembershipServiceCartApi: (state, action) => {
      state.isMembershipServices = action.payload;
    },
    membershipSort: (state, action) => {
      // let sort = state.isSort ? state.isSort : {};
      // state.isSort = Object.assign(sort, action.payload);
      state.isSort = action.payload;
    },
    membershipSortRemove: (state) => {
      state.isSort = "";
    },
    OpenMembershipSearchList: (state) => {
      state.isSearchList = "open";
    },
    CloseMembershipSearchList: (state) => {
      state.isSearchList = "";
    },
    MembershipSearchName: (state, action) => {
      state.isSearchName = action.payload;
    },
    MembershipSearchObj: (state, action) => {
      state.isSearchObj = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(MembershipStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipStoreApi: "loading" };
      })
      .addCase(MembershipStoreApi.fulfilled, (state, action) => {
        if (state.isGridView && state.isGridView.data) {
          state.isGridView.data = [action.payload, ...state.isGridView.data];
        } else {
          state.isGridView = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, MembershipStoreApi: "succeeded" };
      })
      .addCase(MembershipStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipStoreApi: "failed" };
      })
      .addCase(MembershipUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipUpdateApi: "loading" };
      })
      .addCase(MembershipUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isGridView.data.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, MembershipUpdateApi: "succeeded" };
      })
      .addCase(MembershipUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipUpdateApi: "failed" };
      })
      .addCase(MembershipGridViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipGridViewApi: "loading" };
      })
      .addCase(MembershipGridViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isGridView.current_page ? state.isGridView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isGridView && state.isGridView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isGridView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, MembershipGridViewApi: "succeeded" };
        state.isGridView = action.payload;
      })
      .addCase(MembershipGridViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipGridViewApi: "failed" };
        state.isGridView = [];
      })
      .addCase(MembershipDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipDetailApi: "loading" };
      })
      .addCase(MembershipDetailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipDetailApi: "succeeded" };
        state.isDetailData = action.payload;
      })
      .addCase(MembershipDetailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipDetailApi: "failed" };
        state.isDetailData = "";
      })
      .addCase(MembershipDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipDeleteApi: "loading" };
      })
      .addCase(MembershipDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isGridView.data = state.isGridView.data ? state.isGridView.data.filter((item) => item.id != id) : state.isGridView.filter((item) => item.id != id);
        state.isApiStatus = { ...state.isApiStatus, MembershipDeleteApi: "succeeded" };
      })
      .addCase(MembershipDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipDeleteApi: "failed" };
      })
      .addCase(MembershipOptions.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipOptions: "loading" };
      })
      .addCase(MembershipOptions.fulfilled, (state, action) => {
        state.isMembershipOption = action.payload;
        state.isApiStatus = { ...state.isApiStatus, MembershipOptions: "succeeded" };
      })
      .addCase(MembershipOptions.rejected, (state) => {
        state.isMembershipOption = [];
        state.isApiStatus = { ...state.isApiStatus, MembershipOptions: "failed" };
      })
      .addCase(MembershipServiceApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipServiceApi: "loading" };
      })
      .addCase(MembershipServiceApi.fulfilled, (state, action) => {
        let old_current_page = state.isServices.current_page ? state.isServices.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isServices && state.isServices.data;
        let newviewdata = action.payload && action.payload.data;
        state.isServices = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isServices.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, MembershipServiceApi: "succeeded" };
        state.isServices = action.payload;
      })
      .addCase(MembershipServiceApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipServiceApi: "failed" };
        state.isServices = [];
      })
      .addCase(MembershipServiceCartApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipServiceCartApi: "loading" };
      })
      .addCase(MembershipServiceCartApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isMembershipServices && state.isMembershipServices.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        } else {
          const membership = [...state.isMembershipServices, action.payload];
          state.isMembershipServices = membership;
        }
        state.isApiStatus = { ...state.isApiStatus, MembershipServiceCartApi: "succeeded" };
      })
      .addCase(MembershipServiceCartApi.rejected, (state) => {
        state.isMembershipServices = "";
        state.isApiStatus = { ...state.isApiStatus, MembershipServiceCartApi: "failed" };
      })
      .addCase(MembershipSuggetionListApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipSuggetionListApi: "loading" };
      })
      .addCase(MembershipSuggetionListApi.fulfilled, (state, action) => {
        let old_current_page = state.isSuggetionListView.current_page ? state.isSuggetionListView.current_page : "";
        let new_current_page = action.payload.current_page ? action.payload.current_page : "";
        let viewdata = state.isSuggetionListView && state.isSuggetionListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isSuggetionListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
          viewdata && newviewdata ? (state.isSuggetionListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, MembershipSuggetionListApi: "succeeded" };
        state.isSuggetionListView = action.payload;
      })
      .addCase(MembershipSuggetionListApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, MembershipSuggetionListApi: "failed" };
        state.isSuggetionListView = [];
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, OpenAddMembershipForm, CloseAddMembershipForm, OpenEditMembershipForm, CloseEditMembershipForm, OpenMembershipDetailModal, CloseMembershipDetailModal, MembershipServiceRemoveToCart, EditMembershipServiceCartApi, membershipSort, membershipSortRemove, OpenMembershipSearchList, CloseMembershipSearchList, MembershipSearchName, MembershipSearchObj } = membershipSlice.actions;
export default membershipSlice.reducer;
