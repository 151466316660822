import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import signupApiController from "services/signup.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const SignupStoreApi = createAsyncThunk("signup/create", async (formValues, thunkAPI) => {
  try {
    const resposedata = await signupApiController
      .create(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CheckExistApi = createAsyncThunk("signup/check", async (formValues, thunkAPI) => {
  try {
    const resposedata = await signupApiController
      .checkexist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "check"))
      .catch((error) => HandleError(thunkAPI, error, "check"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const IsExistApi = createAsyncThunk("signup/isexist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await signupApiController
      .isexist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "isexist"))
      .catch((error) => HandleError(thunkAPI, error, "isexist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isSignupStep: 1,
  isVerifyEmail: "",
  isVerifyEmailForm: "",
  isResendOtp: "",
  isTermsModal: "",
  isResendSmsOtp: "",
  isVerifySmsForm: "",
  isVerifySms: "",
  isExistError: { email: "", business_name: "", business_phone_number: "", sms: "" },
  isExistApi: "",
  isCustomAccept: { terms: "", policy: "" },
  isApiStatus: {
    SignupStoreApi: "",
    CheckExistApi: "",
    IsExistApi: "",
  },
};

const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    reset: () => initialState,
    PreviewStep: (state, action) => {
      state.isSignupStep = action.payload - 1;
    },
    NextStep: (state, action) => {
      state.isSignupStep = action.payload + 1;
    },
    VerifyEmailForm: (state, action) => {
      state.isVerifyEmailForm = action.payload;
    },
    VerifySmsForm: (state, action) => {
      state.isVerifySmsForm = action.payload;
    },
    VerifyEmail: (state, action) => {
      state.isVerifyEmail = action.payload;
    },
    VerifySms: (state, action) => {
      state.isVerifySms = action.payload;
    },
    ResendOtp: (state, action) => {
      state.isResendOtp = action.payload;
    },
    ResendSmsOtp: (state, action) => {
      state.isResendSmsOtp = action.payload;
    },
    TermsModal: (state, action) => {
      state.isTermsModal = action.payload;
    },
    CustomAccept: (state, action) => {
      state.isCustomAccept = { ...state.isCustomAccept, ...action.payload };
    },
    ExistError: (state, action) => {
      state.isExistError = { ...state.isExistError, ...action.payload };
    },
    StepResets: (state) => {
      state.isSignupStep = 1;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(SignupStoreApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SignupStoreApi: "loading" };
    })
    .addCase(SignupStoreApi.fulfilled, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SignupStoreApi: "succeeded" };
    })
    .addCase(SignupStoreApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, SignupStoreApi: "failed" };
    })
    .addCase(CheckExistApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, CheckExistApi: "loading" };
    })
    .addCase(CheckExistApi.fulfilled, (state) => {
      state.isApiStatus = { ...state.isApiStatus, CheckExistApi: "succeeded" };
    })
    .addCase(CheckExistApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, CheckExistApi: "failed" };
    })
    .addCase(IsExistApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, IsExistApi: "loading" };
    })
    .addCase(IsExistApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, IsExistApi: "succeeded" };
      state.isExistApi = action.payload;
    })
    .addCase(IsExistApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, IsExistApi: "failed" };
    });
  },
});

// Action creators are generated for each case reducer function
export const { reset, PreviewStep, NextStep, VerifyEmailForm, VerifyEmail, ResendOtp, TermsModal, CustomAccept, ResendSmsOtp, ExistError, StepResets, VerifySmsForm, VerifySms } = signupSlice.actions;
export default signupSlice.reducer;
