import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import calendarApiController from "services/calendar.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const CalendarStaffListApi = createAsyncThunk("calendar/calendarStaffList", async (formValues, thunkAPI) => {
  try {
    const resposedata = await calendarApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "calendarStaffList"))
      .catch((error) => HandleError(thunkAPI, error, "calendarStaffList"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const CalendarResourceListApi = createAsyncThunk("calendar/calendarStaffAndResourceList", async (formValues, thunkAPI) => {
  try {
    const resposedata = await calendarApiController
      .resourceitemview(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "calendarStaffAndResourceList"))
      .catch((error) => HandleError(thunkAPI, error, "calendarStaffAndResourceList"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isTabView: "day",
  isRangeInfo: "",
  isStaffFilter: "",
  isClickEventPopoverData: "",
  isCalendarStaffList: [],
  isCalendarResourceList: [],
  isCalendarSelectEvent: "",
  isSmsRunOutAlerts: "",
  isOpenCalendarPrintModal:"",
  isAppointmentPrintViewDrawer:"",
  isAppointmentPrintViewDrawerData:{},
  isApiStatus: {
    CalendarStaffListApi: "",
  },
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    calendarTabWeekView: (state, action) => {
      state.isTabView = "week";
    },
    calendarTabDayView: (state, action) => {
      state.isTabView = "day";
    },
    calendarRangeInfo: (state, action) => {
      state.isRangeInfo = action.payload;
    },
    calendarStaffFilter: (state, action) => {
      state.isStaffFilter = action.payload;
    },
    calendarResetStaffFilter: (state) => {
      state.isStaffFilter = "";
    },
    CalendarEventPopover: (state, action) => {
      state.isClickEventPopoverData = action.payload;
    },
    CalendarSelectEvent: (state, action) => {
      state.isCalendarSelectEvent = action.payload;
    },
    OpenSmsRunOutAlerts: (state, action) => {
      state.isSmsRunOutAlerts = action.payload;
    },
    OpenCalendarPrintModal: (state, action) => {
      state.isOpenCalendarPrintModal = action.payload;
    },
    AppointmentPrintViewDrawerData: (state, action) => {
      state.isAppointmentPrintViewDrawerData = action.payload;
    },
    AppointmentPrintViewDrawer: (state, action) => {
      state.isAppointmentPrintViewDrawer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CalendarStaffListApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CalendarStaffListApi: "loading" };
      })
      .addCase(CalendarStaffListApi.fulfilled, (state, action) => {
        state.isCalendarStaffList = action.payload;
        state.isApiStatus = { ...state.isApiStatus, CalendarStaffListApi: "succeeded" };
      })
      .addCase(CalendarStaffListApi.rejected, (state) => {
        state.isCalendarStaffList = [];
        state.isApiStatus = { ...state.isApiStatus, CalendarStaffListApi: "failed" };
      })
      .addCase(CalendarResourceListApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, CalendarResourceListApi: "loading" };
      })
      .addCase(CalendarResourceListApi.fulfilled, (state, action) => {
        state.isCalendarResourceList = action.payload;
        state.isApiStatus = { ...state.isApiStatus, CalendarResourceListApi: "succeeded" };
      })
      .addCase(CalendarResourceListApi.rejected, (state) => {
        state.isCalendarResourceList = [];
        state.isApiStatus = { ...state.isApiStatus, CalendarResourceListApi: "failed" };
      });
  },
});

// Action creators are generated for each case reducer function
export const { CalendarSelectEvent, calendarTabWeekView, calendarTabDayView, calendarRangeInfo, calendarStaffFilter, calendarResetStaffFilter, CalendarEventPopover, OpenSmsRunOutAlerts,OpenCalendarPrintModal,AppointmentPrintViewDrawer,AppointmentPrintViewDrawerData } = calendarSlice.actions;
export default calendarSlice.reducer;
