import { lazy } from "react";

// project imports
import Loadable from "../component/Loadable";
import MinimalLayout from "../layout/MinimalLayout";
import GuestGuard from "./../utils/route-guard/GuestGuard";
import config from "./../config";
// login option 3 routing
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
// const TwoStepAuth = Loadable(lazy(() => import("../pages/auth/TwoStepAuth")));
const Signup = Loadable(lazy(() => import("../pages/Signup")));
const NoMatch = Loadable(lazy(() => import("../pages/errors/NoMatch")));
const ForgotPassword = Loadable(lazy(() => import("../pages/auth/ForgotPassword")));
const TermsAndConditionHyperLink = Loadable(lazy(() => import("../pages/Signup/hyperlink")));
// const Booking = Loadable(lazy(() => import("../pages/Booking")));

// import { useSelector } from "react-redux";
// const { isLoggedIn } = useSelector((state) => state.auth);

const AuthenticationRoutes = {
  path: config.basePath + "/",
  element: (
    <GuestGuard>
      <MinimalLayout />
    </GuestGuard>
  ),
  children: [
    { path: config.basePath + "/login", element: <Login /> },
    // { path: config.basePath + "/authentication", element: <TwoStepAuth /> },
    { path: config.basePath + "/login/:token", element: <Login /> },
    { path: config.basePath + "/signup", element: <Signup /> },
    { path: config.basePath + "/forgotpassowrd", element: <ForgotPassword /> },
    { path: config.basePath + "/termsandconditions", element: <TermsAndConditionHyperLink /> },
    // { path: config.basePath + "/booking/:title/:id", element: <Booking /> },
    { path: "*", element: <NoMatch /> },
  ],
};

export default AuthenticationRoutes;
