import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/clientnote/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  formData.append("client_id", values.client_id);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const update = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/clientnote/update/" + values.id;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  formData.append("client_id", values.client_id);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  let pagination = true;
  if(values){
    if(values.id){
      pagination = false;
    }
    if(values.option){
      pagination = false;
    }
    if(values.pagination){
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/clientnote/view?page=${page}` : `afterlogin/clientnote/view`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: pagination, //true or false
    id: values && values.id ? values.id : "",
    client_id: values && values.client_id ? values.client_id : "",
    result: result, //business_name,owner_name
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const deleted = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/clientnote/delete/${values.id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const clientnoteimport = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/client/clientimportnote`;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  // formData.append("excelfile", values.excelfile);
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const clientnoteexport = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/client/clientexportnote`;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  // formData.append("excelfile", values.excelfile);
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const samplenoteexports = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `common/sampleclientnoteexport`;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const clientnoteApiController = {
  create,
  update,
  view,
  deleted,
  clientnoteimport,
  samplenoteexports,
  clientnoteexport
};
export default clientnoteApiController;
